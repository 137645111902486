import PropTypes from 'prop-types';

import { Ring } from './AccessibleRing.styles';

/** Puts blue ring around parent for keyboard navigation when a specified sibling (which must precede this element) is in focus. Parent must have `position: relative`. */
const AccessibleRing = ({ tag = 'input', borderRadius = 4, ...props }) => {
    return <Ring {...props} $tag={tag} $borderRadius={borderRadius} />;
};

AccessibleRing.propTypes = {
    /** HTML tag of the sibling which must be in focus for blue ring to appear. */
    tag: PropTypes.string,
    /** Border radius in px of blue ring. */
    borderRadius: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default AccessibleRing;
