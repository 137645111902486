import styled from 'styled-components';

export const PageContent = styled.main`
    width: 100%;
    margin: ${({ $mobileApp }) =>
        $mobileApp
            ? 0
            : '85px auto 25px'}; /* Allow for fixed navbar at top which has height of 60px */
    overflow: hidden;
    position: relative;
    flex-grow: 1;
`;

PageContent.displayName = 'PageContent';
