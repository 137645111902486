import styled from 'styled-components';
import InputField from '../Input';
import {
    FIELD_WIDTH,
    hideSpinButtons,
    NARROW_FIELD_WIDTH,
} from '../../helpers';
import { VL_BLACK, WHITE } from '../../../../utils/colours';

const controlsMobileBreakpoint = '600px';

// In px
const controlButtonWidth = 24;
const controlsGap = 5; // Gap between button and input field

export const Input = styled(InputField)`
    width: ${({ fullWidth, narrowWidth, setWidth }) => {
        const controlsWidth = (controlButtonWidth + controlsGap) * 2;
        let wd = setWidth ? 
            `${setWidth}px` :
            fullWidth ? 
            `calc(100% - ${controlsWidth}px)` : 
            narrowWidth ? `${NARROW_FIELD_WIDTH}px` : 
            `${FIELD_WIDTH - controlsWidth}px`;
        wd = wd + ' !important';
        return wd;
    }}; /* Make sure this overrules whatever width is in Input */

    @media only screen and (min-width: ${controlsMobileBreakpoint}) {
        ${hideSpinButtons}
    }
`;

Input.displayName = 'Input';

export const ControlsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: ${controlsGap}px;
    align-items: center;
`;

ControlsContainer.displayName = 'ControlsContainer';

export const AdjustNumberButton = styled.button`
    display: none;

    @media only screen and (min-width: ${controlsMobileBreakpoint}) {
        background: none;
        border: none;
        color: ${VL_BLACK()};
        font-size: 18px;
        font-weight: 100;
        height: ${controlButtonWidth}px;
        width: ${controlButtonWidth}px;
        position: relative;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        ::before {
            content: '';
            background: ${({ $colour }) => $colour ? $colour : WHITE(75)};
            height: 18px;
            width: 18px;
            border-radius: 18px;
            position: absolute;
        }

        :not(:disabled):hover::before {
            background: ${({ $hoverColour }) => $hoverColour};
        }

        :disabled {
            cursor: not-allowed;
        }

        ::after {
            content: '${({ plus }) => (plus ? '+' : '-')}';
            z-index: 1; /* Place above ::before */
            ${({ plus }) => plus && `line-height: 18px;`}
        }
    }
`;

AdjustNumberButton.displayName = 'AdjustNumberButton';
