import PropTypes from 'prop-types';

import { areDatesEqual } from "../../../utils/datetime";

import { Calendar } from "../Picker";

// Picker for choosing a single date
const DatePicker = ({
    value,
    onChange,
    min,
    max,
    ...props
}) => {

    return (
        <Calendar
            defaultDateInView={value}
            minDateTime={min}
            maxDateTime={max}
            isDateSelected={date => value && areDatesEqual(date, value)}
            onDateClick={onChange}
        />
    );
}

DatePicker.propTypes = {
    /** Selected date value. */
    value: PropTypes.instanceOf(Date),
    /** Invoked when new date selected. Gets passed a new Date instance. */
    onChange: PropTypes.func.isRequired,
    /** Minimum allowed date value. Time component of Date instance will be ignored. */
    min: PropTypes.instanceOf(Date),
    /** Maximum allowed date value. Time component of Date instance will be ignored. */
    max: PropTypes.instanceOf(Date)
};

export default DatePicker;