import styled from 'styled-components';

import { VL_BLACK } from '../../utils/colours';

export const ZoomButtonsContainer = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    // These buttons are quite big for touch screens
    // Touch screen users are used to panning, pinching and zooming without prompt anyway
    @media (pointer: coarse) {
        display: none;
    }
`;

ZoomButtonsContainer.displayName = 'ZoomButtonsContainer';


const zoomButtonDiamter = 28;
export const ZoomButton = styled.button.attrs({ type: 'button' })`
    width: ${zoomButtonDiamter}px;
    height: ${zoomButtonDiamter}px;
    background: #fff;
    border: none;
    padding: 0;
    border-radius: ${zoomButtonDiamter}px;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    opacity: 0.7;

    svg {
        color: ${VL_BLACK()};
        width: auto;
        height: auto;
        max-width: 16px;
        max-height: 16px;

        // Need all mouse events to register against the button for logic in VideoPlayer mouse listeners
        pointer-events: none;

        /* Safari needs this. */
        flex-grow: 1;
    }
    
    @media (hover: hover) {
        :hover {
            opacity: 1;
        }
    }

    :focus-visible {
        opacity: 1;
    }

    [disabled],
    &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
    }
`;

ZoomButton.displayName = 'ZoomButton';