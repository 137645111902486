import styled from 'styled-components';

import { inputStyling } from '../helpers';

export const Select = styled.select`
    ${(props) => inputStyling(props)}

    /* Already seems to have some padding so use lower value to make it look consistent with inputs */
    padding: 5px;

    &[disabled] {
        cursor: not-allowed;
    }

    /* Use min-content for narrow width version instead */
    ${({ narrowWidth }) => (narrowWidth ? `width: min-content;` : '')}
`;

Select.displayName = 'Select';

export const Option = styled.option``;

Option.displayName = 'Option';
