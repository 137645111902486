import { useState } from "react";
import { InputContainer } from '../Input.styles';
import PropTypes from 'prop-types';
import { OptionsBox, OptionsArrow, Option } from "./OptionsTextInput.styles";
import { Flex } from "../../../layout";

const OptionsTextInput = ({
    onChange,
    name,
    error = false,
    value = '',
    options,
    label,
    onBlur,
    onSubmit,
    submitDisabled,
    largeFontSize = false,
}) => {
    const [optionsVisibility, setOptionsVisibility] = useState(false);
    const [inputValue, setInputValue] = useState(value);

    return <div style={{position: 'relative'}}>
        <label htmlFor={name}>{label}</label>
        <Flex style={{marginTop: '20px'}}>
            <InputContainer 
                onChange={(e) => {onChange(e); setInputValue(e.target.value)}}
                name={name}
                id={name}
                error={error}
                value={inputValue}
                onBlur={onBlur}
                onKeyDown={(event) => {
                    if(event.key === 'Enter' && !submitDisabled) {
                        onSubmit();
                    }
                }}
                largeFontSize={largeFontSize}
            />
            {options && options.length > 0 && <OptionsArrow onClick={() => setOptionsVisibility(!optionsVisibility)}>{'\u2304'}</OptionsArrow>}
        </Flex>
        {optionsVisibility && options && 
            <OptionsBox>

                {
                    options.map(opt => 
                        <Option key={opt} onClick={() => {onChange({ target: { type: 'text', name: name, checked: false, value: opt } }); setInputValue(opt); setOptionsVisibility(false)}}>{opt}</Option>
                    )
                }
            </OptionsBox>
        }
    </div>
};


OptionsTextInput.propTypes = {
    /** Function to call whenever user changes the field's value. */
    onChange: PropTypes.func,
    /** Name of field. Used in `name` and `id` attribute. Must be unique to page. */
    name: PropTypes.string,
    /** Chosen value is invalid. Sets `aria-invalid` to true and renders red border around element. */
    error: PropTypes.bool,
    /** Value of input field. */
    value: PropTypes.string,
    /**  */
    options: PropTypes.arrayOf(PropTypes.string),
};


export default OptionsTextInput;