import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

import useQueryParams from '../../hooks/useQueryParams';
import useWhiteLabelComponent from '../../hooks/useWhiteLabelComponent';

import { Container, Tab } from './Tabs.styles';
import { isCCTVConnect } from '../../utils/getHost';

/** Renders row of tabs for switching between page content. */
const Tabs = ({ links, large = false, ...props }) => {
    const location = useLocation();
    const { tab, ...params } = useQueryParams();
    const { accentColour, accentColour2, useAccentColour2 } = useWhiteLabelComponent();

    return (
        <Container {...props}>
            {links.map(({ text, path, name, defaultTab }) => (
                <Tab
                    key={path ?? name}
                    to={
                        // Use path if provided
                        // Otherwise using query string, so link is current path with new tab in query string
                        path ??
                        `${location.pathname}?${qs.stringify({
                            ...params,
                            tab: name,
                        })}`
                    }
                    // Need to use isActive prop if managing tab through query string
                    isActive={
                        path
                            ? undefined
                            : () => (tab ? tab === name : defaultTab)
                    }
                    $accentColour={useAccentColour2 ? accentColour2 : accentColour}
                    $useDefault={isCCTVConnect}
                    activeClassName="selected"
                    $large={large}
                >
                    {text}
                </Tab>
            ))}
        </Container>
    );
};

Tabs.propTypes = {
    /** Links to go in tabs. Tabs will be rendered in the order specified by this array. */
    links: PropTypes.arrayOf(
        PropTypes.shape({
            /** Text for nav link. */
            text: PropTypes.string.isRequired,
            /** Link destination. Required when controlling tabs through pathname rather than query string. */
            path: PropTypes.string,
            /** Name to use in `tab` query param. Must be unique. Required when controlling tabs through query string rather than pathname. */
            name: PropTypes.string,
            /** Only for when controlling tabs in query string. When no `tab` param is present, this boolean dictates the default tab. Only one link can have `default: true`. */
            defaultTab: PropTypes.bool,
        })
    ).isRequired,
    /** Component has two possible sizes: normal or large. */
    large: PropTypes.bool,
};

export default Tabs;
