import PropTypes from 'prop-types';
// import useWhiteLabelComponent from '../../hooks/useWhiteLabelComponent';

import {
    FooterContainer,
    // LogoImage,
    LinksRow,
    FooterLink,
} from './Footer.styles';

/** Shown at bottom of every page that uses header/footer layout. */
const Footer = ({
    termsLinkText,
    privacyLinkText,
    termsLink,
    privacyLink,
    ...props
}) => {
    // const { fullScreenLayoutLogo, appLayoutLogo, appLayoutLogoSmall } = useWhiteLabelComponent();
    // const logo = fullScreenLayoutLogo ?? appLayoutLogo ?? appLayoutLogoSmall;
    return (
        <FooterContainer {...props}>
            {/* <LogoImage src={logo} alt="Logo" /> */}
            <LinksRow>
                <FooterLink href={termsLink}>{termsLinkText}</FooterLink>
                <FooterLink href={privacyLink}>{privacyLinkText}</FooterLink>
            </LinksRow>
        </FooterContainer>
    );
};

Footer.propTypes = {
    /** Text used for 'Terms of Service' link. */
    termsLinkText: PropTypes.string.isRequired,
    /** Text used for privacy policy link. */
    privacyLinkText: PropTypes.string.isRequired,
};

export default Footer;
