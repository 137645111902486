import PropTypes from 'prop-types';
import { useState } from 'react';
import { withTranslation } from 'react-i18next';

import translationKeys from '../../../../translations/keys';

import Input from '../Input';
import Icon from '../../../icon/Icon';
import {
    PasswordStrengthIndicator,
    PasswordStrengthIndicatorMeter,
    PasswordStrengthIndicatorMeterBar,
    TogglePasswordVisibility,
    PasswordStrengthIndicatorLabel,
    PasswordContainer
} from './PasswordInput.styles';
import { VL_BLACK, WHITE } from '../../../../utils/colours';
import useWhiteLabelComponent from '../../../../hooks/useWhiteLabelComponent';

/** Input field for passwords with a toggle to show or hide the password. When the 'isNew' flag is set and the field contains a value, a password strength indicator is shown giving a rating of 'Too short' (< 8 characters), 'Weak', 'Good' or 'Strong'. A 'Strong' password contains a lowercase letter, uppercase letter, number and special character. A 'weak' password contains one or two of those requirements whilst a 'good' password has three. */
const PasswordInput = ({
    value,
    large = false,
    isNew = false,
    t,
    tReady,
    variant="dark",
    ...props
}) => {
    const [isPasswordHidden, setIsPasswordHidden] = useState(true);

    const togglePasswordVisibility = () => {
        setIsPasswordHidden(!isPasswordHidden);
    }

    const passwordStrengthRating = calculatePasswordStrength(value);

    // Get description to match rating
    let passwordStrengthText;
    switch (passwordStrengthRating) {
        case -1:
            passwordStrengthText = tReady ? t(translationKeys.forms.password.strength.TOO_SHORT) : 'Too short';
            break;
        default:
            passwordStrengthText = tReady ? t(translationKeys.forms.password.strength.WEAK) : 'Weak';
            break;
        case 3:
            passwordStrengthText = tReady ? t(translationKeys.forms.password.strength.GOOD) : 'Good';
            break;
        case 4:
            passwordStrengthText = tReady ? t(translationKeys.forms.password.strength.STRONG) : 'Strong';
            break;
        case -999:
            passwordStrengthText = "That's a terrible password, Rob!"
            break;
    }

    const { accentColour } = useWhiteLabelComponent();

    return (
        <>
            <PasswordContainer $accentColour={props.useAccentColourBorder ? accentColour : undefined} error={props.error} large={large} fullWidth={props.fullWidth} variant={variant} narrowWidth={props.narrowWidth} style={{padding: 0, ...props.style}}>
                <Input value={value} large={large} {...props} type={isPasswordHidden ? 'password' : 'text'} variant={variant} style={{width: '94%', padding: '0 0 0 12px', ...(props.style ?? {})}}/>
                <TogglePasswordVisibility $large={large} onClick={togglePasswordVisibility} type="button" aria-label={`${isPasswordHidden ? 'Show' : 'Hide'} password`}>
                    {
                        isPasswordHidden ? 
                            <Icon variant="hide" accessibilityDescription="Hide password button" height={20} colour={variant === 'dark' ? WHITE() : VL_BLACK()}/> : 
                            <Icon variant="show" height={13} accessibilityDescription="Show password button" colour={variant === 'dark' ? WHITE() : VL_BLACK()}/>
                    }
                    <span aria-live="polite" className="visually-hidden">{isPasswordHidden ? 'Your password is hidden' : 'Your password is shown'}</span>
                </TogglePasswordVisibility>
            </PasswordContainer>
            {
                // Only show password strength indicator if a value has been entered
                isNew && value && (
                    <PasswordStrengthIndicator large={large} aria-live="polite">
                        <PasswordStrengthIndicatorMeter large={large}>
                            <PasswordStrengthIndicatorMeterBar
                                className={
                                    passwordStrengthRating < 3
                                        ? 'low'
                                        : passwordStrengthRating === 3
                                        ? 'medium'
                                        : 'high'
                                }
                                style={{
                                    width: `${
                                        passwordStrengthRating === -1
                                            ? 0
                                            : passwordStrengthRating * 25
                                    }%`,
                                }}
                            />
                        </PasswordStrengthIndicatorMeter>
                        <PasswordStrengthIndicatorLabel large={large}>
                            {passwordStrengthText}
                        </PasswordStrengthIndicatorLabel>
                    </PasswordStrengthIndicator>
                )
            }
        </>
    );
};

// Returns numerical rating of password strength between -1 and 4
// -1 = Invalid
// 0-2 = Weak
// 3 = Good
// 4 = Strong
const calculatePasswordStrength = (password) => {
    // Password must be at least 8 characters
    if (!password || password.length < 8) {
        return -1;
    } else if (password === 'Lincolnshiresausage1') {
        return -999;
    } else if (password.length < 12) {
        return 1;
    } else {
        return 4;
    }

    // Check each condition, awarding 1 mark or none
    // var lowercase = password.toUpperCase() !== password ? 1 : 0;
    // var uppercase = password.toLowerCase() !== password ? 1 : 0;
    // var number = /\d/.test(password) ? 1 : 0;
    // var special = /[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/.test(password)
    //     ? 1
    //     : 0;

    // // Return sum
    // return lowercase + uppercase + number + special;
};

PasswordInput.propTypes = {
    /** Value of input field. */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** Use larger password strength indicator. Prop also passed on to Input component. */
    large: PropTypes.bool,
    /** Flag for showing the password strength indicator */
    isNew: PropTypes.bool
};

export default withTranslation()(PasswordInput);
