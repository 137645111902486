import PropTypes from 'prop-types';
import useWhiteLabelComponent from '../../../../hooks/useWhiteLabelComponent';

import {
    Input,
    ControlsContainer,
    AdjustNumberButton,
} from './NumberControlsInput.styles';

/** Input field of type "number" with buttons to increment/decrement value either side. */
const NumberControlsInput = ({
    onChange,
    name,
    value,
    buttonColour,
    ...props
}) => {
    // Convert required values to integers
    // Note they may be undefined
    const intValue = parseInt(value);
    const intMin = parseInt(props.min);
    const intMax = parseInt(props.max);

    const { accentColour } = useWhiteLabelComponent(); 

    return (
        <ControlsContainer>
            {/* Disable buttons if input has reached min/max value or value is not a number. */}
            <AdjustNumberButton
                $colour={buttonColour}
                $hoverColour={accentColour}
                disabled={
                    isNaN(intValue) || (!isNaN(intMin) && intValue <= intMin)
                }
                type="button"
                onClick={() =>
                    onChange({
                        target: {
                            type: props.type,
                            name,
                            value:
                                !isNaN(intMax) && intValue > intMax
                                    ? intMax
                                    : intValue - 1,
                        },
                    })
                }
                aria-label={`Decrement ${name}`}
            />
            <Input
                type="number"
                onChange={onChange}
                name={name}
                value={value}
                {...props}
            />
            <AdjustNumberButton
                $colour={buttonColour}
                $hoverColour={accentColour}
                disabled={
                    isNaN(intValue) || (!isNaN(intMax) && intValue >= intMax)
                }
                type="button"
                onClick={() =>
                    onChange({
                        target: {
                            type: props.type,
                            name,
                            value:
                                !isNaN(intMin) && intValue < intMin
                                    ? intMin
                                    : intValue + 1,
                        },
                    })
                }
                aria-label={`Increment ${name}`}
                plus
            />
        </ControlsContainer>
    );
};

NumberControlsInput.propTypes = {
    /** Function to call whenever user changes the field's value. */
    onChange: PropTypes.func,
    /** Name of field. Used in `name` and `id` attribute. Must be unique to page. Name also used in aria-label of each button. */
    name: PropTypes.string,
    /** Value of input field. */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** Background colour of buttons on hover. */
    accentColour: PropTypes.string,
};

export default NumberControlsInput;
