import PropTypes from 'prop-types';
import { useState } from 'react';

import {
    NavbarContainer,
    Navlinks,
    Navlink,
    MobileMenuToggle,
} from './Navbar.styles';
import Logo from '../logo';
import { ReactComponent as HamburgerIcon } from '../../assets/images/icons/hamburger.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/icons/close.svg';
import useWhiteLabelComponent from '../../hooks/useWhiteLabelComponent';

/** Responsive navbar containing logo on the left, and other elements (including navlinks) on the right. Must fall below the React Router provider in the tree. */
const Navbar = ({
    links = [],
    ...props
}) => {
    const { fullScreenLayoutLogo, appLayoutLogo, appLayoutLogoSmall, returnLink: logoLink, accentColour: selectedColour } = useWhiteLabelComponent();
    const logoSrc = fullScreenLayoutLogo ?? appLayoutLogo ?? appLayoutLogoSmall;
    // Whether mobile menu is open or closed
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    // Whether mobile menu is currently opening/closing.
    // This controls whether nav tag has a CSS transition time set.
    // Transition time cannot always be >0 as otherwise it runs every time
    // screen width is lowered past the breakpoint.
    const [collapsing, setCollapsing] = useState(false);

    const toggleMobileMenu = () => {
        // Toggle menu
        setMobileMenuOpen(!mobileMenuOpen);

        // Menu is now opening/closing
        setCollapsing(true);

        // Reset once transition has definitely finished
        setTimeout(() => setCollapsing(false), 500);
    };

    // Each is either an object defining a Navlink to be created, or a render prop
    const items = links.map((link) =>
        typeof link === 'object' ? (
            <Navlink
                to={link.path}
                $selectedColour={selectedColour}
                activeClassName="selected"
                key={link.text}
                onClick={() =>
                    mobileMenuOpen ? toggleMobileMenu() : undefined
                } // Close mobile menu
            >
                {link.text}
            </Navlink>
        ) : (
            link()
        )
    );

    return (
        <NavbarContainer {...props}>
            <Logo src={logoSrc} link={logoLink} maxWidth={160} maxHeight={50} />
            <Navlinks
                id="navigation-menu"
                mobileMenuOpen={mobileMenuOpen}
                collapsing={collapsing}
                role="navigation"
            >
                {items}
            </Navlinks>
            <MobileMenuToggle
                onClick={toggleMobileMenu}
                aria-expanded={mobileMenuOpen}
                aria-controls="navigation-menu"
                aria-haspopup="true"
                data-testid="mobile-menu-toggle"
            >
                {mobileMenuOpen ? <CloseIcon /> : <HamburgerIcon />}
            </MobileMenuToggle>
        </NavbarContainer>
    );
};

Navbar.propTypes = {
    /** Logo URL. */
    logoSrc: PropTypes.string,
    /** Href value for anchor tag that wraps logo. If no value given for this prop, anchor tag is not used. */
    logoLink: PropTypes.string,
    /** Links to go in navbar. Each array item must either be an object (with properties path [string] and text [string]) which defines a navlink, or a function which returns a React component. Items in the navbar will be rendered in the order specified by this array. */
    links: PropTypes.array,
    /** Colour to use for the active navlink. */
    selectedColour: PropTypes.string,
};

export default Navbar;
