// Some forms are set up to have confirmation modals but if user clicks 'Enter' key in a field they can bypass modal and still submit form
// Possibly need to come up with solution to this to cover all cases, either in this component or in the useForm hook

import PropTypes from 'prop-types';
import { isValidElement, useEffect, useState } from 'react';
import useModal from '../../hooks/useModal/useModal';
import Modal from '../modal';

import Dropdown from './dropdown';
import DropdownWithOther from './dropdown/dropdown-with-other';
import Field from './field';
import Input from './input';
import PasswordInput from './input/password-input';
import NumberControlsInput from './input/number-controls-input';
import OpenEndedDropdown from './dropdown/open-ended-dropdown';
import PrefixedInput from './input/prefixed-input';
import DiscreteSlider from './discrete-slider';
import TagsInput from './tags-input';
import OptionsTextInput from './input/options-text-input';
import Toggle from '../toggle';
import P from '../p';

import { FormContainer, FormError } from './Form.styles';


/** Wraps around forms. On submit, it prevents default event and calls function provided through prop. */
const Form = ({ showErrorsInModal = false, ...props }) => {
    if (showErrorsInModal) {
        return <FormWithModalErrorMessages {...props} />;
    } else {
        return <FormComponent {...props} />;
    }
};

const FormComponent = ({ onSubmit, children, errorMessage, ...props }) => {
    const handleOnSubmit = (event) => {
        event.preventDefault();

        // Only want this form to be submitted
        // Stops parent forms being submitted if this one is nested
        event.stopPropagation();

        if (onSubmit) {
            onSubmit();
        }
    };

    return (
        <FormContainer onSubmit={handleOnSubmit} {...props} style={{...props.style, ...(props.columnGap ? {display: 'flex', flexDirection: 'column', gap: props.columnGap, alignItems: 'flex-start'} : {})}}>
            {children}
            {
                errorMessage && (
                    <FormError>
                        {
                            errorMessage
                        }
                    </FormError>
                )
            }
        </FormContainer>
    );
};

// Renders FormComponent but manages error messages
const FormWithModalErrorMessages = ({ errorMessage, onSubmit, ...props }) => {
    const [modalProps, showModal] = useModal();

    // Count number of submissions made
    const [submissions, setSubmissions] = useState(0);

    // Increment submissions when user submits form
    // Then call the onSubmit function prop
    const handleOnSubmit = () => {
        setSubmissions((submissions) => submissions + 1);
        if (onSubmit) {
            onSubmit();
        }
    };

    // Show modal when we get a new error message.
    // Cannot just check when errorMessage changes as the user may need to be
    // shown the same error message twice (so message won't have changed).
    // Thus we also check when a new submission has been made (by seeing if
    // the value of submissions has changed).
    useEffect(() => {
        if (errorMessage) {
            showModal();
        }
    }, [errorMessage, submissions, showModal]);

    // `errorMessage` could be string or object
    let errorMessageModalProps = {};
    if (errorMessage) {
        if (typeof errorMessage === 'object') {
            // We need the `body` property here
            // All other props go to Modal
            ({ ...errorMessageModalProps } = errorMessage);
            delete errorMessageModalProps.body;
        } else {
            errorMessageModalProps['aria-label'] = errorMessage;
        }
    }

    return (
        <>
            <FormComponent onSubmit={handleOnSubmit} {...props} />
            {errorMessage && (
                <Modal
                    {...modalProps}
                    {...errorMessageModalProps}
                >
                    { typeof errorMessage === 'string' ? (<P>{errorMessage}</P>) : errorMessage.body }
                </Modal>
            )}
        </>
    );
};

Form.propTypes = {
    /** Function called when user submits form. */
    onSubmit: PropTypes.func,
    /** Error message to display. Node is only supported if `showErrorsInModal` is false. Object is only supported if `showErrorsInModal` is true; all properties except `body` (node, required) will be passed as props to Modal component. */
    errorMessage: (props, propName, componentName) => {
        const value = props[propName];
        // Prop not required
        // String is always acceptable
        if (value && typeof value !== 'string') {
            if (props.showErrorsInModal) {
                // If `showErrorsInModal` is true, prop must either be a string or an object (containing at least the property `body`)
                if (typeof value !== 'object' || !value.body) {
                    return new Error(`In ${componentName}, when prop 'showErrorsInModal' is true, '${propName}' must either be a string or it must be an object containing the required property 'body' and any other props to pass to Modal.`);
                }
            } else {
                // If `showErrorsInModal` is false, prop must either be a string or a node
                if (!isValidElement(value)) {
                    return new Error(`In ${componentName}, when prop 'showErrorsInModal' is false, '${propName}' must either be a string or a node.`);
                }
            }
        }
    },
    /** Show error messages in a modal rather than at the bottom of the form. */
    showErrorsInModal: PropTypes.bool,
};

// Form fields
// Each field is wrapped with Field component to give it standard form layout with label, description, etc.

// HOC
// Takes a component and wraps it in Field
const withFormLayout = (WrappedFieldComponent) => {
    return (props) => <Field component={WrappedFieldComponent} {...props} />;
};

// Would be nice to include Checkbox in this list at some point but its unique layout makes it awkward

Form.Dropdown = withFormLayout(Dropdown);

Form.DropdownWithOther = withFormLayout(DropdownWithOther);

Form.Input = withFormLayout(Input);

Form.PasswordInput = withFormLayout(PasswordInput);

Form.NumberControlsInput = withFormLayout(NumberControlsInput);

Form.OpenEndedDropdown = withFormLayout(OpenEndedDropdown);

Form.PrefixedInput = withFormLayout(PrefixedInput);

Form.DiscreteSlider = withFormLayout(DiscreteSlider);

Form.TagsInput = withFormLayout(TagsInput);

Form.OptionsTextInput = withFormLayout(OptionsTextInput);

Form.Toggle = withFormLayout(({ style = {}, ...props }) => (
    <Toggle style={{ fontWeight: 100, ...style }} {...props} />
));

// Also re-export Field so any component can be placed in a form layout
Form.Field = Field;

export default Form;
