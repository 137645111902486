import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { getQueryParams } from '../../utils/url';

const useQueryParams = () => {
    const { search } = useLocation();

    return useMemo(() => {
        return getQueryParams(search);
    }, [search]);
};

export default useQueryParams;
