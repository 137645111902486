import PropTypes from 'prop-types';
import ConditionalWrapper from '../conditional-wrapper';

import { LogoContainer, LogoImage, LogoLink } from './Logo.styles';

/** Image using specified src. Optionally can be a link. Can also optionally be wrapped in div tag to control max width/height. Additional props go to outermost component. */
const Logo = ({ src, link, maxWidth, maxHeight, ...props }) => {
    // If a link has been specified, wrap LogoImage in LogoLink
    // If a maxWidth or maxHeight has been specified, wrap LogoImage/LogoLink in LogoContainer
    // Pass extra props to outermost component
    return (
        <ConditionalWrapper
            useWrapper={!!(maxWidth || maxHeight)}
            wrapper={(children) => (
                <LogoContainer
                    $maxWidth={maxWidth}
                    $maxHeight={maxHeight}
                    {...props}
                >
                    {children}
                </LogoContainer>
            )}
        >
            <ConditionalWrapper
                useWrapper={!!link}
                wrapper={(children) => (
                    <LogoLink
                        href={link}
                        {...(maxWidth || maxHeight ? {} : props)}
                    >
                        {children}
                    </LogoLink>
                )}
            >
                <LogoImage
                    src={src}
                    alt={props.alt ?? "Logo"}
                    {...(maxWidth || maxHeight || link ? {} : props)}
                />
            </ConditionalWrapper>
        </ConditionalWrapper>
    );
};

Logo.propTypes = {
    /** Image src. */
    src: PropTypes.string,
    /** URL if image should be a link. */
    link: PropTypes.string,
    /** Max width of image. Either valid CSS in string or a number of px. */
    maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** Max height of image. Either valid CSS in string or a number of px. */
    maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Logo;
