import { forwardRef } from 'react';

import Popup from '../Popup';
import DateTimeRangePicker from '../../date-time-picker/date-time-range-picker';
import DateRangePicker from '../../date-time-picker/date-range-picker';
import TimeRangePicker from '../../date-time-picker/time-range-picker';
import TimeDurationPicker from '../../date-time-picker/time-duration-picker';
import DatePicker from '../../date-time-picker/date-picker';
import DateTimePicker from '../../date-time-picker/date-time-picker';

/** Popup version of date pickers. */
const PopupDateTimePicker = forwardRef(({
    // Popup props
    width,
    open,
    maxHeight,
    arrowProps,
    style = {},
    placement,

    type = 'datetime-range',

    // Rest get passed to DateTimeRangePicker
    ...props
}, ref) => {

    let Component;
    switch (type) {
        case 'time-range':
            Component = TimeRangePicker;
            break;
        case 'time-duration':
            Component = TimeDurationPicker;
            break;
        case 'date':
            Component = DatePicker;
            break;
        case 'datetime':
            Component = DateTimePicker;
            break;
        case 'date-range':
            Component = DateRangePicker;
            break;
        case 'datetime-range':
        default:
            Component = DateTimeRangePicker;
            break;
    }

    return (
        <Popup
            width={width ?? 347}
            open={open}
            maxHeight={maxHeight}
            arrowProps={arrowProps}
            style={{
                padding: 15,
                ...style
            }}
            ref={ref}
            placement={placement}
        >
            {
                // Unmount component when popup closed to reset the currently displayed month
                // Maybe not best solution for this
                open && (
                    <Component
                        {...props}
                    />
                )
            }
        </Popup>
    );
});

export default PopupDateTimePicker;
