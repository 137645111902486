// This is only used in header/footer layout
// Possibly best to merge this into HeaderFooterLayout component somehow?
// I don't like this being its own component in the layout folder - it's too complex and specific

import PropTypes from 'prop-types';
import { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Switch, Route, Redirect } from 'react-router-dom';

import translationKeys from '../../../translations/keys';

import {
    PageNavigationContainer,
    Sidebar,
    PageLinks,
    PageContent,
    PageHeading,
    Navlink,
    MobileNavDropdown,
    MobileNavSelected,
} from './PageNavigation.styles';
import ErrorBoundary from '../../error-boundary';
import ErrorScreen from '../../error-screen';

/** Adds sidebar containing navigation links (rendered using React Router's NavLink component) and heading to page. Requires access to translations. */
const PageNavigation = ({
    pages,
    defaultPath,
    accentColour = '#E32747',
    noHeadingDot = false,
    hideNavigation = false,
    t,
    ...props
}) => {
    const getAllHeadings = ({ translationKey, path, routes }) => {
        const headings = [];

        if (path && translationKey) {
            headings.push({ path, translationKey });
        }

        if (routes) {
            for (const page in routes) {
                headings.push(...getAllHeadings(routes[page]));
            }
        }

        return headings;
    };

    const [mobileNavExpanded, setMobileNavExpanded] = useState(false);

    const onMobileNavDropdownClicked = (event) => {
        setMobileNavExpanded((expanded) => !expanded);
        // event.stopPropagation();
    };

    const createNavlink = (path, translationKey, header) => (
        <Navlink
            key={translationKey}
            onClick={() => setMobileNavExpanded(false)}
            $header={header}
            to={path}
            activeClassName="selected"
            $activeColour={accentColour}
        >
            {t(translationKey)}
        </Navlink>
    );

    return (
        <PageNavigationContainer {...props}>
            {!hideNavigation && (
                <Sidebar>
                    <MobileNavSelected>
                        {pages.map(({ translationKey, path }) => (
                            <Route path={path} key={path}>
                                {createNavlink(path, translationKey, true)}
                            </Route>
                        ))}
                        <MobileNavDropdown
                            activeColour={accentColour}
                            expanded={mobileNavExpanded}
                            onClick={onMobileNavDropdownClicked}
                        />
                    </MobileNavSelected>
                    <PageLinks
                        mobileNavExpanded={mobileNavExpanded}
                        numberOfMobileNavlinks={pages.length - 1}
                    >
                        {pages.map(({ translationKey, path }) =>
                            createNavlink(path, translationKey, false)
                        )}
                    </PageLinks>
                </Sidebar>
            )}

            <Switch>
                {pages.map(
                    ({ path, translationKey, renderComponent, routes }) => (
                        <Route path={path} key={translationKey}>
                            <Switch>
                                {getAllHeadings({
                                    path,
                                    translationKey,
                                    routes,
                                })
                                    .slice(1)
                                    .map(({ path, translationKey }) => (
                                        <Route path={path} key={translationKey}>
                                            <PageHeading
                                                accentColour={accentColour}
                                                noDot={noHeadingDot}
                                            >
                                                {t(translationKey)}
                                            </PageHeading>
                                        </Route>
                                    ))}
                                <Route>
                                    <PageHeading
                                        accentColour={accentColour}
                                        noDot={noHeadingDot}
                                    >
                                        {t(translationKey)}
                                    </PageHeading>
                                </Route>
                            </Switch>

                            <PageContent>
                                <ErrorBoundary
                                    message={t(
                                        translationKeys.errors.PAGE_ERROR
                                    )}
                                    errorComponent={ErrorScreen}
                                >
                                    {renderComponent()}
                                </ErrorBoundary>
                            </PageContent>
                        </Route>
                    )
                )}
                {
                    // Redirect to default path
                    // Maintains search params
                    defaultPath && (
                        <Route
                            render={({ location }) => (
                                <Redirect
                                    to={{
                                        pathname: defaultPath,
                                        search: location.search,
                                    }}
                                />
                            )}
                        />
                    )
                }
            </Switch>
        </PageNavigationContainer>
    );
};

PageNavigation.propTypes = {
    /** Links to these pages (excluding subroutes) will be displayed in sidebar. Content displayed will come from page which matches current route. Heading displayed will come from the relevant subroute of the current page, or failing that the current page itself. Each page object will be structured similarly to the routes in `src/pages/routes.js`. */
    pages: PropTypes.arrayOf(
        PropTypes.shape({
            translationKey: PropTypes.string.isRequired,
            path: PropTypes.string.isRequired,
            renderComponent: PropTypes.func.isRequired,
            routes: PropTypes.object,
        })
    ),
    /** Redirect to this path if none of the pages match the current route. */
    defaultPath: PropTypes.string,
    /** Colour of active navigation links and the page heading bottom border. */
    accentColour: PropTypes.string,
    /** Exclude trailing dot from page heading. */
    noDot: PropTypes.bool,
    /** Hide navigation sidebar. Use when accessing page through mobile app. */
    hideNavigation: PropTypes.bool,
};

export default withTranslation()(PageNavigation);
