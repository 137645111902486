import {
    useEffect,
    useReducer,
    lazy,
    Suspense,
    useState,
    useCallback,
} from 'react';
import { withTranslation } from 'react-i18next';
import {
    BrowserRouter,
    Switch,
    Route,
    useHistory,
    Redirect,
    useLocation
} from 'react-router-dom';

import { initRequestManagerWebLogin, logout } from '../../api/managers/requestManager';

import useWhiteLabel from '../../hooks/useWhiteLabel';
import useQueryParams from '../../hooks/useQueryParams';

import host, { isDemo } from '../../utils/getHost';
import { alphabeticalOrderComparison } from '../../utils/string';
import { clearLoggedInRedirectInSessionStorage, getLoggedInRedirectInSessionStorage } from '../../utils/manageStorage';
import { TURQUOISE, WHITE } from '../../utils/colours';

import '../../translations/i18n'; // Translations - needs to be bundled
import routes, {
    LAYOUT_HEADER_FOOTER,
    LAYOUT_FULLSCREEN,
    LAYOUT_NONE,
    getRoutesWithLayout,
    LAYOUT_APP,
    getRouteByPath,
    canUserAccessRoute,
    DEMO_PATH
} from '../../pages/routes';
import translationKeys from '../../translations/keys';

import ErrorBoundary from '../error-boundary';
import WhiteLabel from '../white-label';
import Loading from '../loading';
import ScrollToTop from '../scroll-to-top';
import ErrorPage from '../../pages/error';
import ErrorScreen from '../error-screen';
import {
    AppLayout,
    FullScreenLayout,
    HeaderFooterLayout,
} from '../layout-managers';

import NetworkError from './NetworkError';
import DemoApp from './DemoApp';

// 404 page
// All other pages come from imported 'routes' object above
const NotFound = lazy(() => import('../../pages/404'));

const isCCTVConnect = /cctvconnect\.com/i.test(host);

// Account data managed using a reducer
const reducer = (state, action) => {
    switch (action.type) {
        case CHANGE_EMAIL:
            return { ...state, email: action.payload };
        case LOGIN:
            return action.payload;
        case REFRESH_TOKEN:
            const [loginToken, authToken, managementToken] = action.payload;

            return {
                ...state,
                authToken,
                managementToken,
                webLogin: {
                    ...state.webLogin,
                    loginToken
                }
            };
        case LOGOUT:
            return {};
        case NEW_ORGANISATION:
            return {
                ...state,
                organisations: state.organisations.concat(action.payload),
            };
        case CHANGE_OWN_ORGANISATION:
            return {
                ...state,
                organisations: [
                    ...state.organisations.slice(0, state.organisations.findIndex(o => o.id === state.uid)),
                    {
                        ...state.organisations[state.organisations.findIndex(o => o.id === state.uid)],
                        ...action.payload
                    },
                    ...state.organisations.slice(state.organisations.findIndex(o => o.id === state.uid) + 1)
                ]
            };
        case NEW_CAMERA_GROUP:
            return {
                ...state,
                cameraGroups: state.cameraGroups.concat(action.payload).sort(({ name: a }, { name: b }) => alphabeticalOrderComparison(a, b))
            };
        case DELETE_CAMERA_GROUP:
            return {
                ...state,
                cameraGroups: state.cameraGroups.filter(({ id }) => id !== action.payload)
            };
        case EDIT_CAMERA_GROUP:
            const editedGroup = action.payload;
            return {
                ...state,
                cameraGroups: state.cameraGroups.map(group => group.id === editedGroup.id ? editedGroup : group)
            };
        case COMPLETE_SELF_REG_STEP:
            // Completed step is in payload
            return {
                ...state,
                incompleteSelfRegSteps: state.incompleteSelfRegSteps?.filter(step => step !== action.payload)
            };
        default:
            throw new Error();
    }
};

// Actions for reducer
const CHANGE_EMAIL = 'CHANGE_EMAIL';
const LOGIN = 'LOGIN';
const LOGOUT = 'LOGOUT';
const REFRESH_TOKEN = 'REFRESH_TOKEN';
const NEW_ORGANISATION = 'NEW_ORGANISATION';
const CHANGE_OWN_ORGANISATION = 'CHANGE_OWN_ORGANISATION';
const NEW_CAMERA_GROUP = 'NEW_CAMERA_GROUP';
const DELETE_CAMERA_GROUP = 'DELETE_CAMERA_GROUP';
const EDIT_CAMERA_GROUP = 'EDIT_CAMERA_GROUP';
const COMPLETE_SELF_REG_STEP = 'COMPLETE_SELF_REG_STEP';

// Chooses between normal and demo version of app
const AppRouter = () => {

    return (
        <BrowserRouter
            // All demo routes need to be prefixed with /demo
            // Note this means we cannot use React Router to link from demo app to normal app and vice versa
            basename={isDemo ? DEMO_PATH : undefined}
        >
            {/* Code common to both here - currently only `ScrollToTop` */}
            <ScrollToTop />

            {/* Render app */}
            {
                isDemo ? (
                    <DemoApp />
                ) : (
                    <App />
                )
            }
        </BrowserRouter>
    );
}

// Main app version
// Only exported for tests
export const App = withTranslation()(({ tReady, t }) => {
    // Account data
    // Only property is loading: true until data has been fetched
    // We know account data has been received once (among other properties) accountData.authToken exists
    const [accountData, dispatch] = useReducer(reducer, { loading: true });

    // Whether a network error has prevented a token refresh
    // If true, we show the `NetworkError` page
    // Once a network error has occurred, this page will keep checking the connection and reload the app when it returns
    const [networkError, setNetworkError] = useState(false);
    const handleNetworkError = useCallback(() => {
        setNetworkError(true);
    }, []);

    const history = useHistory();

    // Functions for managing account data
    const handleChangeEmail = useCallback(newEmail => dispatch({ type: CHANGE_EMAIL, payload: newEmail }), []);
    const handleLogin = useCallback(accountData => {

        // Add preset group(s)
        // Preset groups have ids <= 0
        // For preset group names we just use the translation key as translations may not have been loaded yet
        // These get translated before being passed to AppLayout layout manager

        // If installer and they have some cameras, add "Free partner demo" group
        if (accountData.managementType === 'i') {
            const installerOrg = accountData.organisations.find(({ id }) => id === accountData.uid);
            if (installerOrg && installerOrg.cameras) {
                accountData.cameraGroups.unshift({
                    id: -1,
                    name: translationKeys.installers.FREE_PARTNER_DEMO,
                    cloudStorageDuration: installerOrg.cloudStorageDuration,
                    cameras: accountData.partnerDemoCameras
                });
            }
        }
        
        dispatch({ type: LOGIN, payload: accountData });
    }, []);
    const handleLogout = useCallback(() => {
        dispatch({ type: LOGOUT });
    }, []);
    const handleNewOrganisation = useCallback(newOrganisation => {
        dispatch({ type: NEW_ORGANISATION, payload: newOrganisation });
    }, []);
    const handleChangeOwnOrganisation = useCallback(changes => {
        dispatch({ type: CHANGE_OWN_ORGANISATION, payload: changes });
    }, []);
    const handleNewCameraGroup = useCallback(newCameraGroup => {
        dispatch({ type: NEW_CAMERA_GROUP, payload: newCameraGroup });
    }, []);
    const handleDeleteCameraGroup = useCallback(groupId => {
        dispatch({ type: DELETE_CAMERA_GROUP, payload: groupId });
    }, []);
    const handleEditCameraGroup = useCallback(editedGroup => {
        dispatch({ type: EDIT_CAMERA_GROUP, payload: editedGroup });
    }, []);
    const handleCompleteSelfRegStep = useCallback(completedStep => {
        dispatch({ type: COMPLETE_SELF_REG_STEP, payload: completedStep });
    }, []);


    // Runs once when App first loaded
    // Logs in using data in local storage if possible
    useEffect(() => {
        const initialLogin = async () => {
            try {
                handleLogin(await initRequestManagerWebLogin(handleLogout, handleNetworkError));
            } catch (error) {
                // console.error(error);
                handleLogout();
            }
        }
        initialLogin();
    }, [handleNetworkError, handleLogin, handleLogout]);
    
    // White label custom hook
    const [whiteLabelHostInfo, installerCCTVCBranding] = useWhiteLabel(
        host,
        isCCTVConnect,
        accountData.installerid
    );

    // Record if viewing through mobile app
    // `app` query param will be true
    // This state saves us having to maintain `app` param
    const queryParams = useQueryParams();
    const [mobileApp] = useState(queryParams.app === 'true');

    // Expose functions needed by mobile app
    useEffect(() => {
        if (mobileApp) {
            window.mobileAppNavigate = page => {
                if (routes[page]?.path) {
                    history.push(routes[page].path);
                }
            }
        }
    }, [mobileApp, history]);

    // Passed down to sign out buttons
    const logoutAction = useCallback(async () => {
        // No cb (for useAsyncButtonAction) as we leave page

        // Browser may cancel request if we leave page without waiting for request
        // Thus we wait for it to finish to ensure login token series is deleted
        await logout();

        // Go to white label's return link if it exists (and we're not on localhost as that's really annoying otherwise)
        if (
            whiteLabelHostInfo?.returnLink &&
            !window.location.hostname.match(/localhost/)
        ) {
            window.location.assign(whiteLabelHostInfo.returnLink);
        } else {
            // Otherwise back to sign in page
            handleLogout();
            history.push(routes.signIn.path);
        }
    }, [handleLogout, history, whiteLabelHostInfo?.returnLink]);

    const isSignedIn = !!accountData.uid;

    // Function which takes a page's config object (as defined in src/pages/routes.js) and renders its component within a Route component
    const renderComponentWithRoute = ({
        path,
        allPaths,
        renderComponent,
        routes: subroutes,
        ...routeConfig
    }) => {

        // Passes required args to given renderComponent function
        // These args include account data, functions for modifying account data and flag indicating whether web app is being accessed through mobile app
        const renderComponentWithArgs = (renderComponent) =>
            renderComponent(
                accountData,
                {
                    // Functions for modifying account data
                    handleChangeEmail,
                    handleLogin,
                    handleLogout,
                    handleNetworkError,
                    handleNewOrganisation,
                    handleChangeOwnOrganisation,
                    handleNewCameraGroup,
                    handleDeleteCameraGroup,
                    handleEditCameraGroup,
                    handleCompleteSelfRegStep,

                    // Also include logoutAction
                    logoutAction
                },
                mobileApp
            );

        // Function to generate component
        const component = () => {
            // If there are subroutes and no renderComponent function, then we need to route user rather than just render a component
            if (!renderComponent && subroutes) {
                return (
                    <Switch>
                        {/* Redirect from base route to first subroute */}
                        <Redirect
                            exact
                            from={path}
                            to={Object.values(subroutes)[0].path}
                        />

                        {/* Subroutes */}
                        {Object.values(subroutes).map(
                            ({ allPaths, path, renderComponent }) => (
                                <Route exact path={allPaths} key={path}>
                                    {renderComponentWithArgs(renderComponent)}
                                </Route>
                            )
                        )}
                    </Switch>
                );
            }

            // Run function from routes config to generate component
            return renderComponentWithArgs(renderComponent);
        };


        return (
            <Route
                // Path needs to include all paths that could go to this page, including subroutes
                // Then we can use the 'exact' Route prop and all requests to a URL not in pages/routes.js will go to 404 page
                path={getAllPathsThatMeetAccessCondition({ routes: subroutes, path, allPaths, ...routeConfig }, accountData)}
                key={path}
                exact
            >
                {component()}
            </Route>
        );
    };

    // Render
    if (
        // Initial loading includes translations, white label info and user account data
        tReady &&
        !accountData.loading &&
        whiteLabelHostInfo &&
        // Either this isn't CCTVC so we don't need installer branding
        // Or this is CCTVC and we have installer branding
        // Or this is CCTVC but as we don't have an installer id (e.g. we're not logged in) we can't look up installer branding yet
        (!isCCTVConnect ||
            installerCCTVCBranding ||
            !accountData.installerid)
    ) {
        // Only render app once user data and white label info has been received
        return (
            <ErrorBoundary
                errorComponent={ErrorScreen}
                message={t(translationKeys.errors.APP_ERROR)}
            >
                <WhiteLabel
                    info={
                        isCCTVConnect
                            ? {
                                  ...whiteLabelHostInfo,
                                  ...installerCCTVCBranding,
                              } // Overwrite with installer specific branding if CCTVC
                            : whiteLabelHostInfo
                    }
                >
                    <Suspense
                        // Too many spinners on mobile app (as sidebar, etc. are hidden)
                        fallback={mobileApp ? null : <Loading fill />}
                    >
                        <ErrorBoundary
                            errorComponent={ErrorPage}
                            message={t(translationKeys.errors.PAGE_ERROR)}
                        >
                            {
                                networkError ? (
                                    <NetworkError />
                                ) : (
                                    <Switch>
                                        {/* Root goes to sign in */}
                                        <Redirect
                                            exact
                                            from="/"
                                            to={routes.signIn.path}
                                        />

                                        {/* If user signed in, redirect away from all pages that are only for non-logged in users */}
                                        {isSignedIn && (
                                            <Route
                                                exact
                                                path={Object.values(routes).reduce(
                                                    (
                                                        acc,
                                                        { redirectIfLoggedIn, allPaths }
                                                    ) =>
                                                        redirectIfLoggedIn
                                                            ? acc.concat(allPaths)
                                                            : acc,
                                                    []
                                                )}
                                            >
                                                <LoggedInRedirect
                                                    accountType={accountData.accountType}
                                                />
                                            </Route>
                                        )}

                                        {/*
                                            If user not signed in, redirect to sign in page from all pages with restricted access.
                                            Have just wrapped those pages in <PrivateRoute> components before but better to stop
                                            layout manager (e.g. AppLayout) rendering too.
                                        */}
                                        {!isSignedIn && (
                                            <Route
                                                exact
                                                path={Object.values(routes).reduce(
                                                    (
                                                        acc,
                                                        { restrictAccess, allPaths }
                                                    ) =>
                                                        restrictAccess
                                                            ? acc.concat(allPaths)
                                                            : acc,
                                                    []
                                                )}
                                                render={({ location }) => (
                                                    <Redirect
                                                        to={{
                                                            pathname:
                                                                routes.signIn.path,
                                                            state: { from: location },
                                                        }}
                                                    />
                                                )}
                                            />
                                        )}

                                        {/*
                                            If user is self reg and has not completed the sign up process, all restricted access pages
                                            should redirect to "Get Started".
                                            after self reg updates march 2023 we can ignore 'shipping' step as that will always either:
                                                - be completed before payment
                                                - never be completed because user checks 'already have a cloud adapter'
                                        */}
                                        {
                                            isSignedIn && 
                                            accountData.isSelfReg && 
                                            (accountData.incompleteSelfRegSteps?.length > 0 && accountData.incompleteSelfRegSteps?.includes('payment')) &&
                                            // Make exception for additional users
                                            // We've had a number of cases where additional users (who obviously don't want their own plan) have created
                                            // an account before being granted access (rather than creating an account through an email invite).
                                            !accountData.organisations.some(({ id }) => id !== accountData.uid) &&
                                            (
                                                <Route
                                                    exact
                                                    path={Object.values(routes).reduce(
                                                        (
                                                            acc,
                                                            { restrictAccess, allPaths }
                                                        ) =>
                                                            restrictAccess
                                                                ? acc.concat(allPaths)
                                                                : acc,
                                                        []
                                                    )}
                                                    render={() => (
                                                        <Redirect
                                                            to={{
                                                                pathname:
                                                                    routes.getStarted.path,
                                                                search: '?type=enduser'
                                                            }}
                                                        />
                                                    )}
                                                />
                                            )
                                        }

                                        {/* Pages with no defined layout */}
                                        {getRoutesWithLayout(LAYOUT_NONE).map(
                                            renderComponentWithRoute
                                        )}

                                        {/* Full screen layout pages */}
                                        <Route
                                            path={getRoutesWithLayout(LAYOUT_FULLSCREEN)
                                                .map(({ allPaths }) => allPaths)
                                                .flat()}
                                            exact
                                        >
                                            <FullScreenLayout>
                                                <Suspense
                                                    // Too many spinners on mobile app (as sidebar, etc. are hidden)
                                                    fallback={mobileApp ? null : <Loading />}
                                                >
                                                    <Switch>
                                                        {getRoutesWithLayout(
                                                            LAYOUT_FULLSCREEN
                                                        ).map(renderComponentWithRoute)}
                                                    </Switch>
                                                </Suspense>
                                            </FullScreenLayout>
                                        </Route>

                                        {/* Header/footer and app layout pages */}
                                        {/*
                                            If user signed in, we want 404 page in app layout.
                                            Otherwise we want it in header/footer layout.

                                            The layout containing the 404 page needs to be rendered last
                                            so it catches all other paths (using wildcard '*').

                                            If possible, it would be better to keep order permanent
                                            and include regex to match all routes EXCEPT those belonging
                                            to the component below. For example, app layout could be
                                            rendered first and if user logged in it could match all routes
                                            except header/footer pages.
                                        */}
                                        {
                                            // Header/footer layout without 404 page
                                            isSignedIn && (
                                                <Route
                                                    exact
                                                    path={getRoutesWithLayout(
                                                                LAYOUT_HEADER_FOOTER
                                                            )
                                                                .map(({ allPaths }) => allPaths)
                                                                .flat()
                                                        }
                                                    render={({ match }) => (
                                                        <HeaderFooterLayoutPages
                                                            match={match}
                                                            accountType={accountData.accountType}
                                                            mobileApp={mobileApp}
                                                            logoutAction={logoutAction}
                                                            renderComponentWithRoute={renderComponentWithRoute}
                                                        />
                                                    )}
                                                />
                                            )
                                        }

                                        {/* App layout pages */}
                                        <Route
                                            // If user is signed in, must exclude pages that they do not have access to so they still get routed to 404 below
                                            path={getRoutesWithLayout(LAYOUT_APP)
                                                    .map(
                                                        (routeConfig) => getAllPathsThatMeetAccessCondition(
                                                                routeConfig,
                                                                accountData
                                                            )
                                                    )
                                                    .flat()
                                                    // Need to add wildcard if including 404 page
                                                    .concat(isSignedIn ? '*' : [])
                                                }
                                            render={({ match }) => (
                                                <AppLayout
                                                    routeConfig={getRouteByPath(
                                                        match.path
                                                    )}
                                                    logout={logoutAction}
                                                    mobileApp={mobileApp}
                                                    accountInfo={accountData}
                                                >
                                                    <Suspense
                                                        fallback={
                                                            // Too many spinners on mobile app (as sidebar, etc. are hidden)
                                                            mobileApp ? null : <Loading fill />
                                                        }
                                                    >
                                                        <Switch>
                                                            {getRoutesWithLayout(
                                                                LAYOUT_APP
                                                            ).map(
                                                                renderComponentWithRoute
                                                            )}
                                                            
                                                            {
                                                                // 404 page
                                                                isSignedIn && (
                                                                    <Route>
                                                                        <NotFound />
                                                                    </Route>
                                                                )
                                                            }
                                                        </Switch>                        
                                                    </Suspense>
                                                </AppLayout>
                                            )}
                                            // Need exact prop to be true
                                            // This ensures subroutes that should not be rendered are not matched by parent routes
                                            exact
                                        />
                                        {
                                            // Header/footer layout with 404 page
                                            !isSignedIn && (
                                                <Route
                                                    exact
                                                    path={getRoutesWithLayout(
                                                                LAYOUT_HEADER_FOOTER
                                                            )
                                                                .map(({ allPaths }) => allPaths)
                                                                .flat()
                                                                .concat('*')
                                                        }
                                                    render={({ match }) => (
                                                        <HeaderFooterLayoutPages
                                                            match={match}
                                                            accountType={accountData.accountType}
                                                            mobileApp={mobileApp}
                                                            logoutAction={logoutAction}
                                                            renderComponentWithRoute={renderComponentWithRoute}
                                                            include404
                                                        />
                                                    )}
                                                />
                                            )
                                        }
                                    </Switch>
                                ) 
                            }
                        </ErrorBoundary>
                    </Suspense>
                </WhiteLabel>
            </ErrorBoundary>
        );
    } else if (!mobileApp) {
        // Default to black loading spinner as we don't have white label styles yet
        // Make exception for VL and show pink
        return <Loading fill spinnerColour={/videoloft/.test(host) ? TURQUOISE : WHITE()} />;
    } else {
        // Don't want black loading spinner on mobile app as there are too many spinners already
        return null;
    }
});

/* ---------------------------- Helper components --------------------------- */

// Component to render instead if user is signed in and page cannot be accessed by a signed in user (i.e. redirectIfLoggedIn is true)
const LoggedInRedirect = ({ accountType }) => {
    const location = useLocation();
    
    // Redirect to first possible page out of the last page visited, portal, or cameras
    let pathname, search;
    if (location.state?.from) {
        // Last page visited
        if (typeof location.state.from === 'string') {
            // If location.state.from is just a URL, split into pathname and search
            const split = location.state.from.split('?');
            pathname = split[0];
            if (split[1]) {
                search = '?' + split[1];
            }
        } else {
            // If location.state.from is an object, extract pathname and search
            pathname = location.state.from.pathname;
            search = location.state.from.search;
        }
    } else if (typeof getLoggedInRedirectInSessionStorage() === 'string') {
        // Not always possible to have `from` in current location state
        // We use session storage in these cases
        // This can cause issues though with stale values left in storage
        [pathname, search] = getLoggedInRedirectInSessionStorage().split('?');
        if (search.length > 0) {
            search = '?' + search;
        }
        
    } else if (routes.portal?.restrictAccess.includes(accountType)) {
        // Portal
        // routes.portal may not exist if removed by white label
        pathname = routes.portal.path;
    } else {
        // Cameras
        // If on a takeaway white label, cameras won't exist and we want /orders instead
        pathname = routes.cameras?.path ?? routes.takeawayOrders.path;

        // Need to tell mobile app that login was successful
        if (navigator.userAgent.match(/canvas/i) && window.nativeFunctions) {
            window.nativeFunctions.login();
        }
    }

    useEffect(() => {
        // Clear session storage
        return clearLoggedInRedirectInSessionStorage;
    }, []);
    
    return (
        <Redirect
            to={{
                pathname,
                search,
                state: {
                    from: location.pathname, // Store page redirected from in location state
                },
            }}
        />
    );
};

// Header/footer layout code gets duplicated in App component above as its position in tree changes depending on whether it needs to include 404 page or not
// This component minimises that duplication
const HeaderFooterLayoutPages = withTranslation()(({ match, accountType, mobileApp, logoutAction, renderComponentWithRoute, include404, t }) => {

    return (
        <HeaderFooterLayout
            accountType={
                accountType
            }
            logout={logoutAction}
            routeConfig={getRouteByPath(
                match.path
            )}
            mobileApp={mobileApp}
        >
            <ErrorBoundary
                errorComponent={ErrorScreen}
                message={t(
                    translationKeys.errors
                        .PAGE_ERROR
                )}
            >
                <Suspense
                    fallback={
                        <Loading
                            fill
                        />
                    }
                >
                    <Switch>
                        {getRoutesWithLayout(
                            LAYOUT_HEADER_FOOTER
                        ).map(
                            renderComponentWithRoute
                        )}

                        {
                            // 404 page
                            include404 && (
                                <Route>
                                    <NotFound />
                                </Route>
                            )
                        }
                        
                    </Switch>
                </Suspense>
            </ErrorBoundary>
        </HeaderFooterLayout>
    );
});

/* ---------------------------- Helper functions ---------------------------- */

export const getAllPathsThatMeetAccessCondition = (
    routeConfig, accountInfo
) => {
    if (
        !canUserAccessRoute(routeConfig, accountInfo)
    ) {
        return [];
    }

    const { path, acceptPaths, routes } = routeConfig;

    const allPaths = [path];

    if (acceptPaths) {
        allPaths.push(...acceptPaths);
    }

    if (routes) {
        for (const page in routes) {
            allPaths.push(
                ...getAllPathsThatMeetAccessCondition(
                    routes[page],
                    accountInfo
                )
            );
        }
    }

    return allPaths;
};


export default AppRouter;
