import { useCallback, useContext } from 'react';

// Importing directing from file as tests don't like imports from /app/index.js
import { DemoContext } from '../../components/app/DemoApp';

/**
 * @callback handleRestrictedDemoFormProps
 * @param {object} props Original props provided to form.
 * @returns {object} If in demo mode, this will be new props. Otherwise this will just be original props.
 */

/**
 * @callback AsyncActionCallback
 */

/**
 * @callback AsyncAction
 * @param {AsyncActionCallback} cb Callback invoked when async action has finished.
 */

/**
 * @callback handleRestrictedDemoButtonAsyncAction
 * @param {AsyncAction} asyncAction Original asyncAction passed to button.
 * @returns {AsyncAction} If in demo mode, this will be new async action to trigger demo modal.
 *                        Otherwise this will just be original async action.
 */

/**
 * @callback Function
 */

/**
 * @callback handleRestrictedDemoFunction
 * @param {Function} f Original function (e.g. button's onClick).
 * @returns {Function} If in demo mode, this will be function to open demo warning modal.
 *                     Otherwise this will just be the original function passed in.
 */

/**
 * @typedef DemoObject
 * @type {object}
 * @property {boolean} isDemo Whether app is in demo mode
 * @property {handleRestrictedDemoFormProps} handleRestrictedDemoFormProps Function which alters a form's props in demo to make it trigger demo warning modal on submit.
 * @property {handleRestrictedDemoButtonAsyncAction} handleRestrictedDemoButtonAsyncAction Function which returns new async action in demo to make it trigger modal.
 * @property {handleRestrictedDemoFunction} handleRestrictedDemoFunction Function which returns the demo warning modal's `showModal` function in demo.
 */

/**
 * Hook which accesses Demo Context set up by demo app and provides helpful values/functions to pages which need adjusting for demo mode. 
 * @returns {DemoObject}
 */
const useDemo = () => {

    // Get functions/refs provided through Context API by DemoApp
    const demo = useContext(DemoContext);

    const handleRestrictedDemoFormProps = useCallback((props) => {

        // If we're not in demo mode, just return original props
        if (!demo) {
            return props;
        }

        return {
            ...props,
            // Overwrite onSubmit to show demo modal instead
            onSubmit: demo.showModal
        };
    }, [demo]);

    const handleRestrictedDemoButtonAsyncAction = useCallback(f => {
        if (!demo) {
            return f;
        }

        return cb => {
            demo.showModal();
            cb();
        }
    }, [demo]);

    const handleRestrictedDemoFunction = useCallback(f => {
        return demo ? demo.showModal : f;
    }, [demo]);

    return {
        isDemo: !!demo,
        handleRestrictedDemoFormProps,
        handleRestrictedDemoButtonAsyncAction,
        handleRestrictedDemoFunction,
    };
}

export default useDemo;