import styled from 'styled-components';
import Button from '../button/Button';

import { colourWithOpacity, MID_GREY, VL_BLACK, VL_RED } from '../../utils/colours';

export const OptionsListContainer = styled.div`
    width: 100%;

    ${({ $fullHeight }) =>
        $fullHeight
            ? `
        display: grid;
        grid-template-rows: min-content auto min-content min-content;
        height: 100%;
        overflow: hidden;
    `
            : ''}
`;

OptionsListContainer.displayName = 'OptionsListContainer';


export const GroupHeader = styled.div`
    background: #fff;
    border: 1px solid #e7e7e7;
    border-radius: 5px;
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    align-items: center;
    box-shadow: 0 0 5px 0 #6b6b6b26;
    font-weight: 300;

    span {
        text-overflow: ellipsis;
        overflow: hidden;
    }
`;

GroupHeader.displayName = 'GroupHeader';


export const GroupExpandButton = styled.button`
    background: none;
    margin: 0 8px;
    padding: 0;
    border: none;
    width: 16px;
    height: 16px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${({ $expanded }) => $expanded ? '0 0 2px' : '3px 2px 0 0'};

    ::before {
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        border-left: 2px solid #000;
        border-bottom: 2px solid #000;
        transform-origin: center center;
        transform: rotate(${({ $expanded }) => $expanded ? -45 : -135}deg);
        /* margin-bottom: 3px; */
    }
`;

GroupExpandButton.displayName = 'GroupExpandButton';


export const GroupsContainer = styled.div`
    overflow-y: auto;
`;

GroupsContainer.displayName = 'GroupsContainer';


export const ListContainer = styled.div`
    overflow-y: auto;
    width: 100%;
`;

ListContainer.displayName = 'ListContainer';


export const OptionContainer = styled.div`
    width: 100%;
    max-width: 100%;
    text-align: left;
    font-weight: 100;
    border-top: ${({ $firstBorder }) => $firstBorder ? `1px solid ${MID_GREY}` : 'none'};
    display: grid;
    align-items: center;
    padding: 10px 5px;

    /* 1fr for text, min-content for action icon */
    grid-template-columns: 1fr min-content;

    overflow: hidden;

    ${({ $disabled }) => !$disabled ? `
        @media (hover: hover) {
            :hover {
                background: ${VL_BLACK(10)};
            }
        }
    ` : ''}
`;

OptionContainer.displayName = 'OptionContainer';


export const OptionButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    font-weight: inherit;
    height: 100%;
    margin: 0;
    text-align: inherit;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    color: #000;

    :disabled {
        cursor: not-allowed;
        color: #0006;
    }
`;

OptionButton.displayName = 'OptionButton';


export const OptionCheckboxLabel = styled.label`
    display: grid;
    grid-template-columns: 1fr min-content;
    align-items: center;
    cursor: pointer;
    width: 100%;

    input {
        cursor: pointer;
    }

    span {
        text-overflow: ellipsis;
        overflow: hidden;
    }

    ${({ $disabled }) => $disabled ? `
        cursor: not-allowed;

        input {
            cursor: not-allowed;
        }
    ` : ''}
`;

OptionCheckboxLabel.displayName = 'OptionCheckboxLabel';


export const OptionActionButton = styled.button`
    background: none;
    border: none;
    margin: 0;
    padding: 0 5px;
    font-weight: 100;
    text-decoration: underline;
    cursor: pointer;
    height: 100%;
    font-size: 85%;
    color: #000;

    @media (hover: hover) {
        :hover:not(:disabled) {
            text-decoration: none;
        }
    }
`;

OptionActionButton.displayName = 'OptionActionButton';


export const OptionCount = styled.span`
    margin-left: 5px;
    background-color: transparent;
    border: 1px solid ${VL_BLACK()};
    height: 15px;
    width: 25px;
    padding: 0 3px;
    border-radius: 100px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #464646;
    font-size: 70%;
    line-height: 11;
`;

OptionCount.displayName = 'OptionCount';


export const BottomButton = styled.button`
    margin-top: 10px;
    border: 1px solid ${MID_GREY};
    width: 100%;
    display: block;
    border-radius: 5px;
    padding: 10px;
    font-weight: 100;
    cursor: pointer;
    color: #808080;
    background: none;
    overflow: hidden;
    font-size: initial;
    text-align: center;

    @media (hover: hover) {
        :hover {
            background: ${MID_GREY};
        }
    }
`;

BottomButton.displayName = 'BottomButton';


// Container required for spinner as its animation was messing with parent dimensions
export const SpinnerContainer = styled.div`
    max-width: 18px; /* To match Spinner */
    overflow: hidden;
    margin: auto;
`;

SpinnerContainer.displayName = 'SpinnerContainer';


export const BulkActionButton = styled(Button).attrs({ variant: 'text' })`
    font-size: 80% !important;
    min-width: max-content; // Do not want text breaking on to multiple lines
    
    color: ${VL_RED} !important;
    
    :disabled {
        color: ${VL_BLACK(50)};
    }
`;

BulkActionButton.displayName = 'BulkActionButton';

export const OptionsListFooterButton = styled(Button).attrs({ variant: 'text' })`
    font-size: 80%;
    width: 100%;
    min-height: 34px;
    border-radius: 0 0 10px 10px;
    color: ${VL_RED} !important;

    :disabled {
        color: ${colourWithOpacity(VL_RED, 50)} !important;
    }

    ${( { $mobile } ) => !$mobile ? `
        background-color: ${MID_GREY} !important;
    ` : ''}
`;