import { publicAuthenticatorRequest } from './managers/requestBuilder';

/**
 * Identify whether browser can connect to the internet.
 * @returns {boolean} Whether there is an internet connection.
 */
export const testInternetConnection = async () => {
    try {
        // Just call auth root which should always return 200
        await publicAuthenticatorRequest('GET', '/');
        return true;
    } catch {
        return false;
    }
}