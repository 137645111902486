import styled from 'styled-components';
import { LIGHT_GREY, VL_BLACK } from '../../../utils/colours';

const borderRadius = '10px';

export const ModalPlayerContainer = styled.div`
    width: 80vw;
    max-height: 95vh;
    min-width: min(400px, 98vw);
    max-width: 98vw;
    overflow-y: auto;

    /* If video size was only based off screen width, then this component would be too tall in wide short windows */
    @media only screen and (max-height: 60vw) {
        width: calc((90vh - 200px) * (16 / 9));
    }
`;

ModalPlayerContainer.displayName = 'ModalPlayerContainer';

export const ModalPlayerHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    border-radius: ${borderRadius} ${borderRadius} 0 0;
    background: ${({ $backgroundColour }) => $backgroundColour};
    color: ${({ $textColour }) => $textColour ?? '#fff'};
    font-weight: 100;
    font-size: 1.5em;
`;

ModalPlayerHeader.displayName = 'ModalPlayerHeader';

export const ModalPlayerTitle = styled.h2`
    margin: 0;
    font-size: inherit;
    color: inherit;
`;

ModalPlayerTitle.displayName = 'ModalPlayerTitle';

export const ModalPlayerCloseButton = styled.button`
    background: none;
    border: none;
    height: 20px;
    width: 20px;
    padding: 0;
    cursor: pointer;

    svg {
        height: 100%;
        width: 100%;

        rect {
            fill: ${({ $colour }) => $colour ?? '#fff'};
        }
    }
`;

ModalPlayerCloseButton.displayName = 'ModalPlayerCloseButton';


const CONTENT_MAX_HEIGHT_VH = 72;

export const ContentWrapper = styled.div`
    width: 100%;
    max-height: ${CONTENT_MAX_HEIGHT_VH}vh;
    background-color: #000;
    overflow: hidden;
    display: flex;
    justify-content: center;
    
	/*
    // Relative position needed by absolutely positioned loading spinner
    position: relative;

    @media only screen and (max-height: 60vw) {
        width: calc((90vh - 200px) * (16 / 9));
        height: calc(90vh - 200px);
    }*/
`;

ContentWrapper.displayName = 'ContentWrapper';


export const VideoContainer = styled.div`
    width: 100%;
    max-height: inherit;
    max-width: ${CONTENT_MAX_HEIGHT_VH * 16/9}vh; /* Based on max height of content and aspect ratio of 16:9 */
    aspect-ratio: 16 / 9;
    position: relative;
`;

VideoContainer.displayName = 'VideoContainer';


export const Image = styled.img`
    max-width: 100%;
    max-height: inherit;
`;

Image.displayName = 'Image';


// Wraps image loading spinner or image error message
// Ensures they both maintain height
export const MediaPlaceholder = styled.div`
    aspect-ratio: 16 / 9;
    width: 100%;
    max-height: 100%;
    // Relative position needed by absolutely positioned loading spinner
    position: relative;
`;

MediaPlaceholder.displayName = 'MediaPlaceholder';


export const MediaErrorMessageContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 20px 30px;
    text-align: center;
    overflow-y: auto;

    h2, p {
        color: #fff;
    }

    /* Space will only be small on mobile so render from top and with scrolling. */
    @media (max-width: 700px) {
        display: block;
    }
`;

MediaErrorMessageContainer.displayName = 'MediaErrorMessageContainer';


const FOOTER_MOBILE_BREAKPOINT = 700; // In px
export const ModalPlayerFooter = styled.div`
    background: ${LIGHT_GREY};
    color: ${VL_BLACK()};
    border-radius: 0 0 ${borderRadius} ${borderRadius};
    padding: 10px;
    column-gap: 15px;
    overflow: auto;

    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: min-content auto;
    grid-template-areas:
        'left right'
        'content content';

    @media (max-width: ${FOOTER_MOBILE_BREAKPOINT}px) {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto;
        grid-template-areas:
            'left'
            'right'
            'content';
    }
`;

ModalPlayerFooter.displayName = 'ModalPlayerFooter';

export const ModalPlayerFooterLeft = styled.div`
    grid-area: left;
    display: flex;
    gap: 5px;
    align-items: center;
    font-weight: 100;
    overflow: hidden;
`;

ModalPlayerFooterLeft.displayName = 'ModalPlayerFooterLeft';

export const ModalPlayerFooterActions = styled.div`
    grid-area: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 6px;
    overflow: hidden;

    @media (max-width: ${FOOTER_MOBILE_BREAKPOINT}px) {
        justify-content: center;
    }
`;

ModalPlayerFooterActions.displayName = 'ModalPlayerFooterActions';

export const ModalPlayerFooterContent = styled.div`
    grid-area: content;

    /* Use margin top here rather than gap on ModalPlayerFooter in case ModalPlayerFooterContent isn't used. */
    margin-top: 15px;
    h3 {
        color: ${VL_BLACK()};
    }
`;

ModalPlayerFooterContent.displayName = 'ModalPlayerFooterContent';
