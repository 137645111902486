// If an element has an onClick, needs a matching onKeyPress function so still accessible for keyboard users
// This function calls the onClick function when the enter key is pressed (or more specifically, when it is released)
// Pass result of this function to component's onKeyUp prop
// e.g. <Icon onClick={func} onKeyUp={callOnClickOnEnterKeyUp(func)} />
const callOnClickOnEnterKeyUp = (onClick) => (event) => {
    if (event.key === 'Enter') {
        onClick();
    }
};

export default callOnClickOnEnterKeyUp;
