import { getRegion } from "./store";

export const PRODUCTION_PAYMENT_SERVER = 'https://payment.manything.com';
export const DEVELOPMENT_PAYMENT_SERVER = 'https://uswest2-payment.manything.com';
// Rob's payment server: https://uswest2-rob-payment-vpc.manything.com
// Rob's auth: https://uswest2-rob-auth-vpc.manything.com
// Rob's logger (subject to change): https://uswest2-rob-logger-1653577619-i-03b93873e8da420f8.manything.com
// Matthew's auth: https://uswest2-moc-auth-vpc.manything.com
// Matthew's payment server: https://uswest2-moc-payment-vpc.manything.com
// Oana's payment server: https://uswest2-oanad-payment-vpc.manything.com
// Oana's auth: https://uswest2-oanad-auth-vpc.manything.com

export const getPaymentServerForRegion = region => region === 'uswest2' ? DEVELOPMENT_PAYMENT_SERVER : PRODUCTION_PAYMENT_SERVER;

export const getAuthenticatorForRegion = region => `https://${region}-auth-1.manything.com`;

export const getVideoServerForRegion = (region = getRegion()) => `https://${region}-video.manything.com`;

export const getAnalyticsServerForRegion = (region = getRegion()) => `https://${region}-analytics.manything.com`;

// auth1.manything.com blocks localhost by CORS policy
export const DEFAULT_AUTH =
    window.location.hostname === 'localhost'
        ? 'https://uswest2-auth-1.manything.com'
        : 'https://auth1.manything.com';

export const DEFAULT_PAYMENT_SERVER = 
    window.location.hostname === 'localhost'
        ? DEVELOPMENT_PAYMENT_SERVER
        : PRODUCTION_PAYMENT_SERVER;