import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import translationKeys from '../../translations/keys';

import { ReactComponent as CrossIcon } from '../../assets/images/icons/close.svg';

import { ClearButton, SearchBoxContainer, SearchBoxInput } from './SearchBox.styles';

/** Search box input component. */
const SearchBox = ({ placeholder, value, onChange, large = false, t, tReady, style, ...props }) => {

    const showClearButton = !!value?.length;

    return (
        <SearchBoxContainer
            $large={large}
            // style attribute gets placed on container
            // May need to create `inputStyle` prop if we want to apply inline styles to <input> too
            style={style}
        >
            <SearchBoxInput
                placeholder={placeholder ?? (tReady ? t(translationKeys.actions.SEARCH) : 'Search')}
                value={value}
                onChange={(event) => onChange(event.target.value)}
                $large={large}
                {...props}
            />

            {
                !props.readOnly && (
                    <ClearButton
                        type="button"
                        aria-label={tReady ? t(translationKeys.forms.CLEAR) : 'Clear'}
                        onClick={() => onChange('')}
                        $large={large}
                        style={{
                            opacity: showClearButton ? 1 : 0,
                            cursor: showClearButton ? 'pointer' : undefined
                        }}
                        // Stop it being focusable when hidden
                        tabIndex={showClearButton ? undefined : -1}
                        aria-hidden={showClearButton ? undefined : 'true'}
                        variant={props.variant}
                    >
                        <CrossIcon
                            aria-hidden="true"
                        />
                    </ClearButton>
                )
            }
            
        </SearchBoxContainer>
    );
};

SearchBox.propTypes = {
    /** Placeholder text. */
    placeholder: PropTypes.string,
    /** Value of input field. */
    value: PropTypes.string.isRequired,
    /** Function called whenever user changes value of field. Function takes new search string as only argument (note that it does not take the whole event object, just event.target.value). */
    onChange: PropTypes.func.isRequired,
    /** Use larger height. */
    large: PropTypes.bool
};

export default withTranslation()(SearchBox);
