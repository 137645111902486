import { useRef, useLayoutEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

/**
 * @callback callback
 */

/**
 * Runs a callback function whenever a specified element changes size.
 *
 * @param {object} targetRef - React ref of element we are monitoring.
 * @param {callback} callback - Runs when element changes size. Gets passed element's `contentRect` and the element itself.
 */
const useResizeOberserver = (targetRef, callback) => {

    // Resize observer
    const observerRef = useRef(
        new ResizeObserver((entries) => {
            callback(entries[0].contentRect, entries[0].target);
        })
    );

    // Observe on mount
    // Disconnect when unmounted
    // Note that ref must be ready when this effect runs and last until component is unmounted for this to work
    useLayoutEffect(() => {
        const observer = observerRef.current;
        
        if (targetRef.current) {
            observer.observe(targetRef.current);
        }

        return () => observer.disconnect();
    }, [targetRef]);
};

export default useResizeOberserver;
