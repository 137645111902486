import { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { usePopper } from 'react-popper';

import { isUsingMouse } from '../../utils/pointer';

/**
 * Config to pass to usePopper. See https://popper.js.org/docs/v2/constructors for more info.
 * 
 * @typedef Config
 * @type {object}
 * @property {boolean} [arrow] Show arrow.
 * @property {boolean} [fixedPositioning] Determines which usePopper positioning strategy to use ('fixed' or 'absolute').
 * @property {('auto', 'auto-start', 'auto-end', 'top', 'top-start', 'top-end', 'bottom', 'bottom-start', 'bottom-end', 'right', 'right-start', 'right-end', 'left', 'left-start', 'left-end')} [placement] Placement of popup.
 * @property {number} [preventOverflowPadding] Minimum distance in px between popup and container edges. E.g. useful for keeping popup away from screen edge.
 * @property {number[]} [offset] Specify custom offset to displace the popup from the trigger button. This is an array of two numbers [x, y], where x is displacement along the trigger button edge and y is displacement away from trigger button edge.
 */

/**
 * @typedef PopupProps
 * @type {object}
 * @property {boolean} open Is popup open?
 * @property {closePopup} closePopup Function to close popup.
 * @property {popupRef} ref Callback ref for popup.
 * @property {object} style Styles to apply to popup.
 * @property {('top', 'bottom', 'left', 'right')} placement Placement of popup.
 * @property {object} arrowProps Props for arrow.
 * @property {arrow} arrowProps.ref Callback ref for arrow.
 * @property {object} arrowProps.style Styles to apply to arrow.
 * @property {number} arrowProps.size Size of arrow in px.
 */

/**
 * @callback closePopup
 */

/**
 * @callback popupRef
 */

/**
 * @callback arrowRef
 */

/**
 * @callback triggerButtonRef
 */

/**
 * @typedef TriggerButtonProps
 * @type {object}
 * @property {TriggerButtonOnClick} onClick Function called when button clicked.
 * @property {triggerButtonRef} ref Callback ref for button.
 */

/**
 * @callback TriggerButtonOnClick
 */

/**
 * Custom hook for managing popup state and props.
 * Returns props to pass to Popup component and to the Button component which triggers the popup.
 * 
 * @param {Config} config Config to pass to usePopper.
 * @returns {[PopupProps, TriggerButtonProps]}
 */
const usePopup = ({ arrow = true, fixedPositioning = false, placement = 'auto', preventOverflowPadding = 40, offset = null, data } = {}) => {

    // State of popup
    const [open, setOpen] = useState(false);
    
    // Function to close popup
    const closePopup = useCallback(() => {
        setOpen(false);
    }, []);

    // Function to open popup
    const openPopup = useCallback(() => {
        setOpen(true);
    }, []);

    // Open popup via mouse hover
    const openPopupThroughHover = useCallback(() => {
        if (isUsingMouse()) {
            openPopup(true);
        }
    }, [openPopup]);

    // Callback refs for trigger button, popup container and popup arrow
    const [triggerButtonElement, setTriggerButtonElement] = useState(null);
    const [popupContainerElement, setPopupContainerElement] = useState(null);
    const [arrowElement, setArrowElement] = useState(null);

    // usePopper handles positioning the popup
    const modifiers = useMemo(() => {
        const modifiers = [];

        if (offset || arrow) {
            modifiers.push({
                name: 'offset',
                options: {
                    offset: offset ?? [0, ARROW_SIZE]
                }
            });
        }

        if (arrow) {
            modifiers.push({
                name: 'arrow',
                options: {
                    element: arrowElement,
                    // Allow for rounded borders of popup
                    padding:  10
                }
            });
        }

        if (preventOverflowPadding) {
            // Prevent popup from touching screen edge
            modifiers.push({
                name: 'preventOverflow',
                options: {
                    padding: preventOverflowPadding
                },
            });
        }

        return modifiers;
    }, [arrow, preventOverflowPadding, arrowElement, offset]);
    const { styles, attributes, update } = usePopper(triggerButtonElement, popupContainerElement, {
        modifiers,
        placement,
        strategy: fixedPositioning ? 'fixed' : 'absolute'
    });

    useEffect(() => {
        if (data) {
            // update positioning on data change
            update && update();
        }
    }, [data]);
    
    // Close popup when user clicks outside
    // Rarely used now popups are mainly controlled through hover
    useEffect(() => {
        if (open) {
            const mouseListener = (event) => {
                if (
                    !popupContainerElement?.contains(event.target) &&
                    !triggerButtonElement?.contains(event.target)
                ) {
                    closePopup();
                }
            };

            document.addEventListener('mousedown', mouseListener);

            return () => document.removeEventListener('mousedown', mouseListener);
        }
    }, [closePopup, popupContainerElement, triggerButtonElement, open]);

    // If popup was opened through hover, add listeners for closing popups when mouse leaves
    useEffect(() => {
        if (open && isUsingMouse()) {

            // If the user opened the popup without using a mouse, the cursor may not initially be in the bounding area
            // Only close the popup on mouse leave once the mouse has entered the area
            let hasEnteredArea = false;

            // Close popup when mouse leaves bounding area containing both trigger button and popup
            const onMouseMove = (event) => {
                const { x: triggerX, y: triggerY, width: triggerWidth, height: triggerHeight } = triggerButtonElement.getBoundingClientRect();
                const { x: popupX, y: popupY, width: popupWidth, height: popupHeight } = popupContainerElement.getBoundingClientRect();
                
                // Calculate bounding area that includes both button and popup
                const leftX = Math.min(triggerX, popupX);
                const rightX = Math.max(triggerX + triggerWidth, popupX + popupWidth);
                const topY = Math.min(triggerY, popupY);
                const bottomY = Math.max(triggerY + triggerHeight, popupY + popupHeight);

                // Mouse coordinates can be rounded
                // Adding a tolerance of 1px extra to each bounding coordinate to allow for this
                const tolerance = 1;

                // Is the mouse in the bounding area?
                const mouseInBoundingArea = (
                    event.clientX >= leftX - tolerance &&
                    event.clientX <= rightX + tolerance &&
                    event.clientY >= topY - tolerance &&
                    event.clientY <= bottomY + tolerance
                );

                if (hasEnteredArea && !mouseInBoundingArea) {
                    // The mouse has left the bounding area having previously been inside it
                    closePopup();
                } else if (!hasEnteredArea && mouseInBoundingArea) {
                    // The mouse has moved into the bounding area for the first time
                    hasEnteredArea = true;

                    // Now also add mouse over listener
                    document.addEventListener('mouseover', onMouseOver);
                }
            }

            // If mouse hovers over another unrelated interactable element within the bounding area
            // (e.g. a button for a different popup), close the popup
            const onMouseOver = (event) => {
                if (
                    // The hovered over element must not be part of this popup ...
                    !popupContainerElement?.contains(event.target) &&
                    // ... or the trigger button ...
                    !triggerButtonElement?.contains(event.target) &&
                    // ... and it must be interactable
                    event.target.matches(`button, [href], input, select, textarea, [tabindex]`)
                ) {
                    closePopup();
                }
            }

            window.addEventListener('mousemove', onMouseMove);
            
            return () => {
                window.removeEventListener('mousemove', onMouseMove);
                document.removeEventListener('mouseover', onMouseOver);
            };
        }
    }, [open, triggerButtonElement, popupContainerElement, closePopup]);

    return [
        // Popup props
        {
            open,
            closePopup, // Not used by Popup.jsx, but used by variations like PopupList.jsx
            ref: setPopupContainerElement,
            style: styles.popper,
            placement: attributes?.popper?.['data-popper-placement'],
            arrowProps: arrow ? {
                ref: setArrowElement,
                style: styles.arrow,
                size: ARROW_SIZE
            } : undefined
        },

        // Trigger button props
        {
            onClick: open ? closePopup : openPopup,
            onMouseEnter: openPopupThroughHover,
            ref: setTriggerButtonElement,
        },
    ];
};

// In px
const ARROW_SIZE = 10;

export default usePopup;