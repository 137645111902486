import styled from 'styled-components';

import { VL_BLACK, WHITE } from '../../../utils/colours';


export const DateRangeSelector = styled.button`
    background: ${WHITE()};
    padding: 0 5px;
    margin: 0;
    border: 1px solid
        ${({ $selected, $colour }) => ($selected ? $colour : VL_BLACK())};
    font-weight: 100;
    cursor: pointer;
    width: 100%;
    height: 24px;
`;

DateRangeSelector.displayName = 'DateRangeSelector';