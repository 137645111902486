/*
    Exports functions for making API requests. All requests should go through a function in this file.
*/

import axios from 'axios';
import qs from 'qs';

import { isDevelopment } from "../../utils/getHost";
import { clearDetailsAndTokens, getAccountType, getAllDetails, getAllTokens, getRegion, getAuthToken, getInstallerId, getManagementToken, getManagerOverrideInstallerId, getManagerOverrideUid, getUid, updateDetails, getManagerOverrideDistributorId, getDistributorId, getManagerOverrideRegion, updateTokens } from './store';
import { DEFAULT_AUTH, DEFAULT_PAYMENT_SERVER, DEVELOPMENT_PAYMENT_SERVER, PRODUCTION_PAYMENT_SERVER, getAnalyticsServerForRegion, getAuthenticatorForRegion, getPaymentServerForRegion, getVideoServerForRegion } from './servers';

/**
 * Make a request without using any stored data or tokens.
 * @param {string} server Which server to send request to. If string does not start with "https://" it will added automatically (e.g. useful when passing loggers). You can also pass in "auth" or "payment" to use the relevant auth/payment server for the provided region, or the default server if no region is provided. Same applies to "video" except that a region must be provided in `config` to use it.
 * @param {('GET', 'POST', 'PUT', 'PATCH', 'DELETE')} method 
 * @param {string} endpoint Path.
 * @param {object} config Axios config (e.g. headers, params, data). Also can include some of our own added config.
 * @param {string} [config.region] Region to use if `server` is "auth" or "payment".
 * @returns Axios promise.
 */
export const makePublicRequest = (
    server,
    method,
    endpoint,
    {
        region,
        ...axiosConfig
    } = {}
) => {

    if (server === 'auth') {
        if (isDevelopment && sessionStorage.authenticator) {
            server = sessionStorage.authenticator;
        } else {
            server = region ? getAuthenticatorForRegion(region) : DEFAULT_AUTH;
        }
    } else if (server === 'payment') {
        if (isDevelopment && sessionStorage.paymentServer) {
            server = sessionStorage.paymentServer;
        } else {
            server = region ? getPaymentServerForRegion(region) : DEFAULT_PAYMENT_SERVER;
        }
    } else if (server === 'video') {
        if (isDevelopment && sessionStorage.videoServer) {
            server = sessionStorage.videoServer;
        } else {
            server = getVideoServerForRegion(region);
        }
    } else if (server === 'analytics') {
        if (isDevelopment && sessionStorage.analyticsServer) {
            server = sessionStorage.analyticsServer;
        } else {
            server = getAnalyticsServerForRegion(region);
        }
    } else if (!server.match(/^https:\/\//)) {
        // Probably a logger
        server = `https://${server}`;
    }

    const args = {
        method,

        // Replace :uid and :installerId with actual data (if request is being made by a staff member to view an installer/customer, must not use the staff member's own details)
        url: server + endpoint,

        // Each array item without the brackets
        // e.g. `uidd=1.1&uidd=1.2` instead of default `uidd[]=1.1&uidd[]=1.2`
        paramsSerializer: (params) =>
            qs.stringify(params, { arrayFormat: 'repeat' }),

        ...axiosConfig,
    };
    
    return axios(args);
}

/**
 * @callback makePrivateRequest
 * @param {string} server Which server to send request to. If string does not start with "https://" it will added automatically (e.g. useful when passing loggers). You can also pass in "auth" or "payment" to use the relevant auth/payment server for the provided region, or the default server if no region is provided. Same applies to "video" except that a region must be stored or provided to use it.
 * @param {('GET', 'POST', 'PUT', 'PATCH', 'DELETE')} method HTTP method.
 * @param {string} endpoint Path.
 * @param {object} config Axios config (e.g. headers, params, data). Also can include some of our own added config.
 * @param {string} [config.region] Region to use if `server` is "auth", "payment" or "video".
 * @returns Axios promise.
 */

/**
 * @typedef PrivateRequestData
 * @type {object}
 * @property {makePrivateRequest} makePrivateRequest
 * @property {number} [uid]
 * @property {string} region
 * @property {string} [installerId]
 * @property {string} token
 */

/**
 * @callback privateApiRequest
 * @param {PrivateRequestData} requestData
 * @param {...any} args Specific arguments required by function.
 * @returns {*}
 */

/**
 * 
 * @param {PrivateRequestData} requestData 
 * @returns {makePrivateRequest}
 */
const createMakePrivateRequest = ({ token, region, ...details }) => {
    // `makePrivateRequest` takes same arguments as `makePublicRequest`
    const makePrivateRequest = (
        server,
        method,
        endpoint,
        config = {}
    ) => {

        // Replace :uid and :installerId with actual data
        for (const detail of ['uid', 'installerId']) {
            endpoint = endpoint.replace(new RegExp(`:${detail}`, 'g'), details[detail]);
        }

        // Add token if config hasn't overwritten Authorization
        if (token && !config.headers?.Authorization) {
            (config.headers ?? (config.headers = {})).Authorization = `ManythingToken ${token}`;
        }

        return makePublicRequest(
            server,
            method,
            endpoint,
            {
                region,
                ...config
            }
        );
    }

    return makePrivateRequest;
}

/**
 * Make a private request using the given data.
 * @param {PrivateRequestData} requestData 
 * @param {privateApiRequest} apiRequest 
 * @returns {Function} Function which invokes the given API request with request data and any arguments it gets given.
 */
export const makePrivateRequestWithData = (requestData, apiRequest) => {

    const makePrivateRequest = createMakePrivateRequest(requestData);

    return (...args) => apiRequest({ ...requestData, makePrivateRequest }, ...args);
}

/**
 * Make a private request for a resource owned by the logged in user.
 * @param {privateApiRequest} apiRequest 
 * @returns {Function} Function which invokes the given API request with request data and any arguments it gets given.
 */
export const makePrivateRequestAsUser = apiRequest => {
    
    return (...args) => {

        const data = {
            installerId: getInstallerId(),
            region: getRegion(),
            uid: getUid(),
            accountType: 'u',
            token: getAuthToken()
        };
        
        const makePrivateRequest = createMakePrivateRequest(data);

        return apiRequest({ ...data, makePrivateRequest }, ...args);
    };
}

/**
 * Make a private request as a manager for a user or installer resource.
 * @param {privateApiRequest} apiRequest 
 * @param {object} config Use to specify `resourceType` or override any request data.
 * @param {('u', 'i', 's')} [config.resourceType] Type of requested resource.
 * @returns {Function} Function which invokes the given API request with request data and any arguments it gets given.
 */
export const makePrivateRequestAsManager = (apiRequest, { resourceType = 'u', ...overrideDetails } = {}) => {

    return (...args) => {

        const accountType = getAccountType();
    
        const data = {
            managerUid: getUid(),
            // Include uid if accessing a user resource
            uid: resourceType === 'u' ? getManagerOverrideUid() : undefined,
            // If logged in user is an installer manager, they can only access their installer id
            // Staff can access any installer id
            installerId: accountType === 'i' ? getInstallerId() : getManagerOverrideInstallerId(),
            // Only user resources can be regional
            region: resourceType === 'u' ? getManagerOverrideRegion() : getRegion(),
            accountType,
            token: getManagementToken(),
            ...overrideDetails
        };

        const makePrivateRequest = createMakePrivateRequest(data);

        return apiRequest({ ...data, makePrivateRequest }, ...args);
    };
}




/* -------------------------------------------------------------------------- */
/*                           Old request functions.                           */
/* -------------------------------------------------------------------------- */
/* ----------- They're being phased out. Do not use them anymore. ----------- */
/* -------------------------------------------------------------------------- */

// Auth

/**
 * Use to make auth requests that don't need/use a token or user info. You can still specify which auth to use through `regionOrAuthenticator`.
 * @param {('GET', 'POST', 'PUT', 'PATCH', 'DELETE')} method 
 * @param {string} endpoint path
 * @param {Object} [config] axios config (e.g. params, body)
 * @param {string} [regionOrAuthenticator] the authenticator or region (from which authenticator will be identified) to use
 * @returns axios promise
 * @deprecated
 */
export const publicAuthenticatorRequest = (method, endpoint, config = {}, regionOrAuthenticator) => authenticatorRequest(regionOrAuthenticator, method, endpoint, config);

/**
 * Auth requests that need a token or user info.
 * @param {('GET', 'POST', 'PUT', 'PATCH', 'DELETE')} method
 * @param {string} endpoint path
 * @param {Object} config axios config (e.g. params, body)
 * @param {*} managementToken whether to use management token instead of end user auth token
 * @param {*} allowsStaffAccess if this is true and the logged in user is a staff member, staffOverrideDetails from store.js will be used as appropriate
 * @returns axios promise
 * @deprecated
 */
export const privateAuthenticatorRequest = (
    method,
    endpoint,
    config = {},

    // false = end user auth token
    // true = management token
    managementToken = false,

    // Whether this request can be used by staff accounts to view/edit installers/customers
    allowsStaffAccess = false
) => authenticatorRequest(getRegion() && getAuthenticatorForRegion(getRegion()), method, endpoint, config, managementToken ? 'management' : 'auth', allowsStaffAccess);

const authenticatorRequest = (regionOrAuthenticator, ...args) => {

    // If authenticator unspecified, use first available from session storage (development only) or default
    let server;
    if (regionOrAuthenticator) {
        server = /^https:\/\//.test(regionOrAuthenticator) ? regionOrAuthenticator : getAuthenticatorForRegion(regionOrAuthenticator);
    } else {
        server = DEFAULT_AUTH;
    }

    if (isDevelopment && sessionStorage.authenticator) {
        server = sessionStorage.authenticator;
    }

    return makeRequest(server, ...args);
}


// Payment

/**
 * Use to make payment requests that do not need a token.
 * @param {('GET', 'POST', 'PUT', 'PATCH', 'DELETE')} method 
 * @param {string} endpoint path
 * @param {Object} config axios config (e.g. params, body)
 * @returns axios promise
 * @deprecated
 */
export const publicPaymentServerRequest = (method, endpoint, config = {}) => paymentServerRequest(method, endpoint, config);

/**
 * Payment requests that require token.
 * @param {('GET', 'POST', 'PUT', 'PATCH', 'DELETE')} method 
 * @param {string} endpoint path
 * @param {Object} config axios config (e.g. params, body)
 * @param {*} managementToken whether to use management token instead of end user auth token
 * @param {*} allowsStaffAccess if this is true and the logged in user is a staff member, staffOverrideDetails from store.js will be used as appropriate
 * @returns axios promise
 * @deprecated
 */
export const privatePaymentServerRequest = (
    method,
    endpoint,
    config = {},

    // false = end user auth token
    // true = management token
    managementToken = false,

    // Whether this request can be used by staff accounts to view/edit installers/customers
    allowsStaffAccess = false
) => paymentServerRequest(method, endpoint, config, managementToken ? 'management' : 'auth', allowsStaffAccess);

const paymentServerRequest = (...args) => {

    // Use first available from user's details, session storage (development only), or default
    let server = (getRegion() && getPaymentServerForRegion(getRegion())) ?? (isDevelopment ? DEVELOPMENT_PAYMENT_SERVER : PRODUCTION_PAYMENT_SERVER);

    if (isDevelopment && sessionStorage.paymentServer) {
        server = sessionStorage.paymentServer;
    }

    return makeRequest(server, ...args);
}


// Logger

/**
 * Logger requests always private.
 * @param {string} logger logger to send request to (without "https://")
 * @param {('GET', 'POST', 'PUT', 'PATCH', 'DELETE')} method 
 * @param {string} endpoint path
 * @param {Object} config axios config (e.g. params, body)
 * @param {boolean} managementToken Use management token instead of end user auth token.
 * @returns axios promise
 * @deprecated
 */
export const privateLoggerRequest = (logger, method, endpoint, config={}, managementToken = false) => {
    if (isDevelopment && sessionStorage.logger) {
        logger = sessionStorage.logger;
    }

    // `logger` does not include 'https://' unlike auth and payment server
    return makeRequest('https://' + logger, method, endpoint, config, managementToken ? 'management' : 'auth');
}


// Video

/**
 * @deprecated
 */
export const privateVideoRequest = (method, endpoint, config = {}) => {
    let server = getVideoServerForRegion();

    if (isDevelopment && sessionStorage.videoServer) {
        server = sessionStorage.videoServer;
    }

    return makeRequest(server, method, endpoint, config, 'auth');
}


// All API requests go through this function
// `tokenType` can be 'management' or 'auth'
/**
 * @deprecated
 */
export const makeRequest = (server, method, endpoint, config, tokenType, allowsStaffAccess) => {

    // Use staff override if allowed by the endpoint, the logged in user is staff and relevant override details have been stored
    const useStaffOverride = !!(allowsStaffAccess && getAccountType() === 's' && (getManagerOverrideUid() || getManagerOverrideInstallerId()));

    // Get relevant token (if applicable)
    // For staff overrides we must always use management token
    const token = tokenType && (tokenType === 'management' || useStaffOverride ? getManagementToken() : getAuthToken());

    const args = {
        method,

        // Replace :uid and :installerId with actual data (if request is being made by a staff member to view an installer/customer, must not use the staff member's own details)
        url: server + (token ? endpoint.replace(/:uid/g, useStaffOverride ? getManagerOverrideUid() : getUid()).replace(/:installerId/g, useStaffOverride ? getManagerOverrideInstallerId() : getInstallerId()).replace(/:distributorId/g, useStaffOverride ? getManagerOverrideDistributorId() : getDistributorId()) : endpoint),

        // Each array item without the brackets
        // e.g. `uidd=1.1&uidd=1.2` instead of default `uidd[]=1.1&uidd[]=1.2`
        paramsSerializer: (params) =>
            qs.stringify(params, { arrayFormat: 'repeat' }),

        ...config,
    };

    // Add token if given AND config hasn't overwritten Authorization
    if (token && !config?.headers?.Authorization) {
        (args.headers ?? (args.headers = {})).Authorization = `ManythingToken ${token}`;
    }
    
    return axios(args);
}

// Use this function to make a request with different details/tokens
// This function needs a better name!
// It could also do with a better solution than actually (temporarily) overwriting stored info
/**
 * @deprecated
 */
export const requestGenerator = (tempDetails = {}, tempTokens = {}) => (apiFunction, ...args) => new Promise((res, rej) => {
    // Store current info
    const storedDetails = getAllDetails();
    const storedTokens = getAllTokens();

    const overwriteDetailsAndTokens = (newDetails, newTokens) => {
        clearDetailsAndTokens();
        updateDetails(newDetails.uid, newDetails.region, newDetails.installerId, newDetails.accountType, newDetails.distributorId);
        updateTokens(newTokens.auth, newTokens.management);
    }

    // Temporary overwrite
    overwriteDetailsAndTokens(tempDetails, tempTokens);

    // Make request
    apiFunction(...args).then(res).catch(rej);

    // Restore original info
    overwriteDetailsAndTokens(storedDetails, storedTokens);
});
