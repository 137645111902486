import { forwardRef } from "react";
import { TransformWrapper, TransformComponent, useControls } from "react-zoom-pan-pinch";
import { withTranslation } from "react-i18next";
import PropTypes from 'prop-types';

import translationKeys from '../../translations/keys';

import { ReactComponent as ZoomInIcon } from '../../assets/images/icons/plus.svg';
import { ReactComponent as ZoomOutIcon } from '../../assets/images/icons/minus.svg';
import { ReactComponent as ResetZoomIcon } from '../../assets/images/icons/reset.svg';
import { ZoomButton, ZoomButtonsContainer } from "./ZoomableWrapper.styles";


/** Wrapper to make content zoomable. Remember to use `useKeyboardZoomControls` as well for accessibility. */
export const ZoomableWrapper = forwardRef(({
    children,
    disabled = false,
    ...props
}, ref) => {

    return (
        <TransformWrapper
            ref={ref}
            doubleClick={{
                disabled: true
            }}
            disablePadding
            disabled={disabled}
            {...props}
        >
            { children }
        </TransformWrapper>
    );
});

ZoomableWrapper.propTypes = {
    /** Disable zooming. */
    disabled: PropTypes.bool
};


/** Put this component inside ZoomableWrapper. The content you want to be zoomable must be a child of this component. */
export const ZoomableContent = ({
    children
}) => {

    return (
        <TransformComponent
            // Need to fill parent
            // TransformComponent creates two divs: wrapper and content
            wrapperStyle={{
                width: '100%',
                height: '100%',
            }}
            contentStyle={{
                width: '100%',
                height: '100%'
            }}
        >
            { children }
        </TransformComponent>
    );
}

/** Optional zoom controls to appear in the top right corner. Position this component as a sibling of `ZoomableContent`. */
export const ZoomControls = withTranslation()(({
    tReady,
    t,
    ...props
}) => {
    const { zoomIn, zoomOut, resetTransform } = useControls();

    return (
        <ZoomButtonsContainer
            {...props}
        >
            {/* Zoom in */}
            <ZoomButton
                onClick={() => zoomIn()}
                aria-label={tReady ? t(translationKeys.cameras.ptz.ZOOM_IN) : 'Zoom in'}
            >
                <ZoomInIcon />
            </ZoomButton>

            {/* Reset */}
            <ZoomButton
                onClick={() => resetTransform()}
                aria-label={tReady ? t(translationKeys.actions.RESET) : 'Reset'}
            >
                <ResetZoomIcon />
            </ZoomButton>

            {/* Zoom out */}
            <ZoomButton
                onClick={() => zoomOut()}
                aria-label={tReady ? t(translationKeys.cameras.ptz.ZOOM_OUT) : 'Zoom out'}
            >
                <ZoomOutIcon />
            </ZoomButton>
        </ZoomButtonsContainer>
    );
});