import styled from 'styled-components';
import { DEFAULT_BACKGROUND_GRADIENT } from '../../../utils/colours';

export const MaxWidthContainer = styled.div`
    max-width: ${({ $maxWidth }) =>
        typeof $maxWidth === 'number' ? `${$maxWidth}px` : $maxWidth};
    margin: auto;
    background: ${({ $background }) => $background ? `${$background}` : DEFAULT_BACKGROUND_GRADIENT};
`;

MaxWidthContainer.displayName = 'MaxWidthContainer';
