import styled from 'styled-components';

export const VideoPlayerContainer = styled.div`
    width: 100%;
    height: 100%;
    
    ::before {
        content: "";
        background: black;
        opacity: 0;
        pointer-events: none;
        z-index: 10;
        display: block;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        transition: opacity 0.08s ease;
    }
    
    ${({ $overlay }) => $overlay && `
        ::before {
            opacity: 0.3;
        }
    `}
`;

VideoPlayerContainer.displayName = 'VideoPlayerContainer';


/* -------------------------------------------------------------------------- */
/*                               Video controls                               */
/* -------------------------------------------------------------------------- */

export const VideoPlayerWithControlsContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    ${({ $hideCursor }) => $hideCursor ? `
        cursor: none !important;

        * {
            cursor: none !important;
        }
    ` : ''}
`;

VideoPlayerWithControlsContainer.displayName = 'VideoPlayerWithControlsContainer';


export const VideoKeyboardAndTouchInterceptor = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    outline: none;

    /* Bring outline to top. */
    &:focus-visible::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        border: 2px solid #4D90FE;
        z-index: 1;
    }
`;

VideoKeyboardAndTouchInterceptor.displayName = 'VideoKeyboardAndTouchInterceptor';


export const VideoControlBar = styled.div`
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 34px;
    background: linear-gradient(to bottom,#0000,#0009,#0009);
    background: #0009;

    display: grid;
    grid-template-columns: auto 1fr auto auto;
    grid-template-rows: 100%;
    grid-template-areas:
        "playButton scrubBar volumeButton modeButton fullscreenButton";
    align-items: center;
    padding: 0 10px;
    gap: 8px;
    transition: opacity .3s;

    ${({ $playing, $controlsFocused, $touchActive, $hideUIInFullscreen }) => $playing && !$controlsFocused && !$touchActive ? `
        opacity: 0;
        
        @media (hover: hover) {
            ${VideoPlayerWithControlsContainer}:hover & {
                opacity: 1;
            }
        }
        
        ${
            // I tried only adding media query above if `$hideUIInFullscreen` was false,
            // but browser CSS still continued to include it even when this string didn't
            $hideUIInFullscreen ? 'opacity: 0 !important;' : ''
        }

    ` : ''}
`;

VideoControlBar.displayName = 'VideoControlBar';


export const PlayButtonContainer = styled.div`
    grid-area: playButton;
`;

PlayButtonContainer.displayName = 'PlayButtonContainer';


const scrubBarTrackHeight = 4;
const scrubBarPlayedColour = '#fff';
const scrubBarLoadedColour = '#888';
const scrubBarUnloadedColour = '#5a5a5a';
const scrubBarThumbDiameter = 10;
export const ScrubBarContainer = styled.div`
    grid-area: scrubBar;
    display: grid;
    grid-template-columns: 30px 1fr 30px;
    align-items: center;
    gap: 10px;
    color: #fff;
    font-weight: 100;

    span {

        &:first-child {
            text-align: right;
        }

        font-size: 85%;
    }

    input[type=range] {

        cursor: pointer;
        height: ${scrubBarThumbDiameter}px;
        width: 100%;

        &::-webkit-slider-runnable-track {
            height: ${scrubBarTrackHeight}px;
            border-radius: ${scrubBarTrackHeight}px;
            cursor: inherit;
        }

        &::-webkit-slider-runnable-track, &:focus::-webkit-slider-runnable-track {
            background: linear-gradient(
                to right,
                ${scrubBarPlayedColour} 0 var(--percentage-played),
                ${scrubBarLoadedColour} var(--percentage-played) var(--percentage-loaded),
                ${scrubBarUnloadedColour} var(--percentage-loaded) 100%
            );
        }
        &::-webkit-slider-thumb {
            margin-top: ${(scrubBarTrackHeight - scrubBarThumbDiameter) / 2}px;
            background: ${scrubBarPlayedColour};
            width: ${scrubBarThumbDiameter}px;
            height: ${scrubBarThumbDiameter}px;
        }

        &::-moz-range-track {
            border-radius: ${scrubBarTrackHeight}px;
            height: ${scrubBarTrackHeight}px;
            background: linear-gradient(
                to right,
                ${scrubBarPlayedColour} 0 var(--percentage-played),
                ${scrubBarLoadedColour} var(--percentage-played) var(--percentage-loaded),
                ${scrubBarUnloadedColour} var(--percentage-loaded) 100%
            );
            cursor: inherit;
        }
        &::-moz-range-thumb {
            background: ${scrubBarPlayedColour};
            width: ${scrubBarThumbDiameter}px;
            height: ${scrubBarThumbDiameter}px;
        }

        &::-ms-fill-upper, &::-ms-fill-lower {
            border-radius: ${scrubBarTrackHeight}px;
        }
        &::-ms-track {
            height: ${scrubBarTrackHeight}px;
            cursor: inherit;
        }
        &::-ms-thumb {
            background: ${scrubBarPlayedColour};
            width: ${scrubBarThumbDiameter}px;
            height: ${scrubBarThumbDiameter}px;
        }

        &:disabled {
            cursor: not-allowed;

            &::-webkit-slider-thumb {
                display: none;
            }

            &::-moz-range-thumb {
                display: none;
            }

            &::-ms-thumb {
                display: none;
            }
        }

        @media (hover: hover) {
            &::-webkit-slider-thumb {
                opacity: 0;
                transition: opacity .2s;
            }

            &:hover::-webkit-slider-thumb {
                opacity: 1;
            }

            &::-moz-range-thumb {
                opacity: 0;
                transition: opacity .2s;
            }

            &:hover::-moz-range-thumb {
                opacity: 1;
            }

            &::-ms-thumb {
                opacity: 0;
                transition: opacity .2s;
            }

            &:hover::-ms-thumb {
                opacity: 1;
            }
        }
    }
`;

ScrubBarContainer.displayName = 'ScrubBarContainer';


export const VolumeButtonContainer = styled.div`
    grid-area: volumeButton;
`;

VolumeButtonContainer.displayName = 'VolumeButtonContainer';


export const FullscreenButtonContainer = styled.div`
    grid-area: fullscreenButton;
`;

FullscreenButtonContainer.displayName = 'FullscreenButtonContainer';


export const ModeButtonContainer = styled.div`
    grid-area: modeButton;
`;

ModeButtonContainer.displayName = 'ModeButtonContainer';


const videoControlButtonDiameter = 28; // In px
export const VideoControlButton = styled.button`
    width: ${videoControlButtonDiameter}px;
    height: ${videoControlButtonDiameter}px;
    background: none;
    border: none;
    padding: 0;
    border-radius: ${videoControlButtonDiameter}px;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    svg {
        color: #fff;
        width: auto;
        height: auto;
        max-width: 14px;
        max-height: 14px;

        /* Safari needs this. */
        flex-grow: 1;
    }
    
    @media (hover: hover) {
        :hover {
            background: #fff3;
        }
    }

    :focus-visible {
        background: #fff3;
    }

    [disabled],
    &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
        background: none;
    }
`;

VideoControlButton.displayName = 'VideoControlButton';