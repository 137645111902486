import styled from 'styled-components';

const ContainerStyles = styled.div`
    padding: 30px;
    margin-top: 30px;
    border-radius: 30px;
    border: 1px solid ${({ $colour }) => $colour};
    box-shadow: 0 0 20px 0 #6b6b6b26;
    background: ${({ $colour }) => $colour};

    & > *:first-child {
        margin-top: 0;
    }
`;

ContainerStyles.displayName = 'ContainerStyles';

export default ContainerStyles;
