import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import Input from '../../input';
import NumberControlsInput from '../../input/number-controls-input';
import Dropdown from '..';

/** Dropdown field where when final 'Other' option is clicked it is replaced by an input field. */
const OpenEndedDropdown = ({
    value = '',
    options,
    otherOption = 'Other',
    showNumberControls = false,
    ...props
}) => {
    // Show input field if initial value is not one of the given options
    const [showInput, setShowInput] = useState(
        isOtherOptionSelected(options, value)
    );

    // If we're not showing input field and value is changed to something not in given options, start showing input field
    useEffect(() => {
        if (
            !showInput &&
            isOtherOptionSelected(options, value)
        ) {
            setShowInput(true);
        }
    }, [value, showInput, options]);

    if (showInput) {
        // Show NumberControlsInput if requested, otherwise just normal Input
        const Component = showNumberControls ? NumberControlsInput : Input;
        return <Component value={value} {...props} />;
    } else {
        return (
            <Dropdown
                options={options.concat(otherOption)}
                value={value}
                {...props}
            />
        );
    }
};

OpenEndedDropdown.propTypes = {
    /** Value of field. */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** Use NumberControlsInput rather than Input. */
    showNumberControls: PropTypes.bool,
    /** List of possible options for select field (not including the `otherOption`). Each array element can be object with properties `value` and `text` or just a string which is used as both option's value and text. */
    options: PropTypes.array.isRequired,
    /** Last option displayed in dropdown which changes the field from a select to an input when clicked. Like the `options`, it can be either an object with properties `value` and `text`, or just a string which is used as both. */
    otherOption: PropTypes.oneOfType([
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            text: PropTypes.string.isRequired,
        }),
        PropTypes.string,
        PropTypes.number,
    ]),
};

const isOtherOptionSelected = (options, value) => !options.some(
    (option) =>
        (option.value ?? option).toString() === value.toString()
);

export default OpenEndedDropdown;
