// Format function to pass to i18n
// Also imported by mock i18n module
const format = (value, format, locale, timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone) => {
    // Display dates in local format
    if (value instanceof Date) {
        if (format === 'long') {
            return value.toLocaleDateString(locale, {
                weekday: 'long',
                month: 'long',
                day: 'numeric',
                year: 'numeric',
                timeZone
            });
        }

        if (format === 'long-no-year') {
            return value.toLocaleDateString(locale, {
                weekday: 'long',
                month: 'long',
                day: 'numeric',
                timeZone
            });
        }

        if (format === 'short-no-year') {
            return value.toLocaleDateString(locale, {
                month: '2-digit',
                day: '2-digit',
                timeZone
            });
        }
        return Intl.DateTimeFormat(locale).format(value);
    }

    // Capitalise first letter of each word
    if (format === 'capitalise-each-word' && typeof value === 'string') {
        return value
            .split(' ')
            .map((word) => word[0].toLocaleUpperCase() + word.substring(1))
            .join(' ');
    }

    return value;
};

export default format;
