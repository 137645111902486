import _ from 'lodash';

import { useWhiteLabelInfo } from "../../components/white-label/context";
import { TURQUOISE, VL_BACKGROUND_GRADIENT, VL_BLACK, WHITE } from "../../utils/colours";
import host from "../../utils/getHost";

/**
 * Returns styles for a component to use. If this is a white label site, it returns white label styles. Otherwise it returns Videoloft styles.
 * 
 * @returns {Object} styles
 */
const useWhiteLabelComponent = () => {
    const info = useWhiteLabelInfo();

    return _.isEmpty(info) ? {
        accentColour: TURQUOISE,
        navColour: VL_BLACK(),
        navTextColour: WHITE(),
        headingDots: true
    } : {
        ...info, 
        backgroundGradient: /videoloft/.test(host) ? VL_BACKGROUND_GRADIENT : undefined
    };
}

export default useWhiteLabelComponent;