import { useState, useRef, useCallback, useEffect } from 'react';
import _ from 'lodash';

/**
 * @callback closeModal Function to close modal.
 */

/**
 * @callback showModal Function to show modal.
 */

/**
 * @typedef ModalProps
 * @property {boolean} show Whether modal is showing.
 * @property {closeModal} closeModal Function to close modal.
 * @property {object} containerRef Ref to apply to modal container.
 * @property {object} closeRef Ref to apply to close button. It will receive focus when modal opened.
 */

/**
 * Custom hook for managing modal state and props.
 * Returns props to pass to Modal component, props to pass to the Button component which triggers the modal, and a function to close the modal.
 * See documentation for Modal component for an example usage.
 * @param {boolean} initialShow Whether to start the modal as open.
 * @returns {[ModalProps, showModal, closeModal]} Array containing modal props, a function to show modal, and a function to close the modal.
 */
const useModal = (initialShow = false) => {

    // Whether modal is showing
    const [show, setShow] = useState(initialShow);

    // Ref for containing element
    const containerRef = useRef();

    // Last element to have focus before modal opened
    // Focus will be put back here if possible when modal closed
    const previousFocusRef = useRef();

    // Ref for modal close button
    const closeRef = useRef();

    // Stop scrolling when modal open
    useEffect(() => {
        document.body.style.overflow = show ? 'hidden' : 'auto';

        // Ensures that body overflow always gets reset even if component gets unmounted
        return (() => document.body.style.overflow = 'auto');
    }, [show]);

    // Switch focus when modal opened and closed
    // Cannot do this in `showModal` and `closeModal` functions as relevant components may not be showing
    useEffect(() => {
        if (show) {
            // Transfer focus to close button
            closeRef.current?.focus();
        } else {
            // Put focus back if possible
            previousFocusRef.current?.focus?.();
        }
    }, [show]);

    // Function to show modal
    const showModal = useCallback(() => {
        // Remember which element has focus so we can put it back when modal closes
        previousFocusRef.current = document.activeElement;
        
        setShow(true);
    }, []);

    // Function to close modal
    const closeModal = useCallback(() => {
        setShow(false);
    }, []);

    // Key listeners used when modal open for trapping focus and closing modal when escape key pressed
    useEffect(() => {
        if (show) {
            const keyListener = (e) => {
                // Escape key can close modal
                if (e.keyCode === 27) {
                    closeModal();
                }

                // Trap focus in modal
                // If tab key pressed while focus on last focusable element in modal, sets focus back to first focusable element
                // Vice versa for shift + tab
                // Code adapted from https://uxdesign.cc/how-to-trap-focus-inside-modal-to-make-it-ada-compliant-6a50f9a70700
                if (e.keyCode === 9 && containerRef.current) {
                    // Tab key
                    // Get all elements that can be focused
                    const focusableElements = ['button', '[href]', 'input', 'select', 'textarea', '[tabindex]'].map(e => `${e}:not([tabindex="-1"])`).join(', ');

                    // This needs to be array for `find` function
                    const focusableContent =
                        [...containerRef.current.querySelectorAll(focusableElements)];

                    // Get first and last focusable elements (make sure elements are actually being displayed)
                    const firstFocusableElement = focusableContent.find(element => getComputedStyle(element).display !== 'none');
                    const lastFocusableElement =
                        _.findLast(focusableContent, element => getComputedStyle(element).display !== 'none');

                    // If shift key pressed for shift + tab combination
                    if (e.shiftKey) {
                        if (document.activeElement === firstFocusableElement) {
                            // Add focus for the last focusable element
                            lastFocusableElement.focus();
                            e.preventDefault();
                        }
                    } else {
                        // If focus has reached last focusable element then focus first focusable element after pressing tab
                        if (document.activeElement === lastFocusableElement) {
                            // Add focus for the first focusable element
                            firstFocusableElement.focus();
                            e.preventDefault();
                        }
                    }
                }
            };

            document.addEventListener('keydown', keyListener);

            return () => document.removeEventListener('keydown', keyListener);
        }
    }, [containerRef, closeModal, show]);

    return [
        // Modal props
        {
            show,
            closeModal,
            containerRef,
            closeRef
        },

        // Show modal function
        // Most often will be passed as onClick to a button
        showModal,

        // Close modal function
        closeModal,
    ];
};

export default useModal;
