import styled from 'styled-components';
import { VL_BLACK } from '../../../utils/colours';

export const Option = styled.label`
    border: 2px solid
        ${({ $selected, $selectedBorderColour }) =>
            $selected ? $selectedBorderColour : '#ccc'};
    border-radius: 10px;
    padding: ${({ $padding }) => $padding};
    box-shadow: ${({ $selected }) =>
        $selected ? `1px 3px 2px 2px ${VL_BLACK(40)}` : 'none'};
    cursor: pointer;
    transition: border 0.2s;
    position: relative;
`;

Option.displayName = 'Option';

export const RadioInput = styled.input.attrs({ type: 'radio' })`
    /* Hide radio button */
    height: 0;
    width: 0;
    opacity: 0;
    position: absolute;
`;

RadioInput.displayName = 'RadioInput';
