import PropTypes from 'prop-types';

import useWhiteLabelComponent from '../../hooks/useWhiteLabelComponent';

import { SalesLabelContainer } from './SalesLabel.styles';

/** Rounded rectangle with (a very short amount of) text inside. Usually used to show the word "Popular" next to a product option. */
const SalesLabel = ({ outline = false, children, ...props }) => {

    const { accentColour } = useWhiteLabelComponent();

    return (
        <SalesLabelContainer $backgroundColour={accentColour} $outline={outline} {...props}>
            {children}
        </SalesLabelContainer>
    );
};

SalesLabel.propTypes = {
    /** use if instead of background colour the label should just have an outline */
    outline: PropTypes.bool,
};

export default SalesLabel;
