import styled from 'styled-components';

export const FormContainer = styled.form`
    margin: 30px 0 0;
`;

FormContainer.displayName = 'FormContainer';

export const FormError = styled.span`
    color: red;
    margin-top: 25px;
    display: block;
`;

FormError.displayName = 'FormError';

export default FormContainer;
