import styled from 'styled-components';

import { FADED_GREY, PINK } from '../../../utils/colours';
import { FIELD_WIDTH } from '../helpers';

// In px
const horizontalGap = 30;
const verticalGap = (large) => (large ? 10 : 7);
const descriptionMinWidth = 200;

export const FormGroup = styled.div.attrs({ 'data-testid': 'form-group' })`
    display: flex;
    flex-direction: ${({ layout }) =>
        layout === 'vertical' ? 'column' : 'row'};
    flex-wrap: wrap;
    gap: ${({ large }) => verticalGap(large)}px ${horizontalGap}px;
    ${({ layout }) => (layout === 'horizontal' ? 'align-items: center;' : '')}

    ${({ large, $noMTop }) => $noMTop ? '' : `:not(:first-child) {
        margin-top: ${large ? 30 : 20}px;
    }`}
`;

FormGroup.displayName = 'FormGroup';

export const Label = styled.label`
    font-weight: 300;
    font-size: ${({ large }) => (large ? 16 : 14)}px;
    width: ${({ layout, $width }) =>
        layout === 'horizontal' ? `${$width}px` : '100%'};
    max-width: 100%;

    ${({ isRequired }) =>
        isRequired && `&:after { content: "*"; color: ${PINK}; }`}
`;

Label.displayName = 'Label';

export const FieldContainer = styled.div`
    width: ${({ fullWidth, layout, $autoWidth }) => {
        if ($autoWidth) {
            return 'auto';
        }

        if (fullWidth || layout === 'vertical') {
            return '100%';
        }

        return `${FIELD_WIDTH}px`;
    }};
    display: flex;
    flex-direction: column;
    row-gap: ${({ large }) => verticalGap(large)}px;
    ${({ layout }) =>
        layout === 'horizontal' ? 'justify-content: center;' : ''}
`;

FieldContainer.displayName = 'FieldContainer';

// In px this is the minimum width FormGroup must be to fit all three columns horizontally
// So min width = label width + gap + field width + gap + description width
// If FormGroup is below this width, RightColumn must use `flex-direction: column;` to maintain two column layout with label on left and field/description on right
const minFormGroupWidthForHorizontalLayout = (fieldWidth, labelWidth) =>
    labelWidth + 2 * horizontalGap + fieldWidth + descriptionMinWidth;
export const RightColumn = styled.div`
    display: flex;
    ${({ layout, $parentWidth, $fieldWidth, $labelWidth }) => {
        // Regardless of layout, must be 'column' if there's no room for all three rows
        // If fieldAutoWidth prop is true, then field width can vary too
        // $fieldWidth only defined (as only needed) if fieldAutoWidth prop is true
        if (
            $parentWidth &&
            $parentWidth <=
                minFormGroupWidthForHorizontalLayout(
                    $fieldWidth || FIELD_WIDTH,
                    $labelWidth
                )
        ) {
            return 'flex-direction: column;';
        }

        return layout === 'horizontal'
            ? 'flex-direction: row; align-items: center;'
            : 'flex-direction: column;';
    }};
    flex-wrap: wrap;
    flex-grow: 1;
    gap: ${({ large }) => verticalGap(large)}px ${horizontalGap}px;
    max-width: 100%;
`;

RightColumn.displayName = 'RightColumn';

export const Description = styled.p`
    font-weight: 100;
    color: ${FADED_GREY};
    margin: 0;
    min-width: ${descriptionMinWidth}px;
    width: ${({ layout, $setWidth }) =>
        $setWidth ? `${$setWidth}px` : layout === 'horizontal' ? `${descriptionMinWidth}px` : '100%'};
    flex-grow: 1;
    font-size: ${({ large }) => (large ? 16 : 14)}px !important;
`;

Description.displayName = 'Description';

export const ErrorMessage = styled.span`
    display: block;
    color: red;
    font-size: ${({ large }) => (large ? 16 : 14)}px;

    /* Only show when field not in focus. Only works for <input> fields at the moment. */
    input:focus ~ & {
        display: none;
    }
`;

ErrorMessage.displayName = 'ErrorMessage';
