import PropTypes from 'prop-types';

/** <span> tag which takes commonly used style properties as props. */
const Span = ({
    light,
    bold,
    fontSize,
    colour,
    block = false,
    children,
    style: extraStyles,
    ...props
}) => {
    const style = {
        fontWeight: light ? 100 : bold ? 300 : undefined,
        fontSize,
        color: colour,
        display: block ? 'block' : 'inline',
        ...(extraStyles || {}),
    };

    return (
        <span style={style} {...props}>
            {children}
        </span>
    );
};

Span.propTypes = {
    /** Use light font. */
    light: PropTypes.bool,
    /** Use bold font. */
    bold: PropTypes.bool,
    /** Font size. If given as a number, unit will be px. */
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** Text colour (CSS 'color'). */
    colour: PropTypes.string,
    /** Use `display: block;`. */
    block: PropTypes.bool,
};

export default Span;
