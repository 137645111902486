// If we want to test a specific language, run sessionStorage.i18nextLng=<language code>, e.g. sessionStorage.i18nextLng='ja'

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import format from './format';

// Get the LanguageDetector to detect language each time app starts up
// Otherwise once this key is set in local storage, it would be stuck there
localStorage.removeItem('i18nextLng');

// Init i18next
i18n
    // Load translation.json from public/locales
    .use(Backend)
    // Detect user language
    .use(LanguageDetector)
    // Pass the i18n instance to react-i18next
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        // debug: true, // Prints to console (e.g. missing translation keys)

        interpolation: {
            escapeValue: false, // Not needed for React as it escapes by default

            // All interpolations are put through the format function (required for dates)
            alwaysFormat: true,

            // Format function
            // Can be accessed in code at i18n.format prop through withTranslations HOC
            format,
        },

        react: {
            useSuspense: false, // App renders loading spinner until tReady prop is true
        },
    });

export default i18n;
