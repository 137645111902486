import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import useWhiteLabelComponent from '../../hooks/useWhiteLabelComponent';

import { Flex } from '../layout';
import Spinner from '../spinner';
import { DEFAULT_BACKGROUND_GRADIENT, VL_BACKGROUND_GRADIENT } from '../../utils/colours';
import host from '../../utils/getHost';

/** Displays loading spinner at its centre. */
const Loading = forwardRef(
    ({ spinnerColour, fill = false, style = {}, ...props }, ref) => {

        const { accentColour, backgroundGradient } = useWhiteLabelComponent();

        if (!fill) {
            return (
                <Spinner
                    colour={spinnerColour ?? accentColour}
                    large
                    style={{ margin: 'auto', ...style }}
                    {...props}
                    ref={ref}
                />
            );
        } else {
            return (
                <Flex
                    column
                    centre
                    style={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,

                        // Due to the absolute positioning, this component can sometimes cover other UI components like tabs
                        // This ensures that any lower components can still be interacted with
                        pointerEvents: 'none',
                        background: /videoloft/.test(host) ? VL_BACKGROUND_GRADIENT : backgroundGradient ?? DEFAULT_BACKGROUND_GRADIENT,
                        ...style
                    }}
                    {...props}
                >
                    <Spinner colour={spinnerColour ?? accentColour} large ref={ref} />
                </Flex>
            );
        }
    }
);

Loading.propTypes = {
    /** CSS colour to use for spinner. If not provided, will use white label's accent colour instead. Otherwise fall back to default spinner colour (probably black). */
    spinnerColour: PropTypes.string,
    /** Fill parent. Use when needing to fill page (e.g. initial App loading spinner). */
    fill: PropTypes.bool,
};

export default Loading;
