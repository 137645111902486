/**
 * Is the user using a mouse?
 * @returns {boolean} Does the primary input mechanism include an accurate pointing device, such as a mouse?
 */
export const isUsingMouse = () => window?.matchMedia('(pointer:fine)')?.matches;

/**
 * Is the user using a touch screen?
 * @returns {boolean} Does the primary input mechanism include a pointing device of limited accuracy, such as a finger on a touchscreen?
 */
export const isUsingTouchscreen = () => window?.matchMedia('(pointer:coarse)')?.matches;