import {
    shape,
    oneOf,
    string,
    number,
} from 'prop-types';

export const webLogin = shape({
    loginToken: string.isRequired,
    seriesId: string.isRequired,
    uid: number.isRequired,
});

export const accountType = oneOf(['u', 'i', 'd', 's']);
