import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { Arrow, PopupContainer } from './Popup.styles';

/** Wrapper for popups (similar to how ModalWrapper component works). */
const Popup = forwardRef(({
    width = 250,
    maxHeight,
    open = false,
    children,
    arrowProps,
    placement,
    ...props
}, ref) => {

    if (!open) {
        return null;
    }

    return (
        <PopupContainer
            $width={width}
            $maxHeight={maxHeight}
            ref={ref}
            {...props}
        >
            {children}
            {
                arrowProps && (
                    <Arrow {...arrowProps} $placement={placement} />
                )
            }
        </PopupContainer>
    );
});

Popup.propTypes = {
    /** Width of popup in px. */
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['fit-content'])]),
    /** Whether popup is open or not. */
    open: PropTypes.bool,
    /** Maximum height of popup in px. */
    maxHeight: PropTypes.number,
    /** Props to pass to arrow component (if using one). */
    arrowProps: PropTypes.shape({
        /** Ref for arrow element. */
        ref: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
        /** Styles to apply. */
        style: PropTypes.object,
        /** Size of arrow. */
        size: PropTypes.number.isRequired
    }),
    /** Position of popup as set by usePopper hook. Required if popup is open. */
    placement: PropTypes.oneOf(['auto', 'auto-start', 'auto-end', 'top', 'top-start', 'top-end', 'bottom', 'bottom-start', 'bottom-end', 'right', 'right-start', 'right-end', 'left', 'left-start', 'left-end'])
};

export default Popup;
