import styled from "styled-components";

import { FADED_GREY } from "../../../utils/colours";

export const DateInput = styled.input.attrs({
    type: 'date',
})`
    font-weight: 100;
    border: 1px solid ${FADED_GREY};
    width: 100%;
    height: 24px;
    padding: 0;
`;

DateInput.displayName = 'DateInput';


export const DurationInputContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    width: 100%;
    border: 1px solid #a1a1a1;
    max-width: 100%;
    overflow: auto;

    input {
        border: none !important;
        padding: 0 6px !important;
        width: 42% !important;
        height: 22px !important;
        font-size: 16px !important;
        color: #000 !important;
        max-width: 45% !important;
    }

    input:first-child {
        width: 35% !important;
    }

    ${({ $error }) => $error ? 'border-color: red;' : ''}
`;

DurationInputContainer.displayName = 'DurationInputContainer';