import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import translationKeys from '../../translations/keys';

import { Flex } from '../../components/layout';
import { Message } from './ErrorScreen.styles';

/** Positions an error message in the centre of its parent. Used by the 404 page and commonly passed as the `errorComponent` to ErrorBoundary. */
const ErrorScreen = ({ message, newRelease, t, ...props }) => {
    return (
        <Flex
            column
            centre
            style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}
            data-testid="error-screen"
            {...props}
        >
            <Message>{newRelease ? t(translationKeys.errors.REFRESH_FOR_UPDATE) : message}</Message>
        </Flex>
    );
};

ErrorScreen.propTypes = {
    /** Error message to display. */
    message: PropTypes.string.isRequired,
    /** If true, alternative message prompting user to refresh the page will be shown instead. */
    newRelease: PropTypes.bool
};

export default withTranslation()(ErrorScreen);
