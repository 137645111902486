import styled from 'styled-components';
import { DEFAULT_BACKGROUND_GRADIENT } from '../../../utils/colours';

export const AppLayoutContainer = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    ${({ $banner }) => $banner ? `
        grid-template-rows: min-content 1fr;
        grid-template-areas:
            "sidebar banner"
            "sidebar content";
    ` : `
        grid-template-rows: auto;
        grid-template-areas:
            "sidebar content";
    `}
        
    height: 100vh;
    overflow: hidden;

    @media only screen and (max-width: ${({ $mobileBreakpoint }) =>
            $mobileBreakpoint}px) {
        grid-template-rows: auto 1fr;
        grid-template-columns: auto;
        ${({ $banner }) => $banner ? `
            grid-template-rows: auto min-content 1fr;
            grid-template-areas:
                "sidebar"
                "banner"
                "content";
        ` : `
            grid-template-rows: auto 1fr;
            grid-template-areas:
                "sidebar"
                "content";
        `}
    }

    ${({ $mobileApp }) => ($mobileApp ? 'display: flex;' : '')}
`;

AppLayoutContainer.displayName = 'AppLayoutContainer';


export const SidebarContainer = styled.div`
    grid-area: sidebar;
`;

SidebarContainer.displayName = 'SidebarContainer';


export const Banner = styled.div`
    grid-area: banner;
    display: flex;
    align-items: center;
    padding: 10px 16px;
    gap: 16px;

    p { 
        flex-grow: 1;
        margin: 0;
        text-align: center;
    }
`;

Banner.displayName = 'Banner';


export const PageContent = styled.main`
    grid-area: content;
    background: ${({$background}) => $background ? $background : DEFAULT_BACKGROUND_GRADIENT};
    flex-grow: 1;
    padding: ${({$fullWidth}) => $fullWidth ? 0 : `20px min(30px, 5%)`};
    overflow-y: auto;
    position: relative;
`;

PageContent.displayName = 'PageContent';
