/*
    The idea of this file was to provide a separate white label version of all VL components.
    This was basically just to keep each component as dumb as possible.

    In hindsight though, it is a pain in the arse having to create another version of each
    component. Especially for more complex components that import other components that have
    a white label variety.

    The new plan is for each component to get its styles from useWhiteLabelComponent hook.
    If on a white label site, the hook returns the relevant styles. Otherwise it just returns
    VL styles.

    Gradually we'll move each component over to this new method. This file still exports
    each component though for backwards compatibility (e.g. Button uses new method but
    WhiteLabel.Button still exists as converting all existing usages will take a while).
    Eventually though WhiteLabel will only be the Context provider and nothing else.
*/

import { useContext, forwardRef } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import Breadcrumbs from '../breadcrumbs';
import Button from '../button';
import Footer from '../footer';
import Heading from '../heading';
import Link from '../link';
import Logo from '../logo';
import Navbar from '../navbar';
import PageHeading from '../page-heading';
import { PageNavigation } from '../layout';
import { PopupList } from '../popup';
import Sidebar from '../sidebar';

import Span from '../span';

import WhiteLabelContext from './context';



/**
 * Uses Context API to provide styles to various white label components. Each white label component wraps around the original and passes it required props.
 * Default export is the context provider and all white label components must fall below it in the tree.
 * White label components include:
 * - `WhiteLabel.AccentColour`
 * - `WhiteLabel.Breadcrumbs`
 * - `WhiteLabel.Footer`
 * - `WhiteLabel.Link`
 * - `WhiteLabel.Logo`
 * - `WhiteLabel.ModalPlayer`
 * - `WhiteLabel.Navbar`
 * - `WhiteLabel.PageHeading`
 * - `WhiteLabel.PageNavigation`
 * - `WhiteLabel.PopupList`
 * - `WhiteLabel.Sidebar`
 *
 * White label components take the same props as the original components they wrap around.
 */
const WhiteLabel = ({ info, children }) => {
    return (
        <WhiteLabelContext.Provider value={info}>
            {children}
        </WhiteLabelContext.Provider>
    );
};

WhiteLabel.propTypes = {
    /** White label info to use as context value. */
    info: PropTypes.shape({
        // Colours

        /** CSS gradients. Used on buttons. */
        gradients: PropTypes.arrayOf(PropTypes.string),
        /** Background colour of buttons (if `gradients` not given). */
        buttonColour: PropTypes.string,
        /** Text colour of buttons. */
        buttonTextColour: PropTypes.string,
        /** Background colour of sidebar (in app layout). */
        navColour: PropTypes.string,
        /** Colour of text for selected navbar option. */
        navTextColour: PropTypes.string,
        /** Colour of text for non-selected navbar options. */
        navFadedTextColour: PropTypes.string,
        /** Used in various places around the app to add colour (i.e. wherever Videoloft pink is used). */
        accentColour: PropTypes.string,

        // Other styles

        /** Show trailing dot after each heading (as used on all Videoloft branding). */
        headingDots: PropTypes.bool,
        /** Name to use when referring to app (e.g. Videoloft). */
        appName: PropTypes.string,
        /** Title used for every page in web app. */
        title: PropTypes.string,

        // Logos

        /** Main logo URL. */
        logo: PropTypes.string,
        /** URL of logo to use in full screen layout. */
        fullScreenLayoutLogo: PropTypes.string,
        /** URL of logo to use in app layout. */
        appLayoutLogo: PropTypes.string,
        /** URL of smaller logo to use in app layout when sidebar is collapsed. */
        appLayoutLogoSmall: PropTypes.string,

        // URLs

        /** URL to return to when user logs out or quits demo. */
        returnLink: PropTypes.string,
        /** Terms page URL. */
        terms: PropTypes.string,
        /** Privacy page URL. */
        privacy: PropTypes.string,

        // Demo config

        /** If white label has demo account set up, this must either be true or an object with additional config. */
        demo: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.shape({
                /** Show link to demo on sign in page. */
                showButton: PropTypes.bool,
                /** URL to send user to when they exit demo. */
                returnLink: PropTypes.string
            })
        ]),

        // Other

        /** Does this white label site belong to Videoloft? */
        isVideoloft: PropTypes.bool,
        /** Is this Manything site? */
        isManything: PropTypes.bool,
        /** Does white label allow SSO logins? */
        supportsSSO: PropTypes.bool,
        /** Hide link to partner portal. */
        hidePortalLink: PropTypes.bool,
        /** Supports end user self registration. */
        canRegisterUsers: PropTypes.bool,
        /** Show Cloud Adapter purchase page. */
        canPurchaseHardware: PropTypes.bool,
        /** This white label is for takeaway receipts. */
        isTakeawayVendor: PropTypes.bool
    }).isRequired,
};

// Function which returns HOC
// styles argument must be a function which takes white label info and props, and returns props to apply to WrappedComponent
const withWhiteLabelStyles = (styles) => (WrappedComponent) => {
    const WithWhiteLabelStyles = ({ forwardedRef, children, ...props }) => {
        // Get white label info from context
        const whiteLabelInfo = useContext(WhiteLabelContext);

        // Use styles function to extract required info from whiteLabelInfo
        // stylesToApply is object containing props to pass to WrappedComponent
        const stylesToApply = styles(whiteLabelInfo, props);

        // Merge stylesToApply into props (so stylesToApply will overwrite common properties in props)
        // Needs to be a deep merge so that style properties get merged, rather than stylesToApply's style object just entirely overwrites props's style object
        const newProps = {};
        _.merge(newProps, props, stylesToApply);

        return (
            <WrappedComponent ref={forwardedRef} {...newProps}>
                {children}
            </WrappedComponent>
        );
    };

    WithWhiteLabelStyles.displayName = `WithWhiteLabelStyles(${
        WrappedComponent.displayName || WrappedComponent.name
    })`;

    return forwardRef((props, ref) => (
        <WithWhiteLabelStyles {...props} forwardedRef={ref} />
    ));
};

// White label components
// WARNING: This solution of exporting all components with WhiteLabel object could (but shouldn't) lead to circular dependencies
// if a component which has a white label version imports WhiteLabel: component -> WhiteLabel -> component

WhiteLabel.Breadcrumbs = withWhiteLabelStyles((s, p) => {})(Breadcrumbs);

// Do not use WhiteLabel.Button anymore
// This is just here to support existing usages
WhiteLabel.Button = Button;

WhiteLabel.Footer = withWhiteLabelStyles((s, p) => ({
    logo: s.logo
}))(Footer);

// Do not use WhiteLabel.Heading anymore
// This is just here to support existing usages
WhiteLabel.Heading = Heading;

WhiteLabel.Link = withWhiteLabelStyles((s, p) => ({
    style: {
        color: s.accentColour || '#424143'
    },
}))(Link);

WhiteLabel.Logo = withWhiteLabelStyles((s, p) => {
    // WhiteLabel.Logo has additional `variant` prop which can be undefined (default logo), 'fullScreen' (use fullScreenLayoutLogo) or 'app' (use appLayoutLogo)
    let logo = s.logo;
    if (p.variant === 'fullScreen' && s.fullScreenLayoutLogo) {
        logo = s.fullScreenLayoutLogo;
    } else if (p.variant === 'app' && s.appLayoutLogo) {
        logo = s.appLayoutLogo;
    }

    return {
        src: logo,
        // When using WhiteLabel.Logo, set prop 'link' to true and this code will swap it for actual URL
        // If prop `link` is true but white label info does not contain link, empty string is passed to Logo
        link: p.link && (s.returnLink || ''),
    };
})(Logo);

WhiteLabel.Navbar = withWhiteLabelStyles((s, p) => ({
    logoSrc: s.logo,
    logoLink: s.returnLink,
    selectedColour: s.accentColour,
}))(Navbar);

WhiteLabel.PageHeading = withWhiteLabelStyles((s, p) => ({
    accentColour: s.accentColour || '#424143',
    noDot: !s.headingDots,
}))(PageHeading);

WhiteLabel.PageNavigation = withWhiteLabelStyles((s, p) => ({
    accentColour: s.accentColour || '#424143',
    noHeadingDot: !s.headingDots,
}))(PageNavigation);

WhiteLabel.PopupList = withWhiteLabelStyles((s,p) => ({
    accentColour: s.accentColour
}))(PopupList);

WhiteLabel.Sidebar = withWhiteLabelStyles((s, p) => ({
    logoSrc: s.appLayoutLogo || s.logo,
    collapsedLogoSrc: s.appLayoutLogoSmall,
    logoLink: s.returnLink,
    backgroundColour: s.navColour,
    accentColour: s.accentColour,
    textColour: s.navTextColour,
    fadedTextColour: s.navFadedTextColour || '#ffffff87',
    copyrightName: s.isVideoloft ? 'Videoloft' : null,
}))(Sidebar);

// Nice way to put some text in accentColour
WhiteLabel.AccentColour = withWhiteLabelStyles((s, p) => ({
    colour: s.accentColour,
}))(Span);

// Export provider as default
export default WhiteLabel;
