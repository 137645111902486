import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
    from {
        transform: rotate(0deg); 
    }
    
    to  { 
        transform: rotate(360deg); 
    }
`;

const Spinner = styled.div`
    display: block;
    height: ${({ large }) => (large ? '50' : '18')}px;
    width: ${({ large }) => (large ? '50' : '18')}px;
    animation: ${rotate} 0.8s infinite linear;
    border: ${({ large }) => (large ? '5' : '3')}px solid
        ${({ colour }) => colour};
    border-right-color: transparent;
    border-radius: 50%;
    transition: color .3s;
`;

Spinner.displayName = 'Spinner';

export default Spinner;
