import styled from 'styled-components';
import { VL_BLACK, WHITE } from '../../utils/colours';

export const SalesLabelContainer = styled.span`
    background-color: ${({ $backgroundColour, $outline }) => $outline ? WHITE() : $backgroundColour};
    color: ${({ $backgroundColour, $outline }) => $outline ? $backgroundColour : VL_BLACK()};
    border: ${({ $backgroundColour, $outline }) => $outline ? `1px solid ${$backgroundColour}` : ''};
    border-radius: 100px;
    padding: 2px 5px;
    font-weight: 100;
    font-size: 12px;
`;

SalesLabelContainer.displayName = 'SalesLabelContainer';
