import styled from 'styled-components';

export const IconButton = styled.button`
    padding: 0;
    margin: 0;
    border: none;
    background: none;

    /* Styles to position loading spinner */
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    // Stop iOS showing icons in blue
    color: inherit;

    ${({ disabled }) => `cursor: ${disabled ? 'not-allowed' : 'pointer'};`}
    ${({ $width }) => $width ? `width: ${typeof $width === 'number' ? `${$width}px` : $width};` : ''}
    ${({ $height }) => $height ? `height: ${typeof $height === 'number' || !isNaN(parseInt($height)) ? `${$height}px` : $height};` : ''}
    ${({ $hoverColour }) => $hoverColour ? `
        @media (hover: hover) {
            :hover {
                color: ${$hoverColour};
            }
        }
    ` : ''}
`;

IconButton.displayName = 'IconButton';

export const IconImage = styled.img`
    ${({ $disabled }) => ($disabled ? 'opacity: 0.2;' : '')}
    ${({ height, width, $maxHeight, $maxWidth }) => {
        if ($maxHeight || $maxWidth) {
            return `${$maxHeight ? `max-height: ${$maxHeight};` : ''} ${
                $maxWidth ? `max-width: ${$maxWidth};` : ''
            } width: ${width || 'auto'}; height: ${height || 'auto'};`;
        } else if (height && !width) {
            return `width: auto;`;
        } else if (width && !height) {
            return `height: auto;`;
        } else if (!width && !height) {
            return `width: 30px; height: auto;`;
        }
    }}
    ${({ $hoverColour }) => $hoverColour ? `
        @media (hover: hover) {
            :hover {
                color: ${$hoverColour};
            }
        }
    ` : ''}
`;

IconImage.displayName = 'IconImage';
