import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import PageHeadingComponent from '../../page-heading';

const navWidth = '15vw';
const navRightMarginPx = 40;
const contentHorizontalPaddingPx = 36;

// Sidebar shown above larger breakpoint
// Links shown horizontally at top of page between breakpoints
// Links shown in dropdown below smaller breakpoint
const largerBreakpoint = '1200px';
const smallerBreakpoint = '470px';

export const PageNavigationContainer = styled.div`
    display: grid;
    grid-template-columns: ${navWidth} 1fr;
    grid-template-rows: auto 1fr;

    padding-right: ${navRightMarginPx + contentHorizontalPaddingPx}px;

    @media only screen and (max-width: ${largerBreakpoint}) {
        display: block;
        padding: 0;
    }
`;

PageNavigationContainer.displayName = 'PageNavigationContainer';

export const Sidebar = styled.div`
    grid-row: 1 / 3;

    @media only screen and (max-width: ${smallerBreakpoint}) {
        padding: 5px 0 10px;
    }
`;

Sidebar.displayName = 'Sidebar';

// In px
const mobileTopPadding = 20;
const rowGap = 20;
export const PageLinks = styled.nav`
    display: flex;
    flex-direction: column;
    gap: ${rowGap}px 40px;
    margin: 30px ${navRightMarginPx}px 30px 0;
    font-weight: 100;
    color: #424143;
    font-size: 18px;
    list-style: none;
    border-right: 2px solid #424143;
    padding: 0 2vw;

    @media only screen and (max-width: ${largerBreakpoint}) {
        flex-direction: row;
        justify-content: center;
        padding: 0;
        border: none;
        margin: 0 20px 30px;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    @media only screen and (max-width: ${smallerBreakpoint}) {
        flex-direction: column;
        /** Max height when expanded = top padding + height of each navlink + row gaps (1 less row gap than number of navlinks) */
        max-height: ${({ mobileNavExpanded, numberOfMobileNavlinks }) =>
            mobileNavExpanded
                ? mobileTopPadding +
                  (20 + rowGap) * numberOfMobileNavlinks -
                  rowGap +
                  'px'
                : '0'};
        transition: max-height 0.4s linear;
        margin: 0;
        padding: ${mobileTopPadding}px 0 0;
        overflow: hidden;
        justify-content: flex-start;
    }
`;

PageLinks.displayName = 'PageLinks';

export const Navlink = styled(NavLink)`
    text-decoration: none;

    &.selected {
        font-weight: 300;
        cursor: default;
        ${({ $activeColour }) =>
            $activeColour ? `color: ${$activeColour};` : ''}
    }

    @media only screen and (max-width: ${largerBreakpoint}) {
        text-align: center;
    }

    @media only screen and (max-width: ${smallerBreakpoint}) {
        display: block;
        &.selected {
            /* margin-bottom: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 30px;
            order: -1; */
            ${({ $header }) => !$header && 'display: none;'}
        }
    }
`;

Navlink.displayName = 'Navlink';

export const MobileNavDropdown = styled.button`
    /* Reset button styling */
    background: none;
    border: none;
    padding: 0;

    display: none;
    cursor: pointer;
    width: 12px;
    height: 12px;
    border-left: 2px solid ${({ activeColour }) => activeColour};
    border-bottom: 2px solid ${({ activeColour }) => activeColour};
    transform-origin: center;
    transform: ${({ expanded }) =>
        expanded ? 'translate3d(0,4px,0) rotate(135deg)' : 'rotate(-45deg)'};
    transition: transform 0.4s;

    @media only screen and (max-width: ${smallerBreakpoint}) {
        display: inline-block;
    }
`;

MobileNavDropdown.displayName = 'MobileNavDropdown';

export const MobileNavSelected = styled.div`
    display: none;
    justify-content: center;
    gap: 30px;
    font-size: 18px;

    @media only screen and (max-width: ${smallerBreakpoint}) {
        display: flex;
    }
`;

MobileNavSelected.displayName = 'MobileNavSelected';

export const PageHeading = styled(PageHeadingComponent)`
    padding-right: calc(${navWidth} - ${navRightMarginPx}px);
    margin-left: ${contentHorizontalPaddingPx}px;

    @media only screen and (max-width: ${largerBreakpoint}) {
        margin-right: ${contentHorizontalPaddingPx}px !important;
        padding-right: 0;
    }
`;

PageHeading.displayName = 'PageHeading';

export const PageContent = styled.div`
    position: relative;
    padding-left: ${contentHorizontalPaddingPx}px;
    padding-right: calc(${navWidth} - ${navRightMarginPx}px);

    @media only screen and (max-width: ${largerBreakpoint}) {
        padding-right: ${contentHorizontalPaddingPx}px;
    }
`;

PageContent.displayName = 'PageContent';
