import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import PropTypes from 'prop-types';
import ReactPlayer from "react-player/file";
import { withTranslation } from "react-i18next";
import { useFocusVisible } from "react-aria";
import _ from 'lodash';

import useFullscreen from "../../hooks/useFullscreen";
import useKeyboardZoomControls from "../../hooks/useKeyboardZoomControls";
import useKeyboardDewarpControls from "../../hooks/useKeyboardDewarpControls";

import { ReactComponent as PlayIcon } from '../../assets/images/icons/play.svg';
import { ReactComponent as PauseIcon } from '../../assets/images/icons/pause.svg';
import { ReactComponent as AudioOnIcon } from '../../assets/images/icons/audio-on.svg';
import { ReactComponent as AudioOffIcon } from '../../assets/images/icons/audio-off.svg';
import { ReactComponent as FullscreenIcon } from '../../assets/images/icons/fullscreen.svg';
import { ReactComponent as ShrinkIcon } from '../../assets/images/icons/shrink.svg';
import { ReactComponent as StandardViewIcon } from '../../assets/images/icons/rectangle.svg';
import { ReactComponent as DewarpedViewIcon } from '../../assets/images/icons/panorama.svg';
// import { ReactComponent as DewarpedQuadrantsViewIcon } from '../../assets/images/icons/grid.svg';
// import { ReactComponent as FisheyeGridViewIcon } from '../../assets/images/icons/circle.svg';

import translationKeys from '../../translations/keys';

import { isUsingMouse, isUsingTouchscreen } from "../../utils/pointer";
import { getVideoSnapshot } from "../../utils/video";

import Span from "../span";
import { Input } from "../form";
import { ZoomControls, ZoomableContent, ZoomableWrapper } from "../zoomable-wrapper";
import { FullscreenButtonContainer, ModeButtonContainer, PlayButtonContainer, ScrubBarContainer, VideoControlBar, VideoControlButton, VideoKeyboardAndTouchInterceptor, VideoPlayerContainer, VideoPlayerWithControlsContainer, VolumeButtonContainer } from "./VideoPlayer.styles";

import Dewarper from "./dewarper";
// import FisheyeView from "./fisheye-view/FisheyeView";


const SCRUB_BAR_STEP = 0.1;

// Video player without controls
const VideoPlayer = memo(
    forwardRef(({
        url,
        volume,
        playing = false,
        muted = false,
        playsInline = false,
        playbackRate = 1,
        width = '100%',
        height = '100%',
        mode = 'default',
        onReady,
        onPlay,
        onPause,
        onBuffer,
        onBufferEnd,
        onError,
        onProgress,
        onDuration,
        style,
        config,
        ...props
    }, ref) => {

        const containerRef = useRef();
        const reactPlayerRef = useRef();

        const dewarperRef = useRef();

        // Expose fullscreen function through ref
        const [toggleFullscreen] = useFullscreen(containerRef);

        // Show darkening animation when this is true
        const [isSnapshotTaken, setIsSnapshotTaken] = useState(false);
        // Reset snapshot state to clear darkened overlay
        useEffect(() => {
            if (isSnapshotTaken) {
                const timeout = setTimeout(() => setIsSnapshotTaken(false), 200);
                return () => {
                    clearTimeout(timeout);
                };
            }
        }, [isSnapshotTaken]);

        useImperativeHandle(ref, () => {
            return {
                container: containerRef.current,
                reactPlayer: reactPlayerRef.current,
                getDewarpedCanvas: () => dewarperRef.current?.canvas,
                dewarpZoom: (...args) => dewarperRef.current?.zoom(...args),
                dewarpMoveX: (...args) => dewarperRef.current?.moveX(...args),
                dewarpMoveY: (...args) => dewarperRef.current?.moveY(...args),
                toggleFullscreen,
                hasAudio: () => {
                    return !!reactPlayerRef.current.getInternalPlayer() && doesVideoHaveAudio(reactPlayerRef.current.getInternalPlayer());
                },
                takeSnapshot: (zoomTransformState) => {
                    setIsSnapshotTaken(true);
                    if (dewarperRef.current) {
                        // const dewarpedCanvas = dewarpedCanvasRef.current.canvas;
                        // const canvas = document.createElement('canvas');
                        // canvas.width = dewarpedCanvas.width;
                        // canvas.height = dewarpedCanvas.height;
                        // const context = canvas.getContext('2d');

                        // // Draw cropped video frame on canvas
                        // context.drawImage(dewarpedCanvas, 0, 0);
                        return dewarperRef.current.canvas;
                    } else {
                        const canvas = getVideoSnapshot(
                            reactPlayerRef.current.getInternalPlayer(),
                            containerRef.current,
                            zoomTransformState
                        );
                        return canvas;
                    }
                },
                showSnapshotAnimation: () => {
                    setIsSnapshotTaken(true);
                }
            };
        }, [toggleFullscreen]);

        return (
            <VideoPlayerContainer
                ref={containerRef}
                style={_.merge(style ?? {}, { width, height })}
                $overlay={isSnapshotTaken}
            >
                <ReactPlayer
                    ref={reactPlayerRef}
                    url={url}
                    width="100%"
                    height="100%"
                    playing={playing}
                    volume={volume}
                    muted={muted}
                    playsinline={playsInline}
                    playbackRate={playbackRate}
                    controls={false} // We use our own controls
                    onReady={onReady}
                    onPlay={onPlay}
                    onPause={onPause}
                    onBuffer={onBuffer}
                    onBufferEnd={onBufferEnd}
                    onError={onError}
                    onProgress={onProgress}
                    onDuration={onDuration}

                    style={mode !== 'default' ? { opacity: 0, position: 'absolute', zIndex: -500 } : undefined}

                    {...props}

                    // Need crossorigin="anonymous" to avoid cors errors when using <canvas>
                    config={_.merge({}, { file: { attributes: { crossOrigin: 'anonymous' } } }, config ?? {})}
                />

                {
                    mode === 'dewarp' && (
                        <Dewarper
                            ref={dewarperRef}
                            reactPlayerRef={reactPlayerRef}
                        />
                    )
                }

                {/* {
                    mode === 'dewarp-quadrants' && (
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                display: 'grid',
                                gridTemplateColumns: '50% 50%',
                                gridTemplateRows: '50% 50%'
                            }}
                        >
                            <Dewarper
                                reactPlayerRef={reactPlayerRef}
                            />
                            <Dewarper
                                reactPlayerRef={reactPlayerRef}
                                initialConfig={{
                                    direction: -90
                                }}
                            />
                            <Dewarper
                                reactPlayerRef={reactPlayerRef}
                                initialConfig={{
                                    direction: 180
                                }}
                            />
                            <Dewarper
                                reactPlayerRef={reactPlayerRef}
                                initialConfig={{
                                    direction: 90
                                }}
                            />
                        </div>
                    )
                }

                {
                    mode === 'fisheye-grid' && (
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                display: 'grid',
                                gridTemplateColumns: '50% 50%',
                                gridTemplateRows: '50% 50%'
                            }}
                        >
                            <FisheyeView
                                reactPlayerRef={reactPlayerRef}
                            />
                            <Dewarper
                                reactPlayerRef={reactPlayerRef}
                                borderColour="#F00"
                            />
                            <Dewarper
                                reactPlayerRef={reactPlayerRef}
                                initialConfig={{
                                    direction: -120
                                }}
                                borderColour="#0F0"
                            />
                            <Dewarper
                                reactPlayerRef={reactPlayerRef}
                                initialConfig={{
                                    direction: 120
                                }}
                                borderColour="#00F"
                            />
                        </div>
                    )
                } */}
            </VideoPlayerContainer>
        );
    })
);

VideoPlayer.propTypes = {
    /** URL of video. */
    url: PropTypes.string.isRequired,
    /** Set to `true` or `false` to pause or play the video. */
    playing: PropTypes.bool,
    /** Set the volume between 0 and 1. */
    volume: PropTypes.number,
    /** Mute the player. */
    muted: PropTypes.bool,
    /** Applies the `playsinline` attribute where supported. */
    playsInline: PropTypes.bool,
    /** Set the playback rate. */
    playbackRate: PropTypes.number,
    /** Show video controls. */
    controls: PropTypes.bool,
    /** Called when video is loaded and ready to play. */
    onReady: PropTypes.func,
    /** Called when video starts or resumes playing after pausing or buffering. */
    onPlay: PropTypes.func,
    /** Called when the video is paused. */
    onPause: PropTypes.func,
    /** Called when video starts buffering. */
    onBuffer: PropTypes.func,
    /** Called when video has finished buffering. */
    onBufferEnd: PropTypes.func,
    /** Called when an error occurs whilst attempting to play media. */
    onError: PropTypes.func,
    /** Callback containing `played` and `loaded` progress as a fraction, and `playedSeconds` and `loadedSeconds` in seconds.*/
    onProgress: PropTypes.func,
    /** Callback containing duration of the media, in seconds. */
    onDuration: PropTypes.func,
    /** Override ReactPlayer settings. See https://www.npmjs.com/package/react-player#config-prop. */
    config: PropTypes.object,
    /** Width of player. */
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /** Height of player. */
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /** Viewing mode. */
    mode: PropTypes.oneOf(['default', 'dewarp', 'dewarp-quadrants', 'fisheye-grid'])
};

VideoPlayer.displayName = 'VideoPlayer';


// Video player with our custom controls
export const VideoPlayerWithControls = withTranslation(null, { withRef: true })(memo(
    forwardRef(({
        autoPlay = false,
        initialVolume,
        initialMuted = false,
        initialPlaybackRate = 1,
        onReady: onReadyProp,
        onProgress: onProgressProp,
        onDuration: onDurationProp,
        width = '100%',
        height = '100%',
        zoomable = false,
        style,
        t,
        tReady,
        i18n,
        ...props
    }, ref) => {

        const containerRef = useRef();
        const playerRef = useRef();

        // Element around video which key and touch listeners are added to
        const keyboardAndTouchInterceptorRef = useRef();

        // Is video currently playing?
        const [playing, setPlaying] = useState(false);
        // Volume between 0 and 1, or null for default
        // Don't currently have UI control for volume
        const [volume] = useState(initialVolume);
        // Is video muted?
        const [muted, setMuted] = useState(initialMuted);
        // Playback rate as number
        // Don't currently have UI control for playback rate
        const [playbackRate] = useState(initialPlaybackRate);
        // Is user currently seeking?
        const [seeking, setSeeking] = useState(false);
        // Which mode are we showing?
        const [mode, setMode] = useState('default');

        const zoomControlsRef = useRef();
        const zoomDisabled = !zoomable || mode !== 'default';
        useKeyboardZoomControls(zoomControlsRef, keyboardAndTouchInterceptorRef, zoomDisabled);

        // Reset zoom when switching to dewarp
        useEffect(() => {
            if (mode === 'dewarp') {
                zoomControlsRef.current.resetTransform();
            }
        }, [mode]);
        
        // Keyboard dewarp controls
        useKeyboardDewarpControls(playerRef, keyboardAndTouchInterceptorRef, mode !== 'dewarp');

        useImperativeHandle(ref, () => {
            return {
                container: containerRef.current,
                reactPlayer: playerRef.current.reactPlayer,
                pause: () => setPlaying(false),
                takeSnapshot: () => {
                    playerRef.current.showSnapshotAnimation();
                    if (playerRef.current.getDewarpedCanvas()) {
                        return playerRef.current.getDewarpedCanvas();
                    } else {
                        const canvas = getVideoSnapshot(
                            playerRef.current.reactPlayer.getInternalPlayer(),
                            containerRef.current,
                            zoomControlsRef.current.instance.transformState
                        );
                        return canvas;
                    }
                }
            };
        }, []);

        /*
            Video info identified when video starts loading.
            This needs to be a ref as it is used in event listeners.

            Shape:
            {
                durationSeconds: integer,
                hasAudio: boolean
            }
        */
        const videoInfoRef = useRef({
            durationSeconds: null,
            hasAudio: null
        });
        // Have we obtained the video info?
        const [gotVideoInfo, setGotVideoInfo] = useState();
        // Update video info
        const onDuration = useCallback((durationSeconds) => {

            videoInfoRef.current = {
                durationSeconds,
                hasAudio: doesVideoHaveAudio(playerRef.current.reactPlayer.getInternalPlayer())
            };
            setGotVideoInfo(true);

            onDurationProp?.(durationSeconds);
        }, [onDurationProp]);

        /*
            Playing/loading progress.
            This needs to be a ref as it is used in event listeners.

            Shape:
            {
                playedSeconds: integer,
                loadedSeconds: integer
            }
        */
        const progressInfoRef = useRef({
            playedSeconds: null,
            loadedSeconds: null
        });
        /*
            State used for scrub bar.
            Generated from refs above.

            Shape: {
                value: number,
                max: number,
                percentagePlayed: number,
                percentageLoaded: number,
                currentTimestamp: string,
                endTimestamp: string
            }
        */
        const [scrubBarData, setScrubBarData] = useState({
            value: 0,
            max: 0,
            percentagePlayed: 0,
            percentageLoaded: 0,
            currentTimestamp: getTimestamp(0),
            endTimestamp: null
        });
        // This must be invoked whenever `progressInfoRef` changes
        const updateScrubBarData = useCallback(() => {
            // We set scrub bar range <input> to a fixed time step (e.g. 100ms)
            // Need to make duration, played time and loaded time all line up with that step interval
            // (otherwise we'd get issues like scrub handle never reaching the end or not lining up with track gradient)

            const roundUpToNearestStep = value => value ? Math.ceil(value / SCRUB_BAR_STEP) * SCRUB_BAR_STEP : 0;

            const durationSeconds = roundUpToNearestStep(videoInfoRef.current.durationSeconds);
            const playedSeconds = roundUpToNearestStep(progressInfoRef.current.playedSeconds);
            const loadedSeconds = roundUpToNearestStep(progressInfoRef.current.loadedSeconds);

            setScrubBarData({
                value: playedSeconds,
                max: durationSeconds,
                percentagePlayed: ((playedSeconds / durationSeconds) * 100) || 0,
                percentageLoaded: ((loadedSeconds / durationSeconds) * 100) || 0,
                currentTimestamp: getTimestamp(progressInfoRef.current.playedSeconds ?? 0),
                endTimestamp: videoInfoRef.current.durationSeconds ? getTimestamp(videoInfoRef.current.durationSeconds) :  null
            });
        }, []);
        // ReactPlayer callback to update `progressInfoRef`
        const onProgress = useCallback((progress) => {
            progressInfoRef.current = {
                playedSeconds: progress.playedSeconds,
                loadedSeconds: progress.loadedSeconds
            };
            updateScrubBarData();

            if (progress.played === 1) {
                // Reset `playing` when video reaches end
                setPlaying(false);
            }

            onProgressProp?.(progress);
        }, [updateScrubBarData, onProgressProp]);
       
        /*
            If autoPlay is requested, we attempt to play the video
            when onReady is invoked. We can't just set initial
            value of `playing` to true as browser may not actually
            autoplay video.
        */
        const initialAutoPlayRef = useRef({
            // Lock initial value of `autoPlay` prop in here
            initialProp: autoPlay,
            // Have we attempted to autoplay yet?
            // We only attempt the very first time.
            handled: false
        });
        const onReady = useCallback((player) => {

            if (initialAutoPlayRef.current.initialProp && !initialAutoPlayRef.current.handled) {
                const attemptAutoPlay = async () => {
                    initialAutoPlayRef.current.handled = true;
                    try {
                        // This promise will resolve if successful
                        await player.getInternalPlayer().play();
                        setPlaying(true);
                    } catch (error) {
                        console.error(error);
                    }
                }
                attemptAutoPlay();
            }
            
            onReadyProp?.(player);
        }, [onReadyProp]);

        // For keyboard users, if any video control is in focus, we need to show UI
        // This state stores the video element currently in focus (or null if none are)
        const [focusedVideoElement, setFocusedVideoElement] = useState();
        // onFocus handler for any video element
        const onVideoElementFocus = useCallback((event) => {
            setFocusedVideoElement(event.target);
        }, []);
        // onBlur handler for any video element
        const onVideoElementBlur = useCallback((event) => {
            setFocusedVideoElement(element => element === event.target ? null : element);
        }, []);
        // Only interfere with UI behaviour if this is true
        const { isFocusVisible } = useFocusVisible();

        // Seek to specific time in video
        const seek = useCallback((seconds) => {
            playerRef.current.reactPlayer.getInternalPlayer().currentTime = seconds;
            progressInfoRef.current.playedSeconds = seconds;
            updateScrubBarData();
        }, [updateScrubBarData]);

        // Can make container fullscreen
        const [toggleFullscreen, isFullcreen] = useFullscreen(containerRef);
        // When a mouse user is in fullscreen, UI (including cursor) should hide after a few seconds of inactivity
        // so as not to cover the video
        const [hideUIInFullscreen, setHideUIInFullscreen] = useState(false);
        // Set up mouse listeners when in fullscreen to show/hide UI
        useEffect(() => {
            // We want to hide UI after a few seconds of inactivity whilst video is playing
            // Also don't want to hide it if user is holding down on scrub bar for a long time
            if (isFullcreen && isUsingMouse() && playing && !seeking) {
                
                // Timeout to hide UI
                let timeout;

                // Function to clear existing timeout and start a new one
                const restartTimeout = () => {
                    clearTimeout(timeout);
                    timeout = setTimeout(() => {
                        setHideUIInFullscreen(true);
                    }, 3000);
                }

                // On each mouse action, we want to make sure UI is showing and restart timeout to hide it again
                const showUIAndRestartTimeout = () => {
                    setHideUIInFullscreen(false);
                    restartTimeout();
                }

                // Set initial timeout
                restartTimeout();

                const container = containerRef.current;

                // Listeners for each mouse action
                const onMouseDownHandler = (event) => {
                    showUIAndRestartTimeout();
                };
                const onMouseUpHandler = (event) => {
                    showUIAndRestartTimeout();
                }
                const onMouseMoveHandler = (event) => {
                    showUIAndRestartTimeout();
                }
                
                container.addEventListener('mousedown', onMouseDownHandler);
                container.addEventListener('mouseup', onMouseUpHandler);
                container.addEventListener('mousemove', onMouseMoveHandler);

                return () => {
                    clearTimeout(timeout);
                    container.removeEventListener('mousedown', onMouseDownHandler);
                    container.removeEventListener('mouseup', onMouseUpHandler);
                    container.removeEventListener('mousemove', onMouseMoveHandler);
                };
            } else {
                // Reset this state whenever not in use
                setHideUIInFullscreen(false);
            }
        }, [isFullcreen, playing, seeking]);

        // Play/pause video
        const togglePlaying = useCallback(() => {

            // If video is at the end, restart it
            if (progressInfoRef.current.playedSeconds === videoInfoRef.current.durationSeconds) {
                seek(0);
            }

            setPlaying(currentlyPlaying => !currentlyPlaying);
        }, [seek]);

        // On touch screens, we want to show/hide controls when user touches video
        // This state is a boolean indicating whether we should currently show UI because video was touched recently
        const [showControlsForTouch, setShowControlsForTouch] = useState(false);
        // When UI is activated by a touch, we set a timeout to hide it again
        // This is the id of the current timeout
        const touchTimeoutRef = useRef();
        // This function should be called when any UI control element is actived (e.g. button clicked, scrub bar moved)
        // It restarts the current timeout
        const onControlElementTouched = useCallback(() => {
            if (isUsingTouchscreen()) {
                clearTimeout(touchTimeoutRef.current);
                touchTimeoutRef.current = setTimeout(() => {
                    setShowControlsForTouch(false);
                }, 3000);
                setShowControlsForTouch(true);
            }
        }, []);
        // Make sure no timeout is left running when component unmounts
        useEffect(() => {
            return () => {
                clearTimeout(touchTimeoutRef.current);
            };
        }, []);

        /*
            Set up event listeners to achieve the following:
                * Pause and play video with mouse click or spacebar
                * Toggle controls UI showing on touch
        */
        useEffect(() => {
            const keyboardAndTouchInterceptor = keyboardAndTouchInterceptorRef.current;

            // Mouse handling

            // Position of mouse when click started
            let mouseDownX, mouseDownY;
            const onMouseDownHandler = (event) => {
                // Ignore click events on touch devices
                // We don't want touches to play/pause video (touches bring up UI instead)
                // Also make sure to exclude clicks on zoom control buttons
                if (isUsingMouse() && event.target.tagName !== 'BUTTON') {
                    mouseDownX = event.clientX;
                    mouseDownY = event.clientY;
                }
            };
            const onMouseUpHandler = (event) => {
                // Only take action if mouse didn't move during click, otherwise this was a drag (probably for zoom controls)
                if (typeof mouseDownX === 'number' && typeof mouseDownY === 'number') {
                    // Use Pythagoras to get distance between click start and end
                    const distance = Math.sqrt(Math.pow(event.clientX - mouseDownX, 2) + Math.pow(event.clientY - mouseDownY, 2));
                    mouseDownX = null;
                    mouseDownY = null;
                    if (distance < 1) {
                        togglePlaying();
                    }
                }
            }

            // Spacebar handling
            const keyHandler = (event) => {
                switch (event.key) {
                    case ' ':
                        togglePlaying();
                        event.preventDefault();
                        return;
                    default:
                        return;
                }
            }

            // Touch handling
            // Only show UI for touches not drags (so not to interfere with dewarping controls)
            let touchStartX, touchStartY;
            const onTouchStart = (event) => {
                touchStartX = event.touches[0].clientX;
                touchStartY = event.touches[0].clientY;
            }
            const onTouchEnd = (event) => {
                if (
                    typeof touchStartX === 'number' &&
                    typeof touchStartY === 'number' &&
                    Math.hypot(event.changedTouches[0].clientX - touchStartX, event.changedTouches[0].clientY - touchStartY) < 3
                ) {
                    clearTimeout(touchTimeoutRef.current);

                    setShowControlsForTouch(currentlyShowing => {
                        if (currentlyShowing) {
                            // If currently showing, now hide
                            return false;
                        } else {
                            // If currently hidden, start showing
                            // Start timeout to hide again
                            touchTimeoutRef.current = setTimeout(() => {
                                setShowControlsForTouch(false);
                            }, 3000);
                            return true;
                        }
                    });
                }
                
                // Reset
                touchStartX = null;
                touchStartY = null;
            }
            
            keyboardAndTouchInterceptor.addEventListener('mousedown', onMouseDownHandler);
            keyboardAndTouchInterceptor.addEventListener('mouseup', onMouseUpHandler);
            keyboardAndTouchInterceptor.addEventListener('keydown', keyHandler);
            keyboardAndTouchInterceptor.addEventListener('touchstart', onTouchStart);
            keyboardAndTouchInterceptor.addEventListener('touchend', onTouchEnd);

            return () => {
                keyboardAndTouchInterceptor.removeEventListener('mousedown', onMouseDownHandler);
                keyboardAndTouchInterceptor.removeEventListener('mouseup', onMouseUpHandler);
                keyboardAndTouchInterceptor.removeEventListener('keydown', keyHandler);
                keyboardAndTouchInterceptor.removeEventListener('touchstart', onTouchStart);
                keyboardAndTouchInterceptor.removeEventListener('touchend', onTouchEnd);
            };
        }, [togglePlaying]);

        return (
            <VideoPlayerWithControlsContainer
                ref={containerRef}
                style={_.merge(style ?? {}, { width, height })}
                $hideCursor={hideUIInFullscreen}
            >
                <VideoKeyboardAndTouchInterceptor
                    ref={keyboardAndTouchInterceptorRef}
                    tabIndex={0}
                >
                    <ZoomableWrapper
                        ref={zoomControlsRef}
                        disabled={zoomDisabled}
                    >
                        <ZoomableContent>
                            <VideoPlayer
                                ref={playerRef}
                                playing={playing && !seeking}
                                volume={volume}
                                muted={muted}
                                playsInline={autoPlay}
                                playbackRate={playbackRate}
                                onReady={onReady}
                                onProgress={onProgress}
                                onDuration={onDuration}
                                progressInterval={250}
                
                                onFocus={onVideoElementFocus}
                                onBlur={onVideoElementBlur}

                                mode={mode}
                
                                {...props}
                            />
                        </ZoomableContent>

                        {
                            !zoomDisabled && (
                                <ZoomControls />
                            )
                        }
                    </ZoomableWrapper>
                </VideoKeyboardAndTouchInterceptor>

                <VideoControlBar
                    onTouchEnd={() => onControlElementTouched()}
                    $playing={playing}
                    $controlsFocused={isFocusVisible && !!focusedVideoElement}
                    $touchActive={showControlsForTouch}
                    $hideUIInFullscreen={hideUIInFullscreen}
                >
                    {/* Play button */}
                    <PlayButtonContainer>
                        <VideoControlButton
                            aria-label={tReady ? t(playing ? translationKeys.video.PAUSE : translationKeys.video.PLAY) : (playing ? 'Pause' : 'Play')}
                            onClick={() => {
                                togglePlaying();
                                onControlElementTouched();
                            }}
                            onFocus={onVideoElementFocus}
                            onBlur={onVideoElementBlur}
                        >
                            {
                                playing ? (
                                    <PauseIcon />
                                ) : (
                                    <PlayIcon />
                                )
                            }
                        </VideoControlButton>
                    </PlayButtonContainer>

                    {/* Scrub bar */}
                    <ScrubBarContainer>
                        <Span>{scrubBarData.currentTimestamp}</Span>

                        <Input
                            style={{
                                '--percentage-played': `${scrubBarData.percentagePlayed}%`,
                                '--percentage-loaded': `${scrubBarData.percentageLoaded}%`
                            }}
                            type="range"
                            disabled={!gotVideoInfo}
                            step={SCRUB_BAR_STEP}
                            min={0}
                            max={scrubBarData.max}
                            value={scrubBarData.value}
                            onChange={({ target: { value } }) => {
                                seek(parseFloat(value));
                                onControlElementTouched();
                            }}
                            
                            // Need to stop playing whilst seeking
                            // Shouldn't matter if user scrubs using keyboard
                            onMouseDown={() => {
                                // Ensure mouse and touch events don't both fire
                                if (!isUsingTouchscreen()) {
                                    setSeeking(true);
                                }
                            }}
                            onMouseUp={() => {
                                // Ensure mouse and touch events don't both fire
                                if (!isUsingTouchscreen()) {
                                    setSeeking(false);
                                }
                            }}
                            // Are these needed?
                            onTouchStart={() => {
                                setSeeking(true);
                            }}
                            onTouchEnd={() => {
                                setSeeking(false);
                            }}

                            onFocus={onVideoElementFocus}
                            onBlur={onVideoElementBlur}
                        />

                        {
                            scrubBarData.endTimestamp && (
                                <Span>{scrubBarData.endTimestamp}</Span>
                            )
                        }
                    </ScrubBarContainer>

                    {/* Volume button */}
                    <VolumeButtonContainer>
                        <VideoControlButton
                            aria-label={tReady ? t(muted ? translationKeys.video.audio.TURN_ON : translationKeys.video.audio.TURN_OFF) : (muted ? 'Turn audio on' : 'Turn audio off')}
                            onClick={() => {
                                setMuted(currentlyMuted => !currentlyMuted);
                                onControlElementTouched();
                            }}
                            disabled={!gotVideoInfo || !videoInfoRef.current.hasAudio}
                            onFocus={onVideoElementFocus}
                            onBlur={onVideoElementBlur}
                        >
                            {
                                muted || volume === 0 ? (
                                    <AudioOffIcon />
                                ) : (
                                    <AudioOnIcon />
                                )
                            }
                        </VideoControlButton>
                    </VolumeButtonContainer>
                    
                    {/* Mode button */}
                    <ModeButtonContainer>
                        <VideoControlButton
                            aria-label={t(mode === 'dewarp' ? translationKeys.video.dewarping.STANDARD_VIEW : translationKeys.video.dewarping.DEWARP_FISHEYE)}                             
                            onClick={() => {
                                setMode(currentMode => {
                                    return currentMode === 'dewarp' ? 'default' : 'dewarp';
                                });
                                onControlElementTouched();
                            }}
                            onFocus={onVideoElementFocus}
                            onBlur={onVideoElementBlur}
                        >
                            {
                                mode === 'default' ? (
                                    <DewarpedViewIcon />
                                ) : (
                                    <StandardViewIcon />
                                )
                            }
                        </VideoControlButton>
                    </ModeButtonContainer>

                    {/* Fullscreen button */}
                    <FullscreenButtonContainer>
                        {
                            document.fullscreenEnabled && (
                                <VideoControlButton
                                    aria-label={tReady ? t(isFullcreen ? translationKeys.actions.EXIT_FULLSCREEN : translationKeys.video.PLAY_FULLSCREEN) : (isFullcreen ? 'Exit fullscreen' : 'Play fullscreen')}
                                    onClick={() => {
                                        toggleFullscreen();
                                        onControlElementTouched();
                                    }}
                                    disabled={!gotVideoInfo}
                                    onFocus={onVideoElementFocus}
                                    onBlur={onVideoElementBlur}
                                >
                                    {
                                        isFullcreen ? (
                                            <ShrinkIcon />
                                        ) : (
                                            <FullscreenIcon />
                                        )
                                    }
                                </VideoControlButton>
                            )
                        }
                        
                    </FullscreenButtonContainer>

                    
                </VideoControlBar>
            </VideoPlayerWithControlsContainer>
        );
    })
));

VideoPlayerWithControls.propTypes = {
    /** URL of video. */
    url: PropTypes.string.isRequired,
    /** Attempt to play video immediately. */
    autoPlay: PropTypes.bool,
    /** Set the initial volume between 0 and 1. */
    initialVolume: PropTypes.number,
    /** Start with the player muted. */
    initialMuted: PropTypes.bool,
    /** Set the initial playback rate. */
    initialPlaybackRate: PropTypes.number,
    /** Called when video is loaded and ready to play. */
    onReady: PropTypes.func,
    /** Called when video starts or resumes playing after pausing or buffering. */
    onPlay: PropTypes.func,
    /** Called when the video is paused. */
    onPause: PropTypes.func,
    /** Called when video starts buffering. */
    onBuffer: PropTypes.func,
    /** Called when video has finished buffering. */
    onBufferEnd: PropTypes.func,
    /** Called when an error occurs whilst attempting to play media. */
    onError: PropTypes.func,
    /** Callback containing `played` and `loaded` progress as a fraction, and `playedSeconds` and `loadedSeconds` in seconds.*/
    onProgress: PropTypes.func,
    /** Callback containing duration of the media, in seconds. */
    onDuration: PropTypes.func,
    /** Override ReactPlayer settings. See https://www.npmjs.com/package/react-player#config-prop. */
    config: PropTypes.object,
    /** Width of player. */
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /** Height of player. */
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /** Enable pan, pinch and zoom. */
    zoomable: PropTypes.bool
};

VideoPlayerWithControls.displayName = 'VideoPlayerWithControls';

/* -------------------------------------------------------------------------- */
/*                                   Helpers                                  */
/* -------------------------------------------------------------------------- */

// This always rounds down to nearest whole second
const getTimestamp = seconds => `${Math.floor(seconds / 60)}:${Math.floor(seconds % 60).toString().padStart(2, '0')}`;

const doesVideoHaveAudio = videoPlayer => (
    videoPlayer.mozHasAudio ||
    !!videoPlayer.webkitAudioDecodedByteCount ||
    (videoPlayer.audioTracks && videoPlayer.audioTracks.length > 0)
);

export default VideoPlayer;