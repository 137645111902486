import PropTypes from 'prop-types';
import { TURQUOISE } from '../../utils/colours';

import {
    BreadcrumbsContainer,
    CurrentPage,
    NavLink,
} from './Breadcrumbs.styles';

/** Shows page hierarchy with current page on far right. All but current page are shown as links. */
const BreadCrumbs = ({ pages, accentColour = TURQUOISE, ...props }) => {
    return (
        <BreadcrumbsContainer {...props}>
            {pages.map(
                (page, index) =>
                    // All shown as links except current page (which is last item in array)
                    index === pages.length - 1 ? (
                        <CurrentPage key={index} $accentColour={accentColour}>
                            {page}
                        </CurrentPage> // page will be string
                    ) : (
                        <NavLink
                            key={index}
                            $accentColour={accentColour}
                            to={page.path}
                        >
                            {page.text}
                        </NavLink>
                    ) // page will be object
            )}
        </BreadcrumbsContainer>
    );
};

BreadCrumbs.propTypes = {
    /** Pages to show in order left to right. Current page must be final array element. Each array item must be object with properties text (string) and path (string), except final item which will just be a string (current page name). */
    pages: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                /** Text to display for link. */
                text: PropTypes.string.isRequired,
                /** Link href. Required for all links except last (as that should be current page). */
                path: PropTypes.string,
            }),
        ])
    ).isRequired,
    /** Colour the name of the current page is printed in. */
    accentColour: PropTypes.string,
};

export default BreadCrumbs;
