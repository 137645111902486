export const GRADIENT_DARK_BLUE = '#3343d3';

export const TURQUOISE = '#34cac6';

export const GRADIENT_PURPLE = '#ae50db';

export const TEXT_GREY = '#424143';

export const FADED_GREY = '#a1a1a1';

export const PINK = '#E32747';

export const OFF_WHITE = '#fbfbfb';

export const YELLOW_HIGHLIGHT = '#ffe500';

/**
 * Returns arguments passed to CSS linear-gradient function.
 * e.g. "linear-gradient(to right, blue 0 50%, red)" => ['to right', ['blue', '0', '50%'], ['red']]
 * @param {string} gradient
 * @returns {Array}
 */
export const extractLinearGradientComponents = gradient => {
    const match = gradient.match(/^linear-gradient\((.+)\)$/);
    if (match) {
        return match[1].split(',').map((arg, index) => index === 0 ? arg.trim() : arg.trim().split(' '));
    }
}

export const VL_BACKGROUND_GRADIENT = 'linear-gradient(180deg,#004C5B 0%,rgba(24,24,40,0.83) 100%)';
export const DEFAULT_BACKGROUND_GRADIENT = 'linear-gradient(180deg, #63636F 0%, #52525E 100%)';
export const TIMELINE_OVERLAY_GRADIENT = 'linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0.7) 70%, rgba(255, 255, 255, 0) 100%)';
export const INSTALL_GRADIENT = 'linear-gradient(90deg, #34CAC6 0%, #B03DE3 50%, #3343D3 100%)';

// opacity percentage
export const WHITE = (opacity = 100) => {
    return `rgba(255, 255, 255, ${opacity/100})`;
};

export const VL_BLACK = (opacity = 100) => {
    return `rgba(24, 24, 40, ${opacity/100})`;
};

export const LIGHT_GREY = '#F1F1F1';
export const MID_GREY = '#C9C9C9';
export const DARK_GREY = '#63636F';

export const LIGHT_TURQUOISE = '#B2FFFD';

export const VL_RED = '#EC2144';
export const VL_GREEN = '#00FF29';
export const SUPPORT_RED = '#FF6347';

export const SUBTLE_DROP_SHADOW = '0px 0px 3.6px 0px rgba(8, 18, 106, 0.1)';

export const colourByVariant = (variant, opacity) => {
    if (variant === "dark") {
        return VL_BLACK(opacity);
    } else {
        return WHITE(opacity);
    }
};

export const colourWithOpacity = (colour, opacity) => {
    let rgb;
    if (colour.includes('#')) {
        rgb = hexToRgb(colour);
    } else if (colour.includes('rgb')) {
        rgb = colour.match(/\d+/g).map(Number);
    }
    return `rgba(${rgb.join(', ')}, ${opacity/100})`;
};

export const hexToRgb = (hex) => {
    // Remove the hash symbol if present
    hex = hex.replace('#', '');
    // Parse the hex values to RGB components
    let bigint = parseInt(hex, 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;
    return [r, g, b];
};

export const rgbaToRgb = (rgbaString) => {
    // Use a regular expression to extract the values inside the rgba() or rgb() string
    const match = rgbaString.match(/rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*[\d.]+)?\)/);

    if (match) {
        // Convert the captured groups to integers and return as [r, g, b]
        return [parseInt(match[1], 10), parseInt(match[2], 10), parseInt(match[3], 10)];
    } else {
        // Return null if the string doesn't match the expected format
        return null;
    }
};

export const isReadableContrast = (colour1, colour2) => {
    let rgb1 = colour1;
    if (colour1.includes('rgba')) {
        rgb1 = rgbaToRgb(colour1);
    } else if (colour1.includes('#')) {
        rgb1 = hexToRgb(colour1);
    }
    let rgb2 = colour2;
    if (colour2.includes('rgba')) {
        rgb2 = rgbaToRgb(colour2);
    } else if (colour2.includes('#')) {
        rgb2 = hexToRgb(colour2);
    }
    const ratio = contrastRatio(rgb1, rgb2);
    const minimumContrast = 4.5;
    return ratio >= minimumContrast;
};

// Function to calculate relative luminance
const luminance = (r, g, b) => {
    // Convert sRGB to a linear color space
    [r, g, b] = [r, g, b].map(v => {
        v /= 255;
        return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    // Return the relative luminance
    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
}

export const contrastRatio = (rgb1, rgb2) => {
    // Calculate luminance
    const lum1 = luminance(rgb1[0], rgb1[1], rgb1[2]);
    const lum2 = luminance(rgb2[0], rgb2[1], rgb2[2]);
    // Calculate and return the contrast ratio
    const ratio = lum1 > lum2 ? (lum1 + 0.05) / (lum2 + 0.05) : (lum2 + 0.05) / (lum1 + 0.05);
    return ratio;
};

export const STRIPE_APPEARANCE = (accentColour) => ({
    theme: 'night',
    variables: {
        colorPrimary: accentColour,
        colorBackground: VL_BLACK(),
        colorText: WHITE(),
        colorDanger: VL_RED,
        spacingUnit: '4px',
        borderRadius: '8px',
    }
});