import styled from 'styled-components';
import { inputStyling } from '../../helpers';


export const PasswordContainer = styled.div`
    ${(props) => inputStyling(props)}
    display: flex;
    align-items: center;
    padding: 0 !important;

    // Remove input's own border
    input {
        border-left: none !important;
        border-right: none !important;
        border-left: none !important;
        border-radius: 4px 0 0 4px !important;
        ${({ $accentColour }) => `border-color: ${$accentColour} !important;` ?? ''}
        background-color: transparent !important;
    }

    // Hide password reveal button in Edge as we have our own
    input::-ms-reveal {
        display: none;
    }
`;

PasswordContainer.displayName = 'PasswordContainer';

export const TogglePasswordVisibility = styled.button`
    cursor: pointer;
    margin: 0 7px;
    
    /* resets default button styles */
    border: none;
    background: none;
    padding: 0;

    /* announces password input state(showing or hidden) for screen reader users */
    & > span.visually-hidden {
        position:absolute;
        left:-10000px;
        top:auto;
        width:1px;
        height:1px;
        overflow:hidden;
    }
`;

TogglePasswordVisibility.displayName = 'TogglePasswordVisibility';

export const PasswordStrengthIndicator = styled.div``;

PasswordStrengthIndicator.displayName = 'PasswordStrengthIndicator';

export const PasswordStrengthIndicatorMeter = styled.div`
    width: ${({ large }) => (large ? 92 : 80)}px;
    background-color: #efefef;
    height: ${({ large }) => (large ? 12 : 10)}px;
    border-radius: 10px;
    display: inline-block;
    padding: 1px;
    border: #cbcbcb 1px solid;
`;

PasswordStrengthIndicatorMeter.displayName = 'PasswordStrengthIndicatorMeter';

export const PasswordStrengthIndicatorMeterBar = styled.span`
    height: 100%;
    border-radius: 6px;
    display: block;

    &.low {
        background-color: red;
    }

    &.medium {
        background-color: #6b8e23;
    }

    &.high {
        background-color: #7fdc25;
    }
`;

PasswordStrengthIndicatorMeterBar.displayName =
    'PasswordStrengthIndicatorMeterBar';

export const PasswordStrengthIndicatorLabel = styled.p`
    display: inline;
    color: #999;
    margin-left: 5px;
    font-size: ${({ large }) => (large ? 16 : 14)}px !important;
`;

PasswordStrengthIndicatorLabel.displayName = 'PasswordStrengthIndicatorLabel';
