import { privateAuthenticatorRequest } from "./managers/requestBuilder";
import { getUid } from "./managers/store";

import { isDevelopment } from '../utils/getHost';

/**
 * Post message in Slack about an error.
 * @param {string} message Error message.
 * @param {import('error-stack-parser').StackFrame[]} trace Stack trace.
 * @param {object} [info] Additional info that will be included in Slack message.
 * @param {string} [info.identifier] Identifier for where error came from. Used in message title and notification.
 */
export const postErrorInSlack = async (message, trace, info) => {
    if (
        !isDevelopment &&

        // Must be logged in
        getUid()
    ) {
        privateAuthenticatorRequest(
            'POST',
            '/web-errors/:uid/slack',
            {
                data: {
                    identifier: info?.identifier,
                    href: window.location.href,
                    message,
                    trace,
                    info
                }
            }
        ).catch(console.error);
    }
}