import styled from 'styled-components';

import HeadingComponent from '../heading';

export const Heading = styled(HeadingComponent)`
    margin: 30px 0 50px 0;
    padding-bottom: 30px;
    border-bottom: 1px solid ${({ $borderColour }) => $borderColour};

    @media only screen and (max-width: 800px) {
        margin: 10px 0 30px;
        padding-bottom: 15px;
    }
`;

Heading.displayName = 'Heading';
