import styled from 'styled-components';

export const Ring = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;

    ${({ $tag }) => $tag}:focus-visible ~ & {
        border: 2px solid blue;
        border-radius: ${({ $borderRadius }) => $borderRadius}px;
    }
`;

Ring.displayName = 'Ring';
