/*
    This file handles storing user details and refreshing tokens.

    This should be the only file that uses the setters in src/api/store.js.
*/

import { clearLocalStorage, getLoginDetailsFromLocalStorage, updateLocalStorageOnLogin, updateLocalStorageOnRefreshToken } from "../../utils/manageStorage";

import { logForcedLogout, logLogin } from "../athena";
import { login, refreshToken, refreshTokenAndGetAccountData, logout as logoutAPI, loginWithDemoAccount, ssoLogin } from "../user";

import { getAuthenticatorForRegion } from "./servers";
import { clearDetailsAndTokens, updateDetails, getRegion, updateTokens } from "./store";

// Tokens last 20 mins; we refresh after 10 mins
const REFRESH_TOKENS_TIMEOUT = 600000;

// Key info
const state = {
    // Date object representing when auth tokens were last updated
    // All tokens are refreshed together so we don't need a separate timestamp for management token
    lastAuthTokenUpdate: null,
    // Whenever auth token is updated, we create a new timeout to refresh it
    // This timeout is stored here so it can be cleared if necessary
    authTokenRefreshTimeout: null,
    // Callback invoked if refreshing token fails
    onInvalidToken: null,
    // Callback invoked if network error prevents token refresh
    onNetworkError: null,
    // Whether to store login token and series id in local storage
    // This will be true unless using demo
    updateLocalStorage: false,
    // If `updateLocalStorage` is false, then login token and series id are stored here instead
    webLogin: null
};

// Clears info above and any stored details/tokens
// Called when user is logged out
const resetState = () => {
    clearDetailsAndTokens();
    state.lastAuthTokenUpdate = null;
    state.authTokenRefreshTimeout = null;
    state.onInvalidToken = null;
    state.onNetworkError = null;
    state.updateLocalStorage = false;
    state.webLogin = null;
}

// Use these functions to log a user in, either through email/password or login token in local storage
// onInvalidToken is called when a token refresh fails
// Returns account data
/**
 * Wrapper for `initRequestManager` function that uses the given email/password. Will store web login details in local storage.
 * @param {string} email Email to log in with.
 * @param {string} password Password to log in with.
 * @param {Function} onInvalidToken Invoked if a token refresh fails.
 * @param {Function} onNetworkError Invoked if token cannot be refreshed due to network error.
 * @returns Account data
 */
export const initRequestManagerEmailPassword = async (email, password, onInvalidToken, onNetworkError) => {
    const { webLogin, ...data } = await initRequestManager(() => login(email, password), onInvalidToken, onNetworkError, true);

    // Need to save new login token
    updateLocalStorageOnLogin({ webLogin, ...data });

    logLogin(true, 'password', webLogin.seriesId);

    return data;
}

/**
 * Wrapper for `initRequestManager` function that uses a SSO id token. Will store web login details in local storage.
 * @param {string} provider SSO provider.
 * @param {string} idToken SSO ID token.
 * @param {string} refreshToken SSO refresh token.
 * @param {*} onInvalidToken Invoked if a token refresh fails.
 * @param {*} onNetworkError Invoked if token cannot be refreshed due to network error.
 * @returns Account data
 */
export const initRequestManagerSSO = async (provider, idToken, refreshToken, onInvalidToken, onNetworkError) => {
    const { webLogin, ...data } = await initRequestManager(() => ssoLogin(provider, idToken, refreshToken), onInvalidToken, onNetworkError, true);

    // Need to save new login token
    updateLocalStorageOnLogin({ webLogin, ...data });

    logLogin(true, 'sso', webLogin.seriesId);

    return data;
}

/**
 * Wrapper for `initRequestManager` function that retrieves web login details from local storage.
 * @param {Function} onInvalidToken Invoked if a token refresh fails.
 * @param {Function} onNetworkError Invoked if token cannot be refreshed due to network error.
 * @returns Account data
 */
export const initRequestManagerWebLogin = async (onInvalidToken, onNetworkError) => {
    try {
        const data = await initRequestManager(
            () => runFunctionUsingLoginTokenInLocalStorage(
                refreshTokenAndGetAccountData,
                // Put all data in local storage
                // Do not put any tokens in though
                ({ authToken, managementToken, ...data }) => updateLocalStorageOnRefreshToken(data)
            ),
            onInvalidToken,
            onNetworkError,
            true
        );

        logLogin(true, 'loginToken', data.webLogin.seriesId);

        return data;
    } catch (error) {
        clearLocalStorage();
        throw error;
    }
}

/**
 * Fetch data for demo account. Obviously does not store details in local storage.
 * @param {string|bool} region AWS region, or boolean true to default to nearest region.
 * @param {*} onInvalidToken Invoked if a token refresh fails.
 * @param {Function} onNetworkError Invoked if token cannot be refreshed due to network error.
 * @returns Account data
 */
export const initRequestManagerDemo = async (region, onInvalidToken, onNetworkError) => {
    const { webLogin, ...data } = await initRequestManager(() => loginWithDemoAccount(region), onInvalidToken, onNetworkError, false);

    // Save loginToken in state which will be used to refresh authToken
    state.webLogin = webLogin;

    logLogin(true, 'demo', webLogin.seriesId);

    return data;
}

/**
 * Fetch account data (either through email/password login or refresh token) and store relevant details.
 * Also sets up first timeout to refresh token.
 * @param {Function} f Async function which returns account data.
 * @param {Function} onInvalidToken Invoked if a token refresh fails.
 * @param {Function} onNetworkError Invoked if token cannot be refreshed due to network error.
 * @param {Boolean} updateLocalStorage Whether to store web login details in local storage.
 * @returns Account data
 */
const initRequestManager = async (f, onInvalidToken, onNetworkError, updateLocalStorage) => {
    // Clear state in case some old details are still in there
    resetState();

    // Fetch account data
    const { authToken, managementToken, ...data } = await f();

    // Update state
    state.onInvalidToken = onInvalidToken;
    state.onNetworkError = onNetworkError;  
    state.updateLocalStorage = updateLocalStorage;

    // Update store
    updateDetails(data.uid, data.region, data.installerid, data.accountType, data.distributorId);
    updateTokens(authToken, managementToken);
    state.lastAuthTokenUpdate = new Date();

    // Set up timeout to refresh token
    state.authTokenRefreshTimeout = setTimeout(updateLocalStorage ? refreshTokensFromLocalStorage : refreshTokensFromState, REFRESH_TOKENS_TIMEOUT);

    return data;
}

/**
 * Returns time that auth (and management) token(s) were last updated.
 * @returns {Date}
 */
export const getTimeTokensLastUpdated = () => state.lastAuthTokenUpdate;


// On logout
export const logout = async () => {
    // Delete series
    try {
        // If user clicks log out, then browser back button, then log out again,
        // getLoginDetailsFromLocalStorage() would throw error (as details have been cleared)

        const { loginToken, seriesId, uid } = state.updateLocalStorage ?
            getLoginDetailsFromLocalStorage() : state.webLogin;

        if (state.updateLocalStorage) {
            clearLocalStorage();
        }

        await logoutAPI(
            loginToken,
            seriesId,
            uid,
            getAuthenticatorForRegion(getRegion())
        );
    } catch (error) {
        console.error(error);
    }

    // Clear state
    resetState();
}



// Refreshing token
// Token expires after 20 minutes
// We refresh it every 10 minutes
const refreshTokensFromState = async () => {
    try {
        // If this function was called by something other than timeout, then we no longer need the timeout
        clearRefreshTokenTimeout();

        const [newLoginToken, authToken, managementToken, newSSORefreshToken] = await refreshToken(
            state.webLogin.loginToken,
            state.webLogin.seriesId,
            state.webLogin.uid,
            getAuthenticatorForRegion(getRegion()),
            state.webLogin.ssoRefreshToken
        );

        // Store new login token
        state.webLogin.loginToken = newLoginToken;
        state.webLogin.ssoRefreshToken = newSSORefreshToken;
        
        handleNewTokens(authToken, managementToken, state.webLogin.seriesId);
    } catch (error) {
        onRefreshTokenFail(error, false, state.webLogin.seriesId);
    }
}

// Try to parse webLogin in local storage and use it to fetch new token(s)
const refreshTokensFromLocalStorage = async () => {
    
    // Get series id for logs
    let seriesId;
    try {
        seriesId = JSON.parse(localStorage.webLogin).seriesId;
    } catch (error) {
        console.error(error);
        seriesId = 'ERROR PARSING LOCAL STORAGE';
    }
    
    try {
        clearRefreshTokenTimeout();

        const [, authToken, managementToken] = await runFunctionUsingLoginTokenInLocalStorage(refreshToken, ([newLoginToken,,,newSSORefreshToken]) => updateLocalStorageOnRefreshToken({ webLogin: { loginToken: newLoginToken, ssoRefreshToken: newSSORefreshToken }}));

        

        handleNewTokens(authToken, managementToken, seriesId);
        
    } catch (error) {
        onRefreshTokenFail(error, true, seriesId);
    }
};

// This function will wait until local storage is unlocked, called the specified function f using the login token in local storage, and then update the login token in local storage using `updateLocalStorage`
const runFunctionUsingLoginTokenInLocalStorage = async (f, updateLocalStorage) => {

    /*
        If multiple tabs try to refresh token at same time, the following will happen:
            1. Both tabs read same webLogin from local storage and use it to send request
            2. First tab is successful and login token is updated
            3. Second tab will be unsuccessful as login token has expired
            4. Second tab logs out and clears local storage
            5. First tab will log out as local storage has been cleared

        To avoid this, we put a flag in local storage when a tab is refreshing the token.
        Other tabs must wait for flag to clear before trying to update their own token.

        This flag is called `refreshingTokenUntil` and stores a timestamp of when other tabs are
        free to start using webLogin again. We use time rather than a boolean in case the tab
        that placed the lock gets closed whilst refreshing the token, which would mean the flag
        never gets cleared.

        If more than two tabs are trying to refresh token at same time, then later tabs will have
        to wait for multiple timeouts before being able to access local storage. I've set the
        max attempts before a tab gives up to 5.
    */
    const FLAG = 'refreshingTokenUntil';

    // Promise does not resolve until local storage unlocked
    // Rejects if max attempts reached
    const waitForLocalStorage = () => new Promise((res, rej) => {
        
        // Recursively calls itself until local storage unlocked or attempts limit reached
        const wait = (attempts = 0) => {
            if (localStorage[FLAG]) {
                const refreshingTokenUntil = parseInt(localStorage[FLAG]);
                if (!isNaN(refreshingTokenUntil)) {
                    const currentTime = new Date().getTime();
                    if (currentTime < refreshingTokenUntil) {
                        if (attempts < 5) {
                            // Add random delay to timeout to reduce chance multiple tabs access local storage at same time
                            const randomDelay = Math.floor(Math.random() * 500);
                            setTimeout(() => wait(attempts + 1), refreshingTokenUntil - currentTime + randomDelay);
                            return;
                        } else {
                            // Max attempts exceeded
                            rej();
                            return;
                        }
                    }
                }
            }
            res();
        }

        wait();        
    });

    // Wait for local storage to be unlocked
    try {
        await waitForLocalStorage();
    } catch {
        throw new Error('Max handleRefreshToken attempts exceeded: login token is still locked');
    }

    // Lock local storage
    // Allow 2 seconds for request to have finished
    localStorage[FLAG] = new Date().getTime() + 2000;

    // Get login token from local storage
    const { loginToken: currentLoginToken, seriesId, uid, authenticator, ssoRefreshToken: currentSSORefreshToken } =
        getLoginDetailsFromLocalStorage();

    const response = await f(
        currentLoginToken,
        seriesId,
        uid,
        authenticator,
        currentSSORefreshToken
    );

    // Update local storage with new login token using provided function
    updateLocalStorage(response);
    
    // Release lock
    localStorage.removeItem(FLAG);

    return response;
}


// Clears timeout if not done already
const clearRefreshTokenTimeout = () => {
    if (state.authTokenRefreshTimeout) {
        clearTimeout(state.authTokenRefreshTimeout);
        state.authTokenRefreshTimeout = null;
    }
}

const handleNewTokens = (newAuthToken, newManagementToken, seriesId) => {
    // Update stored tokens
    updateTokens(newAuthToken, newManagementToken);
    state.lastAuthTokenUpdate = new Date();
    state.authTokenRefreshTimeout = setTimeout(state.updateLocalStorage ? refreshTokensFromLocalStorage : refreshTokensFromState, REFRESH_TOKENS_TIMEOUT);
    
    // Log to Athena
    logLogin(false, 'loginToken', seriesId);
}

/**
 * Invokes relevant callback when a token refresh fails. If failure wasn't a simple network
 * request, this also clears state.
 * @param {Error} error Error thrown when token refresh failed.
 * @param {boolean} usingLocalStorage Are login token details stored in local storage as opposed to state?
 * @param {string} seriesId Series id of login token.
 */
const onRefreshTokenFail = (error, usingLocalStorage, seriesId) => {
    if (error) {
        // Will not be an error if another tab logged out
        // In that case, this function is still used for convenience
        console.error(error); 
    }

    if (error?.message === 'Network Error') {
        state.onNetworkError?.();
    } else {
        if (usingLocalStorage) {
            clearLocalStorage();
        }

        if (error) {
            // Attempt to log to Athena if authToken still valid
            try {
                logForcedLogout(seriesId, error.message);
            } catch (error) {
                console.error(error);
            }
        }

        state.onInvalidToken?.();
    }
    resetState();
}



// Refresh token when tab comes back from background as timeout may have been throttled

// Check whether token needs refreshing when tab becomes active again
const onTabVisibilityChange = async () => {
    /*
            Check if token is older than 8 mins.
            Use 8 mins rather than usual 10 to be on safe side.
            Worst case scenario using 10 mins:
                - Tab put in background immediately after token refresh
                - Tab resumed 9:59 later
                - This code wouldn't trigger and timeout could still have 10 mins to run
                - May be a brief period where token has expired
        */
    if (document.visibilityState === 'visible' && state.lastAuthTokenUpdate && new Date().getTime() - state.lastAuthTokenUpdate.getTime() > 480000) {
        // Add random timeout to reduce chance multiple tabs access local storage at same time
        // If that were to happen, local storage may not be locked yet and they could both try to refresh token at same time
        const randomDelay = Math.floor(Math.random() * 500);
        setTimeout(state.updateLocalStorage ? refreshTokensFromLocalStorage : refreshTokensFromState, randomDelay);
    }
};

document.addEventListener('visibilitychange', onTabVisibilityChange);



// If another tab logs out, immediately log this one out too
// Otherwise this would happen on next token refresh which would of course fail
window.addEventListener('storage', event => {
    if (state.updateLocalStorage && (event.key === 'webLogin' || event.key === null) && !event.storageArea.webLogin) {
        console.warn('Another tab cleared local storage')
        onRefreshTokenFail(null, true);
    }
});