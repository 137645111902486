import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';

import useModal from '../../hooks/useModal';

import { isReadableContrast, VL_BLACK, WHITE } from '../../utils/colours';
import translationKeys from '../../translations/keys';

// Mobile menu icons
import { ReactComponent as HamburgerIcon } from '../../assets/images/icons/hamburger.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/icons/close.svg';

import Logo from '../logo';
import Span from '../span';
import {
    SidebarAbsoluteContainer,
    NavLinks,
    MainNavLinks,
    NavLink,
    MainNavLink,
    NavItem,
    MainNavItem,
    Divider,
    Footer,
    Copyright,
    FooterLink,
    Header,
    Menu,
    MobileMenuToggle,
    LogoContainer,
    NavButton,
    SidebarRelativeContainer,
    IconContainer,
    CollapsedLogoContainer,
    BetaTag,
} from './Sidebar.styles';
import useWhiteLabelComponent from '../../hooks/useWhiteLabelComponent';
import { isCCTVConnect } from '../../utils/getHost';

// Key in local storage
const COLLAPSED_SIDEBAR_KEY = 'collapsedSidebar';

/** Sidebar used by AppLayout. */
const Sidebar = ({
    mainLinks,
    secondaryLinks,
    footerLinks = [],
    mobileBreakpoint = 800,
    t,
    tReady,
    ...props
}) => {
    // Mobile menu is very similar to a modal
    // useModal will handle trapping focus and preventing scrolling
    const [{ show: mobileMenuOpen, containerRef: mobileMenuContainerRef, closeRef: closeMobileMenuRef }, showMobileMenu, closeMobileMenu] = useModal();

    const { logo, appLayoutLogo: logoSrc, appLayoutLogoSmall: collapsedLogoSrc, returnLink: logoLink, whiteOverAccent, navColour: backgroundColour,  accentColour: wlAccentColour, accentColour2, copyrightName, isVideoloft } = useWhiteLabelComponent();
    const accentColour = wlAccentColour === backgroundColour ? WHITE(75) : wlAccentColour;

    const determineTextColourOverAccent = () => {
        if (isCCTVConnect) {
            if (isReadableContrast(accentColour, WHITE())) {
                return WHITE();
            } else return VL_BLACK();
        } else {
            return VL_BLACK();
        }
    };

    const textColour = _.isNil(accentColour2) ? whiteOverAccent ? WHITE() : VL_BLACK() : WHITE();
    const alternativeTextColour = determineTextColourOverAccent();

    // Whether desktop menu is collapsed
    const collapsedDesktopMenu = true;

    // On mobile, menu fills screen when open
    const [isMobileScreenWidth, setIsMobileScreenWidth] = useState(window.innerWidth <= mobileBreakpoint);
    useEffect(() => {
        const update = () => setIsMobileScreenWidth(window.innerWidth <= mobileBreakpoint);

        update();

        window.addEventListener('resize', update);

        return () => window.removeEventListener('resize', update);
    }, [mobileBreakpoint]);
    
    // Need useModal to know when mobile menu is closed due to screen width to make sure focus doesn't stay trapped
    useEffect(() => {
        if (!isMobileScreenWidth) {
            closeMobileMenu();
        }
    }, [isMobileScreenWidth, closeMobileMenu]);

    return (
        <SidebarRelativeContainer
            $mobileBreakpoint={mobileBreakpoint}
            $collapsed={collapsedDesktopMenu}
        >
            <SidebarAbsoluteContainer
                ref={mobileMenuContainerRef}
                $backgroundColour={backgroundColour}
                $mobileBreakpoint={mobileBreakpoint}
                $mobileMenuOpen={mobileMenuOpen}
                $collapsed={collapsedDesktopMenu}
                {...props}
            >
                {/* <CollapseButton
                    onClick={() => {
                        recordAnalytics(collapsedDesktopMenu ? 'expand_sidebar' : 'collapse_sidebar');
                        setCollapsedDesktopMenu((c) => {
                            try {
                                localStorage[COLLAPSED_SIDEBAR_KEY] = !c;
                            } catch {}
                            return !c;
                        })
                    }}
                    $colour={fadedTextColour}
                    $collapsed={collapsedDesktopMenu}
                    $mobileBreakpoint={mobileBreakpoint}
                    aria-label={collapsedDesktopMenu ? (tReady ? t(translationKeys.navigation.sidebar.EXPAND) : 'Expand sidebar') : (tReady ? t(translationKeys.navigation.sidebar.COLLAPSE) : 'Collapse sidebar')}
                >
                    <CollapseIcon />
                </CollapseButton> */}

                {/* Header shown on desktop and mobile */}
                <Header
                    $mobileBreakpoint={mobileBreakpoint}
                    $collapsed={collapsedDesktopMenu}
                    $noCollapsedLogo={!collapsedLogoSrc || !logo}
                >
                    {/* Logo on mobile and full desktop */}
                    <LogoContainer
                        $mobileBreakpoint={mobileBreakpoint}
                        $collapsed={collapsedDesktopMenu}
                        $noCollapsedLogo={!collapsedLogoSrc || !logo}
                    >
                        <Logo
                            src={logoSrc ?? logo}
                            link={logoLink}
                            // Should not be able to focus element when hidden
                            tabIndex={!isMobileScreenWidth && collapsedDesktopMenu && collapsedLogoSrc ? -1 : undefined}
                        />
                    </LogoContainer>

                    {/* Collapsed desktop logo */}
                    {(collapsedLogoSrc || logo) && (
                        <CollapsedLogoContainer
                            $collapsed={collapsedDesktopMenu}
                            $mobileBreakpoint={mobileBreakpoint}
                        >
                            <Logo
                                src={collapsedLogoSrc ?? logo}
                                link={logoLink}
                                // Should not be able to focus element when hidden
                                tabIndex={isMobileScreenWidth || !collapsedDesktopMenu ? -1 : undefined}
                                alt="App logo"
                            />
                        </CollapsedLogoContainer>
                    )}

                    {/* Hamburger only shown on mobile */}
                    <MobileMenuToggle
                        $mobileBreakpoint={mobileBreakpoint}
                        ref={closeMobileMenuRef}
                        onClick={mobileMenuOpen ? closeMobileMenu : showMobileMenu}
                        aria-expanded={mobileMenuOpen}
                        aria-controls="navigation-menu"
                        aria-haspopup="true"
                        data-testid="mobile-menu-toggle"
                        $colour={textColour}
                    >
                        {mobileMenuOpen ? <CloseIcon /> : <HamburgerIcon />}
                    </MobileMenuToggle>
                </Header>

                {/* Everything that appears hidden on mobile wrapped in Menu component. Id is referred to by MobileMenuToggle. */}
                <Menu
                    id="navigation-menu"
                    $mobileBreakpoint={mobileBreakpoint}
                    $mobileMenuOpen={mobileMenuOpen}
                >

                    {/* 

                    We used to have a camera group selector at the top of the sidebar. I'm leaving the code for it below
                    as I'm envisaging that one day we may have some sort of profile/account selector (e.g. for installers to
                    choose between installer and demo account, or for users to choose which organisation's cameras they are
                    viewing) that will need a similar UI.
                    
                    {cameraGroups?.length > 0 && selectedCameraGroup && (
                        <CameraGroupSelector>
                            <CameraGroupSelectorButton
                                $colour={textColour}
                                $collapsed={collapsedDesktopMenu}
                                $mobileBreakpoint={mobileBreakpoint}
                                {...popupTriggerButtonProps}
                                title={
                                    collapsedDesktopMenu &&
                                    !isMobileScreenWidth
                                        ? cameraGroupText
                                        : undefined
                                }
                            >
                                <IconContainer>
                                    <CameraGroupsIcon alt={cameraGroupText} />
                                </IconContainer>
                                <SelectedCameraGroupText
                                    $collapsed={collapsedDesktopMenu}
                                    $mobileBreakpoint={mobileBreakpoint}
                                >
                                    <Span fontSize="75%" bold>{cameraGroupText}</Span>
                                    <Span>
                                        {selectedCameraGroup.name}
                                    </Span>
                                </SelectedCameraGroupText>
                            </CameraGroupSelectorButton>
                            <PopupList
                                type="select"
                                maxListHeight={600}
                                options={cameraGroups
                                    .map(({ name, id }) => ({
                                        label: name,
                                        key: id,
                                        selected: selectedCameraGroup.id === id,
                                        action: editCameraGroup && id > 0 ? {
                                            text: editCameraGroupText,
                                            onClick: () => {
                                                // Close popup when "Edit" clicked
                                                popupProps?.closePopup();
                                                editCameraGroup(id);
                                                closeMobileMenu();
                                            }
                                        } : undefined
                                    }))}
                                onChange={(id) => {
                                    changeCameraGroup(id);
                                    closeMobileMenu();
                                }}
                                accentColour={accentColour}
                                {...popupProps}
                            />
                        </CameraGroupSelector>
                    )} */}

                    {/* Links for top section of sidebar. */}
                    <MainNavLinks>
                        {mainLinks.map(({ text, path, icon, beta }) => {
                            const Icon = icon;

                            return (
                                <MainNavItem
                                    key={path}
                                    title={
                                        // collapsedDesktopMenu &&
                                        !isMobileScreenWidth
                                            ? text
                                            : undefined
                                    }
                                >
                                    <MainNavLink
                                        $textColour={textColour}
                                        $alternativeTextColour={alternativeTextColour}
                                        $accentColour={accentColour}
                                        $accentColour2={isVideoloft ? accentColour2 : undefined}
                                        $backgroundColour={backgroundColour}
                                        key={path}
                                        to={path}
                                        onClick={closeMobileMenu}
                                        // $collapsed={collapsedDesktopMenu}
                                        $mobileBreakpoint={mobileBreakpoint}
                                    >
                                        <IconContainer>
                                            <Icon alt={text} />
                                        </IconContainer>
                                        <Span>{text}</Span>
                                        {
                                            beta && (
                                                <BetaTag>{t(translationKeys.technical.BETA)}</BetaTag>
                                            )
                                        }
                                    </MainNavLink>
                                </MainNavItem>
                            );
                        })}
                    </MainNavLinks>

                    {/* Dividing line */}
                    <Divider $colour={accentColour} />

                    {/* Links for lower section of sidebar. */}
                    <NavLinks>
                        {secondaryLinks.map(({ text, path, action, icon }) => {
                            const Icon = icon;

                            return (
                                <NavItem
                                    key={path || action}
                                    title={
                                        !isMobileScreenWidth
                                            ? text
                                            : undefined
                                    }
                                    $mobileBreakpoint={mobileBreakpoint}
                                >
                                    {path ? (
                                        <NavLink
                                            to={path}
                                            onClick={closeMobileMenu}
                                            $textColour={textColour}
                                            $alternativeTextColour={alternativeTextColour}
                                            $backgroundColour={backgroundColour}
                                            $accentColour={accentColour}
                                            $accentColour2={isVideoloft ? accentColour2 : undefined}
                                            $mobileBreakpoint={mobileBreakpoint}
                                        >
                                            <IconContainer>
                                                <Icon />
                                            </IconContainer>
                                            <Span>{text}</Span>
                                        </NavLink>
                                    ) : (
                                        <NavButton
                                            onClick={() => {
                                                action();
                                                closeMobileMenu();
                                            }}
                                            $textColour={textColour}
                                            $alternativeTextColour={alternativeTextColour}
                                            $backgroundColour={backgroundColour}
                                            $accentColour={accentColour}
                                            $mobileBreakpoint={mobileBreakpoint}
                                        >
                                            <IconContainer>
                                                <Icon />
                                            </IconContainer>
                                            <Span>{text}</Span>
                                        </NavButton>
                                    )}
                                </NavItem>
                            );
                        })}
                    </NavLinks>

                    {/* Copyright, terms, privacy */}
                    <Footer
                        $colour={textColour}
                        $mobileBreakpoint={mobileBreakpoint}
                    >
                        {footerLinks.map(({ text, path }) => (
                            <FooterLink
                                key={path}
                                to={path}
                                onClick={closeMobileMenu}
                            >
                                {text}
                            </FooterLink>
                        ))}
                        {copyrightName && (
                            <Copyright>&copy; 2024 {copyrightName}</Copyright>
                        )}
                    </Footer>
                </Menu>
            </SidebarAbsoluteContainer>
        </SidebarRelativeContainer>
    );
};

Sidebar.propTypes = {
    /** Logo URL. */
    logoSrc: PropTypes.string,
    /** URL of smaller logo to use when sidebar collapsed. */
    collapsedLogoSrc: PropTypes.string,
    /** Href value for anchor tag that wraps logo. If no value given for this prop, anchor tag is not used. */
    logoLink: PropTypes.string,
    /** Background colour. */
    backgroundColour: PropTypes.string,
    /** Links to go in upper half of sidebar. */
    mainLinks: PropTypes.arrayOf(
        PropTypes.shape({
            /** Text for nav link. */
            text: PropTypes.string.isRequired,
            /** Link destination. */
            path: PropTypes.string.isRequired,
            /** Icon component to render. */
            icon: PropTypes.elementType.isRequired,
            /** Show "BETA" next to link. */
            beta: PropTypes.bool
        })
    ).isRequired,
    /** Links to go in lower half of navbar. */
    secondaryLinks: PropTypes.arrayOf(PropTypes.shape({
        /** Text for nav link. */
        text: PropTypes.string.isRequired,
        /** Link destination. One of `path` or `action` must be present. */
        path: PropTypes.string,
        /** Called on click. Nav item will be a button rather than link. One of `path` or `action` must be present. */
        action: PropTypes.func,
        /** Icon component to render. */
        icon: PropTypes.elementType.isRequired
    })).isRequired,
    /** Colour to use for highlighted elements, like selected nav link. */
    accentColour: PropTypes.string,
    /** Colour to use for non-active nav links. */
    fadedTextColour: PropTypes.string,
    /** Colour used for non-faded text. */
    textColour: PropTypes.string,
    /** Links to show at bottom of sidebar (e.g. privacy policy). */
    footerLinks: PropTypes.arrayOf(
        PropTypes.shape({
            /** Text for link. */
            text: PropTypes.string.isRequired,
            /** Link destination. */
            path: PropTypes.string.isRequired,
        })
    ),
    /** Mobile view shown for screen widths up to and including this value (in px). */
    mobileBreakpoint: PropTypes.number,
    /** Name to use in copyright. If this is null, no copyright will be shown. */
    copyrightName: PropTypes.string
};

export default withTranslation()(Sidebar);
