import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { getTimestamp, getTimezonedDate, createTimestampFromDateComponents, getDateComponents, isTimestampOnGivenDate, createNewTimeInTimezone } from '../../../utils/datetime';

import useWhiteLabelComponent from '../../../hooks/useWhiteLabelComponent';

import Picker, { TimeField } from '../Picker';
import TimezoneSelector from '../../timezone-selector/TimezoneSelector';

import {
    DateRangeSelector
} from './DateTimeRangePicker.styles';

/** Allows user to select a range from one date and time to another date and time. Renders a calendar grid which shows one month at a time. */
const DateTimeRangePicker = ({
    startDateTime = null,
    endDateTime = null,
    minDateTime = null,
    maxDateTime = null,
    maxDateDifference,
    t,
    onChange,
    onClear,
    timezone,
    availableTimezones,
    timezoneSelectorProps,
    includeTimezone = false,
    ...props
}) => {
    // Currently selected dates and stored times
    const startDate = startDateTime ? getTimestamp(startDateTime, timezone) : null;
    const endDate = endDateTime ? getTimestamp(endDateTime, timezone) : null;

    // Values of time input fields
    const [startTime, setStartTime] = useState(startDateTime ? `${getDateComponents(startDateTime, timezone).hour}:${getDateComponents(startDateTime, timezone).minute}` : '00:00');
        
    const [endTime, setEndTime] = useState(
        endDateTime ? getTimezonedDate(endDateTime, timezone).toTimeString().substring(0, 5) : '23:59'
    );

    // Which end of the range is the user currently selecting
    const [selectingEndDate, setSelectingEndDate] = useState(
        !!(startDate && !endDate)
    );

    const { accentColour } = useWhiteLabelComponent();

    useEffect(() => {
        setStartTime(() => {
            if (startDate) {
                const {hour,minute} = getDateComponents(startDate, timezone);
                return `${hour?.toString().padStart(2, '0')}:${minute?.toString().padStart(2, '0')}`;
            }
            return '00:00';
        });
        setEndTime(() => {
            if (endDate) {
                const {hour, minute} = getDateComponents(endDate, timezone);
                return `${hour?.toString().padStart(2, '0')}:${minute?.toString().padStart(2, '0')}`;
            }
            return '23:59';
        });
    }, [timezone]);

    return (
        <Picker
            defaultDateInView={endDateTime ?? startDateTime}
            minDateTime={minDateTime}
            maxDateTime={maxDateTime}
            renderTimezoneComponent={() => 
                includeTimezone ?  
                    <TimezoneSelector {...timezoneSelectorProps} variant="select"/> :  null
            }
            renderRangeRowComponents={(end) => (
                <>
                    <DateRangeSelector
                        $colour={accentColour}
                        $selected={end ? selectingEndDate : !selectingEndDate}
                        onClick={() => setSelectingEndDate(end)}
                    >
                        {(end && endDate) || startDate ? `${end ? new Date(endDate ?? startDate).toLocaleDateString(undefined, { timeZone: timezone }) : new Date(startDate).toLocaleDateString(undefined, { timeZone: timezone })}`: '--/--/----'}
                    </DateRangeSelector>
                    <TimeField
                        isEndTime={end}
                        time={end ? endTime : startTime}
                        setTime={(time) => {
                            // Update time input field
                            if (end) {
                                setEndTime(time);
                            } else {
                                setStartTime(time);
                            }

                            // If selection is valid, invoke `onChange`
                            const enteredStartTime = end ? startTime : time;
                            const enteredEndTime = end ? time : endTime;
                            if (time && startTime && startDate && endDate) {
                                const enteredStart = createNewTimeInTimezone(timezone, startDate, enteredStartTime.split(':')[0], enteredStartTime.split(':')[1], true);
                                const enteredEnd = createNewTimeInTimezone(timezone, endDate, enteredEndTime.split(':')[0], enteredEndTime.split(':')[1], false);
                                if (
                                    !isNaN(enteredStart) &&
                                    !isNaN(enteredEnd) &&
                                    enteredEnd >= enteredStart &&
                                    (startDateTime !== enteredStart || endDateTime !== enteredEnd)
                                ) {
                                    onChange([enteredStart, enteredEnd]);
                                }
                            }
                        }}
                        invalid={end && startDate && endDate && startDate >= endDate}
                    />
                </>
            )}

            // Date functions
            showStartTrail={date => {
                const dateTimestampAtStartOfDate = createTimestampFromDateComponents(date.year, date.month, date.day, 0, 0, timezone, true);
                return startDate && endDate && dateTimestampAtStartOfDate > startDate && dateTimestampAtStartOfDate <= endDate;
            }}
            showEndTrail={date => {
                const dateTimestampAtEndOfDate = createTimestampFromDateComponents(date.year, date.month, date.day, 23, 59, timezone, false);
                return startDate && endDate && dateTimestampAtEndOfDate < endDate && dateTimestampAtEndOfDate >= startDate;
            }}
            isDateDisabled={date => {
                const dateTimestampAtEndOfDate = createTimestampFromDateComponents(date.year, date.month, date.day, 23, 59, timezone, false);
                const maxDateDifferenceInMs = (maxDateDifference ?? 0) * 8.64e7;
                return (
                    // Cannot choose end date that is less than start date
                    (selectingEndDate && dateTimestampAtEndOfDate <= startDate) ||
                    (typeof maxDateDifference === 'number' &&
                        !selectingEndDate &&
                        endDate &&
                        !startDate &&
                        endDate - dateTimestampAtEndOfDate > maxDateDifferenceInMs
                    ) ||
                    // Cannot choose end date that is greater than (start date + maxDateDifference)
                    (typeof maxDateDifference === 'number' &&
                        selectingEndDate &&
                        startDate &&
                        // `startDate` will be at midnight and `dateTimestampAtEndOfDate` will be at the end of the day
                        // Hence need to add (almost) an extra day on when comparing to `maxDateDifference`
                        dateTimestampAtEndOfDate - startDate > maxDateDifferenceInMs + 1000 * 60 * 60 * 24 - 1
                    )
                )
            }}
            isDateSelected={date => ( startDate &&  isTimestampOnGivenDate(startDate, date, timezone) ) || ( endDate && isTimestampOnGivenDate(endDate, date, timezone))}
            onDateClick={date => {
                let newStart, newEnd;
                if (selectingEndDate) {
                    // End date has changed

                    // Time field values may not be valid
                    // Use defaults if that's the case
                    const validEndTime = endTime.match(/\d\d:\d\d/) ? endTime : '23:59';
                    newEnd = createTimestampFromDateComponents(date.year, date.month, date.day, validEndTime.split(':')[0], validEndTime.split(':')[1], timezone, false);
                    
                    const validStartTime = startTime.match(/\d\d:\d\d/) ? startTime : '00:00';
                    newStart = startDate ?? createTimestampFromDateComponents(date.year, date.month, date.day, validStartTime.split(':')[0], validStartTime.split(':')[1], timezone, true);

                    // Decrease start if it is now greater than end
                    if (newStart > newEnd) {
                        newStart = createTimestampFromDateComponents(date.year, date.month, date.day, 0, 0, timezone, true);
                    }
                } else {
                    // Start date has changed

                    const validStartTime = startTime.match(/\d\d:\d\d/) ? startTime : '00:00';
                    newStart = createTimestampFromDateComponents(date.year, date.month, date.day, validStartTime.split(':')[0], validStartTime.split(':')[1], timezone, true);

                    const validEndTime = endTime.match(/\d\d:\d\d/) ? endTime : '23:59';
                    newEnd = endDate ?? createTimestampFromDateComponents(date.year, date.month, date.day, validEndTime.split(':')[0], validEndTime.split(':')[1], timezone, false);

                    if (newEnd < newStart) {
                        // Increase end time if it is now less than start
                        newEnd = createTimestampFromDateComponents(date.year, date.month, date.day, 23, 59, timezone, false);
                    } else {
                        // Need to also check whether the max date difference has been exceeded

                        const maxDateDifferenceInMs = maxDateDifference * 8.64e7 + 1000 * 60 * 60 * 24 - 1;
                        
                        const startTimestampAtStartOfDate = createTimestampFromDateComponents(date.year, date.month, date.day, 0, 0, timezone, true);
                        const currentEndDateComponents = getDateComponents(newEnd, timezone);
                        const currentEndTimestampAtEndOfDate = createTimestampFromDateComponents(currentEndDateComponents.year, currentEndDateComponents.month, currentEndDateComponents.day, 23, 59, timezone, false);

                        // Bring forward end date if range would exceed maxDateDifference otherwise
                        if (
                            typeof maxDateDifference ===
                                'number' &&
                            currentEndTimestampAtEndOfDate - startTimestampAtStartOfDate > maxDateDifferenceInMs
                        ) {
                            newEnd = startTimestampAtStartOfDate + maxDateDifferenceInMs;
                        }
                    }
                }
                
                // Update time fields if we had to make changes
                const expectedStartTime = getTimezonedDate(newStart, timezone).toTimeString().substring(0, 5);
                if (expectedStartTime !== startTime) {
                    setStartTime(expectedStartTime);
                }
                const expectedEndTime = getTimezonedDate(newEnd, timezone).toTimeString().substring(0, 5);
                if (expectedEndTime !== endTime) {
                    setEndTime(expectedEndTime);
                }

                // Invoke `onChange` if selection has changed
                if (newStart !== startDateTime || newEnd !== endDateTime) {
                    onChange([newStart, newEnd]);
                }

                // Switch to selecting the other end of the range
                setSelectingEndDate(
                    (selectingEnd) => !selectingEnd
                );
            }}

            onClear={onClear}
            selectionMade={startDateTime || endDateTime}
            timezone={timezone}
        />
    );
};

DateTimeRangePicker.propTypes = {
    /** Value for the start of the range. */
    startDateTime: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.number]),
    /** Value for the end of the range. */
    endDateTime: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.number]),
    /** Unix timestamp - Minimum allowed value for the range, i.e. start date (and end date) can be no earlier than this. */
    minDateTime: PropTypes.number,
    /** Unix timestamp - Maximum allowed value for the range, i.e. end date (and start date) can be no later than this. */
    maxDateTime: PropTypes.number,
    /** Maximum difference allowed in days between date components of `startDateTime` and `endDateTime`. */
    maxDateDifference: PropTypes.number,
    /** Function called when user inputs new range. Must take array containing start and end dates as only argument. */
    onChange: PropTypes.func.isRequired,
    /** Function called when user clears the current range. If excluded, "Clear" button will not be shown. */
    onClear: PropTypes.func,
    timezone: PropTypes.string,
    availableTimezones: PropTypes.arrayOf(PropTypes.string),
    includeTimezone: PropTypes.bool
};

export default withTranslation()(DateTimeRangePicker);