import PropTypes from 'prop-types';

/** Wraps its children in the provided wrapper if a condition is true. */
const ConditionalWrapper = ({ useWrapper = false, wrapper, children }) => {
    return useWrapper ? wrapper(children) : children;
};

ConditionalWrapper.propTypes = {
    /** Wrap `children` in `wrapper`. */
    useWrapper: PropTypes.bool,
    /** Function which takes `children` as only argument and returns those children wrapped in other React elements. */
    wrapper: PropTypes.func.isRequired,
};

export default ConditionalWrapper;
