import styled from 'styled-components';

import { WHITE, colourByVariant } from '../../utils/colours';

const HEIGHT_DEFAULT = 27;
const HEIGHT_LARGE = 37;

const CROSS_SIZE_DEFAULT = 8;
const CROSS_SIZE_LARGE = 12;

export const SearchBoxContainer = styled.div`
    position: relative;
    display: inline;
    width: ${({ $large }) => $large ? 250 : 200}px;
    height: ${({ $large }) => $large ? HEIGHT_LARGE : HEIGHT_DEFAULT}px;

    ${({ $maxWidth }) => $maxWidth ? `
        @media screen and (max-width: 800px) {
            max-width: ${$maxWidth};
        }
    ` : ''}
    
`;

SearchBoxContainer.displayName = 'SearchBoxContainer';


export const SearchBoxInput = styled.input`
    font-weight: 100;
    padding: 0 ${({ $large }) => $large ? HEIGHT_LARGE : HEIGHT_DEFAULT}px 0 10px;
    border-radius: 50px;
    font-size: 14px;
    width: 100%;
    height: 100%;
    background: none;
    ${({ variant }) => `
        border: 1px solid ${colourByVariant(variant)};
        color: ${colourByVariant(variant)};

        ::placeholder {
            color: ${colourByVariant(variant, 75)};
        }
    `}

    &:read-only {
        background-color: ${WHITE(15)};
        color: ${WHITE()};
        border: none;
        opacity: 0.4;

        ::placeholder {
            color: ${WHITE()};
        }
    }
`;

SearchBoxInput.displayName = 'SearchBoxInput';


const crossMarginDefault = (HEIGHT_DEFAULT - CROSS_SIZE_DEFAULT) / 2;
const crossMarginLarge = (HEIGHT_LARGE - CROSS_SIZE_LARGE) / 2;

export const ClearButton = styled.button`
    position: absolute;
    top: 0;
    right: 0;
    background: none;
    border: none;
    padding: 0;
    border-radius: ${HEIGHT_LARGE}px;
    width: ${({ $large }) => $large ? HEIGHT_LARGE : HEIGHT_DEFAULT}px;
    height: ${({ $large }) => $large ? HEIGHT_LARGE : HEIGHT_DEFAULT}px;
    transition: opacity .2s, color .2s;
    color: ${({ variant }) => colourByVariant(variant, 50)};

    svg {
        display: block;
        position: absolute;
        right: ${({ $large }) => $large ? crossMarginLarge : crossMarginDefault}px;
        top: ${({ $large }) => $large ? crossMarginLarge : crossMarginDefault}px;
        width: ${({ $large }) => $large ? CROSS_SIZE_LARGE : CROSS_SIZE_DEFAULT}px;
        height: ${({ $large }) => $large ? CROSS_SIZE_LARGE : CROSS_SIZE_DEFAULT}px;
    }

    @media (hover: hover) {
        :hover {
            color: ${({ variant }) => colourByVariant(variant)};
        }
    }
`;

ClearButton.displayName = 'ClearButton';