import styled from "styled-components";

import { colourByVariant, FADED_GREY } from "../../utils/colours";

export const Tag = styled.div`
    border-radius: 100px;
    padding: 4px 12px;
    background: ${({variant}) => colourByVariant(variant, 20)};
    font-weight: 100;
    font-size: ${({ large }) => large ? 100 : 80}%;
    box-shadow: 1px 1px 5px 0px #00000017;
    white-space: nowrap;
    display: flex;
    align-items: center;
    overflow: hidden;

    span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    // Close icon
    button {
        margin-left: 8px;
        color: ${FADED_GREY};
        transition: color .2s;

        @media (hover: hover) {
            :hover {
                color: inherit;
            }
        }

        :focus-visible {
            color: inherit;
        }
    }
`;

Tag.displayName = 'Tag';