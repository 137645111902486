import FingerprintJS from '@fingerprintjs/fingerprintjs';
import UAParser from 'ua-parser-js';

export const isSafari =  /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;


/* ------------------------------- Fingerprint ------------------------------ */

// Once we have it, string id gets stored here
let fingerprintId;

export const getFingerprintId = async () => {
    if (!fingerprintId) {
        // Get fingerprint if we don't already have it
        try {
            const fp = await FingerprintJS.load({ monitoring: false });
            const { visitorId: id } = await fp.get();

            fingerprintId = id;

            // Expose fingerprint through console
            window.fingerprintId = fingerprintId;
        } catch (error) {
            console.error(error);
            fingerprintId = 'ERROR';
        }
    }

    return fingerprintId;
}

/* ----------------------------- Browser and OS ----------------------------- */

const parser = new UAParser();
const parserResults = parser.getResult();

export const getBrowserName = () => parserResults.browser.name;
export const getOSName = () => parserResults.os.name;
export const getDeviceModel = () => parserResults.device.model;
export const getDeviceType = () => parserResults.device.type;