import PropTypes from 'prop-types';
import { memo, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useFocusVisible } from 'react-aria';

import usePopup from '../../hooks/usePopup';

import translationKeys from '../../translations/keys';

import { isUsingMouse } from '../../utils/pointer';

import Span from '../span';
import Popup from '../popup';
import ImageEnlargeOnHover from "../image-enlarge-on-hover";
import { Loading } from "./ImageLoader.styles";

/** Renders image if `src` prop is provided. Shows loading graphic if not. */
const ImageLoader = memo(({ popupOnHover, ...props }) => {

    if (popupOnHover) {
        return (
            <ImageLoaderWithHoverPopup
                {...props}
            />
        );
    }

    return (
        <BasicImageLoader
            {...props}
        />
    );
});

// Alternative to `enlargeOnHover`
// Shows enlarged image in a popup
const ImageLoaderWithHoverPopup = memo(({ popupPlacement = 'bottom', ...props }) => {

    const [popupProps, { onClick: togglePopup, ref }] = usePopup({
        arrow: false,
        placement: popupPlacement,
        fixedPositioning: true,
        offset: [0, 10]
    });

    // Show popup on focus when needed
    const { isFocusVisible } = useFocusVisible();

    const imageLoaded = props.src && !props.error;

    return (
        <>
            {/* Initial image */}
            <button
                ref={ref}
                style={{
                    width: props.width,
                    height: props.height,
                    background: 'none',
                    border: 'none',
                    padding: 0,
                    cursor: imageLoaded ? 'zoom-in' : undefined
                }}
                // State was getting stuck on mouse click and this was rectified by only passing
                // function when needed
                onMouseEnter={imageLoaded && !popupProps.open && isUsingMouse() ? togglePopup : undefined}
                onMouseLeave={imageLoaded && popupProps.open && isUsingMouse() ? togglePopup : undefined}
                onFocus={imageLoaded && isFocusVisible && !popupProps.open ? togglePopup : undefined}
                onBlur={imageLoaded && isFocusVisible && popupProps.open ? togglePopup : undefined}
            >
                <ImageLoader
                    {...props}

                    // This is not valid now
                    enlargeOnHover={false}
                />
            </button>

            {/* Enlarged popup */}
            {
                imageLoaded && (
                    <Popup
                        {...popupProps}
                        width="min-content"
                        style={{
                            padding: 5,
                            borderRadius: 5,
                            ...(popupProps.style ?? {})
                        }}
                    >
                        <img
                            src={props.src}
                            width={props.width * 2.5}
                            height="auto"
                            alt={props.alt}
                            style={{
                                // Change from displaying inline so extra space below image is removed
                                display: 'block',
                                borderRadius: 5 // Match popup
                            }}
                        />
                    </Popup>
                )
            }
        </>
    );
});

const BasicImageLoader = ({ width, height, src, alt, loadImage, timeout = 0, error, enlargeOnHover, placeholder, t, tReady, imageObjectFit }) => {

    useEffect(() => {
        if (!src && loadImage) {
            if (timeout) {
                const t = setTimeout(() => {
                    loadImage();
                }, timeout);

                return () => {
                    clearTimeout(t);
                };
            } else {
                loadImage();
            }
        }
    }, [loadImage, src, timeout]);

    if (error) {
        return (
            <Span
                block
                fontSize="80%"
                colour="red"
                light
                style={{
                    width,
                    minWidth: width,
                    textAlign: 'center',
                    textWrap: 'wrap'
                }}
            >
                {tReady ? t(translationKeys.common.NO_IMAGE) : 'No image'}
            </Span>
        );
    }

    if ((!src || src === 'error') && placeholder) {
        return placeholder;
    }
    // Show Loading until `src` is provided
    if (!src) {
        return <Loading $width={width} $height={height} data-testid="ImageLoader.Loading" />;
    }

    // Use ImageEnlargeOnHover component if requested
    if (enlargeOnHover) {
        return (
            <ImageEnlargeOnHover
                width={width}
                height={height}
                src={src}
                alt={alt}
            />
        );
    }

    // Otherwise just standard image
    return (
        <img
            width={width}
            height={height}
            src={src}
            alt={alt}
            style={imageObjectFit ? {
                objectFit: imageObjectFit
            } : undefined}
        />
    );
}

ImageLoader.propTypes = {
    /** Image width as string CSS or number of px. Currently only supports a numeric value as it is used in calculating animation values. */
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    /** Image height as string CSS or number of px. */
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    /** Image src. */
    src: PropTypes.string,
    /** Image alt attribute. */
    alt: PropTypes.string.isRequired,
    /** Called at least once on mount if no `src` provided. May get called many times. */
    loadImage: PropTypes.func,
    /** Error occurred loading image so show fallback text. */
    error: PropTypes.bool,
    /** On mount, delay fetching image by this number of ms. For example. this is useful in virtualised lists when user may just be scrolling past. */
    timeout: PropTypes.number,
    /** Render image using ImageEnlargeOnHover component. */
    enlargeOnHover: PropTypes.bool,
    /** Alternative to `enlargeOnHover`. Enlarged image shows in a popup on hover. */
    popupOnHover: PropTypes.bool,
    /** Position of popup. Only valid when `popupOnHover` is true. */
    popupPlacement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
    /** CSS object-fit to apply to loaded <img>. Currently this prop is not supported when `enlargeOnHover` is true. */
    imageObjectFit: PropTypes.string
};

export default withTranslation()(ImageLoader);