import PropTypes from 'prop-types';
import useWhiteLabelComponent from '../../../hooks/useWhiteLabelComponent';

import { MaxWidthContainer } from './MaxWidth.styles';

const MaxWidth = ({ maxWidth = 1200, children, ...props }) => {
    const { backgroundGradient } = useWhiteLabelComponent();

    return (
        <MaxWidthContainer $maxWidth={maxWidth} $background={backgroundGradient} {...props}>
            {children}
        </MaxWidthContainer>
    );
};

MaxWidth.propTypes = {
    /** Max width either as a number (in px) or a string (including unit, e.g. '90%'). */
    maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default MaxWidth;
