import styled from 'styled-components';
import { VL_BLACK, WHITE } from '../../utils/colours';

export const FooterContainer = styled.footer`
    background-color: ${VL_BLACK(75)};
    padding: 20px 0;
`;

FooterContainer.displayName = 'FooterContainer';

export const LogoImage = styled.img`
    max-width: 200px;
    max-height: 80px;
    margin: auto;
    margin-bottom: 20px;
    display: block;
`;

LogoImage.displayName = 'LogoImage';

export const LinksRow = styled.div`
    max-width: 80%;
    padding-top: 12px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
`;

LinksRow.displayName = 'LinksRow';

export const FooterLink = styled.a`
    font-size: 16px;
    margin: 0 15px;
    text-decoration: none;
    color: ${WHITE(75)};
    text-align: center;
`;

FooterLink.displayName = 'FooterLink';
