import { Fragment, memo } from "react";
import PropTypes from 'prop-types';

import { escapeRegExp } from "../../utils/string";

import Span from "../span";
import { Highlight } from "./SearchableText.styles";

/** Renders text within which any instances of a search string will be highlighted. Case insensitive. */
const SearchableText = memo(({ searchString, highlights, text, Component = Span, ...props}) => {

    // If nothing to highlight, just return text as is
    if (!searchString && !(highlights?.length > 0)) {
        return (
            <Component
                {...props}
            >
                {text}
            </Component>
        );
    }

    if (highlights) {
        // Specific ranges have been given
        // Break the given text up into normal and highlighted groups
        const contents = [];
        // Characters processed so far in loop
        let charactersProcessed = 0;
        // Iterate over each highlight...
        highlights.forEach(([start, end], index) => {
            contents.push(
                // ... adding the text up to it,
                <Fragment
                    key={`text-${index}`}
                >
                 {text.slice(charactersProcessed, start)}
                </Fragment>,
                // ... and the highlighted group
                <Highlight
                    key={index}
                >
                    {text.slice(start, end + 1)}
                </Highlight>
            );
            charactersProcessed = end + 1;
        });
        // Add any trailing text after the last highlight
        contents.push(text.slice(charactersProcessed));

        return (
            <Component
                {...props}
            >
                {
                    contents
                }
            </Component>
        );
    }

    /*
        Otherwise we need to identify which groups to highlight.

        It is not as simple as just replacing any instances of `searchString` in `text` with `<Span>{searchString}</Span>`
        as we need to maintain the case of each letter.

        Instead we...
            1. Identify all instances of `searchString` in `text` (ignoring case)
            2. Split `text` by `searchString` leaving array of remaining parts of `text` that don't meet search condition
            3. Render first element of array, then first match (highlighted), then second element of array, then second match...
    */

    // Identify any parts of text that need highlighting
    const matches = text.match(new RegExp(escapeRegExp(searchString), 'ig'));

    return (
        <Component {...props}>
            {
                text
                // Split text into array
                .split(new RegExp(escapeRegExp(searchString), 'i'))
                // Render first element of array
                // Then before each subsequent array element we render the string matched above
                .map((string, index) => index === 0
                    // First element
                    ? string
                    // Render matched string and then array element
                    : <Fragment key={index}><Highlight>{matches[index - 1]}</Highlight>{string}</Fragment>
                )
            }
        </Component>
    );
});

SearchableText.propTypes = {
    /** Search term to highlight. */
    searchString: PropTypes.string,
    /** Alternative to `searchString`. Can specify exactly which ranges to highlight. E.g. [[3,5], [7,9]]. */
    highlights: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
    /** Full text to show. */
    text: PropTypes.string.isRequired,
    /** Outermost component to use. Highlighted text will be rendered using Span component. */
    Component: PropTypes.elementType
};

export default SearchableText;