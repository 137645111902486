import { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import useWhiteLabelComponent from '../../../hooks/useWhiteLabelComponent';
import useResizeObserver from '../../../hooks/useResizeObserver';

import callOnClickOnEnterKeyUp from '../../../utils/callOnClickOnKeyUp';

import Input from '../input';
import { Flex } from '../../layout';
import SalesLabel from '../../sales-label';
import Dropdown from '../dropdown';
import {
    LabelRow,
    OptionLabel,
    DiscreteSliderContainer,
    LABEL_WIDTH,
} from './DiscreteSlider.styles';

/** Slider field with discrete options. Option labels are displayed above the slider. */
const DiscreteSlider = ({
    options,
    value,
    onChange,
    name,
    error = false,
    descriptionId,
    wide,
    outlineSalesLabel = false,
    overrideAccentColour,
    ...props
}) => {

    let { accentColour, accentColour2, useAccentColour2 } = useWhiteLabelComponent();
    accentColour = overrideAccentColour ? overrideAccentColour : accentColour;

    // Find index of selected option within `options` array
    const index = options.findIndex(
        (option) => (option.value ?? option) === value
    );

    // Can choose option by clicking label as well as dragging slider
    // Need to pass object which is structured like event, just as <input type="range" /> would
    const onLabelClick = (optionIndex) => () =>
        onChange({
            target: {
                value: options[optionIndex].value ?? options[optionIndex],
                name,
            },
        });

    // Montior width of SliderContainer
    // If it falls below minimum required, we show dropdown instead
    const [containerWidth, setContainerWidth] = useState(null);
    const containerRef = useRef();
    useResizeObserver(containerRef, ({ width }) =>
        setContainerWidth(width)
    );

    return (
        <DiscreteSliderContainer $count={options.length} $wide={wide} ref={containerRef}>
            {
                // Show Dropdown instead if we do not have the width for Slider
                typeof containerWidth === 'number' &&
                containerWidth < LABEL_WIDTH * options.length ? (
                    <Dropdown
                        {...props}
                        name={name}
                        value={value}
                        onChange={onChange}
                        error={error}
                        descriptionId={descriptionId}
                        options={options.map((option) => ({
                            value: option.value ?? option,
                            text: option.text
                                ? `${option.text}${
                                      option.tag ? ` (${option.tag})` : ''
                                  }`
                                : option,
                        }))}
                    />
                ) : (
                    <>
                        <LabelRow>
                            {options.map((option, optionIndex) => (
                                <OptionLabel
                                    $selected={index === optionIndex}
                                    onClick={
                                        index !== optionIndex
                                            ? onLabelClick(optionIndex)
                                            : undefined
                                    }
                                    $accentColour={useAccentColour2 ? accentColour2 : accentColour}
                                    $count={options.length}
                                    $wide={wide}
                                    // Needs to be keyboard accessible
                                    onKeyUp={
                                        index !== optionIndex
                                            ? callOnClickOnEnterKeyUp(
                                                  onLabelClick(optionIndex)
                                              )
                                            : undefined
                                    }
                                    tabIndex="0"
                                    key={option.value ?? option}
                                >
                                    {option.tag ? (
                                        <Flex column gap={10}>
                                            <SalesLabel $backgroundColour={useAccentColour2 ? accentColour2 : accentColour}>
                                                {option.tag}
                                            </SalesLabel>
                                            {option.text}
                                        </Flex>
                                    ) : (
                                        option.text ?? option
                                    )}
                                </OptionLabel>
                            ))}
                        </LabelRow>
                        <Input
                            // Slider uses `options` array indicies
                            // So minimum value is 0, max value is index of last array item, step is 1
                            min="0"
                            max={options.length - 1}
                            name={name}
                            id={name}
                            value={index}
                            step="1"
                            $accentColour={useAccentColour2 ? accentColour2 : accentColour}
                            // Need to convert array index back to option value when calling onChange
                            onChange={(event) =>
                                onChange({
                                    target: {
                                        value:
                                            options[event.target.value].value ??
                                            options[event.target.value],
                                        name,
                                    },
                                })
                            }
                            aria-valuetext={
                                options[index]?.text || options[index]
                            }
                            aria-invalid={error}
                            aria-describedby={descriptionId}
                            {...props}
                            type="range"
                        />
                    </>
                )
            }
        </DiscreteSliderContainer>
    );
};

DiscreteSlider.propTypes = {
    /** Function to call whenever user changes the field's value. */
    onChange: PropTypes.func,
    /** Name of field. Used in `name` and `id` attribute. Must be unique to page. */
    name: PropTypes.string,
    /** Chosen value is invalid. Sets `aria-invalid` to true. */
    error: PropTypes.bool,
    /** Selected value. Must match value of one of the options. */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** Id of element describing this field. Will be included in `aria-describedby` attribute. */
    descriptionId: PropTypes.string,
    /* Discrete options for the slider. Each option is either object with keys `text` and `value`, or a string/number which acts as both text and value. */
    options: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.shape({
                /** Text label for option. */
                text: PropTypes.string.isRequired,
                /** Value of option. */
                value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
                    .isRequired,
                /** Text shown in SalesLabel component above label (e.g. 'Popular'). */
                tag: PropTypes.string,
            }),
        ])
    ),
    /** option to double the width of the slider*/
    wide: PropTypes.bool,
    /** if the sales label should have outline instead of block background colour */
    outlineSalesLabel: PropTypes.bool,
};

export default DiscreteSlider;
