import PropTypes from 'prop-types';

import { Flex } from '../../layout';
import AccessibleRing from '../accessible-ring';
import { Option, RadioInput } from './OptionBoxes.styles';
import useWhiteLabelComponent from '../../../hooks/useWhiteLabelComponent';

/** Shows list of options, each in its own box. Exactly one option is always selected. Basically, this component is a heavily-styled group of radio buttons. */
const OptionBoxes = ({
    options,
    value,
    onChange,
    name,
    optionPadding = '15px',
    ...props
}) => {

    const { accentColour } = useWhiteLabelComponent();

    return (
        <Flex wrap gap={20} {...props}>
            {options.map(({ value: optionValue, display }) => (
                // This is selected option if its value (optionValue) equals the value prop
                <Option
                    key={optionValue}
                    $selected={optionValue === value}
                    $selectedBorderColour={accentColour}
                    $padding={optionPadding}
                >
                    {/* Component controlled through hidden radio buttons */}
                    <RadioInput
                        value={optionValue}
                        checked={value === optionValue}
                        onChange={onChange}
                        name={name}
                    />

                    {/* Puts blue ring around selected option for keyboard navigation */}
                    <AccessibleRing tag="input" borderRadius="10" />

                    {/* Content to display in box */}
                    {display}
                </Option>
            ))}
        </Flex>
    );
};

OptionBoxes.propTypes = {
    /** Options to offer user. */
    options: PropTypes.arrayOf(
        PropTypes.shape({
            /** Value of option (e.g. payment method id). Must be unique. */
            value: PropTypes.any.isRequired,
            /** Content to display in option's box (e.g. credit card details). */
            display: PropTypes.any.isRequired,
        })
    ),
    /** Value of selected option. This must match value of one of the options. */
    value: PropTypes.any.isRequired,
    /** Function called by newly-selected radio button when user changes option. */
    onChange: PropTypes.func.isRequired,
    /** Field name. Passed to all radio buttons to form the group. */
    name: PropTypes.string.isRequired,
    /** Padding for each option box. */
    optionPadding: PropTypes.string
};

export default OptionBoxes;
