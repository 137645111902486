import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { ReactComponent as CloseIcon } from '../../assets/images/icons/close.svg';

import translationKeys from '../../translations/keys';

import Button from '../button';
import P from '../p';
import {
    ModalContainer,
    ModalCard,
    ModalHeading,
    ModalBody,
    ModalButtons,
    CloseButton,
    ClickBuffer,
    ModalCardContents,
} from './Modal.styles';
import useWhiteLabelComponent from '../../hooks/useWhiteLabelComponent';

/** Modal appended as last child of <body>. Fully compatible with accessibility guidelines. */
const Modal = ({
    children,
    show,
    closeModal,
    leftAlign = false,
    heading,
    text,
    buttons,
    closeRef,
    containerRef,
    zIndex = 1050,
    wide = false,
    overflowVisible = false,
    hideCloseButton = false,
    t,
    tReady,
    ...props
}) => {
    const { accentColour, accentColour2, isVideoloft } = useWhiteLabelComponent();
    return (
        <ModalWrapper show={show} closeModal={closeModal} containerRef={containerRef} zIndex={zIndex}>
            <ModalCard
                leftAlign={leftAlign}
                aria-label={heading}
                $wide={wide}
                $overflowVisible={overflowVisible}
                accentColour={isVideoloft && accentColour2 ? accentColour2 : accentColour}
                {...props}
            >
                {!hideCloseButton && <CloseButton
                    onClick={closeModal}
                    aria-label={tReady ? t(translationKeys.actions.CLOSE) : 'Close'}
                    ref={closeRef}
                >
                    <CloseIcon />
                </CloseButton>}
                <ModalCardContents
                    $overflowVisible={overflowVisible}
                >
                    {
                        heading && (
                            <ModalHeading>{heading}</ModalHeading>
                        )
                    }
                    {
                        (text || children) && (
                            <ModalBody
                                // If a height has been applied to the modal and body is only component, make sure body fills
                                // height so as not to affect full height children
                                style={props?.style?.height && !heading && !(buttons?.length > 0) ? { height: '100%' } : undefined}
                            >
                                {
                                    text && <P>{text}</P>
                                }
                                { children }
                            </ModalBody>
                        )
                    }
                    {
                        buttons?.length > 0 && (
                            <ModalButtons>
                                {
                                    buttons.map(({ text, ...props }, index) => {
                                        return <Button key={index} {...props}>{text}</Button>;
                                    })
                                }
                            </ModalButtons>
                        )
                    }
                </ModalCardContents>
            </ModalCard>
        </ModalWrapper>
    );
};

export const ModalWrapper = ({ show, closeModal, containerRef, children, zIndex = 1050 }) => {
    // Create div which will house modal and be appended to body
    const [container] = useState(() => {
        const div = document.createElement('div');
        div.setAttribute('role', 'dialog');
        div.setAttribute('aria-modal', 'true');
        div.setAttribute('tabindex', '-1');
        return div;
    });

    // Add container as last child of body
    useEffect(() => {
        document.body.appendChild(container);
        return () => {
            document.body.removeChild(container);
        };
    }, [container]);

    // Use createPortal to put modal inside container
    // It means Modal component can be placed anywhere in code but still be rendered as last child in <body>
    return createPortal(
        <ModalContainer
            ref={containerRef}
            show={show}
            onClick={event => {
                closeModal();

                // Clicking the background should not count as a click on whoever its React parent is
                event.stopPropagation();
            }}
            data-testid="modal-container"
            $zIndex={zIndex}
        >
            {/* Close modal when background clicked. Ensure clicking modal does nothing by stopping event going up to parent. */}
            <ClickBuffer onClick={(e) => e.stopPropagation()}>
                {children}
            </ClickBuffer>
        </ModalContainer>,
        container
    );
};

Modal.propTypes = {
    /** Show the modal. */
    show: PropTypes.bool,
    /** Function to close the modal. Called when close icon or background is clicked. */
    closeModal: PropTypes.func.isRequired,
    /** Value for modal's `aria-label` attribute. */
    'aria-label': (props, propName, componentName) => {
        if (!props[propName] && !props.heading) {
            return new Error(
                `${componentName} must have an aria-label (or a heading which is used as aria-label).`
            );
        }
    },
    /** Ref to attach to close button. */
    closeRef: PropTypes.shape({
        current: PropTypes.oneOfType([PropTypes.element, PropTypes.object])
    }),
    /** Ref to attach to container. Use to trap focus within this element. */
    containerRef: PropTypes.shape({
        current: PropTypes.oneOfType([PropTypes.element, PropTypes.object])
    }),
    /** Display modal using left-align rather than centred (default). */
    leftAlign: PropTypes.bool,
    /** Heading for the modal. Also used as aria-label if not otherwise specified. */
    heading: PropTypes.string,
    /** Shorthand for when only content inside modal body is one <p> element. */
    text: PropTypes.string,
    /** Array of buttons to go at bottom of modal. Each element should be object containing button `text` as well as any props to pass to button. */
    buttons: PropTypes.arrayOf(PropTypes.shape({
        /** Text to go in button. */
        text: PropTypes.string.isRequired
    })),
    /** z-index to use for modal. Only use this if multiple modals could be open at same time. */
    zIndex: PropTypes.number,
    /** Use a wider max width. */
    wide: PropTypes.bool,
    /** Use `overflow: visible;` on both outer modal div and the div that surrounds modal contents. Useful when using absolute positioning on an element that will overflow outside modal. */
    overflowVisible: PropTypes.bool
};

export default withTranslation()(Modal);
