import _ from 'lodash';

/*
    Info needed to make requests is stored here.
    File exposes getters and setters for use elsewhere.
*/

// Details
// Stores uid, authenticator, paymentServer and installerId
const details = {};

export const updateDetails = (uid, region, installerId, accountType, distributorId) => {
    details.uid = uid;
    details.region = region;
    details.installerId = installerId;
    details.accountType = accountType;
    details.distributorId = distributorId;
}

export const getRegion = () => details.region;

export const getUid = () => details.uid;

export const getInstallerId = () => details.installerId;

export const getAccountType = () => details.accountType;

export const getDistributorId = () => details.distributorId;

export const getAllDetails = () => ({ ...details });

// Managers only
// Details of installer/customer that manager is looking at
// Used for APIs that can be used by multiple account types
const managerOverrideDetails = {};

export const clearManagerOverrideDetails = () => {
    clearObject(managerOverrideDetails);
}
export const setManagerOverrideDetails = details => {
    clearManagerOverrideDetails();
    _.assign(managerOverrideDetails, details);
};
export const getManagerOverrideInstallerId = () => managerOverrideDetails.installerId;
export const getManagerOverrideDistributorId = () => managerOverrideDetails.distributorId;
export const getManagerOverrideUid = () => managerOverrideDetails.uid;
export const getManagerOverrideRegion = () => managerOverrideDetails.region;


// Tokens

// Current tokens
// Includes 'auth' and 'management'
const tokens = {};

// Previous auth token
// Needed for local live
let previousAuthToken = null;

export const updateTokens = (newAuthToken, newManagementToken) => {
    previousAuthToken = tokens.auth;
    tokens.auth = newAuthToken;
    tokens.management = newManagementToken;
}

export const getAuthToken = () => tokens.auth;

export const getManagementToken = () => tokens.management;

export const getAllTokens = () => ({ ...tokens });

export const getPreviousAuthToken = () => previousAuthToken;

const clearObject = obj => {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            delete obj[key];
        }
    }
}

export const clearDetailsAndTokens = () => {
    // Clear objects
    for (const obj of [details, tokens]) {
        clearObject(obj);
    }
}


