// Escapes necessary characters in string for use in RegExp
// Taken from https://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex
export const  escapeRegExp = string => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

/**
 * Case insensitive.
 * Returns -1 if a comes before b.
 * Returns 0 if strings are the same.
 * Returns 1 if b comes before a.
 *  
 * @param {string} a 
 * @param {string} b 
 * @returns {number}
 */
export const alphabeticalOrderComparison = (a,b) => a?.toLocaleLowerCase()?.localeCompare(b?.toLocaleLowerCase());

// Returns boolean indicating whether `string` contains `searchTerm`
// Case insensitive
export const doesStringMatchSearchTerm = (string, searchTerm) => !!string?.toLocaleLowerCase().includes(searchTerm?.toLocaleLowerCase());

export const isStringNull = (inputString) => {
    if (
        !inputString ||
        inputString === '' ||
        inputString === null ||
        inputString === undefined
    ) {
        return true;
    }
    return false;
};

export const getAcronym = (inputString) => inputString?.split(/\s+/).map(word => word.charAt(0).toUpperCase()).join('');