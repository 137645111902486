import styled from 'styled-components';
import { NavLink as RRNavLink } from 'react-router-dom';
import { FADED_GREY } from '../../utils/colours';

// Gap in px between each crumb and '>'
const gap = 10;

export const BreadcrumbsContainer = styled.nav`
    display: flex;
    gap: ${gap}px;
`;

BreadcrumbsContainer.displayName = 'BreadcrumbsContainer';

const crumbStyle = (accentColour) => `
    font-weight: 100;
    color: ${FADED_GREY};
    text-decoration: none;
    font-size: 16px;

    :not(:first-child) {
        ::before {
            content: '>';
            color: ${FADED_GREY};
            margin-right: ${gap}px;
        }
    }

    :last-child {
        color: ${accentColour};
    }
`;

export const CurrentPage = styled.span`
    ${({ $accentColour }) => crumbStyle($accentColour)}
`;

CurrentPage.displayName = 'CurrentPage';

export const NavLink = styled(RRNavLink)`
    ${({ $accentColour }) => crumbStyle($accentColour)}

    :hover {
        text-decoration: underline;
    }
`;

NavLink.displayName = 'NavLink';
