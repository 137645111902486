import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/** Scrolls window back to top whenever url pathname changes. Used so that scroll position is not maintained when user changes page. */
const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

export default ScrollToTop;
