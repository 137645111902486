import _ from 'lodash';

import { privateVideoRequest } from "./managers/requestBuilder";

import { downloadZip } from "../utils/download";
import host from '../utils/getHost';
import { getSavedViewerTimezone } from '../utils/manageStorage';
import { formatDuration, getFriendlyTimezoneName } from '../utils/datetime';
import { getAcronym } from '../utils/string';

/**
 * @typedef VideoInfo
 * @type {object}
 * @property {string} file Relative path of video.
 * @property {number} start Number of seconds after requested start time that video begins.
 * @property {number} seek Number of seconds before requested start time that video begins.
 * @property {Array<Array<number>>} padding Times during which the video is padded.
 *                                          Times are seconds into actual video, so are independent
 *                                          of `start`, `seek` and the timeframe requested.
 *                                          E.g. [[2.5,5.7],[50.2,60.1]] means 2.5-5.7s into video
 *                                          are padded, and so are 50.2-60.1s.
 */

/**
 * Get the playback chunk for one camera.
 * @param {string} uidd Camera uidd.
 * @param {number} startTime Start time of chunk in ms.
 * @param {number} endTime End time of chunk in ms.
 * @param {string} [recordedStreamName] Camera's recorded stream name. Makes the request faster.
 * @param {boolean} [padding] Pad sections of requested time where there is no video. Note this padding will not be applied at the start.
 * @returns {VideoInfo}
 */
export const getRecordedVideo = async (uidd, startTime, endTime, recordedStreamName, padding) => {
    const [uid, deviceId] = uidd.split('.'); 

    const { data } = await privateVideoRequest(
        'GET',
        `/video/mp4/${uid}/${deviceId}/${Math.round(startTime / 1000)}/${Math.round(endTime / 1000)}${recordedStreamName ? `/${recordedStreamName}` : ''}`,
        {
            params: {
                padding,
                fixts: host.match(/care-protect/) ? true : undefined
            }
        }
    );

    return data;
}

/**
 * Returns mp4 data.
 * @param {string} uidd Camera uidd.
 * @param {number} startTime Start time of desired time period in ms.
 * @param {number} endTime Start time of desired time period in ms.
 * @param {string} recordedStreamName Camera stream name.
 * @param {*} onDownloadProgress Axios `onDownloadProgress` callback.
 * @returns {Blob}
 */
export const getRecordedVideoData = async (uidd, startTime, endTime, recordedStreamName, onDownloadProgress) => {
    const { file } = await getRecordedVideo(uidd, startTime, endTime, recordedStreamName);

    const { data } = await privateVideoRequest(
        'GET',
        file,
        {
            responseType: 'blob',
            onDownloadProgress
        }
    );
    
    return data;
}

/**
 * @typedef Camera
 * @type {object}
 * @property {string} uidd Camera uidd.
 * @property {string} name Camera name.
 * @property {string} [recordedStreamName] Camera's stream name.
 */

/**
 * @callback updateDownloadProgress
 * @param {number} fractionDownloaded Total fraction downloaded between 0 and 1 inclusive.
 */

/**
 * @callback onErrorOrNoVideo
 * @param {('all-error', 'some-error', 'no-video')} type Reason for error, be it all videos errored, some errored, or no cameras had any video in this time period.
 */

/**
 * Get all video for a specified time period stitched together per camera. Downloads zip file with one mp4 per camera.
 * @param {Camera[]} cameras Array of cameras, including uidds and names.
 * @param {number} startTime Start time of chunk in ms.
 * @param {number} endTime End time of chunk in ms.
 * @param {updateDownloadProgress} [updateDownloadProgress] Callback for tracking fraction downloaded.
 * @param {onErrorOrNoVideo} [onErrorOrNoVideo] Callback invoked if there is no video for any of the specified cameras in the given time period or if an error occurs.
 */
export const bulkDownload = async (cameras, startTime, endTime, updateDownloadProgress, onErrorOrNoVideo) => {

    const timezone = getSavedViewerTimezone();
    const viewerTzAcronym = getAcronym(getFriendlyTimezoneName(timezone));
    const cameraTimezones = [...new Set(_.values(cameras).map(c => c.timezoneName))];
    const durationFormatted = formatDuration(endTime - startTime);

    const formatTimestampForFilename = (timestamp, timeZone) => `${new Date(timestamp)
        .toLocaleDateString(undefined, { timeZone })
        .replaceAll('/', '-')}_${new Date(timestamp)
        .toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit', timeZone })
        .replaceAll(':', '-')}`
    const startTimeFormattedInViewerTimezone = formatTimestampForFilename(startTime, timezone);

    // Maps uidd to array of [loaded, total]
    const downloaded = _.chain(cameras).keyBy('uidd').mapValues(camera => [0, null]).value();

    // Error flag
    const ERROR = 'error';

    const onDownloadProgress = updateDownloadProgress && (uidd => ({ loaded, total }) => {
        downloaded[uidd] = [loaded, total];

        // Calculate percentage downloaded
        // Can only do this once we know all totals
        const arrays = _.values(downloaded);
        if (arrays.every(([, total]) => typeof total === 'number')) {
            const overallLoaded = arrays.reduce((acc, [loaded]) => acc + loaded, 0);
            const overallTotal = arrays.reduce((acc, [, total]) => acc + total, 0);
            updateDownloadProgress(overallLoaded / overallTotal);
        }
    });

    const res = await Promise.all(cameras.map(async ({ uidd, recordedStreamName }) => {
        try {
            return await getRecordedVideoData(uidd, startTime, endTime, recordedStreamName, onDownloadProgress(uidd));
        } catch (error) {
            onDownloadProgress?.(uidd)?.({ loaded: 0, total: 0 });
            
            if (error.response?.status !== 404) {
                console.error(error);
                return ERROR;
            }
            
            return null;
        }
    }));
    
    const [allErrors, someErrors, noVideo] = res.reduce(([allErrors, someErrors, noVideo], data) => [allErrors && data === ERROR, someErrors || data === ERROR, noVideo && data === null], [true, false, true]);
    if (allErrors || someErrors || noVideo) {
        onErrorOrNoVideo?.(allErrors ? 'all-error' : someErrors ? 'some-error' : 'no-video');
        if (allErrors || noVideo) {
            // Return if nothing to download
            return;
        }
    }

    // Create and download zip file
    let dateTimeString = "";
    if (cameraTimezones.length === 1) {
        // cameras and viewer timezone match
        if (timezone === cameraTimezones[0]) {
            dateTimeString = `${startTimeFormattedInViewerTimezone}`;   
        }
        // only one unique cam timezone and it doesn't match viewer timezone 
        else {
            const startTimeFormattedInCamTimezone = formatTimestampForFilename(startTime, cameraTimezones[0]);
            const camTzAcronym = getAcronym(getFriendlyTimezoneName(cameraTimezones[0]));
            dateTimeString += `${viewerTzAcronym}_${startTimeFormattedInViewerTimezone}_${camTzAcronym}_${startTimeFormattedInCamTimezone}`;
        }
    }
    // more than 1 timezone in cameras 
    else {
        dateTimeString = `${viewerTzAcronym}_${startTimeFormattedInViewerTimezone}`;
    }

    const getCameraClipName = (viewerTimezone, camTimezone, name) => {
        let clipName = name;
        if (viewerTimezone === camTimezone) {
            clipName += `_${dateTimeString}`;
        } else {
            const camTzAcronym = getAcronym(getFriendlyTimezoneName(camTimezone));
            const startTimeFormattedInCamTimezone = formatTimestampForFilename(startTime, camTimezone);
            clipName += `_${dateTimeString}_${camTzAcronym}_${startTimeFormattedInCamTimezone}`;
        }
        return clipName;
    };

    downloadZip(
        cameras.reduce((acc, { name, timezoneName }, index) => {
            if (res[index] && res[index] !== ERROR) {
                return [
                    ...acc,
                    {
                        name: `${getCameraClipName(timezone, timezoneName, name)}_${durationFormatted}.mp4`,
                        lastModified: new Date(),
                        input: res[index]
                    }
                ];
            }

            return acc;
        }, []),
        `${dateTimeString}_${durationFormatted}.zip`
    );
}