import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import FlexContainer from './Flex.styles';

/** Layout component for using CSS Flexbox. */
const Flex = forwardRef(
    (
        {
            column = false,
            wrap = false,
            alignItems,
            justifyContent,
            centre = false,
            gap = 0,
            rowGap = 0,
            columnGap = 0,
            children,
            style: extraStyles = {},
            ...props
        },
        ref
    ) => {

        return (
            <FlexContainer
                style={{
                    flexDirection: column ? 'column' : 'row',
                    flexWrap: wrap ? 'wrap' : 'nowrap',
                    rowGap: gap || rowGap,
                    columnGap: gap || columnGap,
                    
                    // Allow UK or US spelling
                    justifyContent: centre || justifyContent === 'centre' ? 'center' : justifyContent,
                    alignItems: centre || alignItems === 'centre' ? 'center' : alignItems,
                    ...extraStyles
                }}
                ref={ref}
                {...props}
            >
                {children}
            </FlexContainer>
        );
    }
);

Flex.propTypes = {
    /** Whether to use `flex-direction: column;`, otherwise use `flex-direction: row;`. */
    column: PropTypes.bool,
    /** Whether to use `flex-wrap: wrap;`, otherwise use `flex-wrap: nowrap;`. */
    wrap: PropTypes.bool,
    /** Value for `align-items`. */
    alignItems: PropTypes.oneOf([
        'flex-start',
        'flex-end',
        'centre',
        'center',
        'stretch',
        'baseline',
    ]),
    /** Value for `justify-content`. */
    justifyContent: PropTypes.oneOf([
        'flex-start',
        'flex-end',
        'centre',
        'center',
        'space-between',
        'space-around',
        'space-evenly',
    ]),
    /** Shorthand for `alignItems='centre' && justifyContent='centre'. Overrules those two individual values. */
    centre: PropTypes.bool,
    /** CSS gap in px. Overules `rowGap` and `columnGap`. */
    gap: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /** CSS row-gap in px. */
    rowGap: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /** CSS column-gap in px. */
    columnGap: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Flex;
