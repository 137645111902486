import styled from 'styled-components';

export const LogoImage = styled.img`
    max-width: inherit;
    max-height: inherit;
`;

LogoImage.displayName = 'LogoImage';

export const LogoLink = styled.a`
    max-width: inherit;
    max-height: inherit;
`;

LogoLink.displayName = 'LogoLink';

// Use maxWidth and maxHeight if specified
// Use 'none' otherwise
// If either are numbers, then this is in px
export const LogoContainer = styled.div`
    max-width: ${({ $maxWidth }) =>
        $maxWidth
            ? typeof $maxWidth === 'number'
                ? $maxWidth + 'px'
                : $maxWidth
            : 'none'};
    max-height: ${({ $maxHeight }) =>
        $maxHeight
            ? typeof $maxHeight === 'number'
                ? $maxHeight + 'px'
                : $maxHeight
            : 'none'};
`;

LogoContainer.displayName = 'LogoContainer';
