import PropTypes from 'prop-types';

/** Redirect to a URL outside this app. This is only used to redirect to portal and webclient. Once those two have both been incorporated into this app, this component should be deleted. */
const ExternalRedirect = ({ to }) => {
    window.location.assign(to);

    return null;
};

ExternalRedirect.propTypes = {
    /** URL to redirect to. */
    to: PropTypes.string,
};

export default ExternalRedirect;
