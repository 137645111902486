import PropTypes from 'prop-types';

import Input from "../../input";
import Dropdown from "../Dropdown";

/** Dropdown field where when final 'Other' option is clicked, an input field is also rendered. */
const DropdownWithOther = ({
    value = ['', ''],
    options,
    otherOption = 'Other',
    onChange,
    inputProps = {},
    required,
    name,
    error,
    descriptionId,
    large = false,
    fullWidth = false,
    narrowWidth = false,
    ...props
}) => {

    const otherOptionSelected = value[0] === otherOption;

    return (
        <>
            <Dropdown
                options={options.concat(otherOption)}
                value={value[0]}
                onChange={event => onChange({ target: { value: [event.target.value, value[1]], name }})}
                required={required}
                descriptionId={descriptionId}
                error={otherOptionSelected ? undefined : error}
                large={large}
                fullWidth={fullWidth}
                narrowWidth={narrowWidth}
                name={otherOptionSelected ? undefined : name}
                {...props}
            />

            {/* Show Input field too if "Other" option is selected */}
            {
                value[0] === otherOption && (
                    <Input
                        required={required}
                        descriptionId={descriptionId}
                        error={error}
                        large={large}
                        fullWidth={fullWidth}
                        narrowWidth={narrowWidth}
                        name={name}
                        {...inputProps}
                        value={value[1]}
                        onChange={event => onChange({ target: { value: [value[0], event.target.value], name }})}
                    />
                )
            }
        </>
    );
}

DropdownWithOther.propTypes = {
    /** Function to call whenever user changes the dropdown or input field's value. */
    onChange: PropTypes.func,
    /** Name of field. Used in `name` and `id` attribute. Must be unique to page. Gets passed to input field if showing, otherwise dropdown. */
    name: PropTypes.string,
    /** Chosen value is invalid. Error shown on element (input field if showing, otherwise dropdown) by setting `aria-invalid` to true and rendering border in red. */
    error: PropTypes.bool,
    /** First element is value of select field. Second element is value of input field. */
    value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    /** Id of element describing this field. Will be included in both dropdown's and input's `aria-describedby` attribute. */
    descriptionId: PropTypes.string,
    /** Whether this is a required field. */
    required: PropTypes.bool,
    /** Whether the select and input fields should be a larger size. */
    large: PropTypes.bool,
    /** Whether the select and input fields should fill width of container. */
    fullWidth: PropTypes.bool,
    /** Set field to narrow width. */
    narrowWidth: PropTypes.bool,
    /** List of possible options. Each array element can be object with properties `value` and `text` or just a string which is used as both option's value and text. */
    options: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.shape({
                /** Text to show to user in dropdown representing this option. */
                text: PropTypes.string.isRequired,
                /** Value of this option. */
                value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
                    .isRequired,
            }),
        ])
    ).isRequired,
    /** Last option displayed in dropdown which when selected causes the input field to appear. Like the `options` prop, it can be either an object with properties `value` and `text`, or just a string which is used as both. */
    otherOption: PropTypes.oneOfType([
        PropTypes.shape({
            value: PropTypes.isRequired,
            text: PropTypes.isRequired,
        }),
        PropTypes.string,
        PropTypes.number,
    ]),
    /** Additional props to pass to input field. */
    inputProps: PropTypes.object
};

export default DropdownWithOther;