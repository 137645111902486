import { useState, useRef, useEffect } from "react";
import { createPortal } from "react-dom";
import PropTypes from 'prop-types';

import { Image, EnlargedImage, ImageContainer, SCALE } from "./ImageEnlargeOnHover.styles";

// Minimum gap in px between enlarged image and edge of viewport
const MARGIN = 2;

/** Displays image that enlarges on hover (or on focus for keyboard navigation). Enlarged image uses "fixed" positioning to avoid being impacted by any parent with `overflow: hidden;`. */
const ImageEnlargeOnHover = ({ src, alt, width = "100%", height = "100%" }) => {

    // Whether mouse is currently hovering over initial image
    const [hoverImage, setHoverImage] = useState(false);

    // Whether mouse is currently hovering over enlarged image
    const [hoverEnlarged, setHoverEnlarged] = useState(false);

    // Ref for initial image
    const imageRef = useRef();

    // We need the size and position of the initial image in order to set styling for enlarged image
    let { top, left, bottom, right, width: imageWidth, height: imageHeight } = imageRef.current?.getBoundingClientRect() ?? {};

    // Need to make sure that enlarged image will fit entirely on screen

    // This value is the distance between the top edges of the enlarged and initial images (same value for bottom edges)
    const heightAllowance = (SCALE - 1) * (imageHeight / 2);

    if (top - heightAllowance < 0) {
        // Top edge of enlarged image would be off screen so need to make adjustment
        top = heightAllowance + MARGIN;
    } else if (bottom + heightAllowance > window.innerHeight) {
        // Bottom edge of enlarged image would be off screen so need to make adjustment
        top = window.innerHeight - heightAllowance - MARGIN;
    }

    // This value is the distance between the left edges of the enlarged and initial images (same value for right edges)
    const widthAllowance = (SCALE - 1) * (imageWidth / 2);

    if (left - widthAllowance < 0) {
        // Left edge of enlarged image would be off screen so need to make adjustment
        left = widthAllowance + MARGIN;
    } else if (right + widthAllowance > window.innerWidth) {
        // Right edge of enlarged image would be off screen so need to make adjustment
        left = window.innerWidth - widthAllowance - MARGIN;
    }

    return (
        <ImageContainer
            $width={width}
            $height={height}
        >

            {/* Initial image */}
            <Image
                src={src}
                alt={alt}
                ref={imageRef}
                onMouseEnter={() => setHoverImage(true)}
                onMouseLeave={() => setHoverImage(false)}

                // For accessibility, there must be equivalent functionality for keyboard only navigation
                // This lets user focus on image using keyboard, at which point enlarged image will show
                tabIndex={0}
                onFocus={() => setHoverImage(true)}
                onBlur={() => setHoverImage(false)}
            />

            {/* Enlarged image - only render when user hovering over (either) image */}
            {
                (hoverImage || hoverEnlarged) && (
                    <EnlargedImageContainer
                        src={src}
                        alt={alt}
                        onMouseEnter={() => setHoverEnlarged(true)}
                        onMouseLeave={() => setHoverEnlarged(false)}

                        // Set size and position
                        style={{
                            top,
                            left,
                            width: imageWidth,
                            height: imageHeight
                        }}
                    />
                )
            }
            
        </ImageContainer>
    );
}

// Use portal to render enlarged image outside DOM hierachy so fixed position is not affected by any transform applied to ancestors
const EnlargedImageContainer = props => {
    // Create div which will house image and be appended to body
    const [container] = useState(() => document.createElement('div'));

    // Add container as last child of body
    useEffect(() => {
        document.body.appendChild(container);
        return () => {
            document.body.removeChild(container);
        };
    }, [container]);

    return createPortal(
        <EnlargedImage
            {...props}
        />,
        container
    );
}

ImageEnlargeOnHover.propTypes = {
    /** Image src. */
    src: PropTypes.string.isRequired,
    /** Alt text for image. */
    alt: PropTypes.string.isRequired,
    /** Max width of image as string CSS or number of px. Component will take up this width and image will be rendered to fill that space as much as possible without compromising aspect ratio. */
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** Max height of image as string CSS or number of px. Component will take up this height and image will be rendered to fill that space as much as possible without compromising aspect ratio. */
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ImageEnlargeOnHover;