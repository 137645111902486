import PropTypes from 'prop-types';

import { Heading } from './PageHeading.styles';

// Considering phasing this component out in favour of using the Heading component with a (bottom) border prop...

/** Title to be used at top of page. */
const PageHeading = ({
    accentColour = '#E32747',
    noDot = false,
    children,
    ...props
}) => {
    return (
        <Heading
            $borderColour={accentColour}
            accentColour={accentColour}
            noDot={noDot}
            {...props}
        >
            {children}
        </Heading>
    );
};

PageHeading.propTypes = {
    /** CSS colour used for the bottom border (line underneath the heading) and trailing dot (if `noDot` is false). */
    accentColour: PropTypes.string,
    /** Exclude trailing dot from heading. */
    noDot: PropTypes.bool,
};

export default PageHeading;
