import PropTypes from 'prop-types';

import AccessibleRing from '../../accessible-ring';
import { Container, Input } from './PrefixedInput.styles';

/** Input field with some permanent string placed at the start of it (e.g. a currency symbol in front of a money input). */
const PrefixedInput = ({ prefix, ...props }) => {
    return (
        // Container is div with styling to make it look like our input fields
        // Container has prefix in ::before pseudo element
        // Input field has no border so it just looks like part of Container
        <Container $prefix={prefix} {...props}>
            <Input {...props} style={{ background: 'none' }}/>
            <AccessibleRing tag="input" borderRadius="4" />
        </Container>
    );
};

PrefixedInput.propTypes = {
    /** Character(s) to place before input field. */
    prefix: PropTypes.string.isRequired,
};

export default PrefixedInput;

// TODO: Need to write tests for this component but not sure how as prefix is a pseudo element
