import { forwardRef, useCallback, useMemo } from 'react';
import Popup from '../Popup';
import OptionsList from '../../options-list';

/** Popup version of OptionsList. */
const PopupList = forwardRef(({
    // Popup props
    width,
    open,
    maxHeight,
    arrowProps,
    style,
    placement,
    closePopup,

    // These get modified
    onChange,
    bottomButton,

    // Rest get passed to OptionsList
    ...props
}, ref) => {
    // If no width specified and options contains images, need to use larger default width
    if (!width && props.options?.some(({ image }) => image)) {
        width = 350;
    }

    // Close popup when `onChange` invoked if type is 'select'
    const modifiedOnChange = useCallback((...args) => {
        if (props.type === 'select') {
            closePopup();
        }
        onChange(...args);
    }, [onChange, props.type, closePopup]);

    // Close popup when bottom button pressed
    const modifiedBottomButton = useMemo(() => {
        if (!bottomButton) {
            return undefined;
        }

        return {
            ...bottomButton,
            onClick: (...args) => {
                closePopup();
                bottomButton?.onClick(...args);
            }
        };
    }, [bottomButton, closePopup]);

    return (
        <Popup
            width={width}
            open={open}
            maxHeight={maxHeight}
            arrowProps={arrowProps}
            style={style}
            ref={ref}
            placement={placement}
            $noPadding={props.$noPadding}
        >
            {
                // Unmount component when popup closed to clear search field
                // Maybe not best solution for this
                open && (
                    <OptionsList
                        {...props}
                        onChange={modifiedOnChange}
                        bottomButton={modifiedBottomButton}
                    />
                )
            }
        </Popup>
    );
});

PopupList.propTypes = {};

export default PopupList;
