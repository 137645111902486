// TODO: Extract gradient border option into another component, e.g. GradientBorderInput.jsx

import PropTypes from 'prop-types';
import useWhiteLabelComponent from '../../../hooks/useWhiteLabelComponent';

import { InputContainer } from './Input.styles';
import { forwardRef } from 'react';

/**
 * Input field for forms.
 *
 * For accessibility, please always do the following:
 * - Use aria-label if not using a label as part of Form.Input
 * - Use autocomplete attribute whenever possible. List of possible values: https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
 */
const Input = forwardRef(({
    onChange,
    name,
    error = false,
    value = '',
    required = false,
    large = false,
    fullWidth = false,
    narrowWidth = false,
    descriptionId,
    hideSpinButtons = false,
    ...props
}, ref) => {

    const { accentColour } = useWhiteLabelComponent();

    return (
        <InputContainer
            ref={ref}
            onChange={onChange}
            name={name}
            id={name}
            error={error}
            value={props.type === 'checkbox' ? undefined : value}
            checked={props.type === 'checkbox' ? value : undefined}
            required={required}
            large={large}
            fullWidth={fullWidth}
            narrowWidth={narrowWidth}
            aria-invalid={error}
            aria-describedby={descriptionId}
            $hideSpinButtons={hideSpinButtons}
            $accentColour={accentColour}
            {...props}
        />
    );
});

Input.propTypes = {
    /** Function to call whenever user changes the field's value. */
    onChange: PropTypes.func,
    /** Name of field. Used in `name` and `id` attribute. Must be unique to page. */
    name: PropTypes.string,
    /** Chosen value is invalid. Sets `aria-invalid` to true and renders red border around element. */
    error: PropTypes.bool,
    /** Value of input field. */
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
    ]),
    /** Id of element describing this field. Will be included in `aria-describedby` attribute. */
    descriptionId: PropTypes.string,
    /** Whether this is a required field. */
    required: PropTypes.bool,
    /** Whether the field should be a larger size. */
    large: PropTypes.bool,
    /** Whether input field should fill width of container. */
    fullWidth: PropTypes.bool,
    /** Set field to narrow width. */
    narrowWidth: PropTypes.bool,
    /** Hide spin buttons added by browser. Only relevant if input type is "number". */
    hideSpinButtons: PropTypes.bool,
};

export default Input;
