import { Banner } from './ContactDetailsBanner.styles';

const ContactDetailsBanner = ({ ...props }) => {
    return (
        <Banner {...props}>
            partners@videoloft.com | (855)&nbsp;737-0237 |
            +44&nbsp;203&nbsp;987&nbsp;0133
        </Banner>
    );
};

export default ContactDetailsBanner;
