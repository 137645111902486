import styled from 'styled-components';

import { VL_BLACK, WHITE } from '../../../utils/colours';

import { Flex } from '../../layout';

export const TimeInputField = styled.input.attrs({
    type: 'time',
})`
    font-weight: 100;
    border: 1px solid ${VL_BLACK()};
    width: 100%;
    max-width: 120px;
    height: 27px;
    text-align: center;

    &[aria-invalid="true"] {
        border-color: red;
    }
`;

TimeInputField.displayName = 'TimeInputField';


export const TimeFieldErrorMessage = styled.span`
    color: red;
    font-weight: 100;
    margin-top: 5px;
    font-size: 80%;
    text-align: center;
    min-height: 15px;
`;

TimeFieldErrorMessage.displayName = 'TimeFieldErrorMessage';


export const DateDisplay = styled(Flex).attrs({ centre: true })`
    background: ${WHITE()};
    border: 1px solid ${VL_BLACK()};
    width: 100%;
    font-weight: 100;
`;

DateDisplay.displayName = 'DateDisplay';