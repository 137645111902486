import PropTypes from 'prop-types';

import useWhiteLabelComponent from '../../hooks/useWhiteLabelComponent';

import { HeadingContainer } from './Heading.styles';

const VARIANT_TO_SIZE = {
    1: '2rem',
    2: '1.74rem',
    3: '1.52rem',
    4: '1.32rem',
    5: '1.15rem',
    6: '1rem'
};

/** Heading component which renders with optional dot (like all VL headings) and bottom border. */
const Heading = ({
    variant = 1,
    border = false,
    ellipsis = false,
    noDot = false,
    children,
    ...props
}) => {

    const { accentColour, accentColour2, useAccentColour2, headingDots }  = useWhiteLabelComponent();

    return (
        <HeadingContainer
            as={`h${variant}`}
            $accentColour={useAccentColour2 ? accentColour2 : accentColour}
            $noDot={!headingDots || noDot}
            $border={border}
            $ellipsis={ellipsis}
            $fontSize={props.fontSize ? `${props.fontSize}px` : VARIANT_TO_SIZE[variant]}
            {...props}
        >
            {children}
        </HeadingContainer>
    );
};

Heading.propTypes = {
    /** Which heading tag to use: h1 - h6. */
    variant: PropTypes.oneOf([1, 2, 3, 4, 5, 6, '1', '2', '3', '4', '5', '6']),
    /** Show bottom border. */
    border: PropTypes.bool,
    /** Render three dots rather than usual one. Ignored if white label doesn't use heading dots. */
    ellipsis: PropTypes.bool,
    /** Don't render any dot */
    noDot: PropTypes.bool,
};

export default Heading;
