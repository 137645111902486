import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { accountType } from '../../../types';

import translationKeys from '../../../translations/keys';
import { DEFAULT_BACKGROUND_GRADIENT } from '../../../utils/colours';

import useWhiteLabelComponent from '../../../hooks/useWhiteLabelComponent';

import ConditionalWrapper from '../../conditional-wrapper';
import routes from '../../../pages/routes';

import Header from '../../header';
import Footer from '../../footer';
import { Flex, MaxWidth } from '../../layout';

import { PageContent } from './HeaderFooterLayout.styles';

/** Wraps around all pages. Includes the Header and Footer. This component must be a child of the WhiteLabel component and requires translations to be set up. */
const HeaderFooterLayout = ({
    children,
    t,
    accountType,
    logout,
    routeConfig,
    mobileApp = false,
    ...props
}) => {
    const { backgroundGradient } = useWhiteLabelComponent();
    return (
        <Flex column style={{ minHeight: '100vh', background: backgroundGradient ?? DEFAULT_BACKGROUND_GRADIENT }} {...props}>
            {/* Flex (and margin-top: auto;) used to keep Footer at bottom of page */}
            {!mobileApp && (
                <Header
                    accountType={accountType}
                    logout={logout}
                    data-testid="header"
                />
            )}

            <PageContent $mobileApp={mobileApp}>
                {/* Wrap page in MaxWidth if requested by routeConfig */}
                <ConditionalWrapper
                    useWrapper={routeConfig?.layout?.narrow}
                    wrapper={(children) => (
                        <MaxWidth style={{ padding: '0 36px', background: 'none' }}>
                            {children}
                        </MaxWidth>
                    )}
                >
                    {children}
                </ConditionalWrapper>
            </PageContent>

            {!mobileApp && (
                <Footer
                    style={{ marginTop: 'auto' }}
                    privacyLink={routes.privacy.path}
                    privacyLinkText={t(translationKeys.footer.PRIVACY)}
                    termsLink={routes.terms.path}
                    termsLinkText={t(translationKeys.footer.TERMS)}
                    data-testid="footer"
                />
            )}
        </Flex>
    );
};

HeaderFooterLayout.propTypes = {
    /** User (u), installer (i), distributor (d) or staff (s). */
    accountType,
    /** Function passed down from App component to call on logout. */
    logout: PropTypes.func.isRequired,
    /** Hide header/footer if true. */
    mobileApp: PropTypes.bool,
};

export default withTranslation()(HeaderFooterLayout);
