import styled from 'styled-components';
import { LIGHT_GREY, MID_GREY, VL_BLACK, WHITE } from '../../utils/colours';

import { ButtonsRow } from '../layout';

// In px
const closeButtonSize = 20;
const closeButtonMargin = 10;

// Wrapper

export const ModalContainer = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: ${VL_BLACK(75)};
    top: 0;
    left: 0;
    z-index: ${({ $zIndex }) => $zIndex};
    justify-content: center;
    align-items: center;
    display: ${({ show }) => (show ? 'flex' : 'none')};
`;

ModalContainer.displayName = 'ModalContainer';


export const ClickBuffer = styled.div`
    display: contents;
`;

ClickBuffer.displayName = 'ClickBuffer';

// Card

export const ModalHeading = styled.h3`
    font-weight: 300;
    margin-top: 0;
    color: ${VL_BLACK()};
`;

ModalHeading.displayName = 'ModalHeading';


export const ModalBody = styled.div``;

ModalBody.displayName = 'ModalBody';


export const ModalButtons = styled(ButtonsRow).attrs({
    centre: true
})``;

ModalButtons.displayName = 'ModalButtons';


export const CloseButton = styled.button`
    height: ${closeButtonSize}px;
    min-height: ${closeButtonSize}px;
    min-width: ${closeButtonSize}px;
    width: ${closeButtonSize}px;
    background: ${WHITE()};
    border: 1px solid ${MID_GREY};
    border-radius: 30px;
    cursor: pointer;
    margin-left: auto;
    color: ${VL_BLACK()};

    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        min-height: ${closeButtonSize - 12}px;
        min-width: ${closeButtonSize - 12}px;
    }
`;

CloseButton.displayName = 'CloseButton';


export const ModalCard = styled.div`
    background: ${LIGHT_GREY};
    color: ${VL_BLACK()};
    border-radius: 5px;
    max-width: ${({ $wide }) => $wide ? '90%' : 'min(700px, 90%)'};
    min-width: min(max(35%, 300px), 550px);
    max-height: 90%;
    padding: ${closeButtonMargin}px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    position: relative;
    overflow-y: ${({ $overflowVisible }) => $overflowVisible ? 'visible' : 'auto'};
    text-align: ${({ leftAlign }) => (leftAlign ? 'left' : 'center')};
    
    /* Left align Modal.Buttons too */
    ${ModalButtons} {
        ${({ leftAlign }) => leftAlign ? 'justify-content: flex-start;' : ''}
    }

    display: flex;
    flex-direction: column;
    gap: ${closeButtonMargin}px;

    a {
        color: ${({ accentColour }) => accentColour};
    }

    h1,h2,h3,h4,h5,h6 {
        color: ${VL_BLACK()};
    }
`;

ModalCard.displayName = 'ModalCard';


export const ModalCardContents = styled.div`
    overflow-y: ${({ $overflowVisible }) => $overflowVisible ? 'visible' : 'auto'};
    padding: 0 15px 15px;

    // Fill space if consistent height put on ModalCard
    flex-grow: 1;
`;

ModalCardContents.displayName = 'ModalCardContents';