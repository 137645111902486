export const getQueryParams = (search) => {
    // URLSearchParams parses search
    // It replaces '+' with space so encode '+' now to stop that happening
    const urlSearchParams = new URLSearchParams(
        search.replace(/\+/g, encodeURIComponent('+'))
    );

    // Iterate through params
    const params = {};
    for (const [key, value] of urlSearchParams) {
        // Arrays can be specified with or without square brackets
        const paramName = key.split('[')[0];
        if (params[paramName]) {
            // If there is already an entry for this param, then assume we want an array
            if (Array.isArray(params[paramName])) {
                params[paramName].push(value);
            } else {
                // Convert to array if not already done
                params[paramName] = [params[paramName], value];
            }
        } else {
            // New entry
            params[paramName] = key.includes('[') ? [value] : value;
        }
    }

    return params;
};
