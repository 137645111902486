import {
    Suspense,
    useState,
    useEffect,
    lazy,
    createContext,
    useMemo,
    useRef
} from 'react';
import { withTranslation } from 'react-i18next';
import {
    Switch,
    Route,
    Redirect,
    useLocation
} from 'react-router-dom';

import { initRequestManagerDemo } from '../../api/managers/requestManager';

import useWhiteLabel from '../../hooks/useWhiteLabel';
import useModal from '../../hooks/useModal';

import host from '../../utils/getHost';
import { TURQUOISE, WHITE } from '../../utils/colours';

import routes, { getRouteByPath } from '../../pages/routes';
import translationKeys from '../../translations/keys';

import Modal from '../modal';
import ErrorBoundary from '../error-boundary';
import WhiteLabel from '../white-label';
import Loading from '../loading';
import ErrorScreen from '../error-screen';
import {
    AppLayout
} from '../layout-managers';

import NetworkError from './NetworkError';
import useQueryParams from '../../hooks/useQueryParams';

// 404 page
const NotFound = lazy(() => import('../../pages/404'));

// Context used to let pages know they are in demo version
export const DemoContext = createContext();
DemoContext.displayName = 'DemoContext';

// Demo version of App
// Mirrors basic setup and just renders demo page
// There is a fair bit of code duplication with main App here so probably best to extract that out in future
const DemoApp = ({ t }) => {

    const { region: selectedRegion } = useQueryParams();
    const selectedRegionRef = useRef(selectedRegion);

    // Fetch white label styles
    const [whiteLabelHostInfo] = useWhiteLabel(host);

    // Account and group info for demo account
    const [account, setAccount] = useState(null);

    // If a network error prevents token refresh, we show "Network error" page until connection returns
    const [networkError, setNetworkError] = useState(false);

    // Login
    useEffect(() => {
        if (whiteLabelHostInfo?.demo) {
            const init = async () => {

                const demo = await initRequestManagerDemo(
                    selectedRegionRef.current ?? whiteLabelHostInfo.demo.regional,
                    
                    // If token refresh fails, just sign in again
                    () => init(),

                    () => setNetworkError(true)
                );

                setAccount(demo);
            };

            init();
        } else if (whiteLabelHostInfo) {
            // Redirect to sign in if there is no demo for this white label
            // Ideally we'd show 404 page here but that would need to be handled by main App so this is simpler
            // Cannot use React Router to redirect as base URL is set
            window.location.href =
                            window.location.origin + routes.signIn.path;
        }
    }, [whiteLabelHostInfo]);

    // On demo, we do not want users making any account/settings changes so instead show a popup message when they click a button
    // `showModal` gets passed to useDemo hook through Context API
    const [
        // Props for modal
        modalProps,
        // Function to open modal
        showModal,
    ] = useModal();

    // Memo keeps Context value constant
    const demoContextValue = useMemo(() => ({
        showModal
    }), [showModal]);

    const location = useLocation();

    // Show loading while waiting for styles and account data
    if (!whiteLabelHostInfo || !account) {
        // Default to black loading spinner as we don't have white label styles yet
        // Make exception for VL and show pink
        return <Loading fill spinnerColour={/videoloft/.test(host) ? TURQUOISE : WHITE()} />;
    }

    // Function to leave demo
    const quit = () => {
        // Go to return link if possible
        if (whiteLabelHostInfo.returnLink && !window.location.hostname.match(/localhost/)) {
            window.location.assign(whiteLabelHostInfo.returnLink);
        } else {
            // Otherwise back to sign in page
            // Cannot use React Router to do this as base URL is set
            window.location.href =
                            window.location.origin + routes.signIn.path;
        }
    };

    // Follow basic App setup with error boundaries, suspense, white label, etc.
    return (
        <ErrorBoundary
            errorComponent={ErrorScreen}
            message={t(translationKeys.errors.APP_ERROR)}
        >
            <WhiteLabel
                info={whiteLabelHostInfo}
            >
                <Suspense
                    fallback={<Loading fill />}
                >
                    {
                        networkError ? (
                            <NetworkError />
                        ) : (
                            <DemoContext.Provider
                                // Demo Context used to pass `showModal` function to pages
                                value={demoContextValue}
                            >
                                {/* All demo pages must use App layout */}
                                <AppLayout
                                    logout={quit}
                                    accountInfo={account}
                                    // We're supposed to pass the matching route config to AppLayout
                                    // Using `useLocation` and `getRouteByPath` is the easiest solution but it will not work for path params
                                    // As it stands though /playback is the only route we actually need to do this for
                                    routeConfig={getRouteByPath(location.pathname)}
                                >
                                    <Suspense
                                        fallback={<Loading fill />}
                                    >
                                        <Switch>

                                            {/* Page routes */}
                                            {
                                                Object.values(routes).map(({ demo, allPaths, path, renderComponent }) => {

                                                    // Only render routes that are included in demo
                                                    if (!demo) {
                                                        return null;
                                                    }

                                                    return (
                                                        <Route
                                                            // This assumes that all pages in demo can be accessed by demo account
                                                            path={allPaths}
                                                            key={path}
                                                            exact
                                                        >
                                                            {
                                                                // Note that we haven't bothered passing in functions to modify account data
                                                                // This may need to change in future if needed by one of the pages
                                                                renderComponent(account, {})
                                                            }
                                                        </Route>
                                                    );
                                                })
                                            }

                                            {/* Default to cameras */}
                                            <Redirect from="/" to={routes.cameras.path} exact />

                                            {/* 404 page */}
                                            <Route>
                                                <NotFound />
                                            </Route>

                                        </Switch>
                                    </Suspense>
                                </AppLayout>

                                {/* Demo message informing users that they cannot make any account/settings changes */}
                                <Modal
                                    {...modalProps}
                                    heading={t(translationKeys.demo.DEMO_ACCOUNT)}
                                    text={t(translationKeys.demo.CANNOT_CHANGE_SETTINGS)}

                                    // Modal must appear above all others
                                    zIndex={2000}
                                />
                            </DemoContext.Provider>
                        )
                    }
                </Suspense>
            </WhiteLabel>
        </ErrorBoundary>
    );
}

export default withTranslation()(DemoApp);