import { useEffect } from "react";

/**
 * Use this hook alongside VideoPlayer component when dewarping. Allows zooming and panning to be done through keyboard presses.
 * @param {object} playerRef Ref applied to VideoPlayer through which we access the dewarp control functions.
 * @param {object} focusableContainerRef Ref applied to a parent element. When this element is in focus, the user will be able to zoom and pan using keyboard presses.
 * @param {boolean} disabled Disable keyboard controls (use when VideoPlayer is not showing dewarped view).
 */
const useKeyboardDewarpControls = (playerRef, focusableContainerRef, disabled = false) => {
    useEffect(() => {
        if (!disabled && focusableContainerRef) {

            const focusableContainer = focusableContainerRef.current;

            const keyHandler = (event) => {
                switch (event.key) {
                    case '+':
                        playerRef.current.dewarpZoom(-5);
                        event.preventDefault();
                        return;
                    case '-':
                        playerRef.current.dewarpZoom(5);
                        event.preventDefault();
                        return;
                    case 'ArrowRight':
                        playerRef.current.dewarpMoveX(5);
                        event.preventDefault();
                        return;
                    case 'ArrowLeft':
                        playerRef.current.dewarpMoveX(-5);
                        event.preventDefault();
                        return;
                    case 'ArrowUp':
                        playerRef.current.dewarpMoveY(5);
                        event.preventDefault();
                        return;
                    case 'ArrowDown':
                        playerRef.current.dewarpMoveY(-5);
                        event.preventDefault();
                        return;
                    default:
                        return;
                }
            }

            focusableContainer.addEventListener('keydown', keyHandler);

            return () => {
                focusableContainer.removeEventListener('keydown', keyHandler);
            };
        }
    }, [disabled, focusableContainerRef, playerRef]);
}

export default useKeyboardDewarpControls;