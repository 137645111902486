import { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import translationKeys from '../../../translations/keys';

import useWhiteLabelComponent from '../../../hooks/useWhiteLabelComponent';

import { ReactComponent as CloseIcon } from '../../../assets/images/icons/close.svg';

import { VideoPlayerWithControls } from '../../video-player';
import Loading from '../../loading';
import P from '../../p';
import { ModalWrapper } from '../Modal';
import {
    ModalPlayerContainer,
    ModalPlayerHeader,
    ModalPlayerTitle,
    ModalPlayerCloseButton,
    ModalPlayerFooter,
    ContentWrapper,
    Image,
    ModalPlayerFooterContent,
    ModalPlayerFooterLeft,
    ModalPlayerFooterActions,
    VideoContainer,
    MediaPlaceholder,
    MediaErrorMessageContainer,
} from './ModalPlayer.styles';

/** Modal for playing a video or displaying an image. Content for footer is passed through children. */
const ModalPlayer = withTranslation()(
    ({
        show = false,
        closeModal,
        loading = false,
        error,
        mediaLoading = false,
        mediaError,
        headerText,
        videoConfig,
        imageConfig,
        children,
        closeRef,
        containerRef,
        videoRef,
        snapshotInfo,
        t,
        ...props
    }) => {

        const { navColour: headerColour, navTextColour: headerTextColour, accentColour: spinnerColour } = useWhiteLabelComponent();

        useEffect(() => {
            // If ModalPlayer is loading when it first opens, useModal hook won't be able to put focus on close button (as it is not mounted)
            // So do that here when it stops loading
            if (show && !loading) {
                closeRef?.current?.focus();
            }
        }, [closeRef, show, loading]);

        return (
            <ModalWrapper
                show={show}
                closeModal={closeModal}
                containerRef={containerRef}
            >
                {error?.length > 0 ? (
                    <P style={{ minWidth: '70vw' }} centre colour="#fff">
                        {error}
                    </P>
                ) : loading ? (
                    <Loading spinnerColour={spinnerColour} />
                ) : (
                    <ModalPlayerContainer
                        {...props}
                    >
                        {/* Header */}
                        <ModalPlayerHeader
                            $backgroundColour={headerColour}
                            $textColour={headerTextColour}
                        >
                            <ModalPlayerTitle>{headerText}</ModalPlayerTitle>
                            <ModalPlayerCloseButton
                                ref={closeRef}
                                aria-label={t(translationKeys.actions.CLOSE)}
                                onClick={closeModal}
                                $colour={headerTextColour}
                            >
                                <CloseIcon />
                            </ModalPlayerCloseButton>
                        </ModalPlayerHeader>

                        {/* Body */}
                        <ContentWrapper>
                            {
                                (mediaLoading || mediaError) ? (
                                    <MediaPlaceholder>
                                        {
                                            mediaLoading ? (
                                                <Loading fill />
                                            ) : (
                                                <MediaErrorMessageContainer>
                                                    {
                                                        typeof mediaError === 'string' ? (
                                                            <p>{mediaError}</p>
                                                        ) : (
                                                            <>
                                                                { mediaError.heading && <h2>{mediaError.heading}</h2> }
                                                                {
                                                                    (Array.isArray(mediaError.text) ? mediaError.text : [mediaError.text]).map((text, index) => (
                                                                        <p key={index}>{text}</p>
                                                                    ))
                                                                }
                                                            </>
                                                        )
                                                    }
                                                </MediaErrorMessageContainer>
                                            )
                                        }
                                    </MediaPlaceholder>
                                ) : videoConfig ? (
                                    <VideoContainer>
                                        <VideoPlayerWithControls
                                            width="100%"
                                            height="100%"
                                            ref={videoRef}
                                            {...videoConfig}
                                            // TODO: Handle errors with onError prop
                                        />
                                    </VideoContainer>
                                ) : imageConfig ? (
                                    <Image {...imageConfig} />
                                ) : null}
                        </ContentWrapper>

                        {/* Footer */}
                        <ModalPlayerFooter>{children}</ModalPlayerFooter>
                    </ModalPlayerContainer>
                )}
            </ModalWrapper>
        );
    }
);

ModalPlayer.FooterLeft = ModalPlayerFooterLeft;
ModalPlayer.FooterActions = ModalPlayerFooterActions;
ModalPlayer.FooterContent = ModalPlayerFooterContent;

ModalPlayer.propTypes = {
    /** Show the player. */
    show: PropTypes.bool,
    /** Function to close the player. Called when close icon or background is clicked. */
    closeModal: PropTypes.func.isRequired,
    /** Ref to attach to close button. */
    closeRef: PropTypes.shape({
        current: PropTypes.oneOfType([PropTypes.element, PropTypes.object])
    }),
    /** Ref to attach to container. Use to trap focus within this element. */
    containerRef: PropTypes.shape({
        current: PropTypes.oneOfType([PropTypes.element, PropTypes.object])
    }),
    /** Loading spinner shown instead of modal while this is true. Use this when the modal cannot be shown yet. If you're only waiting for the media to load, use `mediaLoading` instead. */
    loading: PropTypes.bool,
    /** Loading spinner shown instead of video/image. */
    mediaLoading: PropTypes.bool,
    /** Error message shown instead of modal. Use this when the error means you cannot show the modal at all. If only the media has failed, use `mediaError` instead. */
    error: PropTypes.string,
    /** Error message shown instead of video/image. */
    mediaError: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({
        heading: PropTypes.string,
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
    })]),
    /** Loading component shown instead of modal when `loading` prop is true. */
    Loading: PropTypes.elementType,
    /** Text shown in header. */
    headerText: PropTypes.string,
    /** When using the modal to play a video, this must be an object containing the required props for ReactPlayer. */
    videoConfig: PropTypes.object,
    /** When using the modal to display an image, this must be an object containing required props to pass to <img /> (e.g. src, alt). */
    imageConfig: PropTypes.object,
    /** Content for left side of footer top row. */
    footerLeft: PropTypes.node,
    /** Array of icons for right side of footer top row. */
    actionIcons: PropTypes.arrayOf(PropTypes.node),
    /** Content for bottom of footer. */
    footerContent: PropTypes.node,
};

export default ModalPlayer;
