import { useEffect, useState } from "react";
import _ from 'lodash';
import { withTranslation } from "react-i18next"

import translationKeys from '../../../translations/keys';

import { formatTimeZone, getInputFieldStringFromDate, getTimezonedDate, getUTCOffsetInMillis } from "../../../utils/datetime";

import TimezoneSelector from "../../timezone-selector/TimezoneSelector";
import Span from "../../span";
import { Input } from "../../form";
import Picker, { TimeField } from "../Picker";

import { DateInput, DurationInputContainer } from "./TimeDurationPicker.styles";



const TimeDurationPicker = ({
    startDateTime = null,
    endDateTime = null,
    minDateTime = null,
    maxDateTime = null,
    minDurationMins = 1,
    maxDurationMins,
    defaultStartTime = '00:00',
    defaultDurationMins = 60,
    onChange,
    onClear,
    timezone,
    timezoneSelectorProps,
    t
}) => {
    // Currently selected start date (ignore the time component of this date object)
    const [startDate, setStartDate] = useState(startDateTime ? getInputFieldStringFromDate(startDateTime, timezone) : '');

    // Currently selected start time
    const [startTime, setStartTime] = useState(
        startDateTime ? getTimezonedDate(startDateTime, timezone).toTimeString().substring(0, 5) : defaultStartTime
    );

    useEffect(() => {
        setStartTime(startDateTime ? getTimezonedDate(startDateTime, timezone).toTimeString().substring(0, 5) : defaultStartTime);
        setStartDate(startDateTime ? getInputFieldStringFromDate(startDateTime, timezone) : '');
    }, [startDateTime, timezone]);

    // Values of hours and minutes duration fields
    const [{ hoursInputValue, minutesInputValue }, setDuration] = useState(() => {
        const duration = startDateTime && endDateTime ? Math.floor((endDateTime - startDateTime) / 60000) : defaultDurationMins;

        return {
            hoursInputValue: getHoursFromTotalMinutes(duration).toString(),
            minutesInputValue: getMinutesStringFromTotalMinutes(duration)
        };
    });

    let startValue, endValue, startValueInvalid, endValueInvalid;
    if (startDate && startTime && hoursInputValue && minutesInputValue) {

        let duration = parseInt(hoursInputValue) * 60 + parseInt(minutesInputValue);
        if (isNaN(duration)) {
            duration = 0;
        }

        [startValue, endValue ] = getStartAndEndTimestamps(startDate, startTime, duration, timezone);


        startValueInvalid = (minDateTime && startValue < minDateTime) ||
                                (maxDateTime instanceof Date && startValue > maxDateTime);
        
        endValueInvalid = (minDateTime && endValue < minDateTime) ||
                            (maxDateTime instanceof Date && endValue > maxDateTime) ||
                            (maxDateTime === 'now' && Math.min(new Date().getTime(), endValue) < startValue + minDurationMins * 60000) ||
                            (endValue < startValue + minDurationMins * 60000) ||
                            (maxDurationMins && duration > maxDurationMins);
    }

    const disableSubmissions = !startValue ||
                                !endValue ||
                                startValueInvalid ||
                                endValueInvalid;

    const onSubmit = () => {

        let end = endValue;
        if (maxDateTime === 'now') {
            const maxEnd = new Date();
            maxEnd.setSeconds(0);
            maxEnd.setMilliseconds(0);

            if (end > maxEnd.getTime()) {
                end = maxEnd;
            }
        }

        onChange([
            startValue,
            end
        ]);
    };

    return (
        <Picker
            defaultDateInView={startDateTime}
            minDateTime={minDateTime}
            maxDateTime={maxDateTime === 'now' ? new Date().getTime() : maxDateTime}
            renderTimezoneComponent={() => <TimezoneSelector {...timezoneSelectorProps}/>}
            renderRangeRowComponents={(end) => {
                return (
                    <>
                        {end ? 
                            <DurationInputContainer
                                $error={endValueInvalid}
                            >
                                <Input
                                    type="number"
                                    min={getHoursFromTotalMinutes(minDurationMins)}
                                    max={maxDurationMins ? getHoursFromTotalMinutes(maxDurationMins) : undefined}
                                    aria-label={t(translationKeys.time.HOURS_COMPONENT_DURATION)}
                                    value={hoursInputValue}
                                    onChange={(event) => {

                                        const newValue = event.target.value;
                                        const newValueNumber = parseInt(newValue);
                                        if (
                                            // Input is only valid if it is empty string or a valid number
                                            // Empty string is valid so user can clear value in order to enter new one
                                            // Discard any invalid inputs by not updating state
                                            newValue === '' ||
                                            (
                                                !isNaN(newValueNumber) &&
                                                newValueNumber >= getHoursFromTotalMinutes(minDurationMins) &&
                                                (!maxDurationMins || newValueNumber <= getHoursFromTotalMinutes(maxDurationMins))
                                            )
                                        ) {
                                            setDuration(({ minutesInputValue }) => {

                                                return {
                                                    hoursInputValue: newValue,
                                                    minutesInputValue
                                                };
                                            });
                                        }
                                    }}
                                    // Format input on blur
                                    onBlur={event => {
                                        // Only possible formatting is to remove leading 0s
                                        let formattedValue = parseInt(event.target.value).toString();
                                        if (formattedValue === 'NaN') {
                                            formattedValue = getHoursFromTotalMinutes(minDurationMins).toString();
                                        }

                                        if (formattedValue !== event.target.value) {
                                            setDuration(info => ({
                                                ...info,
                                                hoursInputValue: formattedValue
                                            }));
                                        }
                                    }}
                                    narrowWidth
                                />
                                :
                                <Input
                                    type="number"
                                    // Min value will depend on minutes input
                                    min={parseInt(hoursInputValue) >= 1 ? '0' : getMinutesStringFromTotalMinutes(minDurationMins)}
                                    max="59"
                                    aria-label={t(translationKeys.time.MINUTES_COMPONENT_DURATION)}
                                    value={minutesInputValue}
                                    onChange={(event) => {
                                        const newValue = event.target.value;
                                        const newMinutes = parseInt(newValue);
                                        if (
                                            // Valid input includes empty string and up to two digit numbers (after leading 0s removed)
                                            // between 0-59 inclusive
                                            newValue === '' ||
                                            (newMinutes.toString().match(/^\d{0,2}$/) && newMinutes >= 0 && newMinutes <= 59)
                                        ) {
                                            setDuration(({ hoursInputValue }) => {

                                                return {
                                                    hoursInputValue,
                                                    minutesInputValue: newValue
                                                };
                                            });
                                        }
                                    }}
                                    // Format input on blur
                                    onBlur={event => {
                                        setDuration(info => {
                                            let formattedValue;

                                            const currentValue = parseInt(event.target.value);
                                            if (isNaN(currentValue)) {
                                                // If input is not a number, set it to minimum value
                                                formattedValue = parseInt(info.hoursInputValue) >= 1 ? '00' : getMinutesStringFromTotalMinutes(minDurationMins);
                                            } else {
                                                // If input is a number, ensure it is in valid range
                                                formattedValue = getMinutesStringFromTotalMinutes(_.clamp(currentValue, 0, 59));
                                            }

                                            // Only update state with new object if value needs to change
                                            if (info.minutesInputValue === formattedValue) {
                                                return info;
                                            } else {
                                                return {
                                                    ...info,
                                                    minutesInputValue: formattedValue
                                                };
                                            }
                                        });
                                    }}
                                    narrowWidth
                                />
                            </DurationInputContainer> 
                            :
                            <>
                                <DateInput
                                    value={startDate}
                                    onChange={event => setStartDate(event.target.value)}
                                    required
                                    aria-label={t(translationKeys.time.DATE)}
                                    min={minDateTime ? getInputFieldStringFromDate(minDateTime, timezone) : ''}
                                    max={maxDateTime ? getInputFieldStringFromDate(maxDateTime === 'now' ? new Date() : maxDateTime, timezone) : ''}
                                    onKeyUp={disableSubmissions ? undefined : event => {
                                        if (event.key === 'Enter') {
                                            onSubmit();
                                        }
                                    }}
                                />
                                <TimeField
                                    time={startTime}
                                    setTime={setStartTime}
                                    invalid={startValueInvalid}
                                    onEnterKeyPressed={disableSubmissions ? undefined : onSubmit}
                                /> 
                            </>

                        }
                    </>
                );
            }}

            endLabel={t(translationKeys.time.DURATION)}

            text={maxDurationMins && maxDurationMins % 60 === 0 ? t(translationKeys.time.validation.maxDuration.MAX_X_HOUR, { count: maxDurationMins / 60 }) : t(translationKeys.time.validation.maxDuration.MAX_X_MINUTE, { count: maxDurationMins })}

            // Date functions
            isDateSelected={date => {
                const [dateYear, dateMonth, dateDay] = [date.getFullYear(), date.getMonth(), date.getDate()];
                if (startDate) {
                    const [year, month, day] = startDate.split('-'); 
                    return  dateYear === parseInt(year) && dateMonth === parseInt(month) - 1 && dateDay === parseInt(day);
                }
                return false;
            }}
            onDateClick={date => {
                setStartDate(getInputFieldStringFromDate(date, timezone));
            }}

            onSubmit={onSubmit}
            disableSubmissions={disableSubmissions}

            onClear={startDateTime || endDateTime ? onClear : undefined}
            timezone={timezone}
        />
    );
}


/**
 * Get start and end timestamp from form values.
 * @param {string} startDate In format YYYY-MM-DD.
 * @param {string} startTime In format hh:mm.
 * @param {number} duration In minutes.
 * @param {string} [timezone] Timezone IANA format
 * @returns {[number, number]} Start timestamp and end timestamp
 */
const getStartAndEndTimestamps = (startDate, startTime, duration, timezone) => {
    const [year, month, day] = startDate.split('-').map(part => parseInt(part, 10));
    const [hour, minute] = startTime.split(':').map(part => parseInt(part, 10));

    const timezoneOffset = getUTCOffsetInMillis(timezone);

    // Convert to target timezone
    const date = Date.UTC(year, month - 1, day, hour, minute, 0);
    const startDateTime = date - timezoneOffset;
    const endDateTime = startDateTime + (duration * 60000);

    // Return Unix timestamps
    return [ startDateTime, endDateTime ];
}

const getHoursFromTotalMinutes = totalMinutes => Math.floor(totalMinutes / 60);

const getMinutesStringFromTotalMinutes = totalMinutes => (totalMinutes % 60).toString().padStart(2, '0');

export default withTranslation()(TimeDurationPicker);