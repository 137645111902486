import styled from 'styled-components';

const ButtonsRowContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-top: ${({ large }) => (large ? 30 : 20)}px;
    gap: ${({ large }) => (large ? 15 : 10)}px;
    width: 100%;

    ${({ centre }) => centre && `justify-content: center;`}

    ${({ $justifyContent }) =>
        $justifyContent && `justify-content: ${$justifyContent};`}

    ${({ $centreMobile }) => $centreMobile && `
        @media screen and (max-width: 800px) {
            justify-content: center;
            align-items: center;
        }
    `}
    ${({ $mobileDirection }) => $mobileDirection && `
        @media screen and (max-width: 800px) {
            flex-direction: ${$mobileDirection};
        }
    `}
`;

ButtonsRowContainer.displayName = 'ButtonsRowContainer';

export default ButtonsRowContainer;
