import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { VL_BLACK, WHITE } from '../../utils/colours';

// Mobile menu used for screens of this width (in px) and less.
const mobileMenuMaxWidth = 850;

export const NavbarContainer = styled.div`
    font-family: 'Museo Sans 2017', sans-serif;
    background: ${VL_BLACK(75)};
    color: #FFF;
    height: 60px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000; // Behind modals
    width: 100%;
    font-weight: 100;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: 0 0 8px rgb(0 0 0 / 15%);
    transition: top 0.5s ease-in-out;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

NavbarContainer.displayName = 'NavbarContainer';

export const Navlinks = styled.nav`
    height: 100%;
    display: inline-flex;
    align-items: center;
    padding-top: 3px;

    & > * {
        margin-left: 30px;
    }

    @media (max-width: ${mobileMenuMaxWidth}px) {
        display: flex;
        position: absolute;
        top: 60px;
        left: 0;
        background-color: ${VL_BLACK()};
        width: 100%;
        flex-direction: column;
        height: auto;
        padding: 0;

        transform: ${({ mobileMenuOpen }) =>
            mobileMenuOpen ? `scaleY(1)` : `scaleY(0)`};
        transition: transform ${({ collapsing }) => (collapsing ? `0.3s` : `0`)};
        transform-origin: top;
        overflow: hidden;

        > * {
            margin: 20px;
        }
    }
`;

Navlinks.displayName = 'Navlinks';

export const Navlink = styled(NavLink)`
    color: inherit;
    text-decoration: none;
    padding: 21px 3px 14px;
    height: 100%;
    font-size: 16px;
    text-align: center;

    /* Show bottom border if selected */
    &.selected {
        border-bottom: ${({ $selectedColour }) =>
            `3px solid ${$selectedColour}`};
        cursor: default;
    }

    /* Change colour on hover if not selected option */
    &:not(.selected):hover {
        color: ${WHITE(50)};
    }

    @media (max-width: ${mobileMenuMaxWidth}px) {
        border-bottom: none !important;
        padding: 0;

        &.selected {
            border-left: 3px solid ${({ $selectedColour }) => $selectedColour};
            padding-left: 10px;
            padding-right: 13px;
        }
    }
`;

Navlink.displayName = 'Navlink';

export const MobileMenuToggle = styled.button`
    display: none;
    height: 50px;
    background: none;
    border: none;
    cursor: pointer;

    svg {
        height: 30px;
        color: ${WHITE()};
    }

    @media (max-width: ${mobileMenuMaxWidth}px) {
        display: block;
    }
`;

MobileMenuToggle.displayName = 'MobileMenuToggle';
