import PropTypes from 'prop-types';

import ButtonsRowContainer from './ButtonsRow.styles';

/** Container for buttons, usually at the bottom of a form or section. */
const ButtonsRow = ({
    centre = false,
    children,
    large,
    justifyContent,
    centreMobile,
    mobileDirection,
    ...props
}) => {
    return (
        <ButtonsRowContainer
            centre={centre}
            large={large}
            {...props}
            $justifyContent={justifyContent}
            $centreMobile={centreMobile}
            $mobileDirection={mobileDirection}
        >
            {children}
        </ButtonsRowContainer>
    );
};

ButtonsRow.propTypes = {
    /** Whether to centre buttons horizontally and vertically (equivalent to `justify-content: center; align-items: center;`). */
    centre: PropTypes.bool,
    /** Larger top margin and gap between buttons. Recommended to set this prop to true when using larger buttons. */
    large: PropTypes.bool,
    /** Flex property for justifying buttons */
    justifyContent: PropTypes.oneOf([
        'center',
        'flex-start',
        'flex-end',
        'space-between',
        'space-around',
        'space-evenly',
    ]),
};

export default ButtonsRow;
