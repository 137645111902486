// TODO: This currently only works for relative links as always uses React Router's Link component. Need to just use <a> if absolute link.

import PropTypes from 'prop-types';

import { AnchorContainer, RRLinkContainer } from './Link.styles';
import useWhiteLabelComponent from '../../hooks/useWhiteLabelComponent';

/** Link rendered in specified colour. Underline disappears on hover. */
const Link = ({ to, colour, children, openInNewTab = false, ...props }) => {

    const { accentColour } = useWhiteLabelComponent();

    if (!colour) {
        colour = accentColour;
    }

    // Use React Router's Link component if it is a relative link
    if (to.charAt(0) === '/') {
        return (
            <RRLinkContainer to={to} $colour={colour} target={openInNewTab ? '_blank' : '_self'} {...props}>
                {children}
            </RRLinkContainer>
        );
    } else {
        return (
            <AnchorContainer href={to} $colour={colour} target={openInNewTab ? '_blank' : '_self'} {...props}>
                {children}
            </AnchorContainer>
        );
    }
};

Link.propTypes = {
    /** Link destination. */
    to: PropTypes.string.isRequired,
    /** Link colour. */
    colour: PropTypes.string,
    /** Open link in new tab. */
    openInNewTab: PropTypes.bool
};

export default Link;
