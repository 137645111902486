import styled from "styled-components";

import { FADED_GREY } from "../../../utils/colours";

export const DateInput = styled.input.attrs({
    type: 'date',
})`
    font-weight: 100;
    border: 1px solid ${FADED_GREY};
    width: 100%;
    padding: 0;
    height: 24px;
`;

DateInput.displayName = 'DateInput';