import { useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { createTimestampFromDateComponents, getDateComponents, isTimestampOnGivenDate } from '../../../utils/datetime';

import useWhiteLabelComponent from '../../../hooks/useWhiteLabelComponent';

import Picker from '../Picker';
import TimezoneSelector from '../../timezone-selector/TimezoneSelector';
import { DateRangeSelector } from './DateRangePicker.styles';

/** Allows user to select a range from one date to another date. Renders a calendar grid which shows one month at a time. */
const DateRangePicker = ({
    startDateTime = null,
    endDateTime = null,
    minDateTime = null,
    maxDateTime = null,
    maxDateDifference,
    t,
    onChange,
    onClear,
    timezone,
    availableTimezones,
    timezoneSelectorProps,
    includeTimezone = false,
    ...props
}) => {

    // Which end of the range is the user currently selecting
    const [selectingEndDate, setSelectingEndDate] = useState(
        !!(startDateTime && !endDateTime)
    );

    const { accentColour } = useWhiteLabelComponent();

    return (
        <Picker
            defaultDateInView={endDateTime ?? startDateTime}
            minDateTime={minDateTime}
            maxDateTime={maxDateTime}
            renderTimezoneComponent={() => 
                includeTimezone ?  
                    <TimezoneSelector {...timezoneSelectorProps} variant="select"/> :  null
            }
            renderRangeRowComponents={(end) => (
                <>
                    <DateRangeSelector
                        $colour={accentColour}
                        $selected={end ? selectingEndDate : !selectingEndDate}
                        onClick={() => setSelectingEndDate(end)}
                    >
                        {(end && endDateTime) || startDateTime ? `${end ? new Date(endDateTime ?? startDateTime).toLocaleDateString(undefined, { timeZone: timezone }) : new Date(startDateTime).toLocaleDateString(undefined, { timeZone: timezone })}`: '--/--/----'}
                    </DateRangeSelector>
                </>
            )}

            // Date functions
            showStartTrail={date => {
                const dateTimestampAtStartOfDate = createTimestampFromDateComponents(date.year, date.month, date.day, 0, 0, timezone, true);
                return startDateTime && endDateTime && dateTimestampAtStartOfDate > startDateTime && dateTimestampAtStartOfDate <= endDateTime;
            }}
            showEndTrail={date => {
                const dateTimestampAtEndOfDate = createTimestampFromDateComponents(date.year, date.month, date.day, 23, 59, timezone, false);
                return startDateTime && endDateTime && dateTimestampAtEndOfDate < endDateTime && dateTimestampAtEndOfDate >= startDateTime;
            }}
            isDateDisabled={date => {
                const dateTimestampAtEndOfDate = createTimestampFromDateComponents(date.year, date.month, date.day, 23, 59, timezone, false);
                const maxDateDifferenceInMs = (maxDateDifference ?? 0) * 8.64e7;
                return (
                    // Cannot choose end date that is less than start date
                    (selectingEndDate && dateTimestampAtEndOfDate <= startDateTime) ||
                    (typeof maxDateDifference === 'number' &&
                        !selectingEndDate &&
                        endDateTime &&
                        !startDateTime &&
                        endDateTime - dateTimestampAtEndOfDate > maxDateDifferenceInMs
                    ) ||
                    // Cannot choose end date that is greater than (start date + maxDateDifference)
                    (typeof maxDateDifference === 'number' &&
                        selectingEndDate &&
                        startDateTime &&
                        // `startDate` will be at midnight and `dateTimestampAtEndOfDate` will be at the end of the day
                        // Hence need to add (almost) an extra day on when comparing to `maxDateDifference`
                        dateTimestampAtEndOfDate - startDateTime > maxDateDifferenceInMs + 1000 * 60 * 60 * 24 - 1
                    )
                )
            }}
            isDateSelected={date => ( startDateTime &&  isTimestampOnGivenDate(startDateTime, date, timezone) ) || ( endDateTime && isTimestampOnGivenDate(endDateTime, date, timezone))}
            onDateClick={date => {
                let newStart, newEnd;
                if (selectingEndDate) {
                    // End date has changed

                    newEnd = createTimestampFromDateComponents(date.year, date.month, date.day, 23, 59, timezone, false);
                    
                    newStart = !startDateTime || startDateTime > newEnd ? createTimestampFromDateComponents(date.year, date.month, date.day, 0, 0, timezone, true) : startDateTime;

                    // Decrease start if it is now greater than end
                    if (newStart > newEnd) {
                        newStart = createTimestampFromDateComponents(date.year, date.month, date.day, 0, 0, timezone, true);
                    }
                } else {
                    // Start date has changed

                    newStart = createTimestampFromDateComponents(date.year, date.month, date.day, 0, 0, timezone, true);

                    newEnd = !endDateTime || endDateTime < newStart ? createTimestampFromDateComponents(date.year, date.month, date.day, 23, 59, timezone, false) : endDateTime;

                    // Need to also check whether the max date difference has been exceeded

                    const maxDateDifferenceInMs = maxDateDifference * 8.64e7 + 1000 * 60 * 60 * 24 - 1;
                    
                    const currentEndDateComponents = getDateComponents(newEnd, timezone);
                    const currentEndTimestampAtEndOfDate = createTimestampFromDateComponents(currentEndDateComponents.year, currentEndDateComponents.month, currentEndDateComponents.day, 23, 59, timezone, false);

                    // Bring forward end date if range would exceed maxDateDifference otherwise
                    if (
                        typeof maxDateDifference ===
                            'number' &&
                        currentEndTimestampAtEndOfDate - newStart > maxDateDifferenceInMs
                    ) {
                        newEnd = newStart + maxDateDifferenceInMs;
                    }
                }
                
                // Invoke `onChange` if selection has changed
                if (newStart !== startDateTime || newEnd !== endDateTime) {
                    onChange([newStart, newEnd]);
                }

                // Switch to selecting the other end of the range
                setSelectingEndDate(
                    (selectingEnd) => !selectingEnd
                );
            }}

            onClear={onClear}
            selectionMade={startDateTime || endDateTime}
            timezone={timezone}

            {...props}
        />
    );
};

DateRangePicker.propTypes = {
    /** Value for the start of the range. */
    startDateTime: PropTypes.number,
    /** Value for the end of the range. */
    endDateTime: PropTypes.number,
    /** Unix timestamp - Minimum allowed value for the range, i.e. start date (and end date) can be no earlier than the date this timestamp falls on. */
    minDateTime: PropTypes.number,
    /** Unix timestamp - Maximum allowed value for the range, i.e. end date (and start date) can be no later than the date this timestamp falls on. */
    maxDateTime: PropTypes.number,
    /** Maximum difference allowed in days between date components of `startDateTime` and `endDateTime`. */
    maxDateDifference: PropTypes.number,
    /** Function called when user inputs new range. Must take array containing start and end dates as only argument. */
    onChange: PropTypes.func.isRequired,
    /** Function called when user clears the current range. If excluded, "Clear" button will not be shown. */
    onClear: PropTypes.func,
    timezone: PropTypes.string,
    availableTimezones: PropTypes.arrayOf(PropTypes.string),
    includeTimezone: PropTypes.bool
};

export default withTranslation()(DateRangePicker);