import { useState, useEffect, useCallback } from "react";

/**
 * Toggles an element in and out of fullscreen.
 * @callback toggleFullscreen
 */

/**
 * Custom hook for making an element fullscreen.
 * @param {object} elementRef Ref of element.
 * @returns {[toggleFullscreen, boolean]} Returns function to toggle element in and out of fullscreen, and a boolean indicating whether it is currently fullscreen.
 */
const useFullscreen = (elementRef) => {
    // Whether element is in fullscreen mode
    const [isFullscreen, setIsFullscreen] = useState(false);

    // Function to enter/exit fullscreen mode
    const toggleFullscreen = useCallback(() => {
        if (document.fullscreenElement) {
            // Exit fullscreen
            document.exitFullscreen().catch(console.error);
        } else {
            // Enter fullscreen
            const element = elementRef.current;
            if (element) {
                const requestFullscreen = element.requestFullscreen ?? element.webkitRequestFullscreen;
                if (requestFullscreen) {
                    requestFullscreen.call(element)
                        .catch(console.error);
                } else {
                    console.error('Cannot request fullscreen');
                }
            }
        }
    }, [elementRef]);

    // Set up listener to update state when we enter and exit fullscreen mode
    useEffect(() => {
        const onChange = () => {
            setIsFullscreen(!!document.fullscreenElement);
        }

        const element = elementRef.current;

        element.addEventListener('fullscreenchange', onChange);

        return () => element.removeEventListener('fullscreenchange', onChange);
    }, [elementRef]);

    return [toggleFullscreen, isFullscreen];
}

export default useFullscreen;