/*
    Creates white label context. Also exports hook to access the information provided.

    This is kept separate from the WhiteLabel component so that useWhiteLabelComponent hook can import directly
    from this file. Otherwise we get circular dependencies: component  -> useWhiteLabelComponent -> white-label/index -> WhiteLabel -> component.

    This will not be an issue anymore once WhiteLabel component no longer exports a white label version of every component.
*/

import { createContext, useContext } from 'react';

// Must set default context value to an object so that we can run tests without having to set up a Provider every time
const WhiteLabelContext = createContext({});
WhiteLabelContext.displayName = 'WhiteLabelContext';


// Custom hook for getting white label info
export const useWhiteLabelInfo = () => useContext(WhiteLabelContext);

export default WhiteLabelContext;