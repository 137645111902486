import { useState } from 'react';

/*
    Custom hook to manage button loading state. This is not for form submit buttons; use the useForm hook for managing form state.

    This hook takes a single argument: the function to call on click. This function should take one argument, a callback function, which
    it must call when it has finished.

    The hook returns an object containing props to pass to button. Those props are 'loading` and 'onClick'.

    When the button is clicked, its loading prop is set to true and the onClick function passed to this hook is called. When the callback is
    invoked, the loading prop is set to false.

    For an example use of this hook, see the documentation for our Button component.
*/
const useAsyncButtonAction = (onClick) => {
    const [loading, setLoading] = useState(false);

    const handleOnClick = () => {
        setLoading(true);

        const cb = () => setLoading(false);

        onClick(cb);
    };

    return {
        loading,
        onClick: handleOnClick,
    };
};

export default useAsyncButtonAction;
