import { useState } from "react";
import { withTranslation } from "react-i18next";

import translationKeys from '../../../translations/keys';

import { areDatesEqual, createDateInLocalTimeFromInputFieldString, getInputFieldStringFromDate } from "../../../utils/datetime";

import Span from "../../span";
import Picker, { TimeField } from "../Picker";
import { DateInput } from "./TimeRangePicker.styles";

/** UI for choosing a start date, start time and end time (which may be following day). Only use for time ranges up to 24 hours. */
const TimeRangePicker = withTranslation()(({
    startDateTime = null,
    endDateTime = null,
    minDateTime = null,
    maxDateTime = null, // Either a Date object or string "now"
    maxDurationMins,
    defaultStartTime = '00:00',
    defaultEndTime = '23:59',
    t,
    onChange,
    onClear,
    ...props
}) => {

    // Currently selected start date (ignore the time component of this date object)
    const [startDate, setStartDate] = useState(startDateTime ? getInputFieldStringFromDate(startDateTime) : '');

    // Currently selected times
    const [startTime, setStartTime] = useState(
        startDateTime ? startDateTime.toTimeString().substring(0, 5) : defaultStartTime
    );
    const [endTime, setEndTime] = useState(
        endDateTime ? endDateTime.toTimeString().substring(0, 5) : defaultEndTime
    );

    if (maxDateTime === 'now') {
        // Use "now" to keep max date as close to current time as possible
        maxDateTime = new Date().getTime();
    }

    let startValue, endValue, startTimeInvalid, endTimeInvalid;
    if (startDate && startTime && endTime) {
        [startValue, endValue] = getStartAndEndDateTimes(startDate, startTime, endTime);

        startTimeInvalid = (minDateTime && startValue.getTime() < minDateTime) ||
                            (maxDateTime && startValue.getTime() > maxDateTime);

        endTimeInvalid = (minDateTime && endValue.getTime() < minDateTime) ||
                            (maxDateTime && endValue.getTime() > maxDateTime) ||
                            (maxDurationMins && startValue && endValue.getTime() - startValue.getTime() > maxDurationMins * 60000);
        
    }

    const disableSubmissions = !startValue ||
                                !endValue ||
                                startTimeInvalid ||
                                endTimeInvalid;

    const onSubmit = () => {
        onChange([
            startValue,
            endValue,
        ]);
    };

    return (
        <Picker
            defaultDateInView={startDateTime}
            minDateTime={minDateTime}
            maxDateTime={maxDateTime}

            renderRangeRowComponents={(end) => {
                if (end) {
                    return (
                        <>
                            <Span
                                light
                            >
                                {
                                    startValue && endValue && !areDatesEqual(startValue, endValue)
                                        ? endValue.toLocaleDateString()
                                        : null
                                }
                            </Span>
                            <TimeField
                                isEndTime
                                time={endTime}
                                setTime={setEndTime}
                                invalid={endTimeInvalid}
                                onEnterKeyPressed={disableSubmissions ? undefined : onSubmit}
                            />
                        </>
                    );
                } else {
                    return (
                        <>
                            <DateInput
                                value={startDate}
                                onChange={event => setStartDate(event.target.value)}
                                required
                                aria-label={t(translationKeys.time.DATE)}
                                min={minDateTime ? getInputFieldStringFromDate(minDateTime) : ''}
                                max={maxDateTime ? getInputFieldStringFromDate(maxDateTime) : ''}
                                onKeyUp={disableSubmissions ? undefined : event => {
                                    if (event.key === 'Enter') {
                                        onSubmit();
                                    }
                                }}
                            />
                            <TimeField
                                time={startTime}
                                setTime={setStartTime}
                                invalid={startTimeInvalid}
                                onEnterKeyPressed={disableSubmissions ? undefined : onSubmit}
                            />
                        </>
                    );
                }
            }}

            text={maxDurationMins && maxDurationMins % 60 === 0 ? t(translationKeys.time.validation.maxDuration.MAX_X_HOUR, { count: maxDurationMins / 60 }) : t(translationKeys.time.validation.maxDuration.MAX_X_MINUTE, { count: maxDurationMins })}

            // Date functions
            isDateSelected={date => {
                return areDatesEqual(date, createDateInLocalTimeFromInputFieldString(startDate));
            }}
            onDateClick={date => {
                setStartDate(getInputFieldStringFromDate(date));
            }}

            onSubmit={onSubmit}
            disableSubmissions={disableSubmissions}

            onClear={startDateTime || endDateTime ? onClear : undefined}
        />
    );
});

/**
 * Get start and end date from form values.
 * @param {string} startDate In format YYYY/MM/DD.
 * @param {string} startTime In format hh:mm.
 * @param {string} endTime In format hh:mm.
 * @returns {[Date, Date]} Start datetime and end datetime.
 */
const getStartAndEndDateTimes = (startDate, startTime, endTime) => {
    const start = createDateInLocalTimeFromInputFieldString(startDate);
    start.setHours(startTime.slice(0, 2));
    start.setMinutes(startTime.slice(3, 5));
    
    const end = createDateInLocalTimeFromInputFieldString(startDate);
    end.setHours(endTime.slice(0, 2));
    end.setMinutes(endTime.slice(3, 5));

    // If end time is less than start time, end time is for the following day
    if (end.getTime() <= start.getTime()) {
        end.setDate(end.getDate() + 1);
    }

    return [start, end];
}

export default TimeRangePicker;