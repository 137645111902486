import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import SpinnerIcon from './Spinner.styles';

/** Loading spinner. */
const Spinner = forwardRef(
    ({ colour = 'white', large = false, ...props }, ref) => {
        return (
            <SpinnerIcon
                colour={colour}
                large={large}
                data-testid="spinner"
                {...props}
                ref={ref}
            />
        );
    }
);

Spinner.propTypes = {
    /** Colour of spinner. */
    colour: PropTypes.string,
    /** There are two sizes: small (default) and large. */
    large: PropTypes.bool,
};

export default Spinner;
