import PropTypes from 'prop-types';
import { useRef, useState } from 'react';

import useResizeObserver from '../../../hooks/useResizeObserver';

import {
    FormGroup,
    Label,
    FieldContainer,
    RightColumn,
    Description,
    ErrorMessage,
} from './Field.styles';

/** Form field layout wrapper. Positions children with label/description/error message/etc. */
const Field = ({
    layout = 'vertical',
    fullWidth = false,
    fieldAutoWidth = false,
    label,
    name,
    required = false,
    hideRequired = false,
    large = false,
    errorMessage,
    description,
    hide = false,
    labelWidth = 140,
    component,
    children,
    ...props
}) => {
    /*
        Field can be laid out in up to three columns:
            * Three columns: Label, then field (with error message), then description
            * Two columns: Label on left, field above description on right
            * One column: All elements laid out vertically with label above field above description
        
        I cannot think of a nice way with CSS alone to achieve this. It is currently structured:
            <div> (FormGroup)
                <label> (Label)
                <div> (RightColumn)
                    <Field />
                    <Description />
                </div>
            </div>

        For the three column layout, I initially had both FormGroup and RightColumn with `flex-direction: row`
        but when three columns cannot fit it causes field to drop below label (so layout is label above field
        on left, with description alone on right).

        As a workaround I now store the width of FormGroup and if it falls below the minimum required for three columns,
        RightColumn switches to `flex-direction: column`.

        To make matters more complicated, if formAutoWidth prop is true then the width of the field can vary. Thus we need
        to do exactly the same thing with FieldContainer as with FormGroup and use the widths of both to calculate whether
        three columns can fit.
    */
    const [formGroupWidth, setFormGroupWidth] = useState(null);
    const formGroupRef = useRef();
    useResizeObserver(formGroupRef, ({ width }) =>
        setFormGroupWidth(width)
    );

    // Only need to measure FieldContainer width if formAutoWidth prop is true
    const [fieldContainerWidth, setFieldContainerWidth] = useState(null);
    const fieldContainerRef = useRef();
    useResizeObserver(fieldContainerRef, ({ width }) =>
        setFieldContainerWidth(width)
    );

    // Return null for hidden fields
    if (hide) {
        return null;
    }

    // Component to render
    const Component = component;

    return (
        // Pass props to FormGroup if no component prop (otherwise that component must receive props instead)
        <FormGroup
            layout={layout}
            large={large}
            ref={formGroupRef}
            {...(component ? {} : props)}
        >
            {label && (
                <Label
                    htmlFor={name}
                    isRequired={!hideRequired && required} // Set to false if hideRequired is true
                    large={large}
                    layout={layout}
                    $width={labelWidth}
                >
                    {label}
                </Label>
            )}
            <RightColumn
                layout={layout}
                large={large}
                $parentWidth={formGroupWidth}
                $fieldWidth={fieldContainerWidth}
                $labelWidth={labelWidth}
            >
                <FieldContainer
                    fullWidth={fullWidth}
                    layout={layout}
                    large={large}
                    $autoWidth={fieldAutoWidth}
                    // Only need to measure FieldContainer width if formAutoWidth prop is true
                    ref={fieldAutoWidth ? fieldContainerRef : undefined}
                >
                    {component ? (
                        <Component
                            fullWidth={fullWidth}
                            name={name}
                            required={required}
                            large={large}
                            error={!!errorMessage}
                            descriptionId={description && `${name}-description`}
                            {...props}
                        />
                    ) : (
                        children
                    )}
                    {errorMessage && (
                        <ErrorMessage large={large}>
                            {errorMessage}
                        </ErrorMessage>
                    )}
                </FieldContainer>
                {description && (
                    <Description
                        layout={layout}
                        large={large}
                        id={name ? name + '-description' : undefined}
                        as={typeof description === 'string' ? undefined : 'div'}
                    >
                        {description}
                    </Description>
                )}
            </RightColumn>
        </FormGroup>
    );
};

Field.propTypes = {
    /** Name of field. Used in `for` attribute of label and the id given to the description will be `${name}-description` (e.g. 'email-description'). */
    name: PropTypes.string,
    /** Label text describing field. Displayed in `<label>` tag. */
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** Vertical layout: label on top of field, on top of description. Horizontal layout: Three columns with label on left, field in middle, and description on right. */
    layout: PropTypes.oneOf(['vertical', 'horizontal']),
    /** Error message to display when provided value is invalid. */
    errorMessage: PropTypes.string,
    /** Whether this is a required field. */
    required: PropTypes.bool,
    /** Do not show in UI that this field is required (e.g. usually with an asterisk). Only applies when `required` is true. */
    hideRequired: PropTypes.bool,
    /** Whether the component should be a larger size. */
    large: PropTypes.bool,
    /** Whether field should fill width of container. */
    fullWidth: PropTypes.bool,
    /** Further information displayed next to field. */
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** Do not render field. Used by our useForm custom hook. */
    hide: PropTypes.bool,
    /** Width of label in px. Ignored if `layout` is 'vertical'. */
    labelWidth: PropTypes.number,
    /** Set width of field column to width of component within it. */
    fieldAutoWidth: PropTypes.bool
};

export default Field;
