import styled from 'styled-components';

export const HeadingContainer = styled.h1`
    font-weight: 300;
    color: #FFF;

    ${({ $border, $accentColour }) =>
        $border
            ? `border-bottom: 1px solid ${$accentColour}; padding-bottom: 10px;`
            : ''}

    ${({ $accentColour, $noDot, $ellipsis }) =>
        !$noDot
            ? `::after { content: "${
                  $ellipsis ? '...' : '.'
              }"; color: ${$accentColour}; }`
            : ''}
    ${({$fontSize}) =>
        $fontSize 
            ? `font-size: ${$fontSize};`
            : ''
    }
`;

HeadingContainer.displayName = 'HeadingContainer';
