import { createGlobalStyle } from 'styled-components';
import normalise from 'normalize.css';

// Initially used @font-face inside GlobalStyle below but this seemed to cause the fonts to reload when buttons were pressed. Not sure why?!
// So now instead load them in a separate CSS file and bundle them in here
import './assets/css/fonts.css';

// Import fonts
// import FontRegularEot from './assets/fonts/GT-Walsheim-Regular.eot';
// import FontRegularOtf from './assets/fonts/GT-Walsheim-Regular.otf';
// import FontRegularTtf from './assets/fonts/GT-Walsheim-Regular.ttf';
// import FontRegularWoff from './assets/fonts/GT-Walsheim-Regular.woff';
// import FontRegularWoff2 from './assets/fonts/GT-Walsheim-Regular.woff2';
// import FontBoldEot from './assets/fonts/GT-Walsheim-Bold.eot';
// import FontBoldOtf from './assets/fonts/GT-Walsheim-Bold.otf';
// import FontBoldTtf from './assets/fonts/GT-Walsheim-Bold.ttf';
// import FontBoldWoff from './assets/fonts/GT-Walsheim-Bold.woff';
// import FontBoldWoff2 from './assets/fonts/GT-Walsheim-Bold.woff2';

const GlobalStyle = createGlobalStyle`
    ${normalise}

    * {
        box-sizing: border-box;
        font-family: "GT-Walsheim";
    }

    html, body {
        padding: 0;
        margin: 0;
        width: 100%;
        color: #FFF;
    }

    h1, h2, h3 {
        font-weight: 100;
        color: white;
    }

    h1 {
        margin: 30px 0;
    }

    hr {
        margin: 20px 0;
        border: 0;
        border-top: 1px solid #cecdcd;
    }

    a {
        color: inherit;
    }

    p, ul, li {
        font-weight: 100;
    }

    /* Stop iOS Safari making all buttons blue */
    button {
        color: #000;
    }

    /* Override custom scrollbar styles because safari < 16 hides the default one */
    ::-webkit-scrollbar-track {
        background-color: rgba(24,24,40, 0.4);
        border-radius: 10px;
    }

    ::-webkit-scrollbar
    {
        width: 8px;
        height: 10px;
        background-color: rgba(24,24,40, 0.2);
    }

    ::-webkit-scrollbar-thumb
    {
        border-radius: 10px;
        background-color: rgba(255,255,255, 0.15);
    }

    /* Stops browser autofill from changing input colours*/
    input:-webkit-autofill,
    input:-webkit-autofill:focus {
        transition: background-color 600000s 0s, color 600000s 0s;
    }
    input[data-autocompleted] {
        background-color: transparent !important;
    }

    option {
        /* Windows has white background on options which clashes with white font  */
        color: black !important;
    }


`;

export default GlobalStyle;

/*
This used to be inside global styles:

@font-face {
        font-family: 'GT-Walsheim';
        src: url(${FontRegularEot}) format('embedded-opentype'),
            url(${FontRegularWoff}) format('woff'),
            url(${FontRegularWoff2}) format('woff2'),
            url(${FontRegularTtf}) format('truetype'),
            url(${FontRegularOtf}) format('opentype');
        font-weight: 100;
        font-style: normal;
    }

    @font-face {
        font-family: 'GT-Walsheim';
        src: url(${FontBoldEot}) format('embedded-opentype'),
            url(${FontBoldWoff}) format('woff'),
            url(${FontBoldWoff2}) format('woff2'),
            url(${FontBoldTtf}) format('truetype'),
            url(${FontBoldOtf}) format('opentype');
        font-weight: 300;
        font-style: normal;
    }
*/
