import axios from 'axios';
import imagePromise from '../utils/images';

// Path to white label folder
export const whiteLabelPath = 'https://assetcdn.manything.com/white-label/';

// White label info for host
export const getWhiteLabelInfo = async (host) => {
    const { data: info } = await axios(`${whiteLabelPath}${host}/colours.json`);

    // Default to logo.png if one is not set in JSON file
    if (!info.logo) {
        info.logo = `${whiteLabelPath}${host}/logo.png`;
    }

    info.favicon = `${whiteLabelPath}${host}/favicon.ico`;

    return info;
};

// CCTV Connect branding for installer
// Returns empty object if installer has no branding set up
export const getInstallerCCTVCBranding = async (installerId) => {
    // Need to request both colours and logo
    // Note: They may not exist and the code must not crash if that is the case
    const reqs = [
        axios
            .get(`${whiteLabelPath}installers/${installerId}/colours.json`)
            .catch((err) => ({ data: {} })), // If no file exists, return empty object as data
        imagePromise(
            `${whiteLabelPath}installers/${installerId}/logo.png`,
            null
        ),
        imagePromise(
            `${whiteLabelPath}installers/${installerId}/logo_rounded.png`,
            null
        ),
    ];

    // branding will either contain colours info or be empty
    const [{ data: branding }, logo, roundedLogo] = await Promise.all(reqs);

    // Add logos to branding if they exist
    if (logo || roundedLogo) {
        branding.logo = logo ?? roundedLogo;
        
        // Use app icon logo (rounded logo) in sidebar as more likely to show up on grey background
        // Fallback to main logo if no rounded logo
        branding.appLayoutLogo = roundedLogo ?? logo;

        if (roundedLogo) {
            // Cannot fall back to main logo for appLayoutLogoSmall though as it would probably be too big
            branding.appLayoutLogoSmall = roundedLogo;
        }
    }

    return branding;
};
