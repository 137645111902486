import PropTypes from 'prop-types';

// SVGs
import Mastercard from '../../../assets/images/cards/mastercard.svg';
import Visa from '../../../assets/images/cards/visa.svg';
import VisaDark from '../../../assets/images/cards/visa-blue.svg';
import Amex from '../../../assets/images/cards/amex.svg';
import JCB from '../../../assets/images/cards/jcb.svg';
import Discover from '../../../assets/images/cards/discover.svg';
import DiscoverDark from '../../../assets/images/cards/discover-dark.svg';
import PayPal from '../../../assets/images/cards/paypal-white.png';
import PayPalDark from '../../../assets/images/cards/paypal.svg';
import Diners from '../../../assets/images/cards/diners.svg';
import UnionPay from '../../../assets/images/cards/unionpay.svg';
import Icon from '../Icon';

/** Wrapper for Icon component to show SVGs for payment providers/processors (e.g. Visa). */
const PaymentMethodIcon = ({ method, variant = 'light', ...props }) => {
    const src = getSrc(method, variant);

    if (!src) {
        return method;
    }

    return (
        <Icon
            customSrc={src}
            accessibilityDescription={method === 'paypal' ? 'PayPal' : method.toLocaleUpperCase()}
            {...props}
        />
    );
}

const getSrc = (method, variant) => {
    switch (method) {
        case 'mastercard':
            return Mastercard;
        case 'visa':
            if (variant === 'dark') {
                return VisaDark;
            }
            return Visa;
        case 'amex':
            return Amex;
        case 'jcb':
            return JCB;
        case 'discover':
            if (variant === 'dark') {
                return DiscoverDark;
            }
            return Discover;
        case 'paypal':
            if (variant === 'dark') {
                return PayPalDark;
            }
            return PayPal;
        case 'diners':
            return Diners;
        case 'unionpay':
            return UnionPay;
        default:
            return '';
    }
}

PaymentMethodIcon.propTypes = {
    /** Payment method. */
    method: PropTypes.oneOf([
        'mastercard',
        'visa',
        'amex',
        'jcb',
        'discover',
        'paypal',
        'diners',
        'unionpay'
    ]).isRequired
};

export default PaymentMethodIcon;