export const getEventThumbSrc = (logger, cameraUidd, eventId, token) =>
    `https://${logger}/alertthumb/${cameraUidd}/${eventId}/${token}`;

export const getHighResStillSrc = (
    logger,
    uid,
    deviceId,
    stillTimestamp,
    token
) =>
    `https://${logger}/simple/${uid}/${deviceId}/hdstill/${stillTimestamp}.jpg?token=${token}`;
