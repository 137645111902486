import { forwardRef, memo, useImperativeHandle, useLayoutEffect, useRef, useState } from "react";
import _ from 'lodash';
import { LinearFilter, MathUtils, Mesh, MeshBasicMaterial, PerspectiveCamera, RGBAFormat, SRGBColorSpace, Scene, SphereGeometry, Vector3, VideoTexture, WebGLRenderer } from "three";
import PropTypes from 'prop-types';

import useResizeOberserver from "../../../hooks/useResizeObserver";

// Constants
const INIT_FOV = 95;
const MAX_FOV = 110;
const MIN_FOV = 50;

const INIT_LATITUDE = 40;
const MIN_LATITUDE = 0;
const MAX_LATITUDE = 80;

const INIT_LONGITUDE = 270;
// const MIN_LONGITUDE = 180;
// const MAX_LONGITUDE = 360;

const SPHERE_RADIUS = 500;

const VIDEO_FPS = 10;

// Renders a canvas with a dewarped view of a fisheye video
// Video must be circular (width = height)
// Assumes camera that recorded the video was positioned on a ceiling
const Dewarper = memo(forwardRef(({ reactPlayerRef, initialConfig, /*borderColour*/ }, ref) => {

    const containerRef = useRef();
    const canvasRef = useRef();

    // Functions to look around scene
    // These get attached to ref
    const controlsRef = useRef({
        zoom: null,
        moveX: null,
        moveY: null
    });

    useImperativeHandle(ref, () => {
        return {
            canvas: canvasRef.current,
            zoom: (...args) => controlsRef.current.zoom(...args),
            moveX: (...args) => controlsRef.current.moveX(...args),
            moveY: (...args) => controlsRef.current.moveY(...args),
        };
    }, []);

    const initialConfigRef = useRef({
        // orientation: 'ceiling',
        initCameraLatitude: initialConfig?.cameraLatitude ?? INIT_LATITUDE,
        initDirection: initialConfig?.direction ?? 0
    });
    
    // Callbacks for user actions
    const handleResizeRef = useRef();
    const [handlers, setHandlers] = useState({
        onMouseDown: null,
        onMouseMove: null,
        onMouseUp: null,
        onTouchStart: null,
        onTouchEnd: null
    });
    
    // Need to tell three.js renderer and camera when canvas is resized
    useResizeOberserver(containerRef, ({ width, height}) => {
        handleResizeRef.current(width, height);
    });

    // Set up scene and listeners
    useLayoutEffect(() => {
        
        const width = containerRef.current.offsetWidth;
        const height = containerRef.current.offsetHeight;
        
        const video = reactPlayerRef.current.getInternalPlayer();
        const canvas = canvasRef.current;

        /*
            The following is a basic but effective approach to dewarping for
            circular fisheye videos. We effectively map the circular image
            onto the inside of a hemisphere (using the arcsin function to
            spread the pixels further at the edges than the centre) and position
            a camera at its centre. We then achieve the feeling of moving around
            the video by either lifting the camera up or down (through adjusting
            its latitude) or rotating the hemisphere. This approach was based on
            https://github.com/yanwsh/videojs-panorama.
        */


        // Renderer
        const renderer = new WebGLRenderer({ canvas: canvas, preserveDrawingBuffer: true });
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(width, height);
        renderer.autoClear = false;
        renderer.setClearColor(0x000000, 1);

        // Scene
        const scene = new Scene();

        // Texture
        const texture = new VideoTexture(video);
        texture.colorSpace = SRGBColorSpace;
        texture.generateMipmaps = false;
        texture.minFilter = LinearFilter;
        texture.maxFilter = LinearFilter;
        texture.format = RGBAFormat;

        // Camera
        const camera = new PerspectiveCamera(INIT_FOV, width / height, 1, 2000);

        // Aim the camera
        // For video recorded by camera on ceilings, we leave longitude fixed and adjust latitude
        let cameraLatitude = initialConfigRef.current.initCameraLatitude;
        let cameraLongitude = INIT_LONGITUDE;

        // Geometry
        const geometry = getGeometry();

        // Mesh
        const material = new MeshBasicMaterial({ map: texture });
        const mesh = new Mesh(geometry, material);

        // Set initial rotation of hemisphere
        if (initialConfigRef.current.initDirection !== 0) {
            mesh.rotateZ(MathUtils.degToRad(initialConfigRef.current.initDirection));
        }
        
        scene.add(mesh);


        // Update the canvas for each video frame using requestAnimationFrame
        let lastUpdate = new Date().getTime();
        let animationId;

        // Have we set up the first frame? After that we only need to update texture when frame changes
        let initialFrameTaken = false;
        const animate = () => {
            animationId = requestAnimationFrame(animate);

            // Only need to update texture if we haven't taken first frame or afterwards if it has changed
            if (!initialFrameTaken || (!video.paused && !video.ended && new Date().getTime() - lastUpdate >= 1000 / VIDEO_FPS)) {
                texture.needsUpdate = true;
                lastUpdate = new Date();
                initialFrameTaken = true;
            }

            renderer.clear();

            // Set camera position
            const latitudeRadians = MathUtils.degToRad(90 - cameraLatitude);
            const longitudeRadians = MathUtils.degToRad(cameraLongitude);
            const cameraTargetX = SPHERE_RADIUS * Math.sin(latitudeRadians) * Math.cos(longitudeRadians);
            const cameraTargetY = SPHERE_RADIUS * Math.cos(latitudeRadians);
            const cameraTargetZ = SPHERE_RADIUS * Math.sin(latitudeRadians) * Math.sin(longitudeRadians);
            camera.lookAt(new Vector3(cameraTargetX, cameraTargetY, cameraTargetZ));

            renderer.render(scene, camera);
        }

        animate();

        // Function invoked when canvas resized
        handleResizeRef.current = (width, height) => {
            renderer.setSize(width, height);
            camera.aspect = width / height;
            camera.updateProjectionMatrix();
        }

        // Functions to control movement
        const zoom = (change) => {
            camera.fov = _.clamp(camera.fov + change, MIN_FOV, MAX_FOV);
            camera.updateProjectionMatrix();
        }
        const moveX = (change) => {
            mesh.rotateZ(change * (Math.PI / 180)); // Rotation in radians
        }
        const moveY = (change) => {
            cameraLatitude = _.clamp(cameraLatitude + change, MIN_LATITUDE, MAX_LATITUDE);
        }


        // Zoom on mouse wheel
        const onWheel = event => {
            event.preventDefault();
            zoom(event.deltaY * 0.05);
        }

        // Handle mouse and touch movements
        let mouseDown;
        let onMouseDownMouseY;
        let onMouseDownLatitude;
        let mouseXOnLastRotation;

        const onMouseDown = event => {
            mouseDown = true;
            onMouseDownMouseY = event.clientY;
            onMouseDownLatitude = cameraLatitude;
            mouseXOnLastRotation = event.clientX;
        }

        const onMouseMove = event => {
            if (mouseDown && typeof onMouseDownMouseY === 'number' && typeof onMouseDownLatitude === 'number' && typeof mouseXOnLastRotation === 'number') {
                // Most cameras are on ceilings, so we want to rotate rather than change longitude (https://threejs.org/docs/index.html#api/en/core/Object3D.rotateY)
                const displacement = mouseXOnLastRotation - event.clientX;
                moveX(displacement / 6);
                mouseXOnLastRotation = event.clientX;
                
                // this.lon = (this.onPointerDownPointerX - clientX) * 0.2 + this.onPointerDownLon;
                cameraLatitude = _.clamp((event.clientY - onMouseDownMouseY) * 0.2 + onMouseDownLatitude, MIN_LATITUDE, MAX_LATITUDE);
            }
        }

        const onMouseUp = event => {
            mouseDown = false;
            onMouseDownMouseY = null;
            onMouseDownLatitude = null;
            mouseXOnLastRotation = null;
        }

        // Handle touch screens
        let pinching; // Is user using multiple fingers?
        let lastMultiTouchDistance; // If pinching, what is the distance between fingers?

        const getDistanceBetweenTouches = event => Math.hypot(event.touches[1].clientX - event.touches[0].clientX, event.touches[1].clientY - event.touches[0].clientY);

        const onTouchStart = event => {
            if (event.touches.length > 1) {
                pinching = true;
                lastMultiTouchDistance = getDistanceBetweenTouches(event);
            }

            // Add `clientX` and `clientY` to event so we can reuse `onMouseDown`
            event.clientX = event.touches[0].clientX;
            event.clientY = event.touches[0].clientY;
            onMouseDown(event);
        }

        const onTouchMove = event => {
            // Stop screen scrolling or zooming
            event.preventDefault();

            if (pinching) {
                const currentDistance = getDistanceBetweenTouches(event);
                // Mock `deltaY` to reuse `onWheel`
                event.deltaY = (lastMultiTouchDistance - currentDistance) * 2;
                onWheel(event);
                lastMultiTouchDistance = currentDistance;
            } else if (event.touches.length === 1) {
                // Add `clientX` and `clientY` to event so we can reuse `onMouseMove`
                event.clientX = event.touches[0].clientX;
                event.clientY = event.touches[0].clientY;
                onMouseMove(event);
            }
        }

        const onTouchEnd = event => {
            if (event.touches.length === 0) {
                // Reset pinching variables when no touches left
                pinching = false;
                lastMultiTouchDistance = null;
            }
            onMouseUp();
        }

        setHandlers({
            onMouseDown,
            onMouseMove,
            onMouseUp,
            onTouchStart,
            onTouchEnd
        });

        // This listeners need to be added manually as they must not be passive to prevent default
        canvas.addEventListener('wheel', onWheel, { passive: false });
        canvas.addEventListener('touchmove', onTouchMove, { passive: false });

        controlsRef.current.zoom = zoom;
        controlsRef.current.moveX = moveX;
        controlsRef.current.moveY = moveY;

        return () => {
            cancelAnimationFrame(animationId);
            canvas.removeEventListener('wheel', onWheel, { passive: false });
            canvas.removeEventListener('touchmove', onTouchMove, { passive: false });

            // Dispose three.js objects (https://threejs.org/docs/#manual/en/introduction/How-to-dispose-of-objects)
            scene.remove(mesh);
            texture.dispose();
            disposeGeometry();
            material.dispose();
            renderer.dispose();
        };

    }, [reactPlayerRef]);

    return (
        <div
            ref={containerRef}
            style={{
                width: '100%',
                height: '100%',
                // border: borderColour ? `1px solid ${borderColour}` : undefined
            }}
        >
            <canvas
                ref={canvasRef}
                style={{
                    width: '100%',
                    height: '100%'
                }}
                onMouseDown={handlers.onMouseDown}
                onMouseUp={handlers.onMouseUp}
                onMouseMove={handlers.onMouseMove}
                onTouchStart={handlers.onTouchStart}
                onTouchEnd={handlers.onTouchEnd}
            />
        </div>
    );
}));

Dewarper.propTypes = {
    /** Ref of a ReactPlayer. */
    reactPlayerRef: PropTypes.object.isRequired,
    /** Initial camera position. */
    initialConfig: PropTypes.shape({
        /** Initial camera latitude. */
        cameraLatitude: PropTypes.number,
        /** Initial camera direction between 0 and 360. */
        direction: PropTypes.number
    })
};


// Reuse three.js geometry to improve performance
// (This will start making a difference when showing multiple dewarped canvases at once)

// Current geometry
let geometry = null;
// Number of Dewarper components using the geometry
let geometryUsers = 0;

const getGeometry = () => {
    geometryUsers++;
    if (!geometry) {
        // Create geometry if we don't have one cached

        // The geometry is a sphere as we'll map the circular video frames onto the inside of half of it
        geometry = new SphereGeometry(SPHERE_RADIUS, 60, 40).toNonIndexed();

        // This maths, taken from https://github.com/yanwsh/videojs-panorama, handles the mapping
        // which dewarps the image.
        // I roughly understood it when I first started implementing our own dewarping a few months
        // ago but can't remember the specifics now to write a decent comment.
        // The gist is that it uses the arcsin function to map pixels in the warped frame onto the inside
        // of the hemisphere, with pixels furthest from the centre being moved the most (as fisheye
        // warping is greatest at the edges).
        const normals = geometry.attributes.normal.array;
        const uvs = geometry.attributes.uv.array;
        for (let i = 0, l = normals.length / 3; i < l; i++) {
            const x = normals[i * 3 + 0];
            const y = normals[i * 3 + 1];
            const z = normals[i * 3 + 2];

            let r = Math.asin(Math.sqrt(x * x + z * z) / Math.sqrt(x * x + y * y + z * z)) / Math.PI; // Range 0 - 0.5
            if (y < 0) {
                r = 1 - r;
            }
            
            let theta = x === 0 && z === 0 ? 0 : Math.acos(x / Math.sqrt(x * x + z * z)); // Range 0 - pi
            if (z < 0) {
                theta = theta * -1;
            }
            
            uvs[i * 2 + 0] = -1 * r * Math.cos(theta) + 0.5;
            uvs[i * 2 + 1] = 1 * r * Math.sin(theta) + 0.5;
        }

        // Initial rotation
        geometry.rotateX(-Math.PI/2); // Needed for ceiling cameras

        geometry.scale(-1, 1, 1);
    }
    return geometry;
}

const disposeGeometry = () => {
    geometryUsers--;
    if (geometryUsers === 0) {
        // If there are no components left using the geometry, we must dispose it
        geometry.dispose();
        geometry = null;
    }
}


export default Dewarper;