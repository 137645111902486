import _ from "lodash";
import { withTranslation } from "react-i18next";
import PopupList from "../popup/popup-list";
import { formatTimeZone } from "../../utils/datetime";
import useWhiteLabelComponent from "../../hooks/useWhiteLabelComponent";
import usePopup from "../../hooks/usePopup";
import Button from "../button";
import Span from "../span";
import { ReactComponent as TimezoneIcon } from "../../assets/images/icons/Timezone.svg"; 
import { VL_BLACK } from "../../utils/colours";
import { Flex } from "../layout";
import { Dropdown } from "../form";
import translationKeys from '../../translations/keys';

const TimezoneSelector = ( props ) => {
    const [popupProps, popupTriggerButtonProps] = usePopup({ placement: 'bottom' });
    const { accentColour} = useWhiteLabelComponent();

    if (props && _.keys(props)?.length > 0) {
        return (
            <Flex
                row
                gap={10}
                alignItems="center"
                style={{
                    flexGrow: 1,
                    flexShrink: 1,
                    flexBasis: 'auto',
                    overflow: 'hidden',
                    minWidth: 0,
                    ...props.style
                }}
            >
                {
                    props.variant === 'select' ? (
                        <>
                            <TimezoneIcon style={{ color: VL_BLACK() }}/>
                            <Dropdown
                                aria-label={props.t(translationKeys.common.TIMEZONE)}
                                style={{
                                    flexGrow: 1,
                                    fontSize: 16,
                                    textAlign: 'center',
                                    borderColor: 'currentColor',
                                    height: 27,
                                    borderRadius: 2,
                                    padding: '2px 10px',
                                    // Only show pointer cursor if there are options
                                    // Otherwise falls back to not-allowed when disabled
                                    cursor: props.options?.length > 1 ? 'pointer' : undefined
                                }}
                                variant="light"
                                onChange={event => props.onChange(event.target.value)}
                                value={props.value ?? Intl.DateTimeFormat().resolvedOptions().timeZone}
                                options={props.options?.length >= 1 ? props.options.map(name => ({
                                    text: formatTimeZone(name),
                                    value: name
                                })) : [{
                                    value: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                    text: formatTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone)
                                }]}
                                disabled={!props.options || props.options.length <= 1}
                            />
                        </>
                    ) : (
                        <>
                            <Button {...popupTriggerButtonProps} variant={props.variant ?? 'hover'} $isActive={popupProps.open} disabled={props.options?.length <= 1}>
                                <Span style={{ maxWidth: 370, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{formatTimeZone(props.value ?? Intl.DateTimeFormat().resolvedOptions().timeZone)}</Span>
                            </Button>
                            <PopupList
                                type='select'
                                name="Timezone selector"
                                {...props}
                                options={
                                    (props.options && props.options.length > 0) ? props.options?.map(o => o && ({
                                        value: o,
                                        label: `${formatTimeZone(o)}`,
                                        key: o
                                    })) : []
                                }
                                accentColour={accentColour}
                                {...popupProps}
                                width='fit-content'
                            />
                        </>
                    )
                }
                
            </Flex>
        );
    }
    return null;
};

export default withTranslation()(TimezoneSelector);
