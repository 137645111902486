import styled, { keyframes } from 'styled-components';
import { MID_GREY, VL_BLACK } from '../../utils/colours';

// Animation for loading graphic
const imageLoadingAnimation = imageWidth => keyframes`
    from {
        transform: translateX(-${imageWidth}px);
    }
    
    to  { 
        transform: translateX(${imageWidth * 2.125}px);
    }
`;

export const Loading = styled.div`

    /* To match image */
    width: ${({ $width }) => $width}px;
    min-width: ${({ $width }) => $width}px;
    height: ${({ $height }) => typeof $height === 'string' ? $height : `${$height}px`};
    
    /* Solid grey background */
    background: ${MID_GREY};
    position: relative;
    overflow: hidden;


    /* Gradient that moves across background */
    ::after {
        content: "";
        background: linear-gradient(to right, ${MID_GREY}, ${VL_BLACK(15)}, ${MID_GREY});
        width: ${({ $width }) => 0.525 * $width}px;
        top: 0;
        height: 100%;
        left: 0px;
        display: block;
        position: absolute;
        animation: ${({ $width }) => imageLoadingAnimation($width)} ${({ $width }) => $width / 53}s linear infinite;
    }
`;