import styled from 'styled-components';
import { WHITE } from '../../../utils/colours';

export const LABEL_WIDTH = 55; // In px


export const DiscreteSliderContainer = styled.div`
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;

    input[type="range"] {
        padding: 0 ${(LABEL_WIDTH - 20) / 2}px;
        color: white;
    }

    @media screen and (min-width: 660px) {
        width: ${({ $count, $wide }) => $count * LABEL_WIDTH * ($wide ? 2 : 1)}px;
    }
`;

DiscreteSliderContainer.displayName = 'SliderContainer';


export const LabelRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

LabelRow.displayName = 'LabelRow';


export const OptionLabel = styled.span`
    font-weight: 100;
    color: ${({ $selected, $accentColour }) =>
        $selected ? $accentColour : WHITE()};
    font-size: 14px;
    width: ${({ $wide, $count }) => $wide ? `${LABEL_WIDTH}px` : `calc(100% / ${$count})`};
    text-align: center;
    word-spacing: ${LABEL_WIDTH}px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    cursor: ${({ selected }) => (selected ? 'default' : 'pointer')};
`;

OptionLabel.displayName = 'Label';