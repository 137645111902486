import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { logError } from '../../utils/errors';

/**
 * Catches any error in a child component and shows a fallback UI.
 */
class ErrorBoundary extends React.Component {
    constructor() {
        super();

        this.state = {
            hasError: false,
        };

        this.reset = this.reset.bind(this);
    }

    static getDerivedStateFromError(error) {
        // Handle chunk load errors as a special case as this almost certainly means we did an update and user just needs to refresh
        return { hasError: true, newRelease: /^Loading chunk/.test(error.message) };
    }

    componentDidCatch(error, errorInfo) {
        logError(error, { identifier: 'ErrorBoundary', errorBoundaryMessage: this.props.message });
    }

    reset() {
        this.setState({ hasError: false });
    }

    render() {
        const { children, errorComponent, message } = this.props;

        if (this.state.hasError) {
            const ErrorComponent = errorComponent;
            return (
                <>
                    <LocationObserver reset={this.reset} />
                    <ErrorComponent message={message} newRelease={this.state.newRelease} />
                </>
            );
        }

        return children;
    }
}

// Reset `hasError` whenever location changes
// This stops us being stuck in error state
const LocationObserver = ({ reset }) => {
    const location = useLocation();
    const firstUpdate = useRef(true);

    useEffect(() => {
        // useEffect runs at start and then again when any value in dependency array changes
        // Only want to call reset() when location changes, NOT at start
        // firstUpdate records whether this is first useEffect call or not
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        reset();
    }, [location, reset]);

    return null;
};

ErrorBoundary.propTypes = {
    /** Error message to display. */
    message: PropTypes.string.isRequired,
    /** Component to render instead of children when an error occurs. */
    errorComponent: PropTypes.elementType.isRequired,
};

export default ErrorBoundary;
