import styled from 'styled-components';

import { Link } from 'react-router-dom';

const styles = (colour) => `
    font-weight: 100;
    text-decoration: underline;
    color: ${colour};

    @media (hover: hover) {
        :hover {
            text-decoration: none;
        }
    }
`;

export const RRLinkContainer = styled(Link)`
    ${({ $colour }) => styles($colour)}
`;

RRLinkContainer.displayName = 'RRLinkContainer';

export const AnchorContainer = styled.a`
    ${({ $colour }) => styles($colour)}
`;

AnchorContainer.displayName = 'AnchorContainer';
