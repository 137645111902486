import PropTypes from 'prop-types';

import ContainerStyles from './Container.styles';

/** Coloured rectangle used to group content. */
const Container = ({ colour = '#fff', children, ...props }) => {
    return (
        <ContainerStyles $colour={colour} {...props}>
            {children}
        </ContainerStyles>
    );
};

Container.propTypes = {
    /** CSS colour to use for background. */
    colour: PropTypes.string,
};

export default Container;
