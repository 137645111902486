import styled from 'styled-components';

import { DEFAULT_BACKGROUND_GRADIENT } from '../../../utils/colours';

export const Background = styled.div`
    position: fixed;
    z-index: -1;
    top: 0;
    width: 100%;
    height: 100vh;
    background: ${({ imageURL, gradient }) =>
        gradient ? gradient : imageURL ? `url(${imageURL}), ${DEFAULT_BACKGROUND_GRADIENT}` : DEFAULT_BACKGROUND_GRADIENT};
`;

Background.displayName = 'Background';

export const PageContainer = styled.main`
    max-width: 90%;
    margin: 50px auto;

    /* & > * {
        margin-bottom: 30px;
    } */

    ${({ narrow }) => narrow && `width: 490px;`}
`;

PageContainer.displayName = 'PageContainer';
