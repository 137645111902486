import styled from 'styled-components';
import { inputStyling } from '../../helpers';

import InputField from '../Input';

export const Container = styled.div`
    ${(props) => inputStyling(props)}

    display: flex;
    align-items: center;
    padding: 0;
    padding-left: 12px;
    position: relative;

    ${({ narrowWidth }) => (narrowWidth ? 'width: min-content;' : '')}

    ::before {
        content: '${({ $prefix }) => $prefix}';
    }
`;

Container.displayName = 'Container';

export const Input = styled(InputField)`
    border: none !important;
    height: 100% !important;

    :focus-visible {
        outline: none;
    }
`;

Input.displayName = 'Input';
