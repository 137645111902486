import { useEffect } from "react";

/**
 * Use this hook alongside ZoomableWrapper component. Allows zooming and panning to be done through keyboard presses.
 * @param {object} zoomControlsRef Ref applied to ZoomableWrapper.
 * @param {object} focusableContainerRef Ref applied to a parent element. When this element is in focus, the user will be able to zoom and pan using keyboard presses.
 * @param {boolean} disabled Disable keyboard controls (use in tandem with ZoomableWrapper `disabled` prop).
 */
const useKeyboardZoomControls = (zoomControlsRef, focusableContainerRef, disabled = false) => {
    useEffect(() => {
        if (!disabled && focusableContainerRef) {

            const focusableContainer = focusableContainerRef.current;

            const keyHandler = (event) => {
                const ARROW_STEP_SIZE = 40;
                const transformState = zoomControlsRef.current.instance.transformState;
                const bounds = zoomControlsRef.current.instance.bounds;

                switch (event.key) {
                    case '+':
                        zoomControlsRef.current.zoomIn();
                        event.preventDefault();
                        return;
                    case '-':
                        zoomControlsRef.current.zoomOut();
                        event.preventDefault();
                        return;
                    case 'ArrowRight':
                        zoomControlsRef.current.setTransform(Math.max(transformState.positionX - ARROW_STEP_SIZE, bounds.minPositionX), transformState.positionY, transformState.scale);
                        event.preventDefault();
                        return;
                    case 'ArrowLeft':
                        zoomControlsRef.current.setTransform(Math.min(transformState.positionX + ARROW_STEP_SIZE, bounds.maxPositionX), transformState.positionY, transformState.scale);
                        event.preventDefault();
                        return;
                    case 'ArrowUp':
                        zoomControlsRef.current.setTransform(transformState.positionX, Math.min(transformState.positionY + ARROW_STEP_SIZE, bounds.maxPositionY), transformState.scale);
                        event.preventDefault();
                        return;
                    case 'ArrowDown':
                        zoomControlsRef.current.setTransform(transformState.positionX, Math.max(transformState.positionY - ARROW_STEP_SIZE, bounds.minPositionY), transformState.scale);
                        event.preventDefault();
                        return;
                    default:
                        return;
                }
            }

            focusableContainer.addEventListener('keydown', keyHandler);

            return () => {
                focusableContainer.removeEventListener('keydown', keyHandler);
            };
        }
    }, [disabled, focusableContainerRef, zoomControlsRef]);
}

export default useKeyboardZoomControls;