import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { DARK_GREY, LIGHT_GREY, MID_GREY, VL_BLACK, WHITE } from '../../utils/colours';

export const Container = styled.nav`
    display: flex;
    max-width: 100%;
    overflow-x: auto;

    /*
        I've generally only applied top margins to components in this app and not bottom margins.
        Easiest in this particular case though to put bottom margin on tabs rather than top margin
        on page content.
        I don't like breaking the rule though so consider alternative solutions in future.
    */
    margin-bottom: 40px;
`;

Container.displayName = 'Container';

export const Tab = styled(NavLink)`
    font-weight: 100;
    padding: 15px 25px;
    text-decoration: none;
    border-bottom: 1px solid ${({ $useDefault }) => $useDefault ? WHITE(50) : WHITE()};
    font-size: ${({ $large }) => ($large ? 18 : 16)}px;
    color: ${({ $useDefault }) => $useDefault ?  WHITE(50) : WHITE()};
    text-align: center;
    display: inline-flex;
    align-items: center;

    &.selected {
        border-color: ${({ $accentColour, $useDefault }) => $useDefault ? WHITE() : $accentColour};
        color: ${({ $useDefault, $accentColour }) => $useDefault ? WHITE() : $accentColour};
    }
`;

Tab.displayName = 'Tab';
