import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

// Use this component to manage suggestions list popup
// The "Using UNSAFE_componentWillReceiveProps in strict mode is not recommended" warning comes from this library
import AutosuggestInput from 'react-autosuggest';

import translationKeys from '../../../translations/keys';

import Span from '../../span';
import Tags from '../../tags';
import { Container } from "./TagsInput.styles";


/** Form field for selecting tags. User can either select tags from a provided list or enter new ones. */
const TagsInput = ({ value, existingTags = [], restrictToExistingTagsOnly = false, onChange, required, descriptionId, t, tReady, placeholder, ...props }) => {

    // Value of input field
    const [inputValue, setInputValue] = useState("");

    // List of tag suggestions that match input value
    const [suggestions, setSuggestions] = useState([]);
    
    // Ref for input field
    const inputRef = useRef();

    // Need to call `onChange` with event object to match other field components
    const handleOnChange = (newValue) => {
        onChange({ target: { value: newValue, name: props.name }});
    }

    return (
        <Container {...props}>

            {/* Selected tags */}
            {
                value?.length > 0 && (
                    <Tags
                        style={{ marginBottom: 10 }}
                        tags={value}
                        large={props.large}
                        onRemove={(tag) => handleOnChange(value.filter(t => t !== tag))}
                    />
                )
            }

            {/* Input field */}
            <AutosuggestInput
                suggestions={suggestions}

                // Called when suggestions list needs updating
                onSuggestionsFetchRequested={({ value: inputValue }) => {
                    const suggestions = [];

                    // If there is an input value and this value does not match an existing tag, include option to add new tag
                    // Exception if `restrictToExistingTagsOnly` is true
                    if (!restrictToExistingTagsOnly && inputValue && !existingTags.includes(inputValue) && !value.includes(inputValue)) {
                        // New tag suggestion is the only array element that isn't a string
                        suggestions.push({
                            addNewTag: true,
                            // Use translations if they are loaded
                            label: tReady ? t(translationKeys.cameras.tags.ADD_NEW_TAG, { tag: inputValue }) : `Add tag "${inputValue}"`
                        });
                    }

                    // Get existing tags that match input value
                    suggestions.push(...getSuggestions(existingTags, inputValue));

                    // Store suggestions in state, excluding any that are already selected
                    setSuggestions(suggestions.filter(tag => !value.includes(tag)));
                }}

                // Reset suggestions
                onSuggestionsClearRequested={() => setSuggestions([])}

                // Get the value of a suggestion
                // This is shown in input field when user highlights suggestion using arrow keys
                getSuggestionValue={tag => tag?.addNewTag ? inputValue : tag}

                // Display suggestion
                renderSuggestion={tag => (
                    <Span>
                        {
                            // If this is the new tag option, show its label
                            // Otherwise `tag` will just be the string itself
                            tag?.label ?? tag
                        }
                    </Span>
                )}
                inputProps={{
                    // Use translations if they are loaded
                    placeholder: placeholder ?? (tReady ? t(translationKeys.cameras.tags.TYPE_TAG_HERE) : 'Type tag here'),

                    value: inputValue,
                    onChange: (event, { newValue }) => setInputValue(newValue),
                    onKeyDown: (event) => {
                        if (event.key === 'Enter') {
                            // Don't submit form
                            event.preventDefault();

                            if (inputValue.length === 0) {
                                // Make sure suggestions list is open when user presses enter
                                // This is keyboard equivalent of onBlur below
                                inputRef.current.blur();
                                inputRef.current.focus();
                            } else if ((!restrictToExistingTagsOnly || existingTags.includes(inputValue)) && inputValue.length > 0) {
                                // Add new tag if valid

                                // If input value is a valid tag, add it
                                if (!value.includes(inputValue)) {
                                    handleOnChange(value.concat(inputValue));
                                }

                                // Otherwise this tag has already been selected, so just clear input field
                                setInputValue('');
                            }
                        }
                    },
                    onBlur: () => {
                        // Add input value as a tag (if valid) on blur
                        // Laura wanted this as she thought anything left in the input field when user clicks to submit form should also get added as a tag
                        if (inputValue.length > 0 && (!restrictToExistingTagsOnly || existingTags.includes(inputValue)) && !value.includes(inputValue)) {
                            handleOnChange(value.concat(inputValue));
                            setInputValue('');
                        }
                    },

                    // If this is a required field, then at least one tag must be entered
                    // Whilst no tags are entered, put the `required` attribute on input field so browser stops form being submitted
                    required: required && value.length === 0,

                    'aria-describedby': descriptionId,
                    'aria-invalid': props.error,

                    ref: inputRef,
                    onClick: () => {
                        // When input field empty and in focus, show suggestions list on click
                        // When user enters a tag, suggestions list closes but input stays in focus so list doesn't open again on click
                        if (inputValue?.length === 0) {
                            // This seems a terrible way to achieve this but not sure of alternative
                            inputRef.current.blur();
                            inputRef.current.focus();
                        }
                    }
                }}

                // Always show tag list, even when no value in input field
                // Most of the time a user is adding a tag, they want to add an existing tag, not a new one
                // Therefore most useful to have full tag list show immediately rather than make user start typing
                shouldRenderSuggestions={() => true}

                // Called when tag is selected from list
                onSuggestionSelected={(event, { suggestion }) => {
                    if (suggestion.addNewTag) {
                        // If user has selected new tag option, add the input field value as a tag
                        handleOnChange(value.concat(inputValue));
                    } else if (!value.includes(suggestion)) {
                        // Otherwise if this is an existing tag that has not already been selected, add it
                        handleOnChange(value.concat(suggestion));
                    }
                    // Reset input field
                    setInputValue('');
                }}

                // Apply CSS classes
                theme={{
                    container: 'tagsinput-container',
                    input: 'tagsinput-input',
                    suggestionsContainer: 'tagsinput-suggestions-container',
                    suggestionsContainerOpen: 'tagsinput-suggestions-container-open',
                    suggestionsList: 'tagsinput-suggestions-list',
                    suggestion: 'tagsinput-suggestion',
                    suggestionFirst: 'tagsinput-suggestion-first',
                    suggestionHighlighted: 'tagsinput-suggestion-highlighted'
                }}

                variant={props.variant}
            />
        </Container>
    );
}

// Get list of suggestions to match input value
// Exported only for use in tests
export const getSuggestions = (tags, value) => {

    // If no value entered yet, show all tags
    if (value.length === 0) {
        return tags;
    }

    // Filter tag list by those whose start match input value
    return tags.filter(tag => tag.toLocaleLowerCase().slice(0, value.length) === value.toLocaleLowerCase());
}

TagsInput.propTypes = {
    /** Currently selected tags (i.e. the value of this field). */
    value: PropTypes.arrayOf(PropTypes.string).isRequired,
    /** List of existing tags that can be used to prompt user. User can either select one of these tags or enter a new one. */
    existingTags: PropTypes.arrayOf(PropTypes.string),
    /** Restrict user to only selecting from existing tags and not adding new ones. */
    restrictToExistingTagsOnly: PropTypes.bool,
    /** Function to call whenever user changes the field's value. Gets passed mock `event` object where `event.target.value` equals updated tag array. */
    onChange: PropTypes.func.isRequired,
    /** Value is invalid. Sets `aria-invalid` to true on input field and renders red border around it. */
    error: PropTypes.bool,
    /** Id of element describing this field. Will be included in `aria-describedby` attribute of input field. */
    descriptionId: PropTypes.string,
    /** If this field is marked as required, at least one tag must be entered. */
    required: PropTypes.bool,
    /** Whether the field should be a larger size. Input field and tags will be bigger. */
    large: PropTypes.bool,
    /** Whether input field should fill width of container. */
    fullWidth: PropTypes.bool,
    /** Overwrite placeholder for input field. */
    placeholder: PropTypes.string
};

export default withTranslation()(TagsInput);