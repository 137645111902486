import styled from 'styled-components';
import { VL_BLACK, WHITE } from '../../utils/colours';

export const Banner = styled.address`
    background-color: ${VL_BLACK()};
    color: ${WHITE()};
    font-style: normal;
    padding: 10px 30px;
    font-weight: 100;
    text-align: right;

    @media only screen and (max-width: 1300px) {
        text-align: center;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 999;
    }
`;

Banner.displayName = 'Banner';
