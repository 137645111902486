import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { forwardRef } from 'react';

import useWhiteLabelComponent from '../../hooks/useWhiteLabelComponent';
import useAsyncButtonAction from '../../hooks/useAsyncButtonAction';

import { TEXT_GREY, VL_BLACK, WHITE } from '../../utils/colours';

import Spinner from '../spinner';
import { StyledButton, TextButton } from './Button.styles';

/** Button component which supports both blue and purple gradients for primary buttons. Offers loading and disabled states, as well as two size options. */
const Button = forwardRef((props, ref) => {
    if (props.asyncAction) {
        return <AsyncActionButton {...props} ref={ref} />;
    } else {
        return <ButtonComponent {...props} ref={ref} />;
    }
});

const ButtonComponent = forwardRef(
    (
        {
            children,
            variant = 'primary',
            loading = false,
            type = 'button',
            large = false,
            link,
            marginTop = false,
            ...props
        },
        ref
    ) => {
        const { accentColour, accentColour2, useAccentColour2, whiteOverAccent, isVideoloft } = useWhiteLabelComponent();
        const alternativeTextColour = whiteOverAccent || isVideoloft ? WHITE() : VL_BLACK();

        // Don't put type='button' if not using button tag
        type = link || (props.as && props.as !== 'button') ? undefined : type;

        // Additional props if rendering as a link
        let linkProps = {};
        if (link) {
            // Use React Router's Link component if it is a relative link
            if (link.charAt(0) === '/') {
                linkProps.as = Link;
                linkProps.to = link;
            } else {
                linkProps.as = 'a';
                linkProps.href = link;
            }
        }

        if (variant === 'text' || variant === 'text-dark' || variant === 'inline') {
            if (loading) {
                return (
                    <Spinner colour={variant !== 'text-dark' ? WHITE() : TEXT_GREY} />
                );
            } else {
                return (
                    <TextButton
                        type={type}
                        ref={ref}
                        $large={large}
                        $marginTop={marginTop}
                        $variant={variant}
                        {...linkProps}
                        {...props}
                    >
                        {children}
                    </TextButton>
                );
            }
        } else {
            return (
                <StyledButton
                    $variant={variant}
                    $showingSpinner={loading}
                    type={type}
                    ref={ref}
                    $large={large}
                    $marginTop={marginTop}
                    // $gradients={gradients}
                    $backgroundColour={useAccentColour2 || isVideoloft ? (accentColour2 ?? accentColour) : accentColour}
                    $textColour={whiteOverAccent || isVideoloft ? WHITE() : VL_BLACK()}
                    $accentColour={accentColour}
                    $alternativeTextColour={alternativeTextColour}
                    {...linkProps}
                    {...props}
                    onClick={loading ? undefined : props.onClick}
                >
                    {children}
                    {loading && (
                        <Spinner
                            colour={/secondary/.test(variant) ? '#000' : /danger/.test(variant) ? '#F00' : (whiteOverAccent || isVideoloft ? WHITE() : VL_BLACK())}
                            style={{ position: 'absolute' }}
                        />
                    )}
                </StyledButton>
            );
        }
    }
);

const AsyncActionButton = forwardRef(({ asyncAction, ...props }, ref) => {
    const asyncActionProps = useAsyncButtonAction(asyncAction);

    return <ButtonComponent {...asyncActionProps} {...props} ref={ref} />;
});

Button.displayName = 'Button';

Button.propTypes = {
    /** Function executed when Button is clicked. */
    onClick: PropTypes.func,
    /** Type of button. 'primary' and 'secondary' are rounded buttons with background. 'text' is text only with no background, padding, etc. 'inline' is same as 'text' but displays inline and inherits font size and colour. */
    variant: PropTypes.oneOf([
        'primary',
        'primary-1',
        'primary-2',
        'secondary',
        'secondary-grey',
        'secondary-white',
        'secondary-transparent',
        'secondary-whitewash',
        'danger',
        'text',
        'text-dark',
        'inline',
        'hover',
        'select'
    ]),
    /** Whether button is loading. Text is replaced by a loading spinner and the button cannot be clicked. */
    loading: PropTypes.bool,
    /** Whether button is disabled. */
    disabled: PropTypes.bool,
    /** Whether button should be a larger size. */
    large: PropTypes.bool,
    /** HTML button type attribute. Will not be used if `<button>` tag is not being used. */
    type: PropTypes.oneOf(['button', 'reset', 'submit']),
    /** Render as `<a>` tag instead. This value will be used as href if external link, or `to` prop passed to React Router's Link component if relative link. */
    link: PropTypes.string,
    /** Add top margin. */
    marginTop: PropTypes.bool,
    /** Function called on click. It takes one argument which is a callback that must be invoked when function has finished. Button will show loading state until callback invoked. Do not use `onClick` when using this prop. */
    asyncAction: PropTypes.func,
};

export default Button;
