import styled from 'styled-components';

import { LIGHT_GREY, VL_BLACK } from '../../../utils/colours';
import { FIELD_WIDTH, inputStyling } from '../helpers';


export const Container = styled.div`

    .tagsinput-container {
        position: relative;
    }

    .tagsinput-input {
        ${(props) => inputStyling(props)}
    }

    .tagsinput-suggestions-container {
        display: none;
    }

    .tagsinput-suggestions-container-open {
        display: block;
        width: ${FIELD_WIDTH}px;
        position: absolute;
        top: 100%;
        
        z-index: 1;

        // Make sure there is 5px gap to bottom of page
        &::after {
            content: "";
            display: block;
            height: 5px;
        }
    }

    .tagsinput-suggestions-list {
        margin: 0;
        padding: 0;
        
        background: ${LIGHT_GREY};
        color: ${VL_BLACK()};
        border: 1px solid #ccc;

        max-height: 200px;
        overflow: auto;
    }

    .tagsinput-suggestion {
        cursor: pointer;
        border-top: 1px solid #d3d2d2;
        padding: 5px;
        font-size: 14px;
        list-style: none;
    }

    .tagsinput-suggestion-first {
        cursor: pointer;
        border-top: none;
    }

    .tagsinput-suggestion-highlighted {
        background: #f5f5f5;
    }
`;

Container.displayName = 'Container';