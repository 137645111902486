import styled from 'styled-components';

export const SeparatorContainer = styled.div`
    ${({$vertical}) => $vertical ? 'width: 2px;' : 'height: 2px;'}
    background-color: ${({$colour}) => $colour};
    margin: 0 30px;

    ${({$hideOnMobile}) => $hideOnMobile ? '@media screen and (max-width: 768px) { display: none; }' : ''}
`;

SeparatorContainer.displayName = 'SeparatorContainer';