import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { testInternetConnection } from "../../api/network";

import translationKeys from '../../translations/keys';

import Heading from "../heading";
import P from "../p";

const NetworkError = ({ tReady, t }) => {

    // Time in seconds until we test connection again
    const [timeUntilTest, setTimeUntilTest] = useState(30);

    // Runs every second
    useEffect(() => {
        if (timeUntilTest > 0) {
            // Decrement time after one second
            const timeout = setTimeout(() => setTimeUntilTest(s => s - 1), 1000);
            return () => clearTimeout(timeout);
        } else {
            // When time reaches 0, test connection
            const runTest = async () => {
                if (testInternetConnection()) {
                    // Success
                    // Reload page to restart app
                    window.location.reload();
                } else {
                    // Fail
                    // Restart timer
                    setTimeUntilTest(30);
                }
            }
            runTest();
        }
    }, [timeUntilTest]);

    return (
        <div style={{ margin: 30 }}>
            <Heading>{ tReady ? t(translationKeys.network.NETWORK_ERROR) : 'Network error'}</Heading>
            <P>
                {
                    timeUntilTest > 0 ? (
                        tReady ? t(translationKeys.network.TRYING_TO_RECONNECT_IN, { seconds: timeUntilTest }) : `Trying to reconnect you in ${timeUntilTest}s...`
                    ) : (
                        tReady ? t(translationKeys.network.TRYING_TO_RECONNECT) : 'Trying to reconnect you...'
                    )
                }
            </P>
        </div>
    );
}

export default withTranslation()(NetworkError);