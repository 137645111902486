import styled from 'styled-components';
import { LIGHT_GREY, VL_BLACK } from '../../utils/colours';

const border = `1px solid #d3d2d2`;

export const PopupContainer = styled.div`
    background: ${LIGHT_GREY};
    color: ${VL_BLACK()};
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    z-index: 500;
    width: ${({ $width }) => $width}px;
    border-radius: 10px;
    padding: ${({ $noPadding }) => $noPadding ? 0 : '10px'};

    border: ${border};
    box-shadow: 0 0 20px 0 #6b6b6b26;

    ${({ $maxHeight }) =>
        typeof $maxHeight === 'number'
            ? `max-height:${$maxHeight}px;overflow-y:auto;`
            : ''}
`;

PopupContainer.displayName = 'PopupContainer';


export const Arrow = styled.div`
    position: absolute;
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;

    ${({ $placement, size }) => {
        // Remove suffixes "-start" and "-end"
        const placement = $placement?.split('-')[0];
        switch(placement) {
            case 'bottom':
            default:
                return `top: -${size / 2}px;`;
            case 'top':
                return `bottom: -${size / 2}px;`;
            case 'right':
                return `left: -${size / 2}px;`;
            case 'left':
                return `right: -${size / 2}px;`;
        }
    }}

    &:after {
        content: " ";
        background-color: ${LIGHT_GREY};
        position: absolute;
        transform: rotate(45deg);
        width: ${({ size }) => size}px;
        height: ${({ size }) => size}px;
        left: 0;
        top: 0;

        box-shadow: ${({ $placement }) => {
            switch($placement) {
                case 'bottom':
                default:
                    return '-1px -1px 1px rgba(0, 0, 0, 0.1)';
                case 'top':
                    return '1px 1px 1px rgba(0, 0, 0, 0.1)';
                case 'right':
                    return '-1px 1px 1px rgba(0, 0, 0, 0.1)';
                case 'left':
                    return '1px -1px 1px rgba(0, 0, 0, 0.1)';
            }
        }};
    }
`;

Arrow.displayName = 'Arrow';