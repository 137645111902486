import PropTypes from 'prop-types';

import { RadioButtonContainer } from './RadioButton.styles';

/**
 * Individual radio button. Ensure that the RadioButtonGroup component (still to be developed) does not fit your needs before using this component.
 * _Note: This component's `value` prop will not be used as its HTML `value` attribute. This is so the component is compatible with our `useForm` custom hook. Instead the `buttonValue` prop is used as the HTML `value` attribute._
 */
const RadioButton = ({ value, buttonValue, onChange, ...props }) => {
    return (
        <RadioButtonContainer
            type="radio"
            value={buttonValue}
            checked={value === buttonValue}
            onChange={onChange}
            {...props}
        />
    );
};

RadioButton.propTypes = {
    /** Unique value associated with this radio button. _Important: This is what will be used as the button's HTML `value` attribute, not the prop named `value`!_*/
    buttonValue: PropTypes.string.isRequired,
    /** Value of the radio button's group. _Important: This is only used to determine if this button is the selected one and will not be used as the button's HTML `value` attribute._ */
    value: PropTypes.string,
    /** Function called when radio button is selected. */
    onChange: PropTypes.func,
};

export default RadioButton;
