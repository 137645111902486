import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import translationKeys from '../../translations/keys';

import { FullScreenLayout } from '../../components/layout-managers';
import { ButtonsRow, Container } from '../../components/layout';
import WhiteLabel, { useWhiteLabelInfo } from '../../components/white-label';
import ErrorScreen from '../../components/error-screen';

import { VL_BLACK } from '../../utils/colours';

const ErrorPage = ({ message, newRelease, t, ...props }) => {
    const { returnLink } = useWhiteLabelInfo();

    return (
        <FullScreenLayout {...props}>
            <Container colour={VL_BLACK(90)}>
                <ErrorScreen
                    message={message}
                    newRelease={newRelease}
                    style={{
                        width: 'auto',
                        height: 'auto',
                        position: 'relative',
                        textAlign: 'center',
                    }}
                />
                <ButtonsRow centre large>
                    <WhiteLabel.Button link={returnLink || '/'} large>
                        {t(translationKeys.navigation.RETURN)}
                    </WhiteLabel.Button>
                </ButtonsRow>
            </Container>
        </FullScreenLayout>
    );
};

ErrorPage.propTypes = {
    /** Error message to display. */
    message: PropTypes.string.isRequired,
    /** If true, alternative message prompting user to refresh the page will be shown instead. */
    newRelease: PropTypes.bool
};

export default withTranslation()(ErrorPage);
