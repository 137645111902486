import PropTypes from 'prop-types';
import useWhiteLabelComponent from '../../../hooks/useWhiteLabelComponent';

import ContactDetailsBanner from '../../contact-details-banner';
import WhiteLabel from '../../white-label';
import { Background, PageContainer } from './FullScreenLayout.styles';

/** Manages layout for full screen pages like /sign-in and /register. Must fall below WhiteLabel provider in the tree. */
const FullScreenLayout = ({ children, narrow = true, ...props }) => {
    const { background, backgroundGradient } = useWhiteLabelComponent();

    return (
        <>
            <Background gradient={backgroundGradient} imageURL={background} data-testid="background" />
            <PageContainer narrow={narrow} {...props}>
                <WhiteLabel.Logo
                    link
                    variant="fullScreen"
                    maxWidth="min(95%, 315px)"
                    maxHeight="min(170px, 20vh)"
                    style={{ margin: '30px auto 60px', textAlign: 'center' }}
                />
                {children}
            </PageContainer>
        </>
    );
};

// Adds contact details banner above full screen page
export const FullScreenLayoutWithContactBanner = ({ narrow=true, children }) => (
    <>
        <ContactDetailsBanner />
        <FullScreenLayout narrow={narrow}>{children}</FullScreenLayout>
    </>
);

FullScreenLayout.propTypes = {
    narrow: PropTypes.bool,
};

export default FullScreenLayout;
