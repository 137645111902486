// Groups uidds by logger
// Used by cameras.js and events.js
// uiddsAndLoggers is array of objects each containing keys 'uidd' and 'logger', e.g. [{ uidd: 1, logger: '...' }]
// Returns object where keys are loggers and each value is array of uidds using that logger
// e.g. [{ uidd: 1, logger: 'logger1' },{ uidd: 2, logger: 'logger2' },{ uidd: 3, logger: 'logger2' }] => { 'logger1': [1], 'logger2': [2,3] }
export const getUiddsPerLogger = (uiddsAndLoggers) => {
    const uiddsPerLogger = {};

    uiddsAndLoggers.forEach(({ uidd, logger }) => {
        if (!uiddsPerLogger[logger]) {
            uiddsPerLogger[logger] = [];
        }

        uiddsPerLogger[logger].push(uidd);
    });

    return uiddsPerLogger;
};
