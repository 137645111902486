// Returns src if it is a valid path to an image
// Returns fallback if not
const imagePromise = (src, fallback) => {
    return new Promise((resolve, reject) => {
        const i = new Image();

        i.onload = () => {
            i.decode ? i.decode().then(resolve).catch(reject) : resolve();
        };

        i.onerror = reject;
        i.src = src;
    })
        .then(() => src)
        .catch(() => fallback);
};

export default imagePromise;

export const imageLoadsSuccessfully = (src) => {
    return new Promise((resolve, reject) => {
        const i = new Image();

        i.onload = () => {
            i.decode ? i.decode().then(resolve).catch(reject) : resolve();
        };

        i.onerror = reject;
        i.src = src;
    })
        .then(() => true)
        .catch(() => false);
};

export const getImageSrcForBase64 = base64 => `data:image;base64,${base64}`;