import _ from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import Button from '../button';
import Navbar from '../navbar';

import { accountType } from '../../types';
import routes from '../../pages/routes';
import translationKeys from '../../translations/keys';
/**
 * Header displayed on every page. Includes logo, navlinks (if signed in) and Sign In/Out button. Displays navlinks as specified by `src/pages/routes.js`.
 * This component must be a child of both the WhiteLabel component and React Router BrowserRouter component, and requires translations to be set up.
 */
const Header = ({ t, accountType, logout, ...props }) => {
    // Used by Log In button
    const location = useLocation();

    // Navbar links different based on whether user is logged in or not
    const links = accountType
        ? // Logged in so shows links
          // Filter out pages for which this user does not have access and/or is not to be included in navbar
          _.filter(
              routes,
              ({ navbar, restrictAccess }) =>
                  navbar &&
                  (!restrictAccess || restrictAccess.includes(accountType))
          )
              .map(({ translationKey, path }) => ({
                  path,
                  text: t(translationKey),
              }))
              // Add Sign out button
              .concat([
                  () => (
                      <Button
                          key="signout"
                          data-testid="signout"
                          asyncAction={logout}
                      >
                          {t(translationKeys.login.LOG_OUT)}
                      </Button>
                  ),
              ])
        : // Not loggged in so only show Log in button
          [
              () => (
                  <Button
                      to={
                          // Return to the current page after sign in
                          // Don't want to this for 404 page though so only pass current location in state.from if current page exists
                          _.some(routes, ({ allPaths }) =>
                              allPaths.includes(location.pathname)
                          )
                              ? {
                                    pathname: routes.signIn.path,
                                    state: { from: location },
                                }
                              : routes.signIn.path
                      }
                      key="signin"
                      as={Link}
                  >
                      {t(translationKeys.login.SIGN_IN)}
                  </Button>
              ),
          ];

    return <Navbar as="header" links={links} {...props} />;
};

Header.propTypes = {
    /** User (u), installer (i), distributor (d) or staff (s). */
    accountType,
    /** Function passed down from App component to call on logout. */
    logout: PropTypes.func.isRequired,
};

export default withTranslation()(Header);
